import { Channels } from 'core/dashboards/types';

import { formatPrice, formatSafely } from 'utils/formatters';


export const getTooltipText = (realValue: number | null, campaignValue: number | null, channel: Channels, isGeoBudget: boolean) => {
  if (realValue === null) {
    if (channel === Channels.APPLOVIN && isGeoBudget) {
      return '';
    }

    if (campaignValue !== null) {
      return 'Campaign budget';
    }
  }

  return '';
};

export const getBudgetText = (value: number | null, campaignValue: number | null, channel: Channels, isGeoBudget: boolean, rounding: number | null) => {
  let valueToFormat = value;
  if (value === null) {
    if (channel === Channels.APPLOVIN && isGeoBudget) {
      return 'Geo';
    }

    valueToFormat = campaignValue;
  }

  return formatSafely(formatPrice, { rounding })({ value: valueToFormat });
};
