import { memo, FunctionComponent, useMemo } from 'react';

import Button from '@mui/material/Button';

import { BUTTON_ACTIVE_CLASS } from 'containers/theme-provider/components-overrides/button';

import { ActionButtonProps } from './types';

import { StyledActionButton } from './styles';


const ActionButton: FunctionComponent<ActionButtonProps> = memo(({ children, isActive, isSmall, ...otherProps }) => {
  const classes = useMemo(() => ({
    text: isActive ? BUTTON_ACTIVE_CLASS : '',
  }), [isActive]);

  return (
    <StyledActionButton
      as={Button}
      variant="text"
      classes={classes}
      $sm={isSmall}
      {...otherProps}
    >
      {children}
    </StyledActionButton>
  );
});


ActionButton.displayName = 'ActionButton';


export default ActionButton;
