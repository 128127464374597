import React, { memo } from 'react';

import PlatformIcon from 'components/common/platform-icon';

import { Props } from './types';
import { Wrapper, IconWrapper, ContentWrapper } from './styles';


const PlatformContainer: React.FunctionComponent<Props> = memo(({ children, platform }) => (
  <Wrapper>
    <IconWrapper>
      <PlatformIcon platform={platform} />
    </IconWrapper>

    {children && (
      <ContentWrapper>{children}</ContentWrapper>
    )}
  </Wrapper>
));


PlatformContainer.displayName = 'PlatformContainer';


export default PlatformContainer;
