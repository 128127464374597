import { SavedFilter } from 'core/users/types';


export interface Props {
  onSave: (values: SaveFilterFormValues, isEditMode: boolean) => void;
  onClose: () => void;
  isOpen: boolean;
  filter?: SavedFilter | null;
}

export interface StyledInputProps {
  $isMultiline?: boolean;
}

export interface StyledTitleProps {
  $isActive: boolean;
  $isDisabled?: boolean;
}

export enum Fields {
  Name = 'name',
  Description = 'description',
}

export type SaveFilterFormValues = Record<Fields, string>;
