import { State } from 'core/types';
import { KEY } from './constants';


const getLocalState = (state: State) => state[KEY];

export const getAppSettings = (state: State) => getLocalState(state).fetchAll.data;
export const getAppSettingsIsLoading = (state: State) => getLocalState(state).fetchAll.isLoading;
export const getAppSettingsError = (state: State) => getLocalState(state).fetchAll.error;

export const getAppSetting = (state: State) => getLocalState(state).fetchOne.data;
export const getAppSettingIsLoading = (state: State) => getLocalState(state).fetchOne.isLoading;
export const getAppSettingError = (state: State) => getLocalState(state).fetchOne.error;
