import { FunctionComponent, memo, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IconButton, MenuItem, MenuList } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { getAuthorizedUserPermissions, getAuthorizedUserRole } from 'core/users/selectors';
import { UserRole, UserStatus } from 'core/users/types';
import AdminUsersService from 'core/admin/users/service';
import { actions as adminUsersAction } from 'core/admin/users/actions';
import Dropdown from 'components/common/dropdown';
import ConfirmationModal from 'components/common/confirmation-modal';
import { ButtonProps as DropdownButtonProps } from 'components/common/dropdown/types';
import { ROUTES } from 'containers/router/constants';
import SnackbarService from 'services/snackbar';

import { Props } from './types';


const UsersRowAction: FunctionComponent<Props> = memo(({ user }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const authRole = useSelector(getAuthorizedUserRole);
  const authPermissions = useSelector(getAuthorizedUserPermissions);

  // const handleClickEditUser = useCallback(() => {
  //   navigate(ROUTES.USERS_EDIT.replace(':userId', user.id.toString()));
  // }, [user, navigate]);

  const [isOpenResetPasswordConfirmationModal, setIsOpenResetPasswordConfirmationModal] = useState(false);
  const handleClickResetPassword = useCallback(() => {
    setIsOpenResetPasswordConfirmationModal(true);
  }, [setIsOpenResetPasswordConfirmationModal]);
  const handleCloseResetPasswordConfirmationModal = useCallback(() => {
    setIsOpenResetPasswordConfirmationModal(false);
  }, [setIsOpenResetPasswordConfirmationModal]);
  const handleSubmitResetPassword = useCallback(() => {
    AdminUsersService.resetPassword({
      userId: user.id,
    }).then(() =>{
      SnackbarService.showSuccess(
        `Reset password instruction email has been sent to ${user.email}.`,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    }).catch((e: any) =>{
      const message = e.response?.data ?? (e as Error).message;
      SnackbarService.showError(
        message,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    });
    handleCloseResetPasswordConfirmationModal();
  }, [user, handleCloseResetPasswordConfirmationModal]);

  const [isOpenUpdateStatusConfirmationModal, setIsOpenUpdateStatusConfirmationModal] = useState(false);
  const updateStatusTarget = useMemo(() => (
    user.status == UserStatus.ACTIVE ? UserStatus.SUSPENDED : UserStatus.ACTIVE
  ), [user]);
  const handleClickUpdateStatus = useCallback(() => {
    setIsOpenUpdateStatusConfirmationModal(true);
  }, [setIsOpenUpdateStatusConfirmationModal]);
  const handleCloseUpdateStatusConfirmationModal = useCallback(() => {
    setIsOpenUpdateStatusConfirmationModal(false);
  }, [setIsOpenUpdateStatusConfirmationModal]);
  const handleSubmitUpdateStatus = useCallback(() => {
    AdminUsersService.updateStatus({
      userId: user.id,
      data: {
        status: updateStatusTarget,
      },
    }).then(() =>{
      SnackbarService.showSuccess(
        `User ${user.fullName} status updated to ${updateStatusTarget}.`,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
      dispatch(adminUsersAction.fetchAll());
    }).catch((e: any) =>{
      const message = e.response?.data ?? (e as Error).message;
      SnackbarService.showError(
        message,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    });
    handleCloseUpdateStatusConfirmationModal();
  }, [user, handleCloseUpdateStatusConfirmationModal, dispatch]);

  const renderButtton = useCallback((props: DropdownButtonProps, isOpen: boolean) => (
    <IconButton
      {...props}
    >
      <MenuIcon />
    </IconButton>
  ), []);

  return (
    <>
      <Dropdown
        renderButton={renderButtton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx:{ padding: '4px 0' },
        }}
      >
        <MenuList
          sx={{
            padding: '0',
            '& .MuiMenuItem-root': {
              padding: '4px 12px',
              borderBottom: 0,

              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                color: 'inherit',
              },
            },
          }}
        >
          {
            (
              authRole === UserRole.SuperAdmin
              || (user.role !== UserRole.SuperAdmin && authPermissions.indexOf('editUser') !== -1)
            ) && (
              <MenuItem
                sx={{
                  padding: '0 !important',
                }}
              >
                <Link
                  to={`${ROUTES.USERS_EDIT.replace(':userId', user.id.toString())}`}
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    padding: '4px 12px',
                    width: '100%',
                  }}
                >Edit User</Link>
              </MenuItem>
            )
            // <MenuItem onClick={handleClickEditUser} >Edit User</MenuItem>
          }
          <MenuItem onClick={handleClickResetPassword}>Reset Password</MenuItem>
          {(authRole === UserRole.SuperAdmin) && <MenuItem disabled >Reset 2FA</MenuItem>}
          {
            !(user.role == UserRole.SuperAdmin && authRole == UserRole.Admin) && (
              <MenuItem onClick={handleClickUpdateStatus}>{updateStatusTarget == UserStatus.ACTIVE ? 'Activate' : 'Suspend'} User</MenuItem>
            )
          }
          <MenuItem
            sx={{
              padding: '0 !important',
            }}
          >
            <Link
              to={`${ROUTES.ACTIVITIES_INDEX}?userId=${user.id}`}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                padding: '4px 12px',
                width: '100%',
              }}
            >View User Activity</Link>
          </MenuItem>
        </MenuList>
      </Dropdown>
      <ConfirmationModal
        isOpen={isOpenResetPasswordConfirmationModal}
        onClose={handleCloseResetPasswordConfirmationModal}
        onSubmit={handleSubmitResetPassword}
        title={`Send reset password instruction to ${user.email}`}
        confirmText="Confirm"
      />
      <ConfirmationModal
        isOpen={isOpenUpdateStatusConfirmationModal}
        onClose={handleCloseUpdateStatusConfirmationModal}
        onSubmit={handleSubmitUpdateStatus}
        title={`Update ${user.fullName} status to ${updateStatusTarget}`}
        confirmText="Confirm"
      />
    </>
  );
});

UsersRowAction.displayName = 'UsersRowAction';

export default UsersRowAction;
