import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({

}));

export const TabWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1.75),
}));


export const StyledCollapse = styled(Collapse)((props) => ({
  ...(props.in ? {
    marginBottom: props.theme.spacing(1.25),
  } : {}),
}));
