import '/node_modules/flag-icons/css/flag-icons.min.css'; // eslint-disable-line import/no-unresolved

import { memo, FunctionComponent } from 'react';

import AdaptiveText from 'components/common/adaptive-text';

import { ReactComponent as UnknownFlag } from 'assets/images/unknown-flag.svg';

import { Props } from './types';
import { getCountry, convertCode } from './utils';
import { Wrapper, IconWrapper } from './styles';


const Country: FunctionComponent<Props> = memo(({ code, isCompact = false, useCountryName = true }) => {
  const { id, name, isUnknown } = getCountry(code);

  if (isCompact) {
    return (
      <>
        {name}
      </>
    );
  }

  return (
    <Wrapper
      title={name}
    >
      <IconWrapper>
        {
          isUnknown ? (
            <UnknownFlag />
          ) : (
            <span
              className={`fi fi-${id}`}
            />
          )
        }
      </IconWrapper>
  
      <AdaptiveText
        variant="body1"
      >
        {useCountryName ? name : convertCode(code)}
      </AdaptiveText>
    </Wrapper>
  );
});


Country.displayName = 'Country';


export default Country;
