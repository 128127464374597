import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DataGridPro, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Box, Button, Chip, Skeleton, Stack, Typography } from '@mui/material';

import { actions as adminPermissionTemplatesAction } from 'core/admin/permission-templates/actions';
import { getPermissionTemplates, getPermissionTemplatesIsLoading } from 'core/admin/permission-templates/selectors';
import { UserRole, UserRoleDisplay } from 'core/users/types';
import { ROUTES } from 'containers/router/constants';

import PermissionsRowAction from './row-action';


const PermissionsIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const permissionTemplates = useSelector(getPermissionTemplates);
  const isLoading = useSelector(getPermissionTemplatesIsLoading);

  useEffect(() => {
    dispatch(adminPermissionTemplatesAction.fetchAll());
  }, [dispatch]);

  const getRoleDisplay = useCallback((params: GridValueGetterParams) => UserRoleDisplay[params.row.role as UserRole], []);

  const handleClickCreate = useCallback(() => {
    navigate(ROUTES.PERMISSIONS_CREATE);
  }, [navigate]);

  const columns = useMemo<GridColDef[]>(() => [
    {
      field: 'name',
      headerName: 'Permission Template',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction='row'
          alignItems='center'
          gap='4px'
        >
          <Typography>{params.row.name}</Typography>
          {
            params.row.isDefault &&
              <Chip
                label="default"
                size='small'
              />
          }
        </Stack>
      ),
    },
    {
      field: 'role',
      headerName: 'User Type',
      minWidth: 100,
      flex: 1,
      valueGetter: getRoleDisplay,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => <PermissionsRowAction permissionTemplate={params.row} />,
      renderHeader: () => (
        <Button
          size="small"
          variant="contained"
          onClick={handleClickCreate}
        >Create</Button>
      ),
    },
  ], [getRoleDisplay, handleClickCreate]);

  const LoadingSkeleton = useCallback(() => (
    <Box
      sx={{
        height: 'max-content',
      }}
    >
      {[...Array(10)].map((_, i) => (
        <Skeleton
          variant='rectangular'
          sx={{ my: 4, mx: 1 }}
          key={i}
        />
      ))}
    </Box>
  ), []);

  return (
    <div>
      <div style={{ height: 600, width: '100%' }}>
        <DataGridPro
          rows={isLoading ? [] : permissionTemplates}
          columns={columns}
          autoHeight={true}
          disableColumnMenu
          loading={isLoading}
          components={{
            LoadingOverlay: LoadingSkeleton,
          }}
        />
      </div>
    </div>
  );
};

PermissionsIndex.displayName = 'PermissionsIndex';

export default PermissionsIndex;
