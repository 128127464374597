import { styled } from '@mui/material/styles';

import { StyledButtonProps, StyledIconProps } from './types';


export const StyledIcon = styled('div')<StyledIconProps>(({ theme, $isExpanded }) => ({
  transform: `rotate(${$isExpanded ? -90 : 90}deg)`,
  transition: theme.transitions.create(['transform', 'color']),
  color: theme.palette.grey[500],
}));

export const StyledButton = styled('button')<StyledButtonProps>(({ theme, $isExpanded }) => ({
  // width: EXPAND_BUTTON_WIDTH,
  height: 40,
  border: 'none',
  backgroundColor: theme.palette.common.white,
  justifyContent: 'space-between',
  textTransform: 'none',
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  transition: `${theme.transitions.create('color')}${!$isExpanded ? `,${theme.transitions.create('border-radius')}` : ''}`,
  ...theme.typography.subtitle2,

  ...($isExpanded ? {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  } : {}),

  '&:hover': {
    color: theme.palette.primary.main,
    border: 'none',
    backgroundColor: theme.palette.common.white,

    [StyledIcon]: {
      color: theme.palette.primary.main,
    },
  },

  '& + &': {
    marginLeft: theme.spacing(1.25),
  },
}));
