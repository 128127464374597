import React, { memo, useEffect, useCallback, useState, useMemo } from 'react';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import SnackbarService from 'services/snackbar';

import { SnackbarConfig } from './types';
import { AUTOHIDE_TIMEOUT, POSITION } from './constants';


const Snackbar = memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState<SnackbarConfig | undefined>(undefined);

  const handleOpen = useCallback((nextConfig) => {
    setConfig(nextConfig);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const ClickAwayListenerProps = useMemo(() => {
    if (config?.autoHideDuration === null) {
      return {
        onClickAway: () => null,
      };
    }

    return {};
  }, [config?.autoHideDuration]);

  useEffect(() => SnackbarService.subscribe(handleOpen), []); // eslint-disable-line react-hooks/exhaustive-deps

  const Component = config?.component;

  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={config?.autoHideDuration === undefined ? AUTOHIDE_TIMEOUT : config.autoHideDuration}
      onClose={handleClose}
      anchorOrigin={config?.position || POSITION}
      ClickAwayListenerProps={ClickAwayListenerProps}
    >
      {
        config && (
          <MuiAlert
            onClose={handleClose}
            severity={config.mode}
            sx={{ width: '100%' }}
          >
            {
              Component ? <Component /> : config.message
            }
          </MuiAlert>
        )
      }
    </MuiSnackbar>
  );
});


Snackbar.displayName = 'Snackbar';


export default Snackbar;
