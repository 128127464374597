import { useCallback, useState, useEffect, useMemo } from 'react';
import memoize from 'lodash.memoize';
import update, { Spec } from 'immutability-helper';
import { useDispatch, useSelector } from 'react-redux';
import { GridRowModel } from '@mui/x-data-grid-premium';

import { CHILDREN_ENTITIES, DEFAULT_PAGE_SIZE } from 'core/dashboards/constants';
import { getCurrentConfig, getSelectedFilters, getFiltersListBasedOnPublishMode } from 'core/dashboards/selectors';
import { getPageSizeByBreakdown } from 'core/dashboards/utils';
import dashboardsActions from 'core/dashboards/actions';
import { Entities, ColumnFilterModel } from 'core/dashboards/types';
import { getSavedViews, getActiveViews, getIsSavedViewsLoaded } from 'core/users/selectors';
import { deleteUserViewSlice, authorizedUserSlice, updateUserViewSlice, createUserViewSlice } from 'core/users/slices';
import usersActions from 'core/users/actions';
import { UpdateUserViewPayload, SavedViewMergedWithFields, SavedViewConfig } from 'core/users/types';
import { BreakdownOption } from 'core/settings/types';

import { isObjectChanged } from 'utils';
import AnalyticsService from 'services/analytics';

import usePrevios from 'hooks/use-previous';
import useQueryParams from 'hooks/use-query-params';

import DashboardHeader from 'components/dashboard/header';
import Tabs from 'components/common/tabs';
import { getActiveView as baseGetActiveView } from 'components/dashboard/grid/utils';

import { ViewConfigValues } from './types';
import { tabConfig, nextTabHandlers } from './config';
import {
  getTabKeyByIndex,
  isParentSelectionChanged,
  getUpdatedView,
  getTabIndexByKey,
  getOtherSelection,
  addFilterToCurrentConfig,
  mergeFilters,
} from './utils';
import { QUERY_PARAMS, QUERY_PARAMS_WITH_TYPES, ENTITIES_BY_COUNT_ROW_KEYS, ALLOWED_COUNT_ROW_TABS, FILTER_ID_BY_COUNT_ROW_NEXT_TAB } from './constants';
import { Wrapper } from './styles';


const Dashboard = () => {
  const dispatch = useDispatch();

  const currentConfig = useSelector(getCurrentConfig);
  const savedViews = useSelector(getSavedViews);
  const isSavedViewsLoaded = useSelector(getIsSavedViewsLoaded);
  const isSavedViewUpdateFetching = useSelector(updateUserViewSlice.selectors.getIsFetching);
  const isSavedViewDeleteFetching = useSelector(deleteUserViewSlice.selectors.getIsFetching);
  const user = useSelector(authorizedUserSlice.selectors.getData);
  const activeViews = useSelector(getActiveViews);
  const selectedFilters = useSelector(getSelectedFilters);
  const availableFilters = useSelector(getFiltersListBasedOnPublishMode);

  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAMS_WITH_TYPES);

  const [currentTab, setCurrentTab] = useState<number>(queryParams[QUERY_PARAMS.TAB] || 0);

  const [isForcedSelection, setIsForcedSelection] = useState(false);

  const previousConfig = usePrevios(currentConfig);

  const getActiveView = useCallback((tab: Entities) => baseGetActiveView(savedViews?.[tab] || [], activeViews[tab]), [savedViews, activeViews]);

  const tabKey = useMemo(() => getTabKeyByIndex(currentTab), [currentTab]);

  const activeView = useMemo(() => getActiveView(tabKey), [getActiveView, tabKey]);

  const viewConfig = activeView?.value?.config;

  const previousViewConfig = usePrevios(viewConfig);

  const previousTabKey = usePrevios(tabKey);

  const { pageSize, group, columnFilter, sorting, breakdown } = viewConfig || {};
  const { selected, page, filter, dateRangeFilter, publishMode, dateRangeRelativeFilter } = currentConfig;

  const handleSelectionChange = useCallback((nextSelected: string[], key: string, resetOther?: boolean) => {
    const otherSelected = getOtherSelection(key, resetOther);

    const nextConfig = update(currentConfig, {
      selected: {
        [key]: { $set: nextSelected },
        ...otherSelected,
      },
    });

    dispatch(dashboardsActions.setCurrentConfig({ config: nextConfig }));
  }, [dispatch, currentConfig]);

  const handleViewConfigChange = useCallback((values: { [key in keyof SavedViewConfig]?: ViewConfigValues }, entity?: Entities) => {
    const view = entity ? getActiveView(entity) : activeView;

    if (!view) {
      return;
    }

    const shceme = Object.entries(values).reduce<Record<keyof SavedViewConfig, Spec<ViewConfigValues>>>((res, [key, value]) => {
      res[key as keyof SavedViewConfig] = { $set: value };

      return res;
    }, {} as Record<keyof SavedViewConfig, Spec<ViewConfigValues>>);

    const nextViewConfig = update(view.value.config, shceme as any); // TODO: fix type

    const nextView = update(view, {
      isEdited: { $set: true },
      value: {
        config: { $set: nextViewConfig },
      },
    });

    dispatch(usersActions.updateView({
      view: nextView,
      entity: entity || tabKey,
    }));
  }, [dispatch, activeView, tabKey, getActiveView]);

  const handleSortingChange = useCallback((nextSorting) => {
    handleViewConfigChange({ sorting: nextSorting });
  }, [handleViewConfigChange]);

  const handlePageChange = useCallback((nextPage, key) => {
    const nextConfig = update(currentConfig, {
      page: {
        [key]: { $set: nextPage },
      },
    });

    dispatch(dashboardsActions.setCurrentConfig({ config: nextConfig }));
  }, [dispatch, currentConfig]);

  const handlePageSizeChange = useCallback((nextPageSize) => {
    handleViewConfigChange({ pageSize: nextPageSize } );
  }, [handleViewConfigChange]);

  const handleColumnFiltersChange = useCallback((nextColumnFilters: ColumnFilterModel, entity?: string) => {
    handleViewConfigChange({ columnFilter: nextColumnFilters }, entity as Entities);
  }, [handleViewConfigChange]);

  const handleTabChange = useCallback((event: React.SyntheticEvent, nextTab: number) => {
    setCurrentTab(nextTab);
    setQueryParams({
      ...queryParams,
      [QUERY_PARAMS.TAB]: nextTab,
    });
  }, [queryParams]);

  const handleRowClick = useCallback((row: GridRowModel<any>, key: string) => {
    const tabLimit = Object.keys(tabConfig).length - 1;
    if (currentTab + 1 > tabLimit) {
      return;
    }

    const nextTab = (nextTabHandlers[key as Entities])(currentTab, row);

    handleTabChange({} as React.SyntheticEvent, nextTab);
    handleSelectionChange([row.id], key);
  }, [handleSelectionChange, currentTab, handleTabChange]);

  const handleCountRowClick = useCallback((ids: string[], key: string, type: Entities) => {
    if (!ALLOWED_COUNT_ROW_TABS.includes(type)) {
      return;
    }

    const nextEntity = ENTITIES_BY_COUNT_ROW_KEYS[key];

    if (!nextEntity) {
      return;
    }

    const filterId = FILTER_ID_BY_COUNT_ROW_NEXT_TAB[nextEntity as keyof typeof FILTER_ID_BY_COUNT_ROW_NEXT_TAB] || null;

    if (!filterId) {
      return;
    }

    const nextTab = getTabIndexByKey(nextEntity);

    handleTabChange({} as React.SyntheticEvent, nextTab);

    const nextConfig = addFilterToCurrentConfig(filterId, ids, currentConfig);
    dispatch(dashboardsActions.setFilters({ filters: nextConfig.filter }));
    dispatch(dashboardsActions.setCurrentConfig({ config: nextConfig }));
  }, [handleTabChange, currentConfig, dispatch]);

  const handleCellSelect = useCallback((value: string[] | null, field: string, include: boolean, row: GridRowModel) => {
    const nextFilters = mergeFilters(value, field, include, selectedFilters, availableFilters, row);

    dispatch(dashboardsActions.setFilters({ filters: nextFilters }));
    dispatch(dashboardsActions.setIsFiltersExpanded({ isExpanded: true }));
  }, [selectedFilters, availableFilters, dispatch]);

  const resetSelection = useCallback((key: string) => {
    const nextConfig = update(currentConfig, {
      selected: {
        [key]: { $set: [] },
        ...CHILDREN_ENTITIES[key].reduce<Record<string, Record<string, any>>>((result, childrenKey) => {
          result[childrenKey as keyof typeof result] = { $set: [] }; // TODO
          return result;
        }, {}),
      },
    });

    dispatch(dashboardsActions.setCurrentConfig({ config: nextConfig }));
  }, [currentConfig, dispatch]);

  const handleBreakdownChange = useCallback((nextBreakdown: BreakdownOption | null) => {
    if (!activeView) {
      return;
    }

    const value = !nextBreakdown ? null : (activeView.value.config.breakdown === nextBreakdown.key ? null : nextBreakdown.key);

    handleViewConfigChange({
      breakdown: value,
      group: null,
    });

    resetSelection(tabKey);
  }, [activeView, handleViewConfigChange, tabKey, resetSelection]);

  const handleGroupingChange = useCallback((nextGrouping: string | null, key: string) => {
    if (!activeView) {
      return;
    }

    handleViewConfigChange({
      group: activeView.value.config.group === nextGrouping ? null : nextGrouping,
      breakdown: null,
    });

    resetSelection(key);
  }, [resetSelection, activeView, handleViewConfigChange]);

  const handleViewChange = useCallback((view: SavedViewMergedWithFields, entity: Entities, saveAsNew?: boolean) => {
    dispatch(usersActions.setView({ view, entity, saveAsNew }));
  }, [dispatch]);

  const handleViewApply = useCallback((nextView: SavedViewMergedWithFields, entity: Entities) => {
    const view = {
      ...nextView,
      isEdited: true,
    };

    dispatch(usersActions.updateView({ view, entity }));
    handleViewChange(view, entity, false);
  }, [dispatch]);

  const handleViewUpdate = useCallback((view: SavedViewMergedWithFields, data: UpdateUserViewPayload['data']) => {
    dispatch(updateUserViewSlice.action({
      viewId: view.id,
      viewName: view.name,
      userId: user.id,
      data: getUpdatedView(view, data),
    }));
  }, [dispatch, user]);

  const handleViewCreate = useCallback((view: SavedViewMergedWithFields, data: UpdateUserViewPayload['data']) => {
    dispatch(createUserViewSlice.action({
      viewName: view.name,
      userId: user.id,
      data: getUpdatedView(view, data),
    }, { data }));
  }, [dispatch, user]);

  const handleViewAdd = useCallback((view: SavedViewMergedWithFields, entity: Entities) => {
    dispatch(usersActions.addView({ view, entity }));
  }, [dispatch]);

  const handleCustomViewDelete = useCallback((view: SavedViewMergedWithFields, entity: Entities) => {
    dispatch(usersActions.deleteView({ view, entity }));
  }, [dispatch]);

  const handleViewDelete = useCallback((view: SavedViewMergedWithFields, entity: Entities) => {
    if (view.isCustom) {
      handleCustomViewDelete(view, entity);
    } else {
      dispatch(deleteUserViewSlice.action({ viewId: view.id,  viewName: view.name, userId: user.id, entity }));
    }
  }, [dispatch, user, handleCustomViewDelete]);

  const tabsConfig = Object.entries(tabConfig)
    .filter(([, { hidden }]) => !hidden)
    .map(([key, {
      title,
      icon: IconComponent,
      component: Component,
      color,
      backgroundColor,
    }]) => ({
      title,
      renderIcon: memoize((fillColor) => <IconComponent fill={fillColor} />),
      selectedCount: currentConfig.selected[key].length,
      key,
      onSelectionChange: handleSelectionChange,
      color,
      backgroundColor,
      content: (
        <Component
          type={key as Entities}
          selected={currentConfig.selected[key]}
          onSelectionChange={handleSelectionChange}
          page={currentConfig.page[key]}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          sortModel={sorting}
          onSortModelChange={handleSortingChange}
          onRowClick={handleRowClick}
          onCountRowClick={handleCountRowClick}
          grouping={group || null}
          onGroupingChange={handleGroupingChange}
          breakdown={breakdown || null}
          onBreakdownChange={handleBreakdownChange}
          currentTab={currentTab}
          filterModel={columnFilter}
          onFilterModelChange={handleColumnFiltersChange}
          pageSize={pageSize || DEFAULT_PAGE_SIZE}
          savedViews={savedViews?.[key as Entities] || null}
          view={activeView}
          onViewApply={handleViewApply}
          onViewChange={handleViewChange}
          onViewDelete={handleViewDelete}
          onViewUpdate={handleViewUpdate}
          onViewCreate={handleViewCreate}
          onViewAdd={handleViewAdd}
          onCustomViewDelete={handleCustomViewDelete}
          isViewsFetching={isSavedViewUpdateFetching || isSavedViewDeleteFetching}
          onCellSelect={handleCellSelect}
          filters={selectedFilters}
          availableFilters={availableFilters}
        >
          {title}
        </Component>
      ),
    }));

  const fetchData = useCallback(() => {
    if (!tabKey || !pageSize || !isSavedViewsLoaded || !sorting || !columnFilter) {
      return;
    }

    // TODO: pass just config
    dispatch(dashboardsActions.fetchData({
      publishMode,
      filter,
      dateRangeFilter,
      page,
      selected,
      tabKey,
      isForcedSelection,

      // TODO: refactor ?
      pageSize: {
        [tabKey]: getPageSizeByBreakdown(breakdown || null, pageSize),
      },
      sorting: {
        [tabKey]: sorting,
      },
      columnFilter: {
        [tabKey]: columnFilter,
      },
      group: {
        [tabKey]: group || null,
      },
      breakdown: {
        [tabKey]: breakdown || null,
      },
    }));
  }, [
    dispatch,
    tabKey,
    publishMode,
    filter,
    dateRangeFilter,
    page,
    selected,
    sorting,
    pageSize,
    columnFilter,
    group,
    isSavedViewsLoaded,
    breakdown,
    isForcedSelection,
  ]);

  useEffect(() => {
    if (previousConfig.selected === selected) {
      return;
    }

    if (isParentSelectionChanged(selected, previousConfig.selected, tabKey)) {
      fetchData();
      return;
    }

    if (!previousConfig.selected[tabKey].length && selected[tabKey].length && isForcedSelection) {
      fetchData();
      setIsForcedSelection(false);
    }
  }, [fetchData, selected, tabKey, isForcedSelection]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!tabKey || !pageSize || !isSavedViewsLoaded || !sorting || !columnFilter || isForcedSelection) {
      return;
    }

    // TODO: find better way and refactor
    if (
      !isObjectChanged(previousViewConfig?.columnFilter?.items || [], columnFilter?.items, false, true) &&
      !isObjectChanged(previousViewConfig?.sorting || [], sorting, false, true) &&
      previousViewConfig?.group === group &&
      previousViewConfig?.pageSize === pageSize &&
      previousViewConfig?.breakdown === breakdown &&
      previousConfig.dateRangeFilter === dateRangeFilter &&
      previousConfig.dateRangeRelativeFilter === dateRangeRelativeFilter &&
      previousConfig.filter === filter &&
      previousConfig.page === page &&
      previousConfig.publishMode === publishMode &&
      previousTabKey === tabKey
    ) {
      return;
    }

    fetchData();
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    tabKey,
    publishMode,
    filter,
    dateRangeFilter,
    dateRangeRelativeFilter,
    page,
    sorting,
    pageSize,
    columnFilter,
    group,
    breakdown,
    isSavedViewsLoaded,
    fetchData,
    isForcedSelection,
  ]);

  useEffect(() => {
    dispatch(dashboardsActions.setConfigToQuery({ config: currentConfig }));
  }, [currentConfig, activeView, dispatch]);

  useEffect(() => {
    AnalyticsService.trackPageView();
  }, []);

  useEffect(() => {
    if (queryParams[QUERY_PARAMS.TAB] !== currentTab) {
      setCurrentTab(queryParams[QUERY_PARAMS.TAB] || 0);
    }
  }, [queryParams[QUERY_PARAMS.TAB]]);

  return (
    <Wrapper>
      <DashboardHeader />

      <Tabs
        config={tabsConfig}
        onChange={handleTabChange}
        value={currentTab}
      />
    </Wrapper>
  );
};


Dashboard.displayName = 'Dashboard';


export default Dashboard;
