import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
}));

export const ChannelIconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  borderRight: `1px solid ${theme.palette.background.default}`,
  paddingRight: theme.spacing(1),

  'svg': {
    width: 16,
  },
}));

export const PlatformIconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),

  'svg': {
    width: 16,
  },
}));
