export const POPPER_OPTIONS = {
  modifiers: [
    {
      name: 'offset',
      options: {
        // offset: [0, 18],
        offset: [0, 6],
      },
    },
  ],
};
