import { GridSortModel, GridFilterOperator, GridFilterModel, GridRowGroupingModel, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium';
import memoize from 'lodash.memoize';

import { HeatmapModel } from 'core/dashboards/types';

import { FILTER_OPERATORS, MIN_NUMBER_OF_ROWS, MIN_NUMBER_OF_COLUMNS, FAKE_COLUMN, HEATMAP_DELTA } from './constants';
import { GridSortColumnLookup, GridColumn, GridFilterColumnLookup, HeatmapValue, ColumnsHeatmap } from './types';


export const gridSortColumnLookup = (sortModel: GridSortModel) => {
  const result = sortModel.reduce<GridSortColumnLookup>((res, sortItem, index) => {
    res[sortItem.field] = {
      sortDirection: sortItem.sort,
      sortIndex: sortModel.length > 1 ? index + 1 : undefined,
    };

    return res;
  }, {});

  return result;
};

export const gridFilterColumnLookup = (filterModel: GridFilterModel) => {
  const result = filterModel.items.reduce<GridFilterColumnLookup>((res, filterItem) => {
    res[filterItem.columnField] = {
      ...filterItem,
    };

    return res;
  }, {});

  return result;
};

export const getFilterOperators = (type = 'string'): GridFilterOperator[] => {
  const filterOperators = FILTER_OPERATORS[type as keyof typeof FILTER_OPERATORS] as GridFilterOperator[];

  if (!filterOperators) {
    return [];
  }

  return filterOperators.filter(({ value }) => value !== 'isAnyOf');
};


export const defaultSortComparator = () => 0;

export const getHeaderName = (column: GridColumn): string => `${column.headerTitle}${column.headerSubTitle ? ` (${column.headerSubTitle})` : ''}`;

export const generateFakeRowsFromColumns = memoize((columns: GridColumn[], rowGroupingModel?: GridRowGroupingModel, count = MIN_NUMBER_OF_ROWS) => {
  const [groupingField] = rowGroupingModel || [];

  const row = columns.reduce((result, { field }, currentIndex) => {
    result[field] = currentIndex;
    return result;
  }, {} as Record<string, number>);

  const results = [];
  for (let index = 0; index < count; index++) {
    for (let innerIndex = 0; innerIndex < (groupingField ? count : 1); innerIndex++) {
      results.push({
        ...row,
        [groupingField]: innerIndex,
        id: `${index}_${innerIndex}`,
      });
    }
  }

  return results;
});


export const generateFakeColumns = memoize((count = MIN_NUMBER_OF_COLUMNS) => {
  const results = [];
  for (let index = 0; index < count; index++) {
    results.push({
      field: String(index),
      ...FAKE_COLUMN,
    });
  }

  return results;
});

export const getPinnedColumns = (columns: GridColumn[], pinnedColumns: string[], field: string, isPinned: boolean): string[] => {
  let nextPinnedColumns = [];

  if (isPinned) {
    const columnIndex = columns.findIndex((column) => (column.fieldOverride || column.field) === field);
    const fieldsToPin = Array(columnIndex + 1).fill(null).map((_item, index) => {
      const column = columns[index];

      return column.fieldOverride || column.field;
    });

    nextPinnedColumns = [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...fieldsToPin];
  } else {
    const columnIndex = pinnedColumns.findIndex((columnField) => columnField === field);

    nextPinnedColumns = pinnedColumns.filter((columnField, index) => index < columnIndex);
  }

  return nextPinnedColumns;
};

export const getAggregationPosition = (rowGroupingModel: GridRowGroupingModel) => rowGroupingModel?.length ? 'inline' : 'footer';

export const getHeatMapOpacity = (heatmap: HeatmapValue | undefined | null, value: number | undefined | null): number => {
  if (!heatmap) {
    return 0;
  }

  const { min, max } = heatmap;
  const cellValue = value || 0;
  const delta = max - min;
  const deltaAvgValue = delta / HEATMAP_DELTA;

  let shouldSearch = true;
  let i = 1;

  if (min === max && deltaAvgValue === 0) {
    return 1;
  }

  while (shouldSearch) {
    const searhcableValue = deltaAvgValue * i;

    if (max - searhcableValue >= cellValue) {
      i = i + 1;
    } else {
      shouldSearch = false;
    }
  }

  const opacity = 1 - ((i - 1) * 0.1);

  return opacity > 0 ? opacity : 0.1;
};

export const getHeatmaps = (heatmapModel: HeatmapModel[], columns: GridColumn[], rows: any[]): ColumnsHeatmap | null => {
  if (!rows?.length) {
    return null;
  }

  return heatmapModel.reduce((result: ColumnsHeatmap, heatmap: HeatmapModel) => {
    const column = (columns as GridColumn[]).find(({ field }) => field === heatmap.field);

    if (!column) {
      return result;
    }

    const { type, field } = column;

    if (type !== 'number') {
      return result;
    }

    if (!result[field]) {
      const values = rows.map((row) => row[field] || 0);

      result[field] = {
        min: Math.min(...values),
        max: Math.max(...values),
        color: heatmap.color,
        values,
      };
    }

    return result;
  }, {});
};
