import { memo, FunctionComponent, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import { AppliedActionProps } from './types';

import {
  AppliedActionWrapper,
  AppliedActionText,
  AppliedActionIconWrapper,
  AppliedActionTooltip,
  AppliedActionTextWrapper,
  AppliedActionResetButton,
  AppliedActionTextInner,
  AppliedActionDescriptionTooltip,
} from './styles';


const AppliedAction: FunctionComponent<AppliedActionProps> = memo(({ children, actionTitle, actionDescription, isWarningVisible, onReset }) => {
  const content = useMemo(() => (
    <AppliedActionTextWrapper
      as={Stack}
      direction="column"
    >
      {children}

      <AppliedActionTextInner
        as={Stack}
        direction="row"
        alignItems="center"
      >
        <AppliedActionText>
          {actionTitle}
        </AppliedActionText>

        <AppliedActionResetButton
          as={IconButton}
          onClick={onReset}
        >
          <CancelIcon
            fontSize="inherit"
            color="inherit"
          />
        </AppliedActionResetButton>
      </AppliedActionTextInner>
    </AppliedActionTextWrapper>
  ), [children, actionTitle, onReset]);

  return (
    <AppliedActionWrapper
      as={Stack}
      direction="row"
      alignItems="flex-start"
    >
      {isWarningVisible && (
        <AppliedActionTooltip
          title="Not all groups are visible. Check the next pages or increase rows per page"
          placement="top"
          arrow
        >
          <AppliedActionIconWrapper>
            <ErrorOutlineIcon color="warning" />
          </AppliedActionIconWrapper>
        </AppliedActionTooltip>
      )}
      {actionDescription ? (
        <AppliedActionDescriptionTooltip
          title={actionDescription}
          placement="top"
          arrow
        >
          {content}
        </AppliedActionDescriptionTooltip>
      ) : content}
    </AppliedActionWrapper>
  );
});


AppliedAction.displayName = 'AppliedAction';


export default AppliedAction;
