import React, { memo } from 'react';

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { Wrapper, СheckboxSkeleton, LabelWrapper, TagWrapper } from './styles';
import { Props } from './types';


const DashboardFiltersSkeleton: React.FunctionComponent<Props> = memo(({ size = 5 }) => (
  <>
    {
      Array(size).fill(null).map((_item, index) => (
        <Wrapper key={index}>
          <СheckboxSkeleton
            as={Skeleton}
            variant="rectangular"
            width={18}
            height={18}
            animation="pulse"
          />

          <LabelWrapper
            as={Typography}
            variant="body1"
          >
            <Skeleton
              variant="text"
              animation="pulse"
            />
          </LabelWrapper>

          <TagWrapper
            as={Typography}
            variant="body1"
          >
            <Skeleton
              variant="text"
              animation="pulse"
            />
          </TagWrapper>
        </Wrapper>
      ))
    }
  </>
));


DashboardFiltersSkeleton.displayName = 'DashboardFiltersSkeleton';


export default DashboardFiltersSkeleton;
