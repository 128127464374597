import React from 'react';

import Header from 'components/common/header';

import { Wrapper, Content } from './styles';

import { Props } from './types';


const PageLayout: React.FunctionComponent<Props> = ({ children = null, isAuthorized, isAuthorizedUserAdmin }) => (
  <Wrapper>
    <Header
      isAuthorized={isAuthorized}
      isAuthorizedUserAdmin={isAuthorizedUserAdmin}
    />

    <Content
      $isAuthorized={isAuthorized}
    >
      {children}
    </Content>
  </Wrapper>
);


PageLayout.displayName = 'PageLayout';


export default PageLayout;
