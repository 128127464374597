import React, { memo, ReactNode, useCallback, useRef, useState } from 'react';

import { ClickAwayListener, MenuList, Popper } from '@mui/material';
import Fade, { FadeProps } from '@mui/material/Fade';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import theme from 'containers/theme-provider/theme';

import { ArrowIcon, ButtonFlex, PopperContainer } from './styles';


type Props = {
  children: ReactNode;
  buttonLabel: string;
};

const HeaderDropdown: React.FunctionComponent<Props> = memo(({ children, buttonLabel }) => {
  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleClickAway = useCallback(() => setIsOpen(false), []);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        ref={wrapperRef}
        style={{ marginLeft: '26px' }}
      >
        <ButtonFlex
          onClick={toggleIsOpen}
        >
          <span
            style={{ fontSize: '14px', color: '#fff' }}
          >{buttonLabel}</span>
          <ArrowIcon
            as={ArrowDropDownIcon}
            $isOpen={isOpen}
          />
        </ButtonFlex>
        <Popper
          open={isOpen}
          anchorEl={wrapperRef.current}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }: { TransitionProps: FadeProps }) => (
            <Fade
              {...TransitionProps}
              timeout={theme.transitions.duration.standard}
            >
              <PopperContainer>
                <MenuList
                  sx={{
                    padding: '8px 0',
                    '& .MuiMenuItem-root': {
                      padding: '6px 16px',
                      borderBottom: 0,
                      cursor: 'default',

                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        color: 'inherit',
                      },
                    },
                  }}
                >
                  {children}
                </MenuList>
              </PopperContainer>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
});

HeaderDropdown.displayName = 'HeaderDropdown';

export default HeaderDropdown;
