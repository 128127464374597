export const UNKNOWN_CODE = 'UNKNOWN';

export const additionalAlpha2Code =  {
  AN: 'AN',
};

export const additionalCountryNames =  {
  [additionalAlpha2Code.AN]: 'Netherlands Antilles',
};

export const customCodesMap = {
  UK: 'GB',
  [UNKNOWN_CODE]: 'UN',
};
