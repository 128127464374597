import React, { memo } from 'react';

import { ReactComponent as DefaultChannelIcon } from 'assets/icons/default-channel-icon.svg';

import { CHANNEL_ICONS } from 'core/dashboards/constants';

import { Props } from './types';


const ChannelIcon: React.FunctionComponent<Props> = memo(({ channel }) => {
  if (channel === undefined) {
    return null;
  }

  const IconComponent = CHANNEL_ICONS[channel as keyof typeof CHANNEL_ICONS] || DefaultChannelIcon;
  return (
    <IconComponent />
  );
});


ChannelIcon.displayName = 'ChannelIcon';


export default ChannelIcon;
