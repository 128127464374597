import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2.5),
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.default}`,
  transition: theme.transitions.create('background-color'),
  minHeight: 41,

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledLabel = styled('p')(({ theme }) => ({
  width: 100,
  marginRight: theme.spacing(2),
}));

export const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  minWidth: 80,
});

export const StyledIconButton = styled('div')({
  color: '#C6C6C6', // TODO: add to design kit
});
