import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers-pro/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { createTheme } from '@mui/material/styles';

import ComponentsOverride from './components-overrides';

import { PADDING_BASE } from './constants';


const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter',
      'Helvetica Neue',
      'sans-serif',
    ].join(','),

    fontWeightBold: 600,

    h1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
    },

    h2: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.5,
    },

    subtitle1: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.5,
    },

    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.5,
    },

    body1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.21,
    },

    body2: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.21,
    },

    button: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.21,
    },

    caption: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.6,
    },
  },

  palette: {
    common: {
      black: '#2D2D2D',
      white: '#FFFFFF',
    },

    primary: {
      main: '#3A76E8',
    },

    secondary: {
      main: '#9E9E9E',
      contrastText: '#F7F7F7',
    },

    error: {
      main: '#F94A3B',
      light: '#FD8176',
    },

    info: {
      main: '#2196F3',
    },

    warning: {
      main: '#FF8F00',
    },

    success: {
      main: '#26C076',
    },

    action: {
      hover: '#E8F1FF',
    },

    background: {
      default: '#E8EAEE',
    },

    grey: {
      500: '#9E9E9E',
      400: '#BDBDBD',
      300: '#E0E0E0',
      50: '#F3F3F3',
    },

    text: {
      primary: '#2D2D2D',
      secondary: '#FFFFFF',
    },
  },

  spacing: PADDING_BASE,

  shape: {
    borderRadius: 8,
  },

  transitions: {
    duration: {
      standard: 200,
      short: 150,
      shorter: 100,
      shortest: 50,
    },
  },

  components: ComponentsOverride,
});

export default theme;
