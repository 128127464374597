import { styled } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import { ShortcutButtonProps } from './types';


export const Wrapper = styled('div')(() => ({

}));

export const ShortcutsWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2.5),
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

export const ControlsWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  maxWidth: 160,
}));

export const StyledTextField = styled('div')(({ theme }) => ({ // TODO: override core input?
  [`.${inputBaseClasses.root}`]: {
    height: 34,
    backgroundColor: theme.palette.grey[50],

    ...theme.typography.body1,
    color: theme.palette.grey[500],

    [`.${inputBaseClasses.input}::placeholder`]: {
      opacity: 1,
      color: 'inherit',
    },
  },

  [`.${outlinedInputClasses.notchedOutline}, &:hover div:not(.Mui-error) .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: 'transparent',
  },

  '& + &': {
    marginTop: theme.spacing(1.25),
  },
}));

export const FieldsWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}));

export const ShortcutButton = styled('div')<ShortcutButtonProps>(({ theme, $isSelected }) => ({
  justifyContent: 'flex-start',
  color: theme.palette.common.black,
  height: 30,

  ...theme.typography.body1,

  '& + &': {
    marginTop: theme.spacing(0.5),
  },

  ...($isSelected ? {
    backgroundColor: '#F2F7FF',
    color: theme.palette.primary.main,

    '&:hover': {
      color: theme.palette.primary.main,
    },
  } : {}),
}));
