import { styled } from '@mui/material/styles';

import { CategoryWrapperProps, HeaderActionButtonProps } from './types';


export const Wrapper = styled('div')(({ theme }) => ({

}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 32,
  marginBottom: theme.spacing(1.75),
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1.75),
}));

export const CategoryLabel = styled('p')(({ theme }) => ({
  transition: theme.transitions.create('color'),
}));

export const CategoryWrapper = styled('div')<CategoryWrapperProps>(({ theme, $isExpanded }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 40,
  cursor: 'pointer',
  borderBottom: '1px solid transparent',

  ...(!$isExpanded ? {
    borderBottomColor: theme.palette.background.default,
    transition: theme.transitions.create('border', { delay: theme.transitions.duration.standard }),
  } : {
  }),

  [CategoryLabel]: {
    color: $isExpanded ? theme.palette.primary.main : theme.palette.common.black,
  },

  userSelect: 'none',
}));

export const CategoryInnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ToggleExpandIcon = styled('div')(({ theme }) => ({
  width: 18,
  height: 18,
  color: theme.palette.grey[300],
  marginRight: theme.spacing(0.5),
}));

export const DragHandleIcon = styled('div')(({ theme }) => ({
  color: theme.palette.grey['500'],
  marginRight: 1,
}));

export const DraggableContent = styled('div')(({ theme, style }) => ({
  ...style,
  marginTop: theme.spacing(0.625),
}));

export const AddAllButton = styled('div')(({ theme }) => ({
  width: 'auto',
  minWidth: 0,
  height: 22,
  borderRadius: theme.shape.borderRadius as number / 2,

  ...theme.typography.caption,
}));

export const RemoveAllButton = styled(AddAllButton)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const HeaderActionButton = styled('div')<HeaderActionButtonProps>(({ theme, $isVisible }) => ({
  width: 'auto',
  minWidth: 0,
  height: '32px',
  color: theme.palette.primary.main,
  opacity: $isVisible ? 1 : 0,
  transition: theme.transitions.create('opacity'),
}));
