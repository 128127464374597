import { memo, FunctionComponent, useCallback, ChangeEvent } from 'react';

import MuiSwitch from '@mui/material/Switch';

import { Props } from './types';


const Switch: FunctionComponent<Props> = memo(({ value, onChange, ...otherProps }) => {
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  }, [onChange]);

  return (
    <MuiSwitch
      checked={value}
      onChange={handleChange}
      color="primary"
      disableRipple
      {...otherProps}
    />
  );
});


Switch.displayName = 'Switch';


export default Switch;
