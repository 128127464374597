import { createActions } from 'utils/redux';

import {
  Actions,
  Types,
  SetViewPayload,
  UpdateViewPayload,
  AddViewPayload,
  DeleteViewPayload,
  SetSharedViewsPayload,
  Payload,
} from './types';
import { PREFIX } from './constants';


export const actions = createActions<Types, Payload, Actions>({
  [Types.SET_VIEW]: (payload: SetViewPayload) => payload,
  [Types.UPDATE_VIEW]: (payload: UpdateViewPayload) => payload,
  [Types.ADD_VIEW]: (payload: AddViewPayload) => payload,
  [Types.DELETE_VIEW]: (payload: DeleteViewPayload) => payload,
  [Types.SET_SHARED_VIEWS]: (payload: SetSharedViewsPayload) => payload,
  [Types.SET_SAVED_VIEWS_LOADED]: () => null,
}, {
  prefix: PREFIX,
});


export default actions;
