import { GridRowModel } from '@mui/x-data-grid-premium';

import { ReactComponent as AdSetsIcon } from 'assets/icons/tabs/ad-sets-icon.svg';
import { ReactComponent as AdsIcon } from 'assets/icons/tabs/ads-icon.svg';
import { ReactComponent as CampaignIcon } from 'assets/icons/tabs/campaign-icon.svg';
import { ReactComponent as CreativesIcon } from 'assets/icons/tabs/creatives-icon.svg';
import { ReactComponent as LionAppIcon } from 'assets/icons/tabs/lion-app-icon.svg';
import { ReactComponent as OfferIcon } from 'assets/icons/tabs/offer-icon.svg';
import { ReactComponent as SkuIcon } from 'assets/icons/tabs/sku-icon.svg';
import { ReactComponent as GeoIcon } from 'assets/icons/tabs/geo-icon.svg';

import { withPrefix } from 'utils';

import { Entities } from 'core/dashboards/types';
import { BACK_END_KEYS, GEO_CHANNELS } from 'core/dashboards/constants';

import DashboardGrid from 'components/dashboard/grid'; // eslint-disable-line import/no-cycle


export const tabConfig = {
  [Entities.Apps]: {
    title: 'Lion App',
    icon: LionAppIcon,
    component: DashboardGrid,
    color: '#F01F30',
    backgroundColor: '#FFCFD3',
    hidden: false,
  },
  [Entities.Skus]: {
    title: 'SKU',
    icon: SkuIcon,
    component: DashboardGrid,
    color: '#26C076',
    backgroundColor: '#C2F9DE',
    hidden: false,
  },
  [Entities.Offers]: {
    title: 'Offer',
    icon: OfferIcon,
    component: DashboardGrid,
    color: '#3A76E8',
    backgroundColor: '#C6D8FB',
    hidden: false,
  },
  [Entities.Campaigns]: {
    title: 'Campaign',
    icon: CampaignIcon,
    component: DashboardGrid,
    color: '#FC7557',
    backgroundColor: '#FDD6CD',
    hidden: false,
  },
  [Entities.AdSets]: {
    title: 'Ad Sets',
    icon: AdSetsIcon,
    component: DashboardGrid,
    color: '#FF4081',
    backgroundColor: '#F9D3E0',
    hidden: false,
  },
  [Entities.Ads]: {
    title: 'Ads',
    icon: AdsIcon,
    component: DashboardGrid,
    color: '#7B61FF',
    backgroundColor: '#D8D3F4',
    hidden: false,
  },
  [Entities.Creatives]: {
    title: 'Creatives',
    icon: CreativesIcon,
    component: DashboardGrid,
    color: '#00B0FF',
    backgroundColor: '#C2EBFD',
    hidden: false,
  },
  [Entities.Geos]: {
    title: 'Geo',
    icon: GeoIcon,
    component: DashboardGrid,
    color: '#78C0AA',
    backgroundColor: '#D0FFF1',
    hidden: false,
  },
};

export const nextTabHandlers: Record<Entities, (currentTab: number, row: GridRowModel<any>) => number> = {
  [Entities.Apps]: (currentTab: number) => currentTab + 1,
  [Entities.Skus]: (currentTab: number) => currentTab + 1,
  [Entities.Offers]: (currentTab: number) => currentTab + 1,
  [Entities.Campaigns]: (currentTab: number, row: any) => {
    const channel = row[withPrefix(Entities.Campaigns, BACK_END_KEYS.CHANNEL)];

    if (!GEO_CHANNELS.includes(channel)) {
      return currentTab + 1;
    }

    const geoTabIndex = Object.keys(tabConfig).findIndex((tab) => tab === Entities.Geos);

    return geoTabIndex === -1 ? currentTab + 1 : geoTabIndex;
  },
  [Entities.AdSets]: (currentTab: number) => currentTab + 1,
  [Entities.Ads]: (currentTab: number) => currentTab + 1,
  [Entities.Creatives]: (currentTab: number) => currentTab + 1,
  [Entities.Geos]: (currentTab: number) => currentTab + 1,
};
