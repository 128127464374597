import { memo, FunctionComponent, useMemo } from 'react';

import InsertLinkTwoToneIcon from '@mui/icons-material/InsertLinkTwoTone';

import { Props } from './types';
import { DEFAULT_ICON_SIZE } from './constants';
import { Wrapper, LinkIcon } from './styles';


const DashboardLinkContainer: FunctionComponent<Props> = memo(({ value, children, icon: Icon = InsertLinkTwoToneIcon, iconSize = DEFAULT_ICON_SIZE, iconColor, iconActiveColor }) => {
  const linkStyles = useMemo(() => ({
    width: iconSize,
    height: iconSize,
  }), [iconSize]);

  if (!value) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <>
      <Wrapper
        href={value}
        $iconColor={iconColor}
        $iconActiveColor={iconActiveColor}
        target="_blank"
      >
        <LinkIcon
          as={Icon}
          sx={linkStyles}
        />
      </Wrapper>

      {children}
    </>

  );
},
);


DashboardLinkContainer.displayName = 'DashboardLinkContainer';


export default DashboardLinkContainer;
