import { isValueExists } from 'utils';


export const ALL_OPTION = {
  id: 'ALL',
  name: 'All',
};

export const NOT_EMPTY_OPTION = {
  id: 'NOT_EMPTY',
  name: 'Not empty',
};

export const getStatusInitialValue = (statuses, row, fieldType) => (statuses || []).find((status) => isValueExists(row) && status.id === row[fieldType]);
export const getManagersOptionLabel = (option) => option?.name;
export const getManagersInitialValue = (managers, row, fieldType) => (managers || []).find((manager) => isValueExists(row) && manager.name === row[fieldType]);

export const getFilteredList = (list, filter) => {
  const filterEntries = Object.entries(filter);

  if (!filterEntries.length) {
    return list;
  }

  return list.filter((game) => filterEntries.reduce((result, [key, value]) => {
    if (!result) {
      return result;
    }

    switch (value) {
      case ALL_OPTION.id:
        return true;
      case NOT_EMPTY_OPTION.id:
        return isValueExists(game[key]);
      default: {
        const fieldValue = (String(game[key]) || '').toLowerCase(); // TODO: add type checking ?
        const filterValue = (value || '').toLowerCase();

        return fieldValue.includes(filterValue); // TODO: use RegEx ?
      }
    }
  }, true));
};

export const getQueryParamsByFilter = (filter, prevQueryParams, queryParamsByFields) => {
  const nextQueryParams = Object.entries(queryParamsByFields).reduce((result, [filterKey, paramKey]) => {
    const filterValue = filter[filterKey];

    if (!filterValue) {
      return result;
    }

    return {
      ...result,
      [paramKey]: filterValue,
    };
  }, {});

  const filteredPrevQueryParams = Object.entries(prevQueryParams).reduce((result, [paramKey, paramValue]) => {
    const isFilterQueryParam = Object.entries(queryParamsByFields).find((entry) => entry[1] === paramKey);

    if (!isFilterQueryParam || nextQueryParams[paramKey]) {
      return {
        ...result,
        [paramKey]: paramValue,
      };
    }

    return result;
  }, {});

  return {
    ...filteredPrevQueryParams,
    ...nextQueryParams,
  };
};

export const getFilterByQueryParams = (params, queryParamsByFields) => Object.entries(params).reduce((result, [key, value]) => {
  const filterEntry = Object.entries(queryParamsByFields).find((entry) => entry[1] === key);
  const [filter] = filterEntry || [];

  if (!filter) {
    return result;
  }

  return {
    ...result,
    [filter]: value,
  };
}, {});

export const isOperatorWithoutValue = (operator) => (['isEmpty', 'isNotEmpty'].includes(operator));
