import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')({
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});


export const StyledText = styled('p')(({ theme }) => ({
  marginTop: theme.spacing(7.5),
  marginBottom: theme.spacing(3.75),
}));
