import { FormEvent, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useQuery from 'hooks/use-query';

import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import authActions from 'core/auth/actions';
import { getIsAuthorized } from 'core/auth/selectors';
import AuthLayout from 'components/auth/layout';
import { ROUTES } from 'containers/router/constants';
import LionstudiosApi from 'services/lionstudios-api';
import SnackbarService from 'services/snackbar';

import Background from 'assets/images/sign-in-okta-bg.png';
import FormBackground from 'assets/images/sign-in-okta-form-bg.svg';


interface State {
  password: string;
  confirmPassword: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

const ResetPassword = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useQuery();

  const userId = params.get('i');
  const token = params.get('t');

  const isAuthorized = useSelector(getIsAuthorized);
  useEffect(() => {
    if (isAuthorized) {
      SnackbarService.showError(
        'Please logout before resetting password',
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
      navigate('/');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!userId || !token?.length) {
      navigate(ROUTES.SIGN_IN);
    }
  }, [navigate, userId, token]);

  const [values, setValues] = useState<State>({
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false,
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = (prop: keyof State) => () => {
    setValues({
      ...values,
      [prop]: !values[prop],
    });
  };

  const handleMouseDownPassword = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  const handleClickBackSignIn = useCallback(() => {
    navigate(ROUTES.SIGN_IN);
  }, [navigate]);

  const handleSubmitResetPassword = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    if (!values.password.length) { return; }

    try {
      if (values.password.length < 6) { throw new Error('Password must be 6 or more characters!'); }
      if (values.password !== values.confirmPassword) { throw new Error('Invalid password confirmation!'); }
      setLoading(true);
      const response = await LionstudiosApi.post('reset', {
        userId: userId,
        token: token,
        password: values.password,
      });
      const authToken = response.data.token;

      // navigate(ROUTES.SIGN_IN);
      dispatch(authActions.signInWithOkta({ token: authToken, withRedirect: true }));
      SnackbarService.showSuccess(
        'Your password has been reset successfully!',
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    } catch (e: any) {
      SnackbarService.showError(
        e?.response?.data?.error ?? (e?.message ?? 'Error'),
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
      setLoading(false);

    }

  }, [values, userId, token, navigate, dispatch]);

  return (
    <AuthLayout
      image={Background}
      formImage={FormBackground}
    >
      <Box
        component="form"
        onSubmit={handleSubmitResetPassword}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: '0 8px' }}
      >
        <Typography
          variant="h2"
          sx={{ mb: '20px' }}
        >
          Reset Password
        </Typography>
        <FormControl
          sx={{ m: 1, width: '250px' }}
          variant="outlined"
        >
          <InputLabel
            htmlFor="outlined-adornment-password"
            sx={{ color: 'currentColor' }}
          >Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword('showPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            required
          />
        </FormControl>
        <FormControl
          sx={{ m: 1, width: '250px' }}
          variant="outlined"
        >
          <InputLabel
            htmlFor="outlined-adornment-confirm-password"
            sx={{ color: 'currentColor' }}
          >Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-confirm-password"
            type={values.showConfirmPassword ? 'text' : 'password'}
            value={values.confirmPassword}
            onChange={handleChange('confirmPassword')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword('showConfirmPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
            required
          />
        </FormControl>
        <LoadingButton
          type="submit"
          variant="outlined"
          sx={{
            fontSize: 18,
            width: 250,
            fontWeight: 500,
            height: 56,
            alignSelf: 'center',
            margin: '8px auto',
            display: 'flex',
          }}
          loading={loading}
          // loadingPosition="start"
          // startIcon={<KeyIcon />}
        >Submit</LoadingButton>
      </Box>

      <Button
        variant="text"
        size="small"
        sx={{
          alignSelf: 'center',
          margin: '4px auto',
          display: 'flex',
        }}
        onClick={handleClickBackSignIn}
      >
        Back to Sign In
      </Button>
    </AuthLayout>
  );
});

ResetPassword.displayName = 'ResetPassword';

export default ResetPassword;
