import React, { memo, useCallback } from 'react';

import ReactMarkdown from 'react-markdown';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {
  Wrapper,
  ContentWrapper,
  InfoIcon,
  ActionButton,
} from './styles';

import { Props } from './types';


const Field: React.FunctionComponent<Props> = memo(({
  description,
  source,
  name,
  isAddable = false,
  isAdded = false,
  primary,
  fieldKey,
  onAdd,
  onDelete,
  isFullWidthAction = false,
  beforeElement = null,
}) => {
  const handleActionClick = useCallback(() => {
    if (isAdded || !isAddable) {
      onDelete(fieldKey);
      return;
    }

    onAdd(fieldKey);
  }, [isAdded, isAddable, onAdd, fieldKey, onDelete]);

  return (
    <Wrapper
      $isAdded={isAdded}
      $isAddable={isAddable}
      $isFullWidthAction={isFullWidthAction}
      $isPrimary={primary}
      data-primary={primary}
      data-added={isAdded}
      {...(isFullWidthAction && !primary ? { onClick: handleActionClick } : {})}
    >
      <ContentWrapper>
        {beforeElement}

        <Typography
          variant="body1"
          mr={0.625}
          color={isAdded ? 'white' : 'black'}
        >
          {name}
        </Typography>

        <Typography
          variant="caption"
          color={isAdded ? 'white' : 'secondary'}
        >
          {source}
        </Typography>
      </ContentWrapper>

      <ContentWrapper>
        <Tooltip
          title={<ReactMarkdown linkTarget="_blank">{description}</ReactMarkdown>}
          placement="top"
          arrow
        >
          <InfoIcon
            as={InfoOutlinedIcon}
          />
        </Tooltip>

        {
          !primary && (
            <ActionButton
              as={IconButton}
              disableRipple
              {...(!isFullWidthAction ? { onClick: handleActionClick } : {})}
            >
              {
                isAdded || !isAddable ? (
                  <CancelIcon />
                ) : (
                  <AddCircleIcon />
                )
              }
            </ActionButton>
          )
        }
      </ContentWrapper>

    </Wrapper>
  );
});


Field.displayName = 'Field';


export default Field;
