import React, { memo } from 'react';

import PlatformIcon from 'components/common/platform-icon';

import { Wrapper } from './styles';
import { Props } from './types';


const Platforms: React.FunctionComponent<Props> = memo(({ platforms }) => (
  <Wrapper>
    {
      platforms?.map((platform) => (
        <PlatformIcon
          key={platform}
          platform={platform}
        />
      )) || null
    }
  </Wrapper>
));


Platforms.displayName = 'Platforms';


export default Platforms;
