export const enum ROUTES {
  DASHBOARD = '/',

  // SIGN_IN_OKTA = '/sign-in',
  // SIGN_IN_OKTA_REDIRECT = '/login/okta',

  // SIGN_IN_FORM = '/sign-in-form',
  SIGN_IN = '/sign-in',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset',

  USERS_INDEX = '/users',
  USERS_CREATE = '/users/create',
  USERS_EDIT = '/users/:userId/edit',

  PERMISSIONS_INDEX = '/permissions',
  PERMISSIONS_CREATE = '/permissions/create',
  PERMISSIONS_EDIT = '/permissions/:permissionTemplateId/edit',

  ACTIVITIES_INDEX = '/activity-log',

  APPSETTINGS_INDEX = '/appsettings',
  APPSETTINGS_CREATE = '/appsettings/create',
  APPSETTINGS_EDIT = '/appsettings/:appSettingId/edit',

  NOT_FOUND = 'not-found',
}

export const AUTH_ROUTES: Partial<ROUTES>[] = [
  // ROUTES.SIGN_IN_OKTA,
  // ROUTES.SIGN_IN_OKTA_REDIRECT
  ROUTES.SIGN_IN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.RESET_PASSWORD,
];
