import { Action, ActionCreator } from 'types/redux';
import { PaginatedResponse, RequestParameters } from 'core/admin/types';


export enum Types {
  FETCH_ALL = 'ADMIN/ACTIVITY_LOGS/FETCH_ALL',
  FETCH_ONE = 'ADMIN/ACTIVITY_LOGS/FETCH_ONE',
  FETCH_ONE_RESET = 'ADMIN/ACTIVITY_LOGS/FETCH_ONE_RESET',
}

export interface Actions extends ActionCreator<Types, Payload, null> {
  fetchAll: (payload: RequestParameters) => Action<Types, Payload, null>;
  fetchOne: (payload: FetchOneActivityLogPayload) => Action<Types, Payload, null>;
}

export type FetchOneActivityLogPayload = {
  activityLogId: number;
};

export type RejectedPayload = {
  error: any;
};

export type Payload = RequestParameters & FetchOneActivityLogPayload;

export type ActivityLog = {
  id: number;
  createdAt: string;
  objectType: string;
  event: string;
  oldValues: Record<string, any>;
  newValues: Record<string, any>;
  user?: ActivityLogUser | null;
};

export type ActivityLogUser = {
  id: number;
  fullName: string;
};

export type PaginatedActivityLogs = PaginatedResponse<ActivityLog>;

export type State = {
  fetchAll: {
    data: PaginatedActivityLogs;
    isLoading: boolean;
    error: any;
  };
  fetchOne: {
    data: ActivityLog | null;
    isLoading: boolean;
    error: any;
  };
};

export type FetchAllResolvedPayload = PaginatedActivityLogs;
export type FetchOneResolvedPayload = ActivityLog;
