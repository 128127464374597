import { Components } from '@mui/material/styles';


const overrides: Components['MuiAlert'] = {
  styleOverrides: {
    root: {
      minHeight: 48,
      alignItems: 'center',
      maxWidth: 1300,
    },
    icon: {
      padding: 0,
    },
    message: {
      padding: 0,
    },
    action: {
      paddingTop: 0,
    },
  },
};


export default overrides;
