import { GridSortDirection } from '@mui/x-data-grid-premium';

import {
  STRING_OPERATORS,
  NUMBER_OPERATORS,
  DATE_OPERATORS,
  DATE_TIME_OPERATORS,
  BOOLEAN_OPERATORS,
  SINGLE_SELECT_OPERATORS,
} from './filter-operators';
import { GridColumn } from './types';


export const PAGE_SIZES = [10, 25, 50, 100, 500];
export const DEFAULT_PAGE_SIZE = PAGE_SIZES[2];

export const COLUMN_WIDTH = {
  EXTRA_SM: 50,
  SM: 100,
  MD: 140,
  LG: 200,
  EXTRA_LG: 300,
};

export const DEFAULT_SORTING_ORDER: GridSortDirection[] = ['desc', 'asc', null];

export const HEADER_HEIGHT = 50;
export const HEADER_FILTER_HEIGHT = 34;
export const EXTENDED_HEADER_HEIGHT = HEADER_HEIGHT + HEADER_FILTER_HEIGHT;
export const GRID_ROW_FETCHING_CLASS = 'data-grid__row--fetching';
export const GRID_ROW_DISABLED_CLASS = 'data-grid__row--disabled';
export const ROW_HEIGHT = 34;
export const MIN_NUMBER_OF_ROWS = 10;
export const MIN_NUMBER_OF_COLUMNS = 10;

export const FILTER_OPERATORS = {
  string: STRING_OPERATORS,
  number: NUMBER_OPERATORS,
  date: DATE_OPERATORS,
  dateTime: DATE_TIME_OPERATORS,
  boolean: BOOLEAN_OPERATORS,
  singleSelect: SINGLE_SELECT_OPERATORS,
};

export const FAKE_COLUMN: Partial<GridColumn> = {
  type: 'string',
  width: COLUMN_WIDTH.LG,
  filterable: false,
  sortable: false,
  isFetching: true,
};

export const EXPERIMENTAL_FEATURES = {
  aggregation: true,
};

export const UNGROUPED_SUMMARY_FIELD = 'UNDEFINED'; // TODO: move to core?

export const HEATMAP_DELTA = 10;
