import React, { memo, useCallback, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import update from 'immutability-helper';

import Stack from '@mui/material/Stack';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import ShareIcon from '@mui/icons-material/Share';

import SnackbarService from 'services/snackbar';

import { filterBasicDataSlice } from 'core/settings/slices';
import { DateRangeFilter, DateRangeRelativeFilter, PublishModes } from 'core/dashboards/types';
import { Filter } from 'core/settings/types';
import dashboardsActions from 'core/dashboards/actions';
import {
  getCurrentConfig,
  getIsQueryConfigLoaded,
  getIsFiltersExpanded,
  getSelectedFilters,
  getDefaultFilters,
  getFiltersListBasedOnPublishMode,
} from 'core/dashboards/selectors';
import {
  authorizedUserSlice,
  createUserFilterSlice,
  fetchSavedUserFiltersSlice,
  deleteSavedUserFilterSlice,
  updateSavedUserFilterSlice,
} from 'core/users/slices';
import { SavedFilter } from 'core/users/types';
import { getDateRangeFilterObject } from 'core/dashboards/utils';

import usePrevios from 'hooks/use-previous';

import ExpandButton from 'components/common/expand-button';
import DateRangeFilterComponent from 'components/common/date-range-filter';
import DashboardSavedFilters from 'components/dashboard/saved-filters';
import { SaveFilterFormValues } from 'components/dashboard/save-filter-modal/types';

import DashboardFilters from '../filters';

import {
  getSanitizedFilters,
  getIsSomeFiltersApplied,
  getFiltersBySaved,
  getAppliedFiltersCount,
} from './utils';

import { Wrapper, SavedFiltersPopoverPaperStyles, ShareButton } from './styles';


const savedFiltersBackdropProps = {
  invisible: true,
};

const savedFiltersPopoverAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

const DashboardHeader: React.FunctionComponent = memo(() => {
  const dispatch = useDispatch();

  const filtersList = useSelector(filterBasicDataSlice.selectors.getData);
  const currentConfig = useSelector(getCurrentConfig);
  const isFetching = useSelector(filterBasicDataSlice.selectors.getIsFetching);
  const isQueryConfigLoaded = useSelector(getIsQueryConfigLoaded);
  const user = useSelector(authorizedUserSlice.selectors.getData);
  const isCreateFilterFetching = useSelector(createUserFilterSlice.selectors.getIsFetching);
  const createdSavedFilter = useSelector(createUserFilterSlice.selectors.getData);
  const isSavedFiltersFetching = useSelector(fetchSavedUserFiltersSlice.selectors.getIsFetching);
  const savedFilters = useSelector(fetchSavedUserFiltersSlice.selectors.getData);
  const isDeleteSavedFilterFetching = useSelector(deleteSavedUserFilterSlice.selectors.getIsFetching);
  const deletableFilterPayload = useSelector(deleteSavedUserFilterSlice.selectors.getPayload);
  const isFilterExpanded = useSelector(getIsFiltersExpanded);
  const selectedFilters = useSelector(getSelectedFilters);
  const defaultFilters = useSelector(getDefaultFilters);
  const filtersListBasedOnPublishMode = useSelector(getFiltersListBasedOnPublishMode);
  
  const { filter, dateRangeFilter, dateRangeRelativeFilter: initialDateRangeRelativeFilter, publishMode } = currentConfig;

  const previousPublishMode = usePrevios(publishMode);

  const [isSavedFiltersExpanded, setIsSavedFiltersExpanded] = useState(false);
  const [savedFiltersAnchor, setSavedFiltersAnchor] = useState<HTMLButtonElement | null>(null);
  const [appliedSavedFilterId, setAppliedSavedFilterId] = useState<number | null>(null);
  const [dateRangeRelativeFilter, setDateRangeRelativeFilter] = useState(initialDateRangeRelativeFilter);
  const [shouldSkipResetFilters, setShouldSkipResetFilters] = useState(false);
  const [isShareLinkCopying, setIsShareLinkCopying] = useState(false);

  const appliedSavedFilter = useMemo(() => {
    const filterModel = (savedFilters || []).find((savedFilter) => savedFilter.id === appliedSavedFilterId);

    return filterModel || null;
  }, [savedFilters, appliedSavedFilterId]);

  const setIsFilerExpanded = useCallback((isExpanded: boolean) => {
    dispatch(dashboardsActions.setIsFiltersExpanded({ isExpanded }));
  }, [dispatch]);

  const handleSavedFiltersClick = useCallback((isExpanded: boolean, event: React.MouseEvent<HTMLButtonElement>) => {
    setSavedFiltersAnchor(event.currentTarget);
    setIsSavedFiltersExpanded(true);
  }, []);

  const handleSavedFiltersClose = useCallback(() => {
    setIsSavedFiltersExpanded(false);
    setSavedFiltersAnchor(null);
  }, []);

  const handleChangeFilters = useCallback((nextFilters: Filter[]) => {
    dispatch(dashboardsActions.setFilters({ filters: nextFilters }));
  }, [dispatch]);

  const submitFilters = useCallback((nextFilters: Filter[], nextDateRangeRelativeFilter?: DateRangeRelativeFilter, nextPublishMode?: PublishModes) => {
    const sanitizedFilters = getSanitizedFilters(nextFilters);

    const nextConfig = update(currentConfig, {
      filter: { $set: sanitizedFilters },
      dateRangeRelativeFilter: { $set: nextDateRangeRelativeFilter },
      ...(nextDateRangeRelativeFilter ? {
        dateRangeFilter: { $set: getDateRangeFilterObject(nextDateRangeRelativeFilter) },
      } : {}),
      ...(nextPublishMode ? {
        publishMode: { $set: nextPublishMode },
      } : {}),
    });

    dispatch(dashboardsActions.setCurrentConfig({ config: nextConfig }));
  }, [dispatch, currentConfig]);

  const handleSubmitFilters = useCallback(() => {
    submitFilters(selectedFilters, dateRangeRelativeFilter);
  }, [selectedFilters, dateRangeRelativeFilter, submitFilters]);

  const handleResetAllFilters = useCallback((shouldApply = false) => {
    handleChangeFilters(defaultFilters);
    setAppliedSavedFilterId(null);

    if (shouldApply) {
      submitFilters(defaultFilters);
    }
  }, [submitFilters, handleChangeFilters, defaultFilters]);

  const resetFilters = useCallback(() => {
    handleResetAllFilters(true);
  }, [handleResetAllFilters]);

  const applySavedFilter = useCallback((savedFilter: SavedFilter) => {
    setAppliedSavedFilterId(savedFilter.id as number);
    submitFilters(savedFilter.value.filters as Filter[], savedFilter.value.dateRangeRelativeFilter, savedFilter.value.publishMode);
    handleSavedFiltersClose();
  }, [submitFilters, handleSavedFiltersClose]);

  const selectSavedFilter = useCallback((savedFilter: SavedFilter) => {
    handleChangeFilters(getFiltersBySaved(savedFilter.value.filters, filtersListBasedOnPublishMode));
    setDateRangeRelativeFilter(savedFilter.value.dateRangeRelativeFilter);

    if (savedFilter.value.publishMode !== publishMode) {
      setShouldSkipResetFilters(true);
      applySavedFilter(savedFilter);
    } else {
      setAppliedSavedFilterId(savedFilter.id as number);
    }

    setIsFilerExpanded(true);
    handleSavedFiltersClose();
  }, [
    handleChangeFilters,
    filtersListBasedOnPublishMode,
    setIsFilerExpanded,
    handleSavedFiltersClose,
    publishMode,
    applySavedFilter,
  ]);

  const deleteSavedFilter = useCallback((savedFilter: SavedFilter) => {
    dispatch(deleteSavedUserFilterSlice.action({
      userId: user.id,
      filterId: (savedFilter.id as number),
    }, {
      useFetching: true,
      useListFetching: false,
    }));

    if (savedFilters.length <= 1) {
      handleSavedFiltersClose();
    }
  }, [dispatch, user, savedFilters, handleSavedFiltersClose]);

  const handleSaveFilters = useCallback((formValues: SaveFilterFormValues) => {
    const sanitizedFilters = getSanitizedFilters(selectedFilters);

    dispatch(createUserFilterSlice.action({
      userId: user?.id,
      data: {
        ...formValues,
        isDefault: false, // TODO: add control for this param ?
        value: {
          filters: sanitizedFilters,
          dateRangeRelativeFilter,
          publishMode,
        },
      },
    }));
  }, [dispatch, selectedFilters, user, dateRangeRelativeFilter, publishMode]);

  const handleUpdateFilters = useCallback((formValues: SaveFilterFormValues) => {
    const sanitizedFilters = getSanitizedFilters(selectedFilters);

    dispatch(updateSavedUserFilterSlice.action({
      userId: user?.id,
      filterId: appliedSavedFilter?.id as number,
      data: {
        ...formValues,
        value: {
          filters: sanitizedFilters,
          dateRangeRelativeFilter,
          publishMode,
        },
      },
    }));
  }, [dispatch, selectedFilters, user, appliedSavedFilter, dateRangeRelativeFilter, publishMode]);

  const handleSubmitDateRangeFilter = useCallback((nextDateRangeFilter: DateRangeFilter, nextDateRangeRelativeFilter?: DateRangeRelativeFilter) => {
    const nextConfig = update(currentConfig, {
      dateRangeFilter: { $set: nextDateRangeFilter },
      dateRangeRelativeFilter: { $set: nextDateRangeRelativeFilter },
    });

    dispatch(dashboardsActions.setCurrentConfig({ config: nextConfig }));
  }, [dispatch, currentConfig]);

  const handleShareConfigClick = useCallback(async () => {
    setIsShareLinkCopying(true);

    try {
      await navigator.clipboard.writeText(window.location.href);
      SnackbarService.showSuccess('Link has been copied to your clipboard');
    } catch (error) {
      SnackbarService.showError('Unable to copy the link. Please, copy it manually');
    } finally {
      setIsShareLinkCopying(false);
    }
  }, []);

  const isSomeFiltersApplied = useMemo(() => getIsSomeFiltersApplied(selectedFilters), [selectedFilters]);

  const activeFiltersCount = useMemo(() => getAppliedFiltersCount(filter), [filter]);

  useEffect(() => {
    if (createdSavedFilter?.id) {
      setAppliedSavedFilterId(createdSavedFilter.id);
    }
  }, [createdSavedFilter]);

  useEffect(() => {
    setDateRangeRelativeFilter(initialDateRangeRelativeFilter);
  }, [initialDateRangeRelativeFilter]);

  useEffect(() => {
    if (previousPublishMode !== publishMode && !shouldSkipResetFilters) {
      handleResetAllFilters();
    }

    setShouldSkipResetFilters(false);
  }, [filtersList, filter, publishMode]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Wrapper>
        <Stack
          direction="row"
          alignItems="center"
        >
          <ExpandButton
            label="Global filters"
            onToggle={setIsFilerExpanded}
            value={isFilterExpanded}
            onReset={resetFilters}
            activeItemsCount={activeFiltersCount}
          />

          <ExpandButton
            label="Saved filters"
            onToggle={handleSavedFiltersClick}
            value={isSavedFiltersExpanded}
            useAnimationDelay={false}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
        >
          <DateRangeFilterComponent
            value={dateRangeFilter}
            relativeValue={dateRangeRelativeFilter}
            onSubmit={handleSubmitDateRangeFilter}
            isFetching={!isQueryConfigLoaded}
          />

          <ShareButton
            onClick={handleShareConfigClick}
            color="primary"
            variant="contained"
            loading={isShareLinkCopying}
          >
            <ShareIcon fontSize="small" />
          </ShareButton>
        </Stack>
      </Wrapper>

      <DashboardFilters
        initialFilters={filtersListBasedOnPublishMode}
        appliedFilters={selectedFilters}
        appliedSavedFilter={appliedSavedFilter}
        onChange={handleChangeFilters}
        onSubmit={handleSubmitFilters}
        onReset={handleResetAllFilters}
        onSave={handleSaveFilters}
        onUpdate={handleUpdateFilters}
        isFetching={isFetching}
        isSaveFetching={isCreateFilterFetching}
        isExpanded={isFilterExpanded}
        isSaveDisabled={!isSomeFiltersApplied}
        publishMode={publishMode}
      />

      <Popover
        anchorEl={savedFiltersAnchor}
        open={Boolean(savedFiltersAnchor)}
        onClose={handleSavedFiltersClose}
        BackdropProps={savedFiltersBackdropProps}
        anchorOrigin={savedFiltersPopoverAnchorOrigin}
        PaperProps={SavedFiltersPopoverPaperStyles}
      >
        <DashboardSavedFilters
          filters={savedFilters}
          selectedFilter={appliedSavedFilter}
          deletableFilterId={deletableFilterPayload?.filterId}
          onSelect={selectSavedFilter}
          onApply={applySavedFilter}
          onDelete={deleteSavedFilter}
          isDeleteFetching={isDeleteSavedFilterFetching}
          isFetching={isSavedFiltersFetching}
        />
      </Popover>
    </>
  );
});


DashboardHeader.displayName = 'DashboardHeader';


export default DashboardHeader;
