import { Spec } from 'immutability-helper';

import { Action  } from 'types/redux';

import { Entities } from 'core/dashboards/types';

import {
  FetchSavedUserViewsResolvedPayload,
  DeleteUserViewResolvedPayload,
  ActiveViewState,
  SavedView,
  State,
  SavedViewsMergedWithFields,
  SavedViewMergedWithFields,
  CreateUserViewResolvedPayload,
} from './types';


export function fetchUserViewsResolvedExtendedReducer (state: State, action: Action<string, FetchSavedUserViewsResolvedPayload>) {
  const { payload } = action;
  
  const savedUserViews = Object.entries(payload).reduce<SavedViewsMergedWithFields>((res, [key, views]) => {
    const prevSavedUserViews = state.savedUserViews.data as SavedViewsMergedWithFields;

    res[key as Entities] = [
      ...views,
      ...((prevSavedUserViews || {})[key as Entities] || []).filter((prevSavedView) => prevSavedView.isCustom),
    ] as SavedViewMergedWithFields[];

    return res;
  }, {});

  const scheme = Object.entries(payload).reduce<Spec<ActiveViewState>>((res, [entity, views]: [string, SavedView[]]) => {
    const defaultView = views.find(({ isDefault }) => isDefault);
    const spec = defaultView ? { $set: defaultView } : { $set: null };
    const currentActiveView = state.activeView[entity as Entities];
    const isCurrentActiveViewExists = savedUserViews[entity as Entities]?.find(({ id }) => id === currentActiveView?.id);

    if (currentActiveView && isCurrentActiveViewExists) {
      return res;
    }

    (res as any)[entity] = spec;

    return res;
  }, {});

  return {
    activeView: scheme,
    savedUserViews: {
      data: { $set: savedUserViews },
      isLoaded: { $set: true },
    },
  };
}

export function deleteUserViewResolvedExtendedReducer<State> (state: State, action: Action<string, DeleteUserViewResolvedPayload>) {
  const { payload: { viewId, entity } } = action;
  const { activeView, savedUserViews } = state as any; // TODO: fix types
  const entityActiveViewId = activeView[entity];

  if (entityActiveViewId !== viewId) {
    return {};
  }

  const defaultView = (savedUserViews.data[entity] as Array<SavedView>).find(({ isDefault }) => isDefault);

  return {
    activeView: {
      [entity]: { $set: defaultView },
    },
  };
}

export function createUserViewResolvedExtendedReducer<State> (state: State, action: Action<string, CreateUserViewResolvedPayload>) {
  const { payload } = action;

  return {
    activeView: {
      [payload.entity]: { $set: payload },
    },
  };
}
