import { handleActions, persistReducer, rejected, combineActions } from 'utils/redux';
import { AsyncActions } from 'types/redux';

import { Types as AuthTypes } from 'core/auth/types';

import {
  groupingSlice,
  filterBasicDataSlice,
  viewConfigurationSlice,
  dataStatusSlice,
  breakdownSlice,
  SliceType,
} from './slices';
import {
  State,
  SliceKeys,
  GroupingResolvedPayload,
  Types,
  FilterBasicDataResolvedPayload,
} from './types';
import persistConfig from './persist';


const defaultState: State = {
  ...groupingSlice.defaultState as Pick<State, SliceKeys>,
  ...filterBasicDataSlice.defaultState as Pick<State, SliceKeys>,
  ...viewConfigurationSlice.defaultState as Pick<State, SliceKeys>,
  ...dataStatusSlice.defaultState as Pick<State, SliceKeys>,
  ...breakdownSlice.defaultState as Pick<State, SliceKeys>,
};


const reducer = handleActions<
State,
Types & SliceType,
AsyncActions<SliceType, null, GroupingResolvedPayload & FilterBasicDataResolvedPayload>
>({
  ...groupingSlice.reducer,
  ...filterBasicDataSlice.reducer,
  ...viewConfigurationSlice.reducer,
  ...dataStatusSlice.reducer,
  ...breakdownSlice.reducer,

  [combineActions<AuthTypes>(
    rejected<AuthTypes>(AuthTypes.LOAD_AUTH),
    rejected<AuthTypes>(AuthTypes.SIGN_IN),
    AuthTypes.LOGOUT,
  )]: (): State => defaultState,
}, defaultState);


export default persistReducer<State>(persistConfig, reducer as any); // TODO: apply types for async actions

