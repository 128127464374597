import { createFetchingSlice } from 'core/fetching';

import { waitForUserSession } from 'core/app/sagas';
import { GetFetchingSliceTypes } from 'core/fetching/types';

import SettingsService from './service';
import { KEY } from './constants';
import {
  groupingModel,
  filterBasicDataResponseModel,
  viewConfigurationResponseModel,
  dataStatusResponseModel,
} from './models';
import {
  SliceKeys,
  GroupingResolvedPayload,
  FilterBasicDataResolvedPayload,
  ViewConfigurationResolvedPayload,
  DataStatusResolvedPayload,
  BreakdownResolvedPayload,
} from './types';


export const groupingSlice = createFetchingSlice<
SliceKeys,
null,
GroupingResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.Grouping,
  serviceMethod: SettingsService.fetchGrouping,
  models: [groupingModel, null],
  sagas: [waitForUserSession, null],
});

export const filterBasicDataSlice = createFetchingSlice<
SliceKeys,
null,
FilterBasicDataResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.FilterBasicData,
  serviceMethod: SettingsService.fetchFilterBasicData,
  models: [filterBasicDataResponseModel, null],
  sagas: [waitForUserSession, null],
});

export const viewConfigurationSlice = createFetchingSlice<
SliceKeys,
null,
ViewConfigurationResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.ViewConfiguration,
  serviceMethod: SettingsService.fetchViewConfiguration,
  models: [viewConfigurationResponseModel, null],
  sagas: [waitForUserSession, null],
});

export const dataStatusSlice = createFetchingSlice<
SliceKeys,
null,
DataStatusResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.DataStatus,
  serviceMethod: SettingsService.fetchDataStatus,
  models: [dataStatusResponseModel, null],
  sagas: [waitForUserSession, null],
});

export const breakdownSlice = createFetchingSlice<
SliceKeys,
null,
BreakdownResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.Breakdown,
  serviceMethod: SettingsService.fetchBreakdown,
  models: [null, null],
  sagas: [waitForUserSession, null],
});


export type SliceType = (
  GetFetchingSliceTypes<typeof groupingSlice> |
  GetFetchingSliceTypes<typeof viewConfigurationSlice> |
  GetFetchingSliceTypes<typeof filterBasicDataSlice> |
  GetFetchingSliceTypes<typeof dataStatusSlice> |
  GetFetchingSliceTypes<typeof breakdownSlice>
);
