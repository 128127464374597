import { UserPermissionOverrideList, UserRole } from 'core/users/types';
import { Action, ActionCreator } from 'types/redux';


export enum Types {
  FETCH_ALL = 'ADMIN/USERS/FETCH_ALL',
  FETCH_ONE = 'ADMIN/USERS/FETCH_ONE',
  FETCH_ONE_RESET = 'ADMIN/USERS/FETCH_ONE_RESET',
}

export interface Actions extends ActionCreator<Types, Payload, null> {
  fetchAll: () => Action<Types, null, null>;
  fetchOne: (payload: FetchOneUserPayload) => Action<Types, Payload, null>;
  fetchOneReset: () => Action<Types, null, null>;
}

export type UserForm = {
  email: string;
  fullName: string;
  role: UserRole;
  permissionTemplateId: number | null;
  permissionList: UserPermissionOverrideList;
};

export type CreateUserPayload = {
  data: UserForm;
};

export type EditUserPayload = {
  data: UserForm;
  userId: number;
};

export type FetchOneUserPayload = {
  userId: number;
};

export type RejectedPayload = {
  error: any;
};

export type Payload = FetchOneUserPayload & EditUserPayload;

export type User = {
  id: number;
  email: string;
  status: string;
  fullName: string;
  role: UserRole;
  permissionTemplateId?: number | null;
  permissionList?: UserPermissionOverrideList | null;
  lastSeenAt?: string | null;
};

export type UserList = Omit<User, 'permissionList' | 'permissionTemplateId' >[];

export interface State {
  fetchAll: {
    data: UserList;
    isLoading: boolean;
    error: any;
  };
  fetchOne: {
    data: User | null;
    isLoading: boolean;
    error: any;
  };
}

export type FetchAllResolvedPayload = UserList;
export type FetchOneResolvedPayload = User;
