import React, { memo } from 'react';

import List from 'components/common/list';

// import DashboardSavedFiltersSkeleton from './skeleton';
import { Props } from './types';
import { extractListKey } from './utils';


const DashboardSavedViews: React.FunctionComponent<Props> = memo(({ views }) => (
  <List
    extractItemKey={extractListKey}
    items={views.items}
    isSearchable={views.isSearchable}
    noResultsText="No saved views"
    // TODO: add skeleton
  />
));


DashboardSavedViews.displayName = 'DashboardSavedViews';


export default DashboardSavedViews;
