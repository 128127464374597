import { GroupingResolvedPayload } from 'core/settings/types';

import { Props as DataGridHeaderProps, ListItemWithNestedActionsProps } from 'components/dashboard/grid-header/types';
import SelectableContainer from 'components/common/selectable-container';
import { getGroupingConfig } from 'components/dashboard/grid/utils'; // eslint-disable-line import/no-cycle


export const getGroupingActions = (
  currentTab: number,
  groupingConfig: GroupingResolvedPayload,
  onGroupingChange: (key: string, tabKey: string) => void,
  selectedGrouping: string | null,
): DataGridHeaderProps['groupingActions'] => {
  const defaultConfig = {
    isSearchable: true,
    items: [],
  };

  const { config, tabKey } = getGroupingConfig(currentTab, groupingConfig);

  if (!config || !tabKey) {
    return defaultConfig;
  }

  const items = config.reduce<Array<ListItemWithNestedActionsProps>>((result, groupConfig) => {
    const { key, title } = groupConfig;

    if (!key) {
      return result;
    }

    result.push({
      key,
      title,
      renderContent: (action) => (
        <SelectableContainer
          isSelected={selectedGrouping === key}
        >
          {action.title}
        </SelectableContainer>
      ),
      onClick: () => onGroupingChange(key, tabKey),
      disableRipple: true,
    });

    return result;
  }, []);

  return {
    ...defaultConfig,
    items,
  };
};
