import React, { memo, useCallback, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import Fade from '@mui/material/Fade';

import { Props } from './types';
import { DEFAULT_COLOR_ALPHA } from './constants';
import { StyledSelectedIndicator, ResetButton } from './styles';


const SelectedIndicator: React.FunctionComponent<Props> = memo(({ color, children, onReset, colorAlpha = DEFAULT_COLOR_ALPHA, isActive = false, ...otherProps }) => {
  const [isResetButtonVisible, setIsResetButtonVisible] = useState(false);

  const handleReset = useCallback((event) => {
    event.stopPropagation();

    if (onReset) {
      onReset();
    }
  }, [onReset]);

  const handleMouseEnterIndicator = useCallback(() => {
    setIsResetButtonVisible(true);
  }, []);

  const handleMouseLeaveIndicator = useCallback(() => {
    setIsResetButtonVisible(false);
  }, []);

  return (
    <StyledSelectedIndicator
      onMouseEnter={handleMouseEnterIndicator}
      onMouseLeave={handleMouseLeaveIndicator}
      $color={color}
      $colorAlpha={colorAlpha}
      {...otherProps}
    >
      <span>{children}</span>
  
      <Fade in={isResetButtonVisible}>
        <ResetButton
          size="medium"
          aria-label="clear"
          $isActive={isActive}
          $color={color}
          onClick={handleReset}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          component="div" // NOTE: MUI doesn't support component prop with styled components
        >
          <CancelIcon
            fontSize="inherit"
          />
        </ResetButton>
      </Fade>
    </StyledSelectedIndicator>
  );
});


SelectedIndicator.displayName = 'SelectedIndicator';


export default SelectedIndicator;
