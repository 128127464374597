import { memo, FunctionComponent, useState, useCallback, MouseEvent, useMemo } from 'react';

import Popover, { PopoverOrigin } from '@mui/material/Popover';

import { isFunction } from 'utils';

import { Props, ChildAsFunction } from './types';


const Dropdown: FunctionComponent<Props> = memo(({ children, renderButton, anchorOrigin, ...otherProps }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const backdropProps = useMemo(() => ({
    invisible: true,
  }), []);

  const popoverAnchorOrigin = useMemo<PopoverOrigin>(() => ({
    vertical: 'bottom',
    horizontal: 'left',
    ...(anchorOrigin || {}),
  }), [anchorOrigin]);

  const button = useMemo(() => renderButton(
    {
      onClick: handleClick,
    },
    Boolean(anchor),
  ), [anchor, renderButton, handleClick]);

  const childrenElement = useMemo(() => {
    if (isFunction(children)) {
      return (children as ChildAsFunction)({ close: handleClose });
    }

    return children;
  }, [children, handleClose]);

  return (
    <>
      {button}

      <Popover
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        BackdropProps={backdropProps}
        anchorOrigin={popoverAnchorOrigin}
        {...otherProps}
      >
        {childrenElement}
      </Popover>
    </>
  );
});


Dropdown.displayName = 'Dropdown';


export default Dropdown;
