import React, { memo, Fragment } from 'react';

import MuiTabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';

import Tab from './tab';

import { StyledTabs, StyledPaper } from './styles';
import { TabsProps } from './types';


const Tabs: React.FunctionComponent<TabsProps> = memo(({ config, onChange, value, ...otherProps }) => (
  <>
    <StyledTabs
      as={MuiTabs}
      value={value}
      onChange={onChange}
      $color={config[value].color}
      {...otherProps}
    >
      {config.map(({ title, renderIcon, selectedCount, onSelectionChange, color, backgroundColor, key }, index) => (
        <Tab
          key={key}
          tabKey={key}
          value={index}
          onClick={onChange}
          title={title}
          isActive={index === value}
          renderIcon={renderIcon}
          selectedCount={selectedCount}
          onSelectionChange={onSelectionChange}
          color={color}
          backgroundColor={backgroundColor}
        />
      ))}
    </StyledTabs>

    <StyledPaper as={Paper}>
      {config.map(({ content }, index) => (
        <Fragment
          key={index} // eslint-disable-line react/no-array-index-key
        >
          {value === index ? content : null}
        </Fragment>
      ))}
    </StyledPaper>
  </>
));


Tabs.displayName = 'Tabs';


export default Tabs;
