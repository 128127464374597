import { MouseEvent } from 'react';

import Popover, { PopoverProps } from '@mui/material/Popover';

import { ListItemProps } from 'components/common/list/types';
import ColorPicker from 'components/common/color-picker';
import UnselectIconButton from 'components/common/unselect-icon-button';

import { ContextMenuListItem } from './styles';
import { ContextMenuKeys } from './types';


export const getContextMenuOptions = (
  onContextMenuItemClick: (event: MouseEvent<HTMLElement>, key: ContextMenuKeys) => void,
  onHeatmapChange: (color: string | null) => void,
  isHeatmapActive: boolean,
  isHeatmapAllowed: boolean,
  heatmapPopoverProps: PopoverProps,
): ListItemProps[] => [
  ...(isHeatmapAllowed ? [{
    key: ContextMenuKeys.Heatmap,
    title: 'Color heatmap',
    disableRipple: true,
    isActive: isHeatmapActive,
    renderContent: ({ title }: any) => ( // TODO: fix types
      <>
        <ContextMenuListItem>
          {title}

          {isHeatmapActive && (
            <UnselectIconButton
              onClick={(event: MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                onHeatmapChange(null);
              }}
            />
          )}
        </ContextMenuListItem>

        <Popover
          {...heatmapPopoverProps}
        >
          <ColorPicker
            onChange={onHeatmapChange}
            useCircles
          />
        </Popover>
      </>
    ),
    onClick: (event: MouseEvent<HTMLElement>) => onContextMenuItemClick(event, ContextMenuKeys.Heatmap),
  }] : []),
];
