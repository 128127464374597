import React, { memo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';


import { SkeletonProps } from './types';
import { StyledSavedFilter } from './styles';


const DashboardSavedFiltersSkeleton: React.FunctionComponent<SkeletonProps> = memo(({ size = 5 }) => (
  <>
    {
      Array(size).fill(null).map((_item, index) => (
        <StyledSavedFilter
          key={index}
          $isSelected={false}
          $isDisabled={false}
          $isActive={false}
        >
          <Typography
            variant="body2"
          >
            <Skeleton
              variant="text"
              animation="pulse"
              width="50%"
            />
          </Typography>
  
          <Typography
            variant="caption"
          >
            <Skeleton
              variant="text"
              animation="pulse"
            />
          </Typography>
        </StyledSavedFilter>
      ))
    }
  </>
));


DashboardSavedFiltersSkeleton.displayName = 'DashboardSavedFiltersSkeleton';


export default DashboardSavedFiltersSkeleton;
