
import { Payload as BasePayload, DefaultMeta, Action, ActionCreators } from 'types/redux';

import { FetchingSliceState } from 'core/fetching/types'; // eslint-disable-line import/no-cycle
import { // eslint-disable-line import/no-cycle
  CurrentFilter,
  DateRangeRelativeFilter,
  Entities,
  PublishModes,
  ViewConfig,
  HeatmapModel,
} from 'core/dashboards/types';
import { ViewColumn } from 'core/settings/types';  // eslint-disable-line import/no-cycle


export enum Types {
  SET_VIEW = 'USERS/SET_VIEW',
  SET_SHARED_VIEWS = 'USERS/SET_SHARED_VIEWS',
  UPDATE_VIEW = 'USERS/UPDATE_VIEW',
  ADD_VIEW = 'USERS/ADD_VIEW',
  DELETE_VIEW = 'USERS/DELETE_VIEW',
  SET_SAVED_VIEWS_LOADED = 'USERS/SET_SAVED_VIEWS_LOADED',
}

export interface Actions extends ActionCreators<Types, Payload, null> {
  setView: (payload: SetViewPayload) => Action<Types, Payload, null>;
  setSharedViews: (payload: SetSharedViewsPayload) => Action<Types, Payload, null>;
  updateView: (payload: UpdateViewPayload) => Action<Types, Payload, null>;
  addView: (payload: AddViewPayload) => Action<Types, Payload, null>;
  deleteView: (payload: DeleteViewPayload) => Action<Types, Payload, null>;
  setSavedViewsLoaded: () => Action<Types, Payload, null>;
}


export enum SliceKeys {
  AuthorizedUser = 'authorizedUser',

  CreateUserFilter = 'createUserFilter',
  SavedUserFilters = 'savedUserFilters',
  DeleteUserFilter = 'deleteUserFilter',
  UpdateUserFilter = 'updateUserFilter',

  SavedUserViews = 'savedUserViews',
  CreateUserView = 'createUserView',
  UpdateUserView = 'updateUserView',
  DeleteUserView = 'deleteUserView',
}

export interface State {
  [SliceKeys.AuthorizedUser]: FetchingSliceState<User | BasePayload>;

  [SliceKeys.CreateUserFilter]: FetchingSliceState<BasePayload>;
  [SliceKeys.SavedUserFilters]: FetchingSliceState<FetchSavedUserFiltersResolvedPayload>;
  [SliceKeys.DeleteUserFilter]: FetchingSliceState<DeleteUserFilterResolvedPayload>;
  [SliceKeys.UpdateUserFilter]: FetchingSliceState<BasePayload>;

  [SliceKeys.SavedUserViews]: FetchingSliceState<BasePayload> & { isLoaded?: boolean };
  [SliceKeys.CreateUserView]: FetchingSliceState<BasePayload>;
  [SliceKeys.UpdateUserView]: FetchingSliceState<BasePayload>;
  [SliceKeys.DeleteUserView]: FetchingSliceState<BasePayload>;

  activeView: ActiveViewState;
}

export interface UserResponse {
  id: number;
  email: string;
  fullName: string;
  role: UserRole;
  permissionList: UserPermissionOverrideList;
  compiledPermissions: string[];
}

export interface User {
  id: number;
  email: string;
  name: string;
  role: UserRole;
  roleDisplay: string;
  compiledPermissions: string[];
}

export enum UserRole {
  RegularUser = 0,
  Admin = 1,
  SuperAdmin = 2,
}

export const UserRoleDisplay: Record<UserRole, string> = {
  [UserRole.RegularUser]: 'Regular User',
  [UserRole.Admin]: 'Admin',
  [UserRole.SuperAdmin]: 'Super Admin',
};

/*
export const UserRoleDisplay: Record<string, string> = {
  RegularUser: 'Regular User',
  Admin: 'Admin',
  SuperAdmin: 'Super Admin',
};
 */

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
}

export type UserPermissionOverrideList = {
  [key: string]: boolean;
};

export interface SavedFilter {
  id?: number;
  name: string;
  description: string;
  isDefault?: boolean;
  value: {
    filters: Array<CurrentFilter>;
    dateRangeRelativeFilter?: DateRangeRelativeFilter;
    publishMode: PublishModes;
  };
}

export type SavedViewConfig = ViewConfig;

export interface BackendSavedViewValue {
  fields: string[];
  pinnedColumns: string[];
  config: SavedViewConfig;
  heatmap: HeatmapModel[];
}

export interface BackendSavedView {
  value: BackendSavedViewValue;
  id: number;
  isDefault: boolean;
  isRemovable: boolean;
  name: string;
  entity: Uppercase<Entities>;
}

export interface SavedView extends Omit<BackendSavedView, 'entity'> {
  entity: Entities;
  isCustom?: boolean;
  isEdited?: boolean;
  isShared?: boolean;
}

export type SavedViewMergedWithFields = Omit<SavedView, 'value'> & {
  value: Omit<BackendSavedViewValue, 'fields'> & {
    fields: ViewColumn[];
  };
};

export type SavedViews = {
  [key in Entities]?: SavedView[];
};

export type SavedViewsMergedWithFields = {
  [key in Entities]?: SavedViewMergedWithFields[];
};

export interface FetchUserPayload {
  userId: number;
}

export interface UserFilterPayload {
  filterId: number;
}

export interface UserViewPayload {
  viewId?: number;
  viewName: string;
}

export interface CreateUserFilterPayload extends FetchUserPayload {
  data: SavedFilter;
}
export interface DeleteUserFilterPayload extends FetchUserPayload, UserFilterPayload {}
export interface UpdateUserFilterPayload extends FetchUserPayload, UserFilterPayload {
  data: Partial<SavedFilter>;
}

export type FetchSavedUserFiltersResolvedPayload = Array<SavedFilter>;
export type DeleteUserFilterResolvedPayload = UserFilterPayload;
export type UpdateUserFilterResolvedPayload = UserFilterPayload;
export type CreateUserFilterResolvedPayload = Pick<SavedFilter, 'id'>;


export interface CreateViewPayload {
  data: Partial<SavedView>;
}

export interface CreateUserViewPayload extends FetchUserPayload, UserViewPayload, CreateViewPayload {}

export type CreateUserViewMeta = DefaultMeta<CreateViewPayload>;

export interface DeleteUserViewPayload extends FetchUserPayload, UserViewPayload {
  entity: Entities;
}
export interface UpdateUserViewPayload extends FetchUserPayload, UserViewPayload {
  data: Partial<Omit<SavedView, 'id'>>;
}

export type FetchSavedUserViewsResolvedPayload = SavedViews;
export type UpdateUserViewResolvedPayload = UserViewPayload;
export type DeleteUserViewResolvedPayload = DeleteUserViewPayload;
export type CreateUserViewResolvedPayload = SavedView;

export type FetchingActionsPayload = FetchUserPayload & CreateUserFilterPayload & DeleteUserFilterPayload & UpdateUserFilterPayload & CreateUserViewPayload & DeleteUserViewPayload & UpdateUserViewPayload;

export type FetchingActionsResolvedPayload = (
  User &
  FetchSavedUserFiltersResolvedPayload &
  DeleteUserFilterResolvedPayload &
  CreateUserFilterResolvedPayload &
  FetchSavedUserViewsResolvedPayload &
  DeleteUserViewResolvedPayload &
  CreateUserViewResolvedPayload &
  UpdateUserViewResolvedPayload
);

export interface DeleteUserFilterMeta extends DefaultMeta {
  useListFetching: boolean;
}

export type Meta = DeleteUserFilterMeta & CreateUserViewMeta;

export interface UpdateViewPayload {
  entity: Entities;
  view: Partial<SavedViewMergedWithFields>;
}

export interface AddViewPayload {
  entity: Entities;
  view: Pick<SavedViewMergedWithFields, 'value' | 'name'>;
}

export interface DeleteViewPayload {
  entity: Entities;
  view: SavedViewMergedWithFields;
}

export interface SetViewPayload {
  entity: Entities;
  view: SavedViewMergedWithFields;
  saveAsNew?: boolean;
}

export type SetSharedViewsPayload =  {
  [key in Entities]: Pick<SavedView, 'entity' | 'value'>;
};

export type ActiveViewState = {
  [key in Entities]: SavedViewMergedWithFields | null;
};

export type Payload = UpdateViewPayload & SetViewPayload & AddViewPayload & DeleteViewPayload & SetSharedViewsPayload;
