import { FunctionComponent, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, MenuItem, MenuList } from '@mui/material';

import AdminPermissionTemplateService from 'core/admin/permission-templates/service';
import { UserRoleDisplay } from 'core/users/types';
import { actions as adminPermissionTemplatesAction } from 'core/admin/permission-templates/actions';
import Dropdown from 'components/common/dropdown';
import { ButtonProps as DropdownButtonProps } from 'components/common/dropdown/types';
import ConfirmationModal from 'components/common/confirmation-modal';
import { ROUTES } from 'containers/router/constants';
import SnackbarService from 'services/snackbar';

import { Props } from './types';


const PermissionsRowAction: FunctionComponent<Props> = memo(({ permissionTemplate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickEdit = useCallback(() => {
    navigate(ROUTES.PERMISSIONS_EDIT.replace(':permissionTemplateId', permissionTemplate.id.toString()));
  }, [permissionTemplate, navigate]);

  const [isOpenDeleteConfirmationModal, setIsOpenDeleteConfirmationModal] = useState(false);
  const handleClickDelete = useCallback(() => {
    setIsOpenDeleteConfirmationModal(true);
  }, [setIsOpenDeleteConfirmationModal]);
  const handleCloseDeleteConfirmationModal = useCallback(() => {
    setIsOpenDeleteConfirmationModal(false);
  }, [setIsOpenDeleteConfirmationModal]);
  const handleSubmitDelete = useCallback(() => {
    AdminPermissionTemplateService.delete({
      permissionTemplateId: permissionTemplate.id,
    }).then(() =>{
      SnackbarService.showSuccess(
        `Permission template ${permissionTemplate.name} deleted.`,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
      dispatch(adminPermissionTemplatesAction.fetchAll());
    }).catch((e: any) =>{
      const message = e.response?.data ?? (e as Error).message;
      SnackbarService.showError(
        message,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    });
    handleCloseDeleteConfirmationModal();
  }, [permissionTemplate, handleCloseDeleteConfirmationModal, dispatch]);

  const [isOpenSetDefaultConfirmationModal, setIsOpenSetDefaultConfirmationModal] = useState(false);
  const handleClickSetDefault = useCallback(() => {
    setIsOpenSetDefaultConfirmationModal(true);
  }, [setIsOpenSetDefaultConfirmationModal]);
  const handleCloseSetDefaultConfirmationModal = useCallback(() => {
    setIsOpenSetDefaultConfirmationModal(false);
  }, [setIsOpenSetDefaultConfirmationModal]);
  const handleSubmitSetDefault = useCallback(() => {
    AdminPermissionTemplateService.setDefault({
      permissionTemplateId: permissionTemplate.id,
    }).then(() =>{
      SnackbarService.showSuccess(
        `Permission template ${permissionTemplate.name} set as default for ${UserRoleDisplay[permissionTemplate.role]}.`,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
      dispatch(adminPermissionTemplatesAction.fetchAll());
    }).catch((e: any) =>{
      const message = e.response?.data ?? (e as Error).message;
      SnackbarService.showError(
        message,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    });
    handleCloseSetDefaultConfirmationModal();
  }, [permissionTemplate, handleCloseSetDefaultConfirmationModal, dispatch]);

  const renderButton = useCallback((props: DropdownButtonProps, isOpen: boolean) => (
    <IconButton
      {...props}
    >
      <MenuIcon />
    </IconButton>
  ), []);

  return (
    <>
      <Dropdown
        renderButton={renderButton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx:{ padding: '4px 0' },
        }}
      >
        <MenuList
          sx={{
            padding: '0',
            '& .MuiMenuItem-root': {
              padding: '4px 12px',
              borderBottom: 0,

              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                color: 'inherit',
              },
            },
          }}
        >
          <MenuItem onClick={handleClickEdit} >Edit</MenuItem>
          <MenuItem onClick={handleClickSetDefault}>Set Default</MenuItem>
          <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
        </MenuList>

      </Dropdown>
      <ConfirmationModal
        isOpen={isOpenSetDefaultConfirmationModal}
        onClose={handleCloseSetDefaultConfirmationModal}
        onSubmit={handleSubmitSetDefault}
        title={`Switch default permission template for role ${UserRoleDisplay[permissionTemplate.role]} to ${permissionTemplate.name}`}
        confirmText="Confirm"
      />
      <ConfirmationModal
        isOpen={isOpenDeleteConfirmationModal}
        onClose={handleCloseDeleteConfirmationModal}
        onSubmit={handleSubmitDelete}
        title={`Delete ${permissionTemplate.name}`}
        confirmText="Confirm"
      />
    </>
  );
});

PermissionsRowAction.displayName = 'PermissionsRowAction';

export default PermissionsRowAction;
