import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { State, Entities } from './types';
import { KEY } from './constants';


const persistConfig: PersistConfig<State> = {
  key: KEY,
  storage,
  blacklist: [
    ...Object.values(Entities),
    'currentConfig',
    'queryConfig',
    'selectedFiltersValues',
    'isFiltersExpanded',
  ],
};


export default persistConfig;
