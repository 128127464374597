import { memo, FunctionComponent, useState, useCallback, useEffect } from 'react';

import Button from '@mui/material/Button';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DateRange, DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { getDateRangeForRelativeFilter } from 'core/dashboards/utils';

import DateRangePickerShortcuts from './shortcuts';

import { ValidationSchema } from './validation';
import { getDefaultValue } from './utils';
import { Props } from './types';
import { Wrapper, Header, ControlsWrapper, FieldsWrapper, StyledTextField } from './styles';


const DateRangePicker: FunctionComponent<Props> = memo(({ value: initialValue, relativeValue: initialRelativeValue, onClose, onSubmit }) => {
  const [relativeValue, setRelativeValue] = useState(initialRelativeValue);
  const [value, setValue] = useState<DateRange<Date>>(() => getDefaultValue(initialValue, relativeValue));
  const [error, setError] = useState(false);

  const handleResetDates = useCallback(() => {
    setValue([null, null]);
  }, []);

  const handleRelativeValueChange = useCallback((nextRelativeValue: NonNullable<Props['relativeValue']>) => {
    const nextValue = getDateRangeForRelativeFilter(nextRelativeValue) as DateRange<Date>;
    setRelativeValue(nextRelativeValue);
    setValue(nextValue);
  }, []);

  const handleChange = useCallback((nextValue: DateRange<Date>) => {
    const [nextStartDate, nextEndDate] = nextValue;

    if (nextStartDate) {
      nextStartDate.setHours(0, 0, 0);
    }

    if (nextEndDate) {
      nextEndDate.setHours(23, 59, 59);
    }

    setValue([nextStartDate, nextEndDate]);
    setRelativeValue(undefined);
  }, []);

  const handleSubmit = useCallback(() => {
    const [startDate, endDate] = value;
    onSubmit({ from: startDate as Date, to: endDate as Date }, relativeValue);
  }, [value, relativeValue, onSubmit]);

  const renderStaticPickerInputs = useCallback(() => ( // NOTE: we don't need inputs for statis view
    <div />
  ), []);

  const renderPickerInputs = useCallback((startProps: TextFieldProps, endProps: TextFieldProps) => {
    const { label: startLabel, ...restStartProps } = startProps;
    const { label: endLabel, ...restEndProps } = endProps;

    return (
      <FieldsWrapper>
        <StyledTextField
          as={TextField}
          {...restStartProps}
          inputProps={{
            ...restStartProps.inputProps,
            placeholder: 'Start',
          }}
          fullWidth
          error={error}
        />

        <StyledTextField
          as={TextField}
          {...restEndProps}
          inputProps={{
            ...restEndProps.inputProps,
            placeholder: 'End',
          }}
          fullWidth
          error={error}
        />
      </FieldsWrapper>
    );
  }, [error]);

  useEffect(() => {
    const [from, to] = value;

    try {
      ValidationSchema.validateSync({ from, to });
      setError(false);
    } catch (e) {
      setError(true);
    }
  }, [value]);

  return (
    <Wrapper>
      <Header>
        <Typography variant="subtitle2">
          Date Range
        </Typography>

        <Stack direction="row">
          <Button
            variant="text"
            onClick={handleResetDates}
          >
            Reset dates
          </Button>

          <IconButton onClick={onClose}>
            <ExpandLessIcon />
          </IconButton>
        </Stack>
      </Header>

      <Stack direction="row" >
        <ControlsWrapper>
          <MuiDateRangePicker
            disableOpenPicker
            value={value}
            onChange={handleChange}
            renderInput={renderPickerInputs}
            disableFuture
          />

          <Button
            variant="contained"
            disabled={error}
            onClick={handleSubmit}
          >
            Run Report
          </Button>

          <DateRangePickerShortcuts
            value={relativeValue}
            onSelect={handleRelativeValueChange}
          />
        </ControlsWrapper>

        <StaticDateRangePicker
          displayStaticWrapperAs="desktop"
          value={value}
          onChange={handleChange}
          renderInput={renderStaticPickerInputs}
          disableHighlightToday
          disableFuture
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          open // NOTE: we need to specify open prop otherwise month authoswitching is not working
        />
      </Stack>
    </Wrapper>
  );
});


DateRangePicker.displayName = 'DateRangePicker';


export default DateRangePicker;
