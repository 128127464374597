import { useState, useCallback, FunctionComponent } from 'react';
import { CirclePicker, ChromePicker, ColorResult } from 'react-color';

import Button from '@mui/material/Button';

import { Props } from './types';
import { Wrapper, ChromePickerStyles, SaveButton, CIRCLE_SIZE, CIRCLE_SPACING } from './styles';


const ColorPicker: FunctionComponent<Props> = ({ onChange, useCircles, ...otherProps }) => {
  const [color, setColor] = useState('#ffffff');

  const handleChange = useCallback(({ hex }: ColorResult) => {
    setColor(hex);
  }, []);

  const handleChangeComplete = useCallback(({ hex }: ColorResult) => {
    onChange(hex);
  }, [onChange]);

  const handleSubmit = useCallback(() => {
    onChange(color);
  }, [onChange, color]);

  return (
    <Wrapper>
      {useCircles ? (
        <CirclePicker
          color={color}
          onChangeComplete={handleChangeComplete}
          onChange={handleChange}
          circleSize={CIRCLE_SIZE}
          circleSpacing={CIRCLE_SPACING}
          {...otherProps}
        />
      ) : (
        <>
          <ChromePicker
            color={color}
            onChange={handleChange}
            styles={ChromePickerStyles}
            disableAlpha
            {...otherProps}
          />

          <SaveButton
            as={Button}
            variant="outlined"
            onClick={handleSubmit}
            fullWidth
          >
            Save
          </SaveButton>
        </>
      )}
    </Wrapper>
  );
};


ColorPicker.displayName = 'ColorPicker';


export default ColorPicker;
