import React, { memo, useCallback, useState, useMemo, useRef, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from 'react-beautiful-dnd';
import { arrayMoveImmutable } from 'array-move';

import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DragIndicatorTwoToneIcon from '@mui/icons-material/DragIndicatorTwoTone';
import Button from '@mui/material/Button';

import SearchInput from 'components/common/search-input';
import TabList from 'components/common/tab-list';

import { KeyCodes } from 'constants/events';

import Field from '../field';

import {
  Wrapper,
  TitleWrapper,
  SearchWrapper,
  CategoryWrapper,
  CategoryLabel,
  ToggleExpandIcon,
  DragHandleIcon,
  DraggableContent,
  CategoryInnerWrapper,
  AddAllButton,
  RemoveAllButton,
  HeaderActionButton,
} from './styles';
import { EXPAND_ALL_SEARCH_LENGTH } from './constants';
import { getIsDeleteAllAvailable, getFieldsKeys } from './utils';
import { Props } from './types';


const FieldsList: React.FunctionComponent<Props> = memo(({ // TODO: refactor!!!
  title,
  fields,
  isAddable = false,
  getIsAdded = () => false,
  isDeletable = false,
  onAdd,
  onDelete,
  isSearchable,
  categories,
  isFullListItemAction = false,
  isSortable = false,
  onSort,
  focusSearchOnMount = false,
  isDeleteAllAvailable = false,
  isExpandAllAvailable = false,
}) => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [searchText, setSearchText] = useState<string>('');
  const [isAllExpanded, setIsAllExpanded] = useState<boolean>(false);
  const [tabCategory, setTabCategory] = useState<string | null>(null);

  const filteredFields = useMemo(() => {
    const availableFields = fields.filter(({ available }) => available);

    if (!isSearchable || !searchText.trim().length) {
      return availableFields;
    }

    return availableFields.filter((field) => (
      field.name.trim().toLowerCase().includes(searchText.trim().toLowerCase()) ||
      field.source.trim().toLowerCase().includes(searchText.trim().toLowerCase()) ||
      field.category.trim().toLowerCase().includes(searchText.trim().toLowerCase())
    ));
  }, [searchText, fields, isSearchable]);

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchText(value);
  }, []);

  const handleSearchKeyPress = useCallback((event: React.KeyboardEvent) => {
    if (event.code !== KeyCodes.Escape && event.code !== KeyCodes.Tab) {
      event.stopPropagation();
    }
  }, []);

  const handleDragEnd = useCallback((result: DropResult) => {
    if (!result.destination) {
      return null;
    }

    const items = arrayMoveImmutable(
      fields,
      result.source.index,
      result.destination.index,
    );

    if (onSort) {
      onSort(items);
    }
  }, [fields, onSort]);

  const toggleExpandAll = useCallback(() => {
    setIsAllExpanded((prevIsAllExpanded) => !prevIsAllExpanded);
  }, []);

  const handleAdd = useCallback((fieldKey: string) => {
    onAdd([fieldKey]);
  }, [onAdd]);

  const handleDelete = useCallback((fieldKey: string) => {
    onDelete([fieldKey]);
  }, [onDelete]);

  const handleAddAll = useCallback((event: React.SyntheticEvent<HTMLButtonElement>, list: Props['fields']) => {
    event.stopPropagation();

    const keys = getFieldsKeys(list.filter((field) => !getIsAdded(field)));

    onAdd(keys);
  }, [onAdd, getIsAdded]);

  const handleDeleteAll = useCallback((event: React.SyntheticEvent<HTMLButtonElement>, list: Props['fields']) => {
    event.stopPropagation();
    
    const keys = getFieldsKeys(list.filter(({ primary }) => !primary));

    onDelete(keys);
  }, [onDelete]);

  const handleDeleteAllFromHeader = useCallback((event: React.SyntheticEvent<HTMLButtonElement>) => {
    handleDeleteAll(event, filteredFields);
  }, [handleDeleteAll, filteredFields]);

  const renderField = useCallback((field, draggableProvided?: DraggableProvided) => (
    <Field
      {...field}
      key={field.key}
      fieldKey={field.key}
      isAddable={isAddable}
      isDeletable={isDeletable}
      isAdded={getIsAdded(field)}
      onAdd={handleAdd}
      onDelete={handleDelete}
      isFullWidthAction={isFullListItemAction}
      {...(draggableProvided ? {
        beforeElement: (
          <div
            {...draggableProvided?.dragHandleProps}
          >
            <DragHandleIcon as={DragIndicatorTwoToneIcon} />
          </div>
        ),
      } : {})}
    />
  ), [getIsAdded, isAddable, isDeletable, handleAdd, handleDelete, isFullListItemAction]);

  const renderCategory = useCallback((name: string, list: Props['fields'], onExpandToggle, isExpanded) => {  
    if (list.length === 0) {
      return null;
    }

    const countOfAdded = list.reduce<number>((sum, field) => {
      const isAdded = getIsAdded(field);
      return sum + (isAdded ? 1 : 0);
    }, 0);

    const isAllAdded = countOfAdded === list.length;

    return (
      <CategoryWrapper
        onClick={() => onExpandToggle(name)}
        $isExpanded={isExpanded}
      >
        <CategoryInnerWrapper>
          <ToggleExpandIcon
            as={isExpanded ? ExpandMoreIcon : ChevronRightIcon}
          />

          <CategoryLabel
            as={Typography}
            variant="subtitle1"
          >
            {name}
          </CategoryLabel>

          <Typography
            variant="body1"
            color="secondary"
            ml={0.675}
          >
            {countOfAdded}/{list.length}
          </Typography>
        </CategoryInnerWrapper>

        {isAllAdded ? (
          getIsDeleteAllAvailable(list) ? (
            <RemoveAllButton
              as={Button}
              variant='text'
              color="primary"
              onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => handleDeleteAll(event, list)}
            >
              Reset
            </RemoveAllButton>
          ) : null
        ) : (
          <AddAllButton
            as={Button}
            variant='text'
            color="primary"
            onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => handleAddAll(event, list)}
          >
            Add all
          </AddAllButton>
        )}
      </CategoryWrapper>
    );
  }, [getIsAdded, handleAddAll, handleDeleteAll]);

  const noOptions = useMemo(() => <Typography>No options</Typography>, []);

  const list = useMemo(() => {
    if (categories) {
      return (
        <TabList
          data={filteredFields}
          withAllTab
          categories={categories}
          renderItem={renderField}
          renderCategory={renderCategory}
          noOptionsElement={noOptions}
          expandAll={(searchText.length > EXPAND_ALL_SEARCH_LENGTH) || isAllExpanded}
          onCategoryChange={setTabCategory}
        />
      );
    }

    if (!filteredFields.length) {
      return noOptions;
    }

    if (!isSortable) {
      return filteredFields.map((field) => renderField(field));
    }
    // TODO: extract to separate component
    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {
            (provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  filteredFields.map((field, index) => (
                    <Draggable
                      key={field.key}
                      draggableId={field.key}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <DraggableContent
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          {renderField(field, draggableProvided)}
                        </DraggableContent>
                      )}
                    </Draggable>
                  ))
                }
                {provided.placeholder}
              </div>
            )
          }
        </Droppable>
      </DragDropContext>
    );
  }, [
    categories,
    filteredFields,
    isSortable,
    handleDragEnd,
    renderField,
    renderCategory,
    noOptions,
    searchText.length,
    isAllExpanded,
  ]);

  const isDeleteAllFromHeaderActive = useMemo(() => getIsDeleteAllAvailable(filteredFields), [filteredFields]);

  useEffect(() => {
    if (focusSearchOnMount && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  return (
    <Wrapper
      data-test-id="fields-list"
      data-sortable={isSortable}
    >
      {
        title && (
          <TitleWrapper>
            <Typography
              variant="h2"
            >
              {title}
            </Typography>

            {isExpandAllAvailable && (
              <HeaderActionButton
                as={Button}
                variant='text'
                color="primary"
                onClick={toggleExpandAll}
                $isVisible={Boolean(tabCategory)}
              >
                {isAllExpanded ? 'Collapse all' : 'Expand all'}
              </HeaderActionButton>
            )}

            {isDeleteAllAvailable && (
              <HeaderActionButton
                as={Button}
                variant='text'
                color="primary"
                onClick={handleDeleteAllFromHeader}
                disabled={!isDeleteAllFromHeaderActive}
                $isVisible
              >
                Reset
              </HeaderActionButton>
            )}
          </TitleWrapper>
        )
      }

      {
        isSearchable && (
          <SearchWrapper>
            <SearchInput
              ref={searchInputRef}
              value={searchText}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyPress}
            />
          </SearchWrapper>
        )
      }

      {list}
    </Wrapper>
  );
});


FieldsList.displayName = 'FieldsList';


export default FieldsList;
