import React, { memo, useState, useCallback, MouseEvent } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { SavedFilter } from 'core/users/types';

import ConfirmationModal from 'components/common/confirmation-modal';

import { Props } from './types';
import DashboardSavedFiltersSkeleton from './skeleton';
import {
  Wrapper,
  StyledSavedFilter,
  FilterDescription,
  FilterTitle,
  FilterActions,
  FilterButton,
  FilterIconButton,
} from './styles';


const DashboardSavedFilters: React.FunctionComponent<Props> = memo(({
  filters,
  selectedFilter,
  onApply,
  onSelect,
  onDelete,
  isFetching,
  isDeleteFetching,
  deletableFilterId,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<SavedFilter | null>(null);

  const handleConfirmationModalOpen = useCallback((event: MouseEvent<HTMLElement>, filter: SavedFilter) => {
    event.stopPropagation();
    setIsConfirmationModalOpen(true);
    setFilterToDelete(filter);
  }, []);

  const handleConfirmationModalClose = useCallback(() => {
    setIsConfirmationModalOpen(false);
    setFilterToDelete(null);
  }, []);

  const handleDeleteSubmit = useCallback(() => {
    if (!filterToDelete) {
      return;
    }

    onDelete(filterToDelete);
    handleConfirmationModalClose();
  }, [filterToDelete, onDelete, handleConfirmationModalClose]);

  const handleApply = useCallback((event: MouseEvent<HTMLElement>, filter: SavedFilter) => {
    event.stopPropagation();
    onApply(filter);
  }, [onApply]);

  const handleSelect = useCallback((filter: SavedFilter, isDisabled: boolean) => {
    if (!isDisabled) {
      onSelect(filter);
    }
  }, [onSelect]);

  return (
    <>
      <Wrapper>
        {isFetching && (
          <DashboardSavedFiltersSkeleton />
        )}

        {!isFetching && !filters.length && (
          <StyledSavedFilter
            $isSelected={false}
            $isDisabled={false}
            $isActive={false}
          >
            <Typography
              variant="body1"
            >
              No saved filters yet
            </Typography>
          </StyledSavedFilter>
        )}

        {!isFetching && filters.map((filter) => {
          const { id, name, description } = filter;
          const isSelected = Boolean(selectedFilter && (selectedFilter.id === id));
          const isDeleted = deletableFilterId === id;
          const isDisabled = isDeleteFetching && isDeleted;

          return (
            <StyledSavedFilter
              key={id}
              $isSelected={isSelected}
              $isDisabled={isDisabled}
              onClick={() => handleSelect(filter, isDisabled)}
            >
              <FilterTitle
                $isSelected={isSelected}
              >
                {name}
              </FilterTitle>
        
              <FilterDescription>
                {description}
              </FilterDescription>
      
              <FilterActions
                as={Stack}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <FilterButton
                  as={Button}
                  variant="contained"
                  onClick={(event: MouseEvent<HTMLElement>) => handleApply(event, filter)}
                  disabled={isDisabled}
                >
                  Apply
                </FilterButton>
      
                <FilterIconButton
                  as={IconButton}
                  onClick={(event: MouseEvent<HTMLElement>) => handleConfirmationModalOpen(event, filter)}
                  disabled={isDisabled}
                >
                  <DeleteForeverIcon />
                </FilterIconButton>
              </FilterActions>
            </StyledSavedFilter>
          );
        })}
      </Wrapper>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={handleConfirmationModalClose}
        onSubmit={handleDeleteSubmit}
        title={`Do you want to delete filter "${filterToDelete?.name}" ?`}
        confirmText="Delete"
      />
    </>
  );
});


DashboardSavedFilters.displayName = 'DashboardSavedFilters';


export default DashboardSavedFilters;
