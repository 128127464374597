import history from 'containers/router/history';


export const extractUrlFromHistoryLocation = () => {
  const { location: { pathname, search } = {} } = history;

  if (pathname) {
    return `${pathname || ''}${search || ''}`;
  }

  return `${pathname || ''}${search || ''}`;
};

export const getQueryParamFromHistory = (paramName: string) => {
  const { location } = history;
  const params = new URLSearchParams(location.search);
  return params.get(paramName);
};

export const addQueryParamToHistory = (name: string, value: string | number) => {
  const { location } = history;
  const params = new URLSearchParams(location.search);
  params.set(name, String(value));

  history.replace({
    pathname: location.pathname,
    search: params.toString(),
  });
};

export const removeQueryParamFromHistory = (name: string) => {
  const { location } = history;
  const params = new URLSearchParams(location.search);

  params.delete(name);

  history.replace({
    pathname: location.pathname,
    search: params.toString(),
  });
};
