import { getDataGridUtilityClass } from '@mui/x-data-grid-premium';
import { unstable_composeClasses as composeClasses } from '@mui/material';


const slots = {
  icon: ['filterIcon'],
};

export const useUtilityClasses = (ownerState: any) => {
  const { classes } = ownerState;

  return composeClasses(slots, getDataGridUtilityClass, classes);
};
