import { ReactComponent as ApplovinIcon } from 'assets/icons/feeds/applovin-icon.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/feeds/facebook-icon.svg';
import { ReactComponent as GoogleadsIcon } from 'assets/icons/feeds/google-ads-icon.svg';
import { ReactComponent as TiktokIcon } from 'assets/icons/feeds/tiktok-icon.svg';
import { ReactComponent as MarketingIcon } from 'assets/icons/feeds/marketing-icon.svg';
import { ReactComponent as HeaderIcon } from 'assets/icons/feeds/header-icon.svg';
import { ReactComponent as AggroIcon } from 'assets/icons/feeds/aggro-icon.svg';
import { ReactComponent as ApplovinProfileIcon } from 'assets/icons/feeds/applovin-profile-icon.svg';


export const ICONS = {
  APPLOVIN_REPORTING: ApplovinIcon,
  FACEBOOK_INSIGHTS: FacebookIcon,
  GOOGLEADS_PERFORMANCE: GoogleadsIcon,
  TIKTOK_REPORTS: TiktokIcon,
  MARKETING: MarketingIcon,
  SANITY_CHECK_PUBLISHER: AggroIcon,
  APPLOVIN_PROFIT_ANALYSIS: ApplovinProfileIcon,
};

export const LABELS = {
  APPLOVIN_REPORTING: 'AL Compass',
  FACEBOOK_INSIGHTS: 'FB Reporting',
  GOOGLEADS_PERFORMANCE: 'Google Ads',
  TIKTOK_REPORTS: 'Tiktok',
  MARKETING: 'Marketing',
  SANITY_CHECK_PUBLISHER: 'Aggro Sanity Check',
  APPLOVIN_PROFIT_ANALYSIS: 'AL Profit Analysis',
};

export const HEADER_ICON = HeaderIcon;
export const HEADER_LABEL = 'Feed';
