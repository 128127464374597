import React from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Wrapper, FormWrapper, BackgroundWrapper, FormInner, StyledTitle, StyledDescription } from './styles';
import { Props } from './types';


const AuthLayout: React.FunctionComponent<Props> = ({ image, formImage, title, description, children }) => (
  <Wrapper
    as={Stack}
    direction="row"
    justifyContent="space-between"
    alignItems="stretch"
  >
    <FormWrapper
      $formImage={formImage}
    >
      <FormInner>
        {
          title && (
            <StyledTitle as={Typography}>
              {title}
            </StyledTitle>
          )
        }

        {
          description && (
            <StyledDescription as={Typography}>
              {description}
            </StyledDescription>
          )
        }

        {children}
      </FormInner>
    </FormWrapper>

    <BackgroundWrapper>
      <img
        src={image}
        alt=""
      />
    </BackgroundWrapper>
  </Wrapper>
);


AuthLayout.displayName = 'AuthLayout';


export default AuthLayout;
