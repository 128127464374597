import axios, { AxiosResponse } from 'axios';

import { API_URL } from './constants';


// TODO: use TS

const instance = axios.create({
  baseURL: API_URL,
});

export const authorizeRequests = (token: string) => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setResponseHandler = (handler: (error: AxiosResponse) => void) => {
  instance.interceptors.response.use((response) => response, (error) => {
    if (handler) {
      handler(error.response);
    }

    return Promise.reject(error);
  });
};


export default instance;
