import { all, put, call, take, takeLatest, select } from 'redux-saga/effects';

import { resolved, resolvedAction, rejectedAction } from 'utils/redux';
import { Action, ResolvedAction } from 'types/redux';

import { Types as AppTypes } from 'core/app/types';

import { authorizeRequests } from 'services/lionstudios-api';
import SnackbarService from 'services/snackbar';

import history from 'containers/router/history';
import { ROUTES } from 'containers/router/constants';
import { isAuthRoutes } from 'containers/router/utils';

import { extractUrlFromHistoryLocation } from 'utils/history';

import { Types as AuthTypes, ResolvedAuthPayload, RejectedAuthPayload, SignInPayload } from './types';
import AuthService from './service';
import authActions from './actions';
import { getToken, getRedirectUrl } from './selectors';
import { isTokenValid } from './utils';

/*
 * Sagas
 */

function* init () {
  yield all([
    put(authActions.loadAuth()),
  ]);
}

function* loadAuth () {
  try {
    const token: ReturnType<typeof getToken> = yield select(getToken);

    if (!token || !isTokenValid(token)) {
      throw new Error('Authorization has expired');
    }

    yield put(resolvedAction<AuthTypes, ResolvedAuthPayload>(AuthTypes.LOAD_AUTH, { token }));
  } catch (error) {
    const redirectUrl = extractUrlFromHistoryLocation();

    yield put(rejectedAction<AuthTypes, RejectedAuthPayload>(AuthTypes.LOAD_AUTH, {
      message: (error as Error).message,
      redirectUrl: isAuthRoutes(redirectUrl) ? null : redirectUrl,
    }));
  }
}

function* logout () {
  try {
    // TODO: call some endpoint ?
    yield put(resolvedAction<AuthTypes>(AuthTypes.LOGOUT));
    // quick fix data not refetching
    window.location.href = window.location.origin;
  } catch (error) {
    yield put(rejectedAction<AuthTypes>(AuthTypes.LOGOUT));
  }
}

function* handleSuccessLogin ({ payload }: ResolvedAction<AuthTypes, ResolvedAuthPayload>) {
  try {
    const { withRedirect } = payload || {};
    const token: ReturnType<typeof getToken> = yield select(getToken);
    const redirectUrl: ReturnType<typeof getRedirectUrl> = yield select(getRedirectUrl);

    authorizeRequests(token as string);

    yield put(authActions.authSuccess());

    if (withRedirect) {
      history.replace(redirectUrl || ROUTES.DASHBOARD);
    }
  } catch (error) {
    yield put(authActions.logout());
  }
}

function* signIn ({ payload }: Action<AuthTypes, SignInPayload>) {
  try {
    const { token }: Awaited<ReturnType<typeof AuthService.signIn>> = yield call(AuthService.signIn, payload);

    yield put(resolvedAction<AuthTypes, ResolvedAuthPayload>(AuthTypes.SIGN_IN, { token, withRedirect: true }));
  } catch (error) {
    const message = (error as any).response?.data ?? (error as Error).message;
    yield put(rejectedAction<AuthTypes, RejectedAuthPayload>(AuthTypes.SIGN_IN, {
      // message: (error as Error).message,
      message,
    }));


    SnackbarService.showError(
      // 'Invalid email or password', // TODO: move text somewhere
      message,
      {
        vertical: 'bottom',
        horizontal: 'left',
      },
    );
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(AppTypes.INIT);
  yield call(init);
}

function* loadAuthWatcher () {
  yield takeLatest(AuthTypes.LOAD_AUTH, loadAuth);
}

function* signInWatcher () {
  yield takeLatest(AuthTypes.SIGN_IN, signIn);
}

function* logoutWatcher () {
  yield takeLatest(AuthTypes.LOGOUT, logout);
}

function* handleSuccessLoginWatcher () {
  yield takeLatest([
    resolved(AuthTypes.LOAD_AUTH),
    resolved(AuthTypes.SIGN_IN),
    AuthTypes.SIGN_IN_WITH_OKTA,
  ], handleSuccessLogin);
}


export default [
  initWatcher,
  loadAuthWatcher,
  logoutWatcher,
  handleSuccessLoginWatcher,
  signInWatcher,
];
