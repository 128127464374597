import { Spec } from 'immutability-helper';

import { Entities, GetConfigFromUrlResolvedPayload } from 'core/dashboards/types';
import { getDefaultColumnFilter } from 'core/dashboards/utils';
import { DEFAULT_PAGE_SIZE } from 'core/dashboards/constants';

import { getRandomId } from 'utils';

import {
  CreateUserFilterPayload,
  CreateUserViewPayload,
  ActiveViewState,
  DeleteUserViewPayload,
  UpdateUserViewPayload,
  SavedViewConfig,
  SetSharedViewsPayload,
  SavedView,
  SavedViewMergedWithFields,
} from './types';


export const getCreateFilterSuccessMessage = ({ data: { name } }: CreateUserFilterPayload) => `Filter "${name}" was saved successfully`;
export const getCreateViewSuccessMessage = ({ data: { name } }: CreateUserViewPayload) => `View "${name}" was saved successfully`;
export const getDeleteViewSuccessMessage = ({ viewName }: DeleteUserViewPayload) => `View "${viewName}" was deleted successfully`;
export const getUpdateViewSuccessMessage = ({ viewName, data }: UpdateUserViewPayload) => {
  if (data.hasOwnProperty('isDefault') && !data.hasOwnProperty('name')) {
    // TODO: is it correct ?
    return `View "${viewName}" was set as default`;
  }

  return `View "${viewName}" was updated successfully`;
};

export const getDefaultActiveViewState = () => Object.values(Entities).reduce<ActiveViewState>((res, entity) => {
  res[entity] = null;

  return res;
}, {} as ActiveViewState);

export const getDefaultViewConfig = (entity: Entities): SavedViewConfig => ({
  sorting: [],
  group: null,
  columnFilter: getDefaultColumnFilter()[entity],
  pageSize: DEFAULT_PAGE_SIZE,
  breakdown: null,
});

export const getViewsFromQueryConfig = (data: NonNullable<GetConfigFromUrlResolvedPayload['data']>): SetSharedViewsPayload => {
  const { views } = data;

  return Object.entries(views || {}).reduce<SetSharedViewsPayload>((res, [entity, view]) => {
    res[entity as Entities] = {
      entity: entity as Entities,
      value: view,
    };

    return res;
  }, {} as SetSharedViewsPayload);
};

export const convertMergedViewToSavedView = (view: SavedViewMergedWithFields): SavedView => ({
  ...view,
  value: {
    ...view.value,
    fields: view.value.fields.map(({ key }) => key),
  },
});

export const getFakeView = (viewToExtend: Partial<SavedView> | null, viewOverride: Partial<SavedView>): SavedView => ({
  ...(viewToExtend || {}),
  id: getRandomId(),
  isDefault: false,
  isRemovable: false,
  isCustom: true,
  ...viewOverride,
} as SavedView);

export const getSharedViewsScheme = (payload: SetSharedViewsPayload) => (
  Object.entries(payload).reduce<{ activeView: Record<Entities, Spec<SavedView>>; views: Record<Entities, Spec<[SavedView]>> }>((res, [entity, view]) => {
    const fakeView = getFakeView(view, {
      name: 'Shared View',
      isEdited: true,
      isShared: true,
      isRemovable: true,
    });

    res.activeView[entity as Entities] = { $set: fakeView };

    res.views[entity as Entities] = { $push: [fakeView] };

    return res;
  }, { activeView: {}, views: {} } as { activeView: Record<Entities, Spec<SavedView>>; views: Record<Entities, Spec<[SavedView]>> })
);

export const getDefaultSavedViewsState = () => Object.values(Entities).reduce<{ [key in Entities]: [] }>((res, entity) => {
  res[entity as Entities] = [];

  return res;
}, {} as { [key in Entities]: [] });
