import { combineReducers } from 'redux';

import auth from './auth/reducer';
import { KEY as AUTH_KEY } from './auth/constants';

import { KEY as DASHBOARDS_KEY } from './dashboards/constants';
import dashboars from './dashboards/reducer';

import { KEY as SETTINGS_KEY } from './settings/constants';
import settings from './settings/reducer';

import { KEY as USERS_KEY } from './users/constants';
import users from './users/reducer';

import { KEY as ADMIN_USERS_KEY } from './admin/users/constants';
import admin_users from './admin/users/reducer';

import { KEY as ADMIN_PERMISSION_TEMPLATES_KEY } from './admin/permission-templates/constants';
import admin_permission_templates from './admin/permission-templates/reducer';

import { KEY as ADMIN_ACTIVITY_LOGS_KEY } from './admin/activity-logs/constants';
import admin_activity_logs from './admin/activity-logs/reducer';

import { KEY as ADMIN_APPSETTINGS_KEY } from './admin/appsettings/constants';
import admin_appsettings from './admin/appsettings/reducer';


const rootReducer = combineReducers({
  [AUTH_KEY]: auth,
  [DASHBOARDS_KEY]: dashboars,
  [SETTINGS_KEY]: settings,
  [USERS_KEY]: users,
  [ADMIN_USERS_KEY]: admin_users,
  [ADMIN_PERMISSION_TEMPLATES_KEY]: admin_permission_templates,
  [ADMIN_ACTIVITY_LOGS_KEY]: admin_activity_logs,
  [ADMIN_APPSETTINGS_KEY]: admin_appsettings,
});

export default rootReducer;
