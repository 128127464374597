import update from 'immutability-helper';

import { combineActions, handleActions, persistReducer, rejected } from 'utils/redux';
import { AsyncActions, RejectedPayload as BaseRejectedPayload, Action } from 'types/redux';

import { Types as DashboardsTypes } from 'core/dashboards/types';
import { Types as AuthTypes } from 'core/auth/types';

import persistConfig from './persist';
import {
  getDefaultActiveViewState,
  getDefaultViewConfig,
  getFakeView,
  convertMergedViewToSavedView,
  getSharedViewsScheme,
  getDefaultSavedViewsState,
} from './utils';
import {
  State,
  SliceKeys,
  FetchingActionsPayload,
  FetchingActionsResolvedPayload,
  Meta,
  Types,
  Payload,
  UpdateViewPayload,
  AddViewPayload,
  DeleteViewPayload,
  SavedViewsMergedWithFields,
  SavedViewMergedWithFields,
} from './types';
import {
  authorizedUserSlice,
  createUserFilterSlice,
  fetchSavedUserFiltersSlice,
  deleteSavedUserFilterSlice,
  updateSavedUserFilterSlice,
  fetchSavedUserViewsSlice,
  createUserViewSlice,
  deleteUserViewSlice,
  updateUserViewSlice,
  SliceType,
} from './slices';


type DefaultSate = Pick<State, SliceKeys>; // TODO: extract to createFetchingSlice util

const defaultState: State = {
  ...authorizedUserSlice.defaultState as DefaultSate,

  ...createUserFilterSlice.defaultState as DefaultSate,
  ...fetchSavedUserFiltersSlice.defaultState as DefaultSate,
  ...deleteSavedUserFilterSlice.defaultState as DefaultSate,
  ...updateSavedUserFilterSlice.defaultState as DefaultSate,

  ...fetchSavedUserViewsSlice.defaultState as DefaultSate,
  ...createUserViewSlice.defaultState as DefaultSate,
  ...deleteUserViewSlice.defaultState as DefaultSate,
  ...updateUserViewSlice.defaultState as DefaultSate,

  activeView: getDefaultActiveViewState(),
};

const reducer = handleActions<
State,
SliceType & Types,
AsyncActions<SliceType, FetchingActionsPayload, FetchingActionsResolvedPayload, BaseRejectedPayload, Meta> | Action<SliceType, Payload>
>({
  // TODO: clear user on logout
  ...authorizedUserSlice.reducer,

  ...createUserFilterSlice.reducer,
  ...fetchSavedUserFiltersSlice.reducer,
  ...deleteSavedUserFilterSlice.reducer,
  ...updateSavedUserFilterSlice.reducer,

  ...fetchSavedUserViewsSlice.reducer,
  ...createUserViewSlice.reducer,
  ...deleteUserViewSlice.reducer,
  ...updateUserViewSlice.reducer,

  [Types.SET_VIEW]: (state: State, { payload: { entity, view, saveAsNew } }: Action<Types, Payload>): State => {
    const savedView = convertMergedViewToSavedView(view);
    const fakeView = getFakeView(savedView, {
      name: `Copy of ${view.name}`,
    });

    return update(state, {
      activeView: {
        [entity]: { $set: saveAsNew ? fakeView : view },
      },
      ...(saveAsNew ? {
        savedUserViews: {
          data: {
            [entity]: { $push: [fakeView] },
          } as any,
        },
      } : {}),
    });
  },

  [Types.SET_SHARED_VIEWS]: (state: State, { payload }: Action<Types, Payload>): State => {
    const { activeView, views } = getSharedViewsScheme(payload);

    const nextState = state.savedUserViews.data ? state : update(state, {
      savedUserViews: {
        data: {
          $set: getDefaultSavedViewsState(),
        },
      },
    });

    return update(nextState, {
      activeView: activeView as any, // TODO: fix types
      savedUserViews: {
        data: views as any, // TODO: fix types
      },
    });
  },

  [Types.UPDATE_VIEW]: (state: State, { payload: { entity, view } }: Action<Types, UpdateViewPayload>): State => {
    const views = state.savedUserViews.data as SavedViewsMergedWithFields;

    if (!views || !views[entity]) {
      return state;
    }

    const viewIndex = (views[entity] as SavedViewMergedWithFields[]).findIndex(({ id }) => id === view.id);

    if (viewIndex === -1) {
      return state;
    }

    const prevView = (views[entity] as SavedViewMergedWithFields[])[viewIndex];

    const breakdown = view?.value?.config?.breakdown;
    const prevBreakdown = prevView?.value?.config?.breakdown;
    const pinnedColumns = (view?.value?.pinnedColumns || []).filter((pinnedColumn) => (pinnedColumn !== breakdown) && (pinnedColumn !== prevBreakdown));

    return update(state, {
      savedUserViews: {
        data: {
          [entity]: {
            [viewIndex]: {
              $set: {
                ...prevView,
                isEdited: view.isEdited,
                value: {
                  pinnedColumns,
                  fields: (view?.value?.fields || []).map(({ key }) => key),
                  config: view?.value?.config || getDefaultViewConfig(prevView.entity),
                  heatmap: view?.value?.heatmap || [],
                },
              },
            },
          },
        },
      },
    } as any);
  },

  [Types.SET_SAVED_VIEWS_LOADED]: (state: State): State => update(state, {
    savedUserViews: {
      isLoaded: { $set: true },
    },
  }),

  [Types.ADD_VIEW]: (state: State, { payload: { entity, view } }: Action<Types, AddViewPayload>): State => {
    const nextView = {
      ...view,
      value: {
        ...view.value,
        fields: view.value.fields.map(({ key }) => key),
      },
    };

    return update(state, {
      savedUserViews: {
        data: {
          [entity]: { $push: [nextView] },
        },
      },
    } as any);
  },

  [Types.DELETE_VIEW]: (state: State, { payload: { entity, view } }: Action<Types, DeleteViewPayload>): State => {
    const views = state.savedUserViews.data as SavedViewsMergedWithFields;

    if (!views || !views[entity]) {
      return state;
    }

    const indexToRemove = (views[entity] as SavedViewMergedWithFields[]).findIndex(({ id }) => id === view.id);

    if (indexToRemove === -1) {
      return state;
    }

    return update(state, {
      savedUserViews: {
        data: {
          [entity]: { $splice: [[indexToRemove, 1]] },
        },
      },
    } as any);
  },

  [DashboardsTypes.RESET_CONFIG]: (state: State): State => update(state, {
    activeView: { $set: defaultState.activeView },
  }),

  // clear user on logout?
  // cleared but won't refetch if login without refreshing the page. same bug with dashboard data
  [combineActions<AuthTypes>(
    rejected<AuthTypes>(AuthTypes.LOAD_AUTH),
    rejected<AuthTypes>(AuthTypes.SIGN_IN),
    AuthTypes.LOGOUT,
  )]: (): State => defaultState,
}, defaultState);


export default persistReducer<State>(persistConfig, reducer as any); // TODO: apply types for async actions
