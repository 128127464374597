import { withPrefix } from 'utils';

import { ReactComponent as TiktokIcon } from 'assets/icons/tiktok-icon.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook-icon.svg';
import { ReactComponent as GoogleadsIcon } from 'assets/icons/googleads-icon.svg';
import { ReactComponent as MintegralIcon } from 'assets/icons/mintegral-icon.svg';
import { ReactComponent as UnityIcon } from 'assets/icons/unity-icon.svg';
import { ReactComponent as ApplovinIcon } from 'assets/icons/applovin-icon.svg';

import { ReactComponent as IosIcon } from 'assets/icons/ios-icon.svg';
import { ReactComponent as IosCNIcon } from 'assets/icons/ios-cn-icon.svg';
import { ReactComponent as AndroidIcon } from 'assets/icons/android-icon.svg';

import { COUNTRY_BREAKDOWN_VALUE } from 'core/settings/constants';

import { Channels, Platform, Entities, PublishModes, DeliveryStatus } from './types'; // eslint-disable-line import/no-cycle
import { getListDependencies } from './utils'; // eslint-disable-line import/no-cycle


export const KEY = 'dashboards';
export const PREFIX = KEY.toUpperCase();

export const DATE_RANGE_FILTER_KEY = 'period.DAY';
export const QUERY_CONFIG_PARAM_NAME = 'query';
export const DEBUG_PARAM_NAME = 'd';
export const TEST_FEATURES_PARAM_NAME = 'tf';

export const BACK_END_KEYS = {
  ID: 'ID',
  DELIVERY_STATUS: 'DELIVERY_STATUS',
  STATUS: 'STATUS',
  BUDGET: 'BUDGET',
  PLATFORM: 'PLATFORM',
  PLATFORMS: 'PLATFORMS',
  CHANNEL: 'CHANNEL',
  NAME: 'NAME',
  CHANNEL_LINK: 'CHANNEL_LINK',
  SALESFORCE_LINK: 'SALESFORCE_LINK',
  COUNTRY: 'COUNTRY',
  CAMPAIGNS: 'CAMPAIGNS',
  ADS: 'ADS',
  CHANNELS: 'CHANNELS',
  IS_GEO_BUDGET: 'IS_GEO_BUDGET',
};

export const { childrens: CHILDREN_ENTITIES, parents: PARENT_ENTITIES } = getListDependencies(Entities);

export const API_ENDPOINTS_BY_PREFIXES = {
  [Entities.Apps]: '/apps',
  [Entities.Skus]: '/skus',
  [Entities.Offers]: '/offers',
  [Entities.Campaigns]: '/campaigns',
  [Entities.AdSets]: '/adsets',
  [Entities.Ads]: '/ads',
  [Entities.Creatives]: '/creatives',
  [Entities.Geos]: '/geos',
};

export const CHANNEL_ICONS = {
  [Channels.TIKTOK]: TiktokIcon,
  [Channels.FACEBOOK]: FacebookIcon,
  [Channels.GOOGLEADS]: GoogleadsIcon,
  [Channels.MINTEGRAL]: MintegralIcon,
  [Channels.UNITY]: UnityIcon,
  [Channels.APPLOVIN]: ApplovinIcon,
};

export const PLATFORMS_ICONS = {
  [Platform.ANDROID]: AndroidIcon,
  [Platform.IOS]: IosIcon,
  [Platform.IOS_CN]: IosCNIcon,
};

export const PLATFORM_NAMES = {
  [Platform.ANDROID]: 'Android',
  [Platform.IOS]: 'iOS',
  [Platform.IOS_CN]: 'iOS (CN)',
};

export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_BREAKDOWN_PAGE_SIZE = 10;

export const COUNTRY_BREAKDOWN_PAGE_SIZE = 3;

export const DEFAULT_PAGE = 0;

export const PUBLISH_MODE_FILTER_KEY = 'app.STAGE';

export const PUBLISH_MODES = [
  {
    id: PublishModes.Published,
    label: 'Published Games',
  },
  {
    id: PublishModes.Unpublished,
    label: 'Test Games',
  },
];

export const CUSTOM_OPERATORS = {
  IS_NOT: {
    value: '!=',
    label: 'is not',
  },
  NOT_CONTAINS: {
    value: 'notContains',
    label: 'not contains',
  },
};

export const DEFAULT_COLUMN_FILTERS = {
  [Entities.Campaigns]: [{
    columnField: withPrefix(Entities.Campaigns, BACK_END_KEYS.DELIVERY_STATUS),
    value: DeliveryStatus.INACTIVE.toLowerCase(),
    operatorValue: CUSTOM_OPERATORS.IS_NOT.value,
  }],
  [Entities.AdSets]: [{
    columnField: withPrefix(Entities.AdSets, BACK_END_KEYS.DELIVERY_STATUS),
    value: DeliveryStatus.INACTIVE.toLowerCase(),
    operatorValue: CUSTOM_OPERATORS.IS_NOT.value,
  }],
  [Entities.Ads]: [{
    columnField: withPrefix(Entities.Ads, BACK_END_KEYS.DELIVERY_STATUS),
    value: DeliveryStatus.INACTIVE.toLowerCase(),
    operatorValue: CUSTOM_OPERATORS.IS_NOT.value,
  }],
};

export const STAGE = {
  [PublishModes.Published]: 'published',
  [PublishModes.Unpublished]: 'test',
};

export const SKIP_SORTING_BREAKDOWN_KEYS = [
  COUNTRY_BREAKDOWN_VALUE,
];

export const GEO_CHANNELS = [Channels.APPLOVIN, Channels.MINTEGRAL, Channels.ADJOE, Channels.UNITY];
