import React, { memo, useState, useCallback, useRef } from 'react';

import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Backdrop from '@mui/material/Backdrop';
import Fade, { FadeProps } from '@mui/material/Fade';

import { ReactComponent as AddIcon } from 'assets/icons/add-outline.svg';

import theme from 'containers/theme-provider/theme';

import FiltersListModal from '../filters-list-modal';

import { Props } from './types';
import { StyledButton, StyledPopper } from './styles';


const DashboardOtherFiltersRow: React.FunctionComponent<Props> = memo(({ filterList, onAddFilters }) => {
  const buttonRef = useRef(null);
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const togglePopperOpen = useCallback(() => {
    setIsPopperOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <>
      <StyledButton
        as={Button}
        startIcon={(
          <AddIcon />
        )}
        disableRipple
        ref={buttonRef}
        onClick={togglePopperOpen}
        $isPopperOpen={isPopperOpen}
      >
        Other filters
      </StyledButton>

      {
        isPopperOpen && (
          <>
            <ClickAwayListener onClickAway={togglePopperOpen}>
              <StyledPopper
                as={Popper}
                anchorEl={buttonRef.current}
                placement="right"
                open={isPopperOpen}
                transition
              >
                {({ TransitionProps }: { TransitionProps: FadeProps }) => (
                  <Fade
                    {...TransitionProps}
                    timeout={theme.transitions.duration.standard}
                  >
                    <div>
                      <FiltersListModal
                        filterList={filterList}
                        onCancel={togglePopperOpen}
                        onSubmit={onAddFilters}
                      />
                    </div>
                  </Fade>
                )}
              </StyledPopper>
            </ClickAwayListener>

            <Backdrop
              open
              transitionDuration={200}
            />
          </>
        )
      }
    </>
  );
});


DashboardOtherFiltersRow.displayName = 'DashboardOtherFiltersRow';


export default DashboardOtherFiltersRow;
