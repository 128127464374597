// eslint-disable-next-line  @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { find, propEq } from 'ramda';
import { deflate, inflate } from 'pako';
import { Base64 } from 'js-base64';


export const createConstants = <T>(constants: string[], constantType?: string): Record<T, T> => constants.reduce((res, constant) => {
  const prefix = constantType ? `${constantType}/` : '';

  res[constant] = `${prefix}${constant}`;

  return res;
}, {} as Record<string, string>);

export const normalize = (scheme) => (data, ...args) => Object.entries(scheme).reduce((res, [schemeKey, dataKey]) => {
  res[schemeKey] = typeof dataKey === 'function' ? dataKey.call(null, data, ...args) : data[dataKey];
  return res;
}, {});

export const reverseObject = (obj): any => (
  Object.keys(obj).reduce((result, key) => {
    result[obj[key]] = key; // eslint-disable-line no-param-reassign
    return result;
  }, {})
);

export const isValueExists = (value) => value !== null && value !== undefined;

export const isString = (value) => typeof value === 'string';
export const isFunction = (value) => typeof value === 'function';
export const isArray = (value) => Array.isArray(value);
export const isDate = (value) => value instanceof Date;
export const isObject = (value) => typeof value === 'object' && value !== null;
export const isNumber = (value) => !Number.isNaN(parseInt(value, 10));
export const isInteger = (value) => value % 1 === 0;

export const isObjectEmpty = (obj) => {
  if (!obj) {
    return true;
  }

  return Object.entries(obj).length === 0;
};

export const shuffleArray = (originalArray) => {
  const array = [...originalArray];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

export const findObjectByProp = (array, key, value) => find(propEq(key, value))(array);

export const getClosestValueInArray = (array, target) => array.reduce((prev, curr) => (Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev));

export const trimObjectValues = (formData) => Object.entries(formData).map(([key, value]) => ([key, value.trim ? value.trim() : value])).reduce((res, [key, value]) => {
  res[key] = value;
  return res;
}, {});

export const getUniqueElementsByProp = (data, prop) => {
  const checkedValues = {};

  return data.reduce((result, item) => {
    const propValue = item[prop];

    if (!checkedValues[propValue]) {
      result.push(item);

      checkedValues[propValue] = true;
    }

    return result;
  }, []);
};

export const isObjectChanged = (prevObject, nextObject, useKeysComparison = true, useDeepComparison = false) => {
  const prevObjectKeys = Object.keys(prevObject);
  const nextObjectKeys = Object.keys(nextObject);

  if ((prevObjectKeys.length !== nextObjectKeys.length) || (useKeysComparison && (JSON.stringify(prevObjectKeys) !== JSON.stringify(nextObjectKeys)))) {
    return true;
  }

  const prevObjectEntries = Object.entries(prevObject);

  for (let i = 0; i < prevObjectEntries.length; i++) {
    const [prevKey, prevValue] = prevObjectEntries[i];

    if (nextObject[prevKey] !== prevValue) {
      if (isObject(nextObject[prevKey]) && isObject(prevValue) && useDeepComparison) {
        if (isObjectChanged(prevValue, nextObject[prevKey], useKeysComparison, useDeepComparison)) {
          return true;
        } else {
          continue;
        }
      }

      return true;
    }
  }

  return false;
};

export const isObjectDeepChanged = (prevObject, nextObject) => JSON.stringify(prevObject) !== JSON.stringify(nextObject);

export const isArrayChanged = (prevArray: unknown[] | null | undefined, nextArray: unknown[] | null | undefined) => {
  if ((!prevArray && nextArray) || (prevArray && !nextArray)) {
    return true;
  }

  if (prevArray.length !== nextArray.length) {
    return true;
  }

  return JSON.stringify(prevArray) !== JSON.stringify(nextArray);
};

export const isValueChanged = (nextValue, prevValue) => {
  const isNextValueExists = isValueExists(nextValue) && nextValue !== '';
  const isChanged = String(nextValue) !== (isValueExists(prevValue) ? String(prevValue) : prevValue);

  return isNextValueExists && isChanged;
};

export const getOptionsFromArray = (array, keyName) => Object.values(array.reduce((res, item) => {
  if (!res[item[keyName]]) {
    res[item[keyName]] = item[keyName];
  }

  return res;
}, {})).map((value) => ({
  id: value,
  name: value,
}));

export const capitalize = (value) => {
  const string = value.toLowerCase();

  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const withPrefix = <Entities>(prefix: Entities, key: string): `${Entities}.${string}` => `${prefix}.${key}`;

export const getElementsFromArrayByIds = (ids, array) => {
  const arrayMap = array.reduce((result, item) => {
    result[item.id] = item;
    return result;
  }, {});

  return ids.map((id) => arrayMap[id]).filter((item) => isValueExists(item));
};

export const groupArrayByKey = <T>(array: T[], field: keyof T): Record<string, T[]> => array.reduce<Record<string, T[]>>((result, item) => {
  if (!result[item[field]]) {
    result[item[field]] = [];
  }

  result[item[field]].push(item);

  return result;
}, {});

export const getRandomId = () => Math.round(Math.random() * 1e5);

export const encodeObject = <T>(object: T): string => {
  const json = JSON.stringify(object);
  const compressedJson = deflate(json);

  return Base64.fromUint8Array(compressedJson, true);
};

export const decodeBase64String = <T>(str: string): T => {
  const compressedJson = Base64.toUint8Array(str);
  const json = inflate(compressedJson, { to: 'string' });

  return JSON.parse(json);
};
