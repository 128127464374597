import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { ListItemProps } from 'components/dashboard/grid-header/types';

import { ActionIconWrapper } from './styles';


export const getBulkActions = (): Array<ListItemProps> => [{
  // TODO: add hover style for the icon
  // TODO: use constants for keys
  key: 'appStatus',
  title: 'App Status',
  renderContent: ({ title }) => (
    <>
      {title}
      <ActionIconWrapper>
        <ChevronRightIcon />
      </ActionIconWrapper>
    </>
  ),
  onClick: () => {},
  disableRipple: true,
}, {
  key: 'channelStatus',
  title: 'Channel Status',
  renderContent: ({ title }) => (
    <>
      {title}
      <ActionIconWrapper>
        <ChevronRightIcon />
      </ActionIconWrapper>
    </>
  ),
  onClick: () => {},
  disableRipple: true,
}, {
  key: 'growthManager',
  title: 'Growth manager',
  renderContent: ({ title }) => (
    <>
      {title}
      <ActionIconWrapper>
        <ChevronRightIcon />
      </ActionIconWrapper>
    </>
  ),
  onClick: () => {},
  disableRipple: true,
}];
