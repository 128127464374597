export const buttonStyles = {
  fontSize: 18,
  width: '19rem',
  fontWeight: 500,
  height: 56,
  alignSelf: 'center',
  margin: '8px auto',
  display: 'flex',
};

export const subButtonStyles = {
  alignSelf: 'center',
  margin: '4px auto',
  display: 'flex',
};
