import React, { memo, useCallback, useMemo } from 'react';
import update from 'immutability-helper';

import MultipleAutocomplete from 'components/common/multiple-autocomplete';

import { preventFilterOptions, preventOptionEqualToValue, renderNumberTagLabel } from './utils';
import { Props, Values } from './types';
import { CHANGE_REASONS, NUMBER_OPTIONS } from './constants';


const MultipleAutocompleteNumber: React.FunctionComponent<Props> = memo(({ values, onChange, ...rest }) => {
  const handleChange = useCallback((nextValue: Values, reason, inputValue) => {
    switch (reason) {
      case CHANGE_REASONS.CREATE:
      case CHANGE_REASONS.SELECT:
        if (inputValue === '') {
          return;
        }

        onChange(update(nextValue, {
          [nextValue.length - 1]: {
            value: { $set: inputValue },
          },
        }));
        break;
      case CHANGE_REASONS.REMOVE:
      default:
        onChange(nextValue);
    }
  }, [onChange]);

  const inputProps = useMemo(() => ({
    type: 'number',
  }), []);

  return (
    <MultipleAutocomplete
      options={NUMBER_OPTIONS}
      onChange={handleChange}
      filterOptions={preventFilterOptions}
      isOptionEqualToValue={preventOptionEqualToValue}
      renderTagLabel={renderNumberTagLabel}
      values={values}
      disableInclude
      inputProps={inputProps}
      {...rest}
    />
  );
});


MultipleAutocompleteNumber.displayName = 'MultipleAutocompleteNumber';


export default MultipleAutocompleteNumber;
