import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { InputLabel } from '@mui/material';


export const BootstrapLabel = styled(InputLabel)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.common.black,
  fontSize: 16,
}));

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(2.25),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 14,
    width: '100%',
    padding: '8px 10px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));
