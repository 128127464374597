import { styled } from '@mui/material/styles';

import { WrapperProps } from './types';


export const Label = styled('p')(({ theme }) => ({
  margin: theme.spacing(0.625),
  transition: theme.transitions.create('color'),
}));

export const IncludeButton = styled('div')(({ theme }) => ({
  opacity: 0.7,
  padding: 0,
  transition: theme.transitions.create(['color', 'opacity']),
  '&:hover': {
    opacity: 1,
  },
}));

export const DeleteButton = styled('div')(({ theme }) => ({
  padding: 0,
  transition: theme.transitions.create('color'),
  color: theme.palette.grey[300],

  '&:hover': {
    color: theme.palette.grey[400],
  },
}));

export const Wrapper = styled('div')<WrapperProps>(({ theme, $isIncluded }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius as number * 2,
  height: 26,
  border: `1px solid ${$isIncluded ? theme.palette.primary.main : theme.palette.grey[500]}`,
  margin: theme.spacing(0.625),
  padding: theme.spacing(0.625),

  [Label]: {
    color: $isIncluded ? theme.palette.primary.main : theme.palette.grey[500],
  },

  [IncludeButton]: {
    color: $isIncluded ? theme.palette.primary.main : theme.palette.grey[500],
  },
}));
