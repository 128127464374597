import { Entities } from 'core/dashboards/types'; // eslint-disable-line import/no-cycle
import { PLATFORM_NAMES } from 'core/dashboards/constants';

import { CUSTOM_FILTER_NAMES, CUSTOM_FILTER_FIELDS, SKU_PLATFORM_NULL_OPTION, OPTION_FIELDS, MARKETING_FEED_ID, FEED_NO_DATA_ERROR_TEXT } from './constants';
import {
  GroupingResponsePayload,
  GroupingResolvedPayload,
  BackEndGroupingModel,
  FilterBasicDataResolvedPayload,
  FilterType,
  Option,
  SkuAppNameOption,
  ViewConfigurationResolvedPayload,
  ViewConfigurationResponseData,
  ViewColumn,
  Feed,
  Status,
} from './types';


export const groupingModel = (data: GroupingResponsePayload): GroupingResolvedPayload => (
  Object.entries<Array<BackEndGroupingModel>>(data).reduce((result: any, [basePrefix, groupingModels]: [string, Array<BackEndGroupingModel>]) => {
    result[basePrefix as Entities] = groupingModels.map((grouping) => { // eslint-disable-line no-param-reassign
      const { key, label } = grouping;
      const [prefix] = key.split('.') as [Entities, string];

      return {
        prefix,
        key,
        title: label,
      };
    });

    return result;
  }, {}) as GroupingResolvedPayload
);

const customOptionsModels = {
  [CUSTOM_FILTER_NAMES.SKU_APP_NAME]: (filter: any, optionsField: string): SkuAppNameOption[] => filter[optionsField].map((option: any): SkuAppNameOption => ({
    name: option[CUSTOM_FILTER_FIELDS[CUSTOM_FILTER_NAMES.SKU_APP_NAME].NAME] as string,
    platform: option[CUSTOM_FILTER_FIELDS[CUSTOM_FILTER_NAMES.SKU_APP_NAME].PLATFORM],
    id: `${option[CUSTOM_FILTER_FIELDS[CUSTOM_FILTER_NAMES.SKU_APP_NAME].NAME]}.${option[CUSTOM_FILTER_FIELDS[CUSTOM_FILTER_NAMES.SKU_APP_NAME].PLATFORM]}`,
  })),
  [CUSTOM_FILTER_NAMES.SKU_PLATFORM]: (filter: any, optionsField: string): Option[] => filter[optionsField].map((option: string): Option => ({
    name: PLATFORM_NAMES[option as keyof typeof PLATFORM_NAMES] || option,
    id: option,
  })).concat([SKU_PLATFORM_NULL_OPTION]),
};

export const baseOptionsModel = (options: any): Option[] => options.map((option: any) => ({
  name: option,
  id: option,
}));

const optionsModel = (filter: any, id: string, testMode = false): Option[] => {
  const optionsField = testMode ? OPTION_FIELDS.TEST : OPTION_FIELDS.NORMAL;

  if (filter.type === FilterType.NUMBER) {
    return [];
  }

  if (customOptionsModels[id]) {
    return customOptionsModels[id](filter, optionsField);
  }

  if (filter.type === FilterType.OBJECT) {
    return [];
  }

  if (filter.options === null) {
    return [];
  }

  return baseOptionsModel(filter[optionsField]);
};

const filterIdModel = (entity: string, id: string) => `${entity}.${id}`;

export const filterBasicDataResponseModel = (response: any): FilterBasicDataResolvedPayload => response.map(({ category, entity, fields }: any) => Object.entries(fields).map(([id, filter]: any) => {
  const filterId = filterIdModel(entity, id);

  return ({
    name: filter.name,
    category: entity,
    categoryName: category,
    isDefault: filter.default,
    id: filterId,
    values: [],
    options: optionsModel(filter, filterId),
    testOptions: optionsModel(filter, filterId, true),
    isAsync: filter.type === FilterType.STRING && filter.options === null,
    type: filter.type,
    entity,
    field: id,
  });
})).flat();

export const viewConfigurationResponseModel = (response: ViewConfigurationResponseData): ViewConfigurationResolvedPayload => response.reduce<ViewConfigurationResolvedPayload>((result, field) => {
  const { entity, key, ...rest } = field;

  entity.forEach(({ name, type, primary, width, available, rounding }) => {
    if (!result[name]) {
      result[name] = {};
    }

    (result[name] as Record<string, ViewColumn>)[key] = {
      key,
      ...rest,
      type,
      primary,
      width,
      available,
      rounding,
    };
  });

  return result;
}, {});

const serverTimestampModel = (timestamp: number | null) => timestamp ? timestamp * 1000 : null;

export const dataStatusResponseModel = (response: any): Feed => {
  const { marketing, reporting, aggro } = response;

  if (marketing === null && reporting === null && aggro === null) {
    return {
      lastRun: null,
      nextRun: null,
      feeds: null,
      status: {
        message: FEED_NO_DATA_ERROR_TEXT,
        status: Status.FAILED,
      },
    };
  }

  const feeds = [];

  if (marketing !== null) {
    feeds.push({
      id: MARKETING_FEED_ID,
      lastRun: serverTimestampModel(marketing?.lastSuccess || null),
      nextRun: serverTimestampModel(marketing?.nextRun || null),
      status: marketing?.status || null,
    });
  }


  Object.entries(reporting?.feeds || {}).sort((feedA, feedB) => {
    const [idA] = feedA;
    const [idB] = feedB;

    return idA.localeCompare(idB);
  }).forEach(([id, feed]: [string, any]) => {
    feeds.push({
      id,
      lastRun: serverTimestampModel(feed.executionDate),
      nextRun: reporting ? serverTimestampModel(reporting.nextRun) : null,
      status: feed.status,
    });
  });


  Object.entries(aggro?.feeds || {}).sort((feedA, feedB) => {
    const [idA] = feedA;
    const [idB] = feedB;

    return idA.localeCompare(idB);
  }).forEach(([id, feed]: [string, any]) => {
    feeds.push({
      id,
      lastRun: serverTimestampModel(feed.executionDate),
      nextRun: aggro ? serverTimestampModel(aggro.nextRun) : null,
      status: feed.status,
    });
  });


  return {
    lastRun: marketing ? serverTimestampModel(marketing?.lastSuccess || null) : null,
    nextRun: marketing ? serverTimestampModel(marketing?.nextRun || null) : null,
    status: marketing?.status || null,
    feeds,
  };
};
