import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';


const LoadingRow = () => (
  <Typography
    variant="body1"
    width="100%"
  >
    <Skeleton
      variant="text"
      animation="pulse"
      width="100%"
    />
  </Typography>
);


export default LoadingRow;
