export const parseQueryParams = (query) => {
  const params = query instanceof URLSearchParams ? query : new URLSearchParams(query);
  const result = [];

  params.forEach((value, key) => {
    result.push([key, value]);
  });

  return result;
};
