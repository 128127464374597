
import { Action, ActionCreators } from 'types/redux';


export enum Types {
  SIGN_IN = 'AUTH/SIGN_IN',
  SIGN_IN_WITH_OKTA = 'AUTH/SIGN_IN_WITH_OKTA',
  AUTH_SUCCESS = 'AUTH/AUTH_SUCCESS',
  LOAD_AUTH = 'AUTH/LOAD_AUTH',
  LOGOUT = 'AUTH/LOGOUT',
}

export interface AuthActions extends ActionCreators<Types, Payload | null, null> {
  signIn: (payload: SignInPayload) => Action<Types, SignInPayload, null>;
  signInWithOkta: (payload: SignInWithOktaPayload) => Action<Types, SignInWithOktaPayload, null>;
  authSuccess: () => Action<Types, null, null>;
  loadAuth: () => Action<Types, null, null>;
  logout: () => Action<Types, null, null>;
}

export interface State {
  isFetching: boolean;
  token: string | null;
  isTokenValid: boolean;
  redirectUrl: string | null;
}

export interface SignInPayload {
  email: string;
  password: string;
  recaptchaCode: string;
}

export interface SignInResponse {
  token: string;
}

export interface ResolvedAuthPayload {
  token: string;
  withRedirect?: boolean;
}

export interface RejectedAuthPayload {
  redirectUrl?: string | null;
  message?: string | null;
}

export interface SignInWithOktaPayload {
  withRedirect?: boolean;
  token: string;
}

export type Payload = SignInPayload & SignInWithOktaPayload;
