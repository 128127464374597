import { formatInTimeZone } from 'date-fns-tz';
import { parse, lastDayOfWeek, format as formatDate } from 'date-fns';


export const getUTCDateFromTimestamp = (timestamp: number, endOfTheDay = false): number | null => {
  if (!timestamp) {
    return null;
  }

  const date = new Date(timestamp);

  return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), ...(endOfTheDay ? [23, 59, 59] : [0, 0, 0, 0]));
};

export const formatBackendTimestampToUTCString = (value: number | null, format: string): string | null => {
  if (!value) {
    return null;
  }

  const date = new Date(value * 1000);

  return formatInTimeZone(date, 'UTC', format);
};

export const formatBackendTimestampToDateTimeString = ({ value }: { value: number | null }) => formatBackendTimestampToUTCString(value, 'Pp z');
export const formatBackendTimestampToDateString = ({ value }: { value: number | null }) => formatBackendTimestampToUTCString(value, 'P');
export const formatBackendDayToDayString = ({ value }: { value: string }) => {
  const date = new Date(value);
  return formatInTimeZone(date, 'UTC', 'P');
};

export const getRangeByWeekNumber = (week: number | string) => {
  const start = parse(String(week), 'I', new Date());
  const end = lastDayOfWeek(start, { weekStartsOn: 1 });

  return {
    start,
    end,
  };
};

export const getStringRangeByWeekNumber = ({ value }: { value: number | string }) => {
  const { start, end } = getRangeByWeekNumber(value);
  const startString = formatDate(start, 'MM/dd/yyyy');
  const endString = formatDate(end, 'MM/dd/yyyy');

  return `${startString} - ${endString}`;
};

export const getMonthNameByNumber = ({ value }: { value: number | string }) => {
  const date = new Date();

  date.setMonth(Number(value) - 1);

  return formatDate(new Date(date), 'MMMM yyyy');
};
