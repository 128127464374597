import { memo, useCallback, FunctionComponent, FocusEvent, ChangeEvent, KeyboardEvent, useState, useMemo, useEffect, useRef } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LoopIcon from '@mui/icons-material/Loop';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';

import { KeyCodes } from 'constants/events';

import { ColumnTypes } from 'core/settings/types';

import { Props, ElementByType } from './types';
import { inputAdornmentByType, inputTypeByColumnType } from './config';
import { rerenderComparator } from './utils';
import {
  Wrapper,
  EditButtonWrapper,
  SubmitButtonWrapper,
  CellWrapper,
  RestartButtonWrapper,
} from './styles';


const EditableCell: FunctionComponent<Props> = memo(({
  cellElement,
  columnType,
  cellValue,
  field,
  row,
  isDisabled,
  onRestart,
  onSubmit,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isEditMode, setIsEditMode] = useState(false);
  const [inputValue, setInputValue] = useState(cellValue);

  const toggleEditMode = useCallback(() => {
    if (!isDisabled) {
      setIsEditMode((prevEditMode) => !prevEditMode);
    }
  }, [isDisabled]);

  const handleSubmit = useCallback(() => {
    onSubmit(inputValue, field, row);
    toggleEditMode();
  }, [
    toggleEditMode,
    onSubmit,
    inputValue,
    field,
    row,
  ]);

  const handleRestart = useCallback(() => {
    onRestart(inputValue, field, row);
  }, [
    onRestart,
    inputValue,
    field,
    row,
  ]);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  }, []);

  const handleInputBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    if (buttonRef.current !== event.relatedTarget) {
      toggleEditMode();
    }
  }, [toggleEditMode]);
  
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (event.key === KeyCodes.Enter) {
      handleSubmit();
      return;
    }

    if (event.key === KeyCodes.Escape) {
      toggleEditMode();
      return;
    }
  }, [handleSubmit, toggleEditMode]);

  const buttonElement = useMemo(() => {
    const Icon = isEditMode ? SaveIcon : EditIcon;
    const ButtonWrapper = isEditMode ? SubmitButtonWrapper : EditButtonWrapper;
    const onClick = isEditMode ? handleSubmit : toggleEditMode;

    return (
      <ButtonWrapper
        ref={buttonRef}
        as={IconButton}
        onClick={onClick}
        size="small"
        $isDisabled={isDisabled}
      >
        <Icon
          fontSize="inherit"
          color="inherit"
        />
      </ButtonWrapper>
    );
  }, [
    isEditMode,
    toggleEditMode,
    handleSubmit,
    isDisabled,
  ]);

  const buttons = useMemo<ElementByType>(() => ({
    [ColumnTypes.Price]: buttonElement,
    [ColumnTypes.Percentage]: buttonElement,
  }), [buttonElement]);

  const button = useMemo(() => buttons[columnType] || null, [
    buttons,
    columnType,
  ]);

  const inputElement = useMemo(() => {
    const adornment = inputAdornmentByType[columnType];
    const type = inputTypeByColumnType[columnType];
 
    return (
      <Input
        type={type}
        endAdornment={adornment}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onBlur={handleInputBlur}
        inputRef={inputRef}
      />
    );
  }, [
    columnType,
    handleInputChange,
    inputValue,
    handleKeyDown,
    handleInputBlur,
  ]);

  const controls = useMemo<ElementByType>(() => ({
    [ColumnTypes.Price]: inputElement,
    [ColumnTypes.Percentage]: inputElement,
  }), [inputElement]);

  const cell = useMemo(() => (
    <CellWrapper
    >
      {isEditMode ? (controls[columnType] || null) : cellElement}
    </CellWrapper>
  ), [
    isEditMode,
    cellElement,
    controls,
    columnType,
  ]);

  const restartButton = useMemo(() => isDisabled ? (
    <RestartButtonWrapper
      as={IconButton}
      onClick={handleRestart}
      size="small"
    >
      <LoopIcon
        fontSize="inherit"
        color="inherit"
      />
    </RestartButtonWrapper>
  ) : null, [
    handleRestart,
    isDisabled,
  ]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  useEffect(() => {
    setInputValue(cellValue); // TODO: do we need it ?
  }, [cellValue]);

  return (
    <Wrapper
      onDoubleClick={toggleEditMode}
    >
      {restartButton}
      {button}
      {cell}
    </Wrapper>
  );
}, rerenderComparator);


EditableCell.displayName = 'EditableCell';


export default EditableCell;
