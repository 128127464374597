import { memo, FunctionComponent } from 'react';

import AdaptiveText from 'components/common/adaptive-text';
import Link from 'components/common/link';

import { Props } from './types';


const DashboardListCountLink: FunctionComponent<Props> = memo(({ value, formattedValue, onClick }) => {
  if (!value) {
    return (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    );
  }

  return (
    <AdaptiveText onClick={onClick}>
      <Link
        element="span"
      >
        {formattedValue}
      </Link>
    </AdaptiveText>
  );
});


DashboardListCountLink.displayName = 'DashboardListCountLink';


export default DashboardListCountLink;
