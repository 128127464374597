import { Components } from '@mui/material/styles';


const overrides: Components['MuiTypography'] = {
  styleOverrides: {
    root: {
      color: '#2D2D2D',
    },
  },
};


export default overrides;
