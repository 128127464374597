import { Components, Theme } from '@mui/material/styles';


const overrides: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: (props) => {
      const localTheme = props.theme as Theme;

      return {
        padding: localTheme.spacing(1),
        color: '#C6C6C6',
      };
    },
  },
};


export default overrides;
