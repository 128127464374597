import React, { memo, useCallback, useMemo, MouseEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  GridRowParams,
  MuiEvent,
  GridCallbackDetails,
  GridApi,
  useGridApiRef,
  GridPinnedColumns,
  GridRowModel,
} from '@mui/x-data-grid-premium';

import { State } from 'core/types';
import { groupingSlice, breakdownSlice, viewConfigurationSlice } from 'core/settings/slices';
import { getDataStateByType } from 'core/dashboards/selectors';
import { SavedViewMergedWithFields } from 'core/users/types';
import { BACK_END_KEYS, TEST_FEATURES_PARAM_NAME } from 'core/dashboards/constants';
import { HeatmapModel } from 'core/dashboards/types';

import { isArrayChanged } from 'utils';
import { getQueryParamFromHistory } from 'utils/history' ;

import DataGrid from 'components/common/data-grid';
import DataGridHeader from 'components/dashboard/grid-header';
import ConfirmationModal from 'components/common/confirmation-modal';
import DashboardSidebar from 'components/dashboard/sidebar';
import DashboardSaveViewModal from 'components/dashboard/save-view-modal';
import { SaveViewFormValues } from 'components/dashboard/save-view-modal/types';

import { // eslint-disable-line import/no-cycle
  getGroupByKey,
  isGroupingRow,
  getFakeView,
  getDefaultView,
  getConvertMergedFields,
  getAggregationModel,
  getAggregationPositionByGroupingModel,
  getActiveBreakdown,
  getRowsPerPageOptions,
  getGridRowGroupingConfig,
  getGridBreakdownConfig,
  filterGridPinnedColumns,
  getViewPinnedColumns,
  filterViewPinnedColumns,
  getActiveSortingTitles,
  getActiveFilterTitles,
  getFiltersValuesMap,
  getAvailableFilters,
  getFilterValueComparator,
  getCellSelectedValue,
} from './utils';
import { getConfig } from './config'; // eslint-disable-line import/no-cycle
import { getBulkActions, getGroupingActions, getSavedViews, getBreakdownActions } from './action-configs'; // eslint-disable-line import/no-cycle
import { Props } from './types';
import { DEFAULT_COLUMN_FIELD, DEFAULT_GROUP_NAME } from './constants';


const DashboardGrid: React.FunctionComponent<Props> = memo(({
  type,
  sortModel,
  grouping,
  page,
  pageSize,
  selected,
  breakdown,
  currentTab,
  filterModel,
  savedViews,
  isViewsFetching,
  view,
  filters,
  availableFilters,
  onBreakdownChange,
  onSelectionChange,
  onPageSizeChange,
  onPageChange,
  onRowClick,
  onCountRowClick,
  onSortModelChange,
  onGroupingChange,
  onFilterModelChange,
  onViewChange,
  onViewApply,
  onViewDelete,
  onViewUpdate,
  onViewCreate,
  onViewAdd,
  onCustomViewDelete,
  onCellSelect,
}) => {
  const apiRef = useGridApiRef();
  const isTestMode = getQueryParamFromHistory(TEST_FEATURES_PARAM_NAME);

  const { isFetching, data, total, summary } = useSelector((state: State) => getDataStateByType(state, type));
  const groupingConfig = useSelector(groupingSlice.selectors.getData);
  const breakdowns = useSelector(breakdownSlice.selectors.getData);
  const viewConfiguration = useSelector(viewConfigurationSlice.selectors.getData);

  const breakdownConfig = useMemo(() => breakdowns ? breakdowns[type] : [], [type, breakdowns]);
  const pinnedColumns = useMemo(() => getViewPinnedColumns(view), [view]);
  const activeBreakdown = useMemo(() => getActiveBreakdown(breakdownConfig, breakdown), [breakdownConfig, breakdown]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [sidebarActiveView, setSidebarActiveView] = useState<SavedViewMergedWithFields | null>(null);
  const [viewToDelete, setViewToDelete] = useState<SavedViewMergedWithFields | null>(null);
  const [processedView, setProcessedView] = useState<SavedViewMergedWithFields | null>(null);
  const [isDeleteViewModalOpen, setIsDeleteViewModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [updatedView, setUpdatedView] = useState<SavedViewMergedWithFields | null>(null);

  const handleSidebarOpen = useCallback((nextSidebarActiveView: SavedViewMergedWithFields | null = null) => {
    setSidebarActiveView(nextSidebarActiveView);
    setIsSidebarOpen(true);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setSidebarActiveView(null);
    setIsSidebarOpen(false);
  }, []);

  const closeSaveModal = useCallback(() => {
    setIsSaveModalOpen(false);
    setUpdatedView(null);
  }, []);

  const handleSelectionChange = useCallback((ids: Props['selected']) => {
    onSelectionChange(ids, type);
  }, [onSelectionChange, type]);

  const handleSortingChange = useCallback((nextSorting: Props['sortModel']) => {
    if (nextSorting) {
      onSortModelChange(nextSorting, type);
    }
  }, [onSortModelChange, type]);

  const resetSorting = useCallback(() => {
    onSortModelChange([], type);
  }, [onSortModelChange, type]);

  const handlePageChange = useCallback((nextPage: Props['page']) => {
    onPageChange(nextPage, type);
  }, [onPageChange, type]);

  const handlePageSizeChange = useCallback((nextPageSize: Props['pageSize']) => {
    onPageSizeChange(nextPageSize, type);
  }, [onPageSizeChange, type]);

  const handleFilterChange = useCallback((nextFilter: Props['filterModel']) => {
    if (nextFilter) {
      onFilterModelChange(nextFilter);
    }
  }, [onFilterModelChange]);

  const resetFilter = useCallback(() => {
    onFilterModelChange({ items: [] });
  }, [onFilterModelChange]);

  const handleRowClick = useCallback((row: any) => {
    onRowClick(row, type);
  }, [onRowClick, type]);

  const handleCountRowClick = useCallback((ids: string[] | null, key: keyof typeof BACK_END_KEYS) => {
    onCountRowClick(ids || [], key, type);
  }, [onCountRowClick, type]);

  const handleBreakdownReset = useCallback(() => {
    onBreakdownChange(null);
  }, [onBreakdownChange]);

  const handleViewDeleteClick = useCallback((nextViewToDelete: SavedViewMergedWithFields) => {
    setViewToDelete(nextViewToDelete);
    setIsDeleteViewModalOpen(true);
  }, []);

  const handleSetDefaultViewClick = useCallback((nextDefaultView: SavedViewMergedWithFields) => {
    setProcessedView(nextDefaultView);
    onViewUpdate(nextDefaultView, { isDefault: true });
  }, [onViewUpdate]);

  const handlePinColumnClick = useCallback((nextPinnedColumns: GridPinnedColumns) => {
    if (!view) {
      return;
    }

    onViewApply({
      ...view,
      value: {
        ...view.value,
        pinnedColumns: filterGridPinnedColumns(nextPinnedColumns),
      },
    }, type);
  }, [onViewApply, view, type]);

  const handleHeatmapChange = useCallback((heatmap: HeatmapModel[]) => {
    if (!view) {
      return;
    }

    onViewApply({
      ...view,
      value: {
        ...view.value,
        heatmap,
      },
    }, type);
  }, [onViewApply, view, type]);

  const handleDeleteViewModalClose = useCallback(() => {
    setIsDeleteViewModalOpen(false);
    setViewToDelete(null);
  }, []);

  const handleApplyView = useCallback((nextView: SavedViewMergedWithFields, saveAsNew = true) => {
    const shouldResetPinnedColumns = isArrayChanged(view?.value.fields, nextView.value.fields);

    onViewApply({
      ...nextView,
      value: {
        ...nextView.value,

        ...(shouldResetPinnedColumns ? {
          pinnedColumns: [],
        } : {}),
      },
    }, type, saveAsNew);
    handleSidebarClose();
  }, [type, onViewApply, view]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleHeaderViewDelete = useCallback(() => {
    if (!view || !view.isCustom) {
      return;
    }

    onCustomViewDelete(view, type);
  }, [view, type, onCustomViewDelete]);

  const handleViewDeleteSubmit = useCallback(() => {
    if (!viewToDelete) {
      return;
    }

    setProcessedView(viewToDelete);
    onViewDelete(viewToDelete, type);
    handleDeleteViewModalClose();

    if (sidebarActiveView?.id === viewToDelete.id) {
      setSidebarActiveView(getDefaultView(savedViews));
    }
  }, [
    onViewDelete,
    viewToDelete,
    handleDeleteViewModalClose,
    type,
    sidebarActiveView,
    savedViews,
  ]);

  const handleCellEdit = useCallback((value: string | number | boolean, field: string, row: GridRowModel) => {
    console.log(value, field, row);
  }, []);

  const handleCellRestart = useCallback((value: string | number | boolean, field: string, row: GridRowModel) => {
    console.log(value, field, row);
  }, []);

  const config = useMemo(() => getConfig(
    type,
    view?.value.fields,
    data,
    handleRowClick,
    handleCountRowClick,
    handleCellEdit,
    handleCellRestart,
  ), [
    type,
    view,
    data,
    handleRowClick,
    handleCountRowClick,
    handleCellEdit,
    handleCellRestart,
  ]);

  const aggregationModel = useMemo(() => getAggregationModel(data, summary, grouping), [data, summary, grouping]);

  const rowsGroupingConfig = useMemo(() => {
    if (!grouping) {
      return null;
    }

    return getGridRowGroupingConfig({
      grouping,
      pinnedColumns,
      gridApi: apiRef.current,
      gridConfig: config,
      defaultLeafField: DEFAULT_COLUMN_FIELD,
      defaultGroupName: DEFAULT_GROUP_NAME,
    });
  }, [apiRef, config, grouping, pinnedColumns]);

  const gridBreakdownConfig = useMemo(() => {
    if (!breakdown) {
      return null;
    }

    return getGridBreakdownConfig({
      breakdown,
      gridConfig: rowsGroupingConfig ? rowsGroupingConfig.config : config,
      viewConfiguration,
      currentTab,
      data,
      gridPinnedColumns: pinnedColumns,
      onRowClick,
      onCellEdit: handleCellEdit,
      onCellRestart: handleCellRestart,
    });
  }, [
    breakdown,
    rowsGroupingConfig,
    config,
    viewConfiguration,
    currentTab,
    data,
    onRowClick,
    pinnedColumns,
    handleCellEdit,
    handleCellRestart,
  ]);

  const groupingModel = useMemo(() => getGroupByKey(grouping, currentTab, groupingConfig), [grouping, currentTab, groupingConfig]);

  const bulkActions = useMemo(() => getBulkActions(), []);

  const breakdownActions = useMemo(() => getBreakdownActions(
    breakdownConfig,
    activeBreakdown,
    onBreakdownChange,
  ), [
    breakdownConfig,
    onBreakdownChange,
    activeBreakdown,
  ]);

  const groupingActions = useMemo(() => getGroupingActions(
    currentTab,
    groupingConfig,
    onGroupingChange,
    grouping,
  ), [
    currentTab,
    groupingConfig,
    onGroupingChange,
    grouping,
  ]);

  const savedViewsActions = useMemo(() => getSavedViews(
    savedViews,
    view,
    isViewsFetching,
    processedView,
    onViewChange,
    handleViewDeleteClick,
    handleSetDefaultViewClick,
    handleSidebarOpen,
  ), [
    savedViews,
    view,
    isViewsFetching,
    processedView,
    onViewChange,
    handleViewDeleteClick,
    handleSetDefaultViewClick,
    handleSidebarOpen,
  ]);

  const activeSortingTitles = useMemo(() => getActiveSortingTitles(view, sortModel), [view, sortModel]);

  const activeFilterTitles = useMemo(() => getActiveFilterTitles(view, filterModel), [view, filterModel]);

  const handleSidebarViewSave = useCallback((nextView: SavedViewMergedWithFields) => {
    if (!sidebarActiveView) {
      return;
    }

    setUpdatedView({
      ...sidebarActiveView,
      value: {
        fields: nextView.value.fields,
        pinnedColumns: sidebarActiveView.value.pinnedColumns,
        config: sidebarActiveView.value.config,
        heatmap: sidebarActiveView.value.heatmap,
      },
    });

    setIsSaveModalOpen(true);
  }, [sidebarActiveView]);

  const handleHeaderViewSave = useCallback(() => {
    if (!view) {
      return;
    }

    setUpdatedView(view);

    setIsSaveModalOpen(true);
  }, [view]);

  const handleHeaderViewEdit = useCallback(() => {
    if (!view) {
      return;
    }

    handleSidebarOpen(view);
  }, [view]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleViewSaveSubmit = useCallback((values: SaveViewFormValues, isEditMode: boolean) => {
    if (!updatedView) {
      return;
    }

    const action = isEditMode ? onViewUpdate : onViewCreate;

    const nextView = {
      ...updatedView,
      ...values,
    };

    action({
      ...nextView,
      value: {
        ...nextView.value,
        pinnedColumns: filterViewPinnedColumns(nextView.value.pinnedColumns),
      },
    }, {
      ...nextView,
      value: {
        fields: getConvertMergedFields(nextView),
        pinnedColumns: filterViewPinnedColumns(nextView.value.pinnedColumns),
        config: nextView.value.config,
        heatmap: nextView.value.heatmap,
      },
      ...(isEditMode ? {} : {
        isRemovable: true,
      }),
    });

    if (isEditMode) {
      handleApplyView(nextView, false);
    } else {
      handleSidebarClose();
    }

    setUpdatedView(null);
  }, [updatedView, onViewUpdate, onViewCreate, handleApplyView]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateNewView = useCallback(() => {
    const fakeView = getFakeView(savedViews || [], view);

    if (!fakeView) {
      return;
    }

    onViewAdd(fakeView, type);
    handleSidebarOpen(fakeView);
  }, [onViewAdd, savedViews, type, view]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCreateNewViewFromSidebar = useCallback(() => {
    const fakeView = getFakeView(savedViews || [], view);

    if (!fakeView) {
      return;
    }

    onViewAdd(fakeView, type);
    setSidebarActiveView(fakeView);
  }, [onViewAdd, savedViews, type, view]);

  const handleGroupingReset = useCallback(() => onGroupingChange(null, type), [onGroupingChange, type]);

  const handleGroupRowClick = useCallback(({ id, row }: GridRowParams, event: MuiEvent<MouseEvent>, details: GridCallbackDetails, gridApi: GridApi) => {
    const rowNode = gridApi.getRowNode(id);

    if (!isGroupingRow(row) || !rowNode) {
      return;
    }

    gridApi.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  }, []);

  const handleExportClick = useCallback(() => {
    apiRef.current.exportDataAsCsv();
  }, [apiRef]);

  const handleCellSelect = useCallback((value: string | number | boolean | string[] | null, field: string, include: boolean, row: GridRowModel) => {
    const selectedCell = getCellSelectedValue(field, row);
    onCellSelect(value ? selectedCell.value : null, selectedCell.field, include, row);
  }, [onCellSelect]);

  const getAggregationPosition = useMemo(() => getAggregationPositionByGroupingModel(rowsGroupingConfig?.rowGroupingModel), [rowsGroupingConfig?.rowGroupingModel]);

  const rowsPerPageOptionsProps = useMemo(() => getRowsPerPageOptions(breakdown), [breakdown]);

  const availableFiltersIds = useMemo(() => getAvailableFilters(availableFilters), [availableFilters]);

  const filtersValues = useMemo(() => getFiltersValuesMap(filters), [filters]);

  const additionalProps = useMemo(() => ({
    ...(rowsPerPageOptionsProps || {}),
  }), [rowsPerPageOptionsProps]);

  const [gridPageSize] = rowsPerPageOptionsProps ? rowsPerPageOptionsProps.rowsPerPageOptions : [pageSize];

  const isOnlyOnePage = total / (pageSize || 1) <= 1;

  return (
    <>
      <DataGridHeader
        savedViews={savedViewsActions}
        activeView={view}
        bulkActions={bulkActions}
        breakdownActions={breakdownActions}
        groupingActions={groupingActions}
        onBreakdownReset={handleBreakdownReset}
        activeBreakdown={activeBreakdown}
        activeGrouping={groupingModel}
        onGroupingReset={handleGroupingReset}
        isGroupingWarningVisible={!isOnlyOnePage}
        onExportClick={handleExportClick}
        onViewEdit={handleHeaderViewEdit}
        onViewSave={handleHeaderViewSave}
        onViewAdd={handleCreateNewView}
        onViewDelete={handleHeaderViewDelete}
        activeSorting={activeSortingTitles}
        onSortingReset={resetSorting}
        activeFilters={activeFilterTitles}
        onFiltersReset={resetFilter}
      />

      <DataGrid
        apiRef={apiRef}
        config={config}
        onSelectionChange={handleSelectionChange}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortModelChange={handleSortingChange}
        selection={selected}
        isFetching={isFetching}
        rowCount={total}
        page={page}
        pageSize={gridPageSize}
        paginationMode="server"
        sortModel={sortModel}
        disableClientSorting
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        filterMode="server"
        extendedHeader
        onRowClick={handleGroupRowClick}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={handlePinColumnClick}
        disableColumnPinning={false}
        aggregationModel={aggregationModel}
        aggregationInfo={summary}
        getAggregationPosition={getAggregationPosition}
        defaultColumnField={DEFAULT_COLUMN_FIELD}
        defaultGroupName={DEFAULT_GROUP_NAME}
        onHeatmapChange={handleHeatmapChange}
        heatmapModel={view?.value?.heatmap || []}
        isStickyHeader
        availableFilters={availableFiltersIds}
        filtersValues={filtersValues}
        onCellSelect={handleCellSelect}
        getCellFilterComparator={getFilterValueComparator}
        {...(isTestMode ? {
          autoHeight: false,
          disableVirtualization: false,
        } : {
          autoHeight: true,
          disableVirtualization: true,
          isStickyHeader: true,
        })}
        {...(rowsGroupingConfig === null ? {} : { ...rowsGroupingConfig })}
        {...(gridBreakdownConfig === null ? {} : { ...gridBreakdownConfig })}
        {...additionalProps}
      />

      <DashboardSidebar
        type={type}
        isOpen={isSidebarOpen}
        views={savedViews}
        activeView={sidebarActiveView}
        processedView={processedView}
        isViewsFetching={isViewsFetching}
        onClose={handleSidebarClose}
        onApply={handleApplyView}
        onSave={handleSidebarViewSave}
        onSetDefaultViewClick={handleSetDefaultViewClick}
        onViewDeleteClick={handleViewDeleteClick}
        onActiveViewChange={setSidebarActiveView}
        onCreateView={handleCreateNewViewFromSidebar}
      />

      <DashboardSaveViewModal
        view={updatedView}
        isOpen={isSaveModalOpen}
        onClose={closeSaveModal}
        onSave={handleViewSaveSubmit}
      />

      <ConfirmationModal
        isOpen={isDeleteViewModalOpen}
        onClose={handleDeleteViewModalClose}
        onSubmit={handleViewDeleteSubmit}
        title={`Do you want to delete table view "${viewToDelete?.name}" ?`}
        confirmText="Delete"
      />
    </>
  );
});


DashboardGrid.displayName = 'DashboardGrid';


export default DashboardGrid;
