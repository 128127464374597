import { UserRole } from 'core/users/types';
import { Action, ActionCreator } from 'types/redux';


export enum Types {
  FETCH_ALL = 'ADMIN/APPSETTINGS/FETCH_ALL',
  FETCH_ONE = 'ADMIN/APPSETTINGS/FETCH_ONE',
}

export interface Actions extends ActionCreator<Types, Payload, null> {
  fetchAll: () => Action<Types, null, null>;
  fetchOne: (payload: FetchOneAppSettingPayload) => Action<Types, Payload, null>;
  fetchOneReset: () => Action<Types, null, null>;
}

export type AppSettingForm = {
  name: string;
  section: string;
  value: string;
  comment: string;
};

export type CreateAppSettingPayload = {
  data: AppSettingForm;
};

export type EditAppSettingPayload = {
  data: AppSettingForm;
  appSettingId: number;
};

export type FetchOneAppSettingPayload = {
  appSettingId: number;
};

export type RejectedPayload = {
  error: any;
};

export type Payload = FetchOneAppSettingPayload & EditAppSettingPayload;

export type AppSetting = {
  id: number;
  name: string;
  section: string;
  value: string;
  comment: string;
};

export type AppSettingList = AppSetting[];

export type State = {
  fetchAll: {
    data: AppSettingList;
    isLoading: boolean;
    error: any;
  };
  fetchOne: {
    data: AppSetting | null;
    isLoading: boolean;
    error: any;
  };
};

export type FetchAllResolvedPayload = AppSettingList;
export type FetchOneResolvedPayload = AppSetting;

// const for pages

// -- List of permission to display in toggle
// used in permission_template edit & user edit
// all roles need be defined here (compile error otherwise),
// this is on purpose to prevent undefined error on page rendering
export const PermissionToggleList: {
  [key in UserRole]: Array<{
    label: string; // section label
    permissions: Array<{ // permissions array
      key: string; // permission key
      label: string; // permission label
    }>;
  }>
} = {
  [UserRole.RegularUser]: [],
  [UserRole.Admin]: [
    {
      label: 'Users',
      permissions: [
        { key: 'editUser', label: 'Can Edit User' },
      ],
    },
  ],
  [UserRole.SuperAdmin]: [],
};
