import { GridColumnHeaderParams, GridSortDirection, GridFilterItem } from '@mui/x-data-grid-premium';

import { ExtendedGridColumn } from '../../types';


export type ExtendedGridColumnHeaderParams = GridColumnHeaderParams & {
  colDef: GridColumnHeaderParams['colDef'] & ExtendedGridColumn;
};

export interface Props extends ExtendedGridColumnHeaderParams {
  title?: string;
  subTitle?: string;
  isExtended?: boolean;
  renderHeader?: GridColumnHeaderParams['colDef']['renderHeader'];
  sort?: { sortDirection: GridSortDirection; sortIndex?: number };
  onFilterClick: (nextFilterButtonEl: HTMLElement | null, field: string) => void;
  filter?: GridFilterItem;
  isFetchingColumn: boolean;
  isPinned: boolean;
  isPinLocked?: boolean;
  onPinClick: (field: string, isPinned: boolean) => void;
  isHeatmapActive: boolean;
  onHeatmapChange: (color: string | null, field: string) => void;
  type?: string;
  description?: string;
}

export interface PinIconWrapperProps {
  $isActive: boolean;
  $isLocked?: boolean;
}

export enum ContextMenuKeys {
  Heatmap = 'heatmap',
}
