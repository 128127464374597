import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { DeliveryStatus } from 'core/dashboards/types';

import { WrapperProps, TooltipProps } from './types';


export const Wrapper = styled('div')<WrapperProps>(({ theme, $status, $value }) => {
  let iconColor = theme.palette.success.main;
  let color = 'inherit';

  if ($value === DeliveryStatus.WARNING) {
    iconColor = theme.palette.warning.main;
  } else if ($value === DeliveryStatus.ERROR || $value === DeliveryStatus.UNCLASSIFIED) {
    iconColor = theme.palette.error.main;
  } else if ($value === DeliveryStatus.INACTIVE && !$status) {
    iconColor = theme.palette.grey[400];
    color = theme.palette.grey[500];
  }

  return {
    textTransform: 'capitalize',
    color,

    '& svg': {
      color: iconColor,
      fontSize: '16px',
    },
  };
});

export const IconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(0.75),
}));

export const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))<TooltipProps>(({ $isVisible }) => ({
  [`.${tooltipClasses.tooltip}`]: {
    backgroundColor: '#676767',
    textAlign: 'center',

    ...($isVisible ? {} : {
      display: 'none',
    }),
  },

  [`.${tooltipClasses.arrow}`]: {
    color: '#676767',

    ...($isVisible ? {} : {
      display: 'none',
    }),
  },
}));
