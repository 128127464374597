import { createSelector } from 'reselect';

import { State } from 'core/types';
import { Entities } from 'core/dashboards/types';
import { viewConfigurationSlice } from 'core/settings/slices';
import { ViewColumn } from 'core/settings/types';

import { SavedViewsMergedWithFields, SliceKeys } from './types';
import { authorizedUserSlice, fetchSavedUserViewsSlice } from './slices';  // eslint-disable-line import/no-cycle
import { KEY } from './constants';
import { getDefaultViewConfig } from './utils';


const getLocalState = (state: State) => state[KEY];
const getActiveViewsState = (state: State) => getLocalState(state).activeView;
const getSavedViewsState = (state: State) => getLocalState(state)[SliceKeys.SavedUserViews];


export const getSavedViews = createSelector( // TODO: can't use typing here
  fetchSavedUserViewsSlice.selectors.getData,
  viewConfigurationSlice.selectors.getData,
  (savedViews, viewConfiguration): SavedViewsMergedWithFields | null => {
    if (!savedViews || !viewConfiguration) {
      return null;
    }

    return Object.entries(savedViews).reduce<SavedViewsMergedWithFields>((result, [key, views]) => {
      result[key as Entities] = views.map(({ value, entity, ...rest }) => {
        const defaultViewConfig = getDefaultViewConfig(entity);

        return {
          ...rest,
          entity,
          value: {
            pinnedColumns: value.pinnedColumns || [],
            config: {
              group: value?.config?.group || defaultViewConfig.group,
              sorting: value?.config?.sorting || defaultViewConfig.sorting,
              pageSize: value?.config?.pageSize || defaultViewConfig.pageSize,
              columnFilter: value?.config?.columnFilter || defaultViewConfig.columnFilter,
              breakdown: value?.config?.breakdown || defaultViewConfig.breakdown,
            },
            fields: value.fields.map((fieldKey) => viewConfiguration?.[key as Entities]?.[fieldKey] || null).filter((field: any) => field !== null) as ViewColumn[],
            heatmap: value?.heatmap || [],
          },
        };
      });

      return result;
    }, {});
  },
);

export const getActiveViews = createSelector(
  getActiveViewsState,
  (view) => view,
);

export const getIsSavedViewsLoaded = createSelector(
  getSavedViewsState,
  (viewsState) => viewsState.isLoaded,
);

export const getAuthorizedUserRole = createSelector(
  authorizedUserSlice.selectors.getData,
  (user) => user?.role ?? null,
);

export const getAuthorizedUserPermissions = createSelector(
  authorizedUserSlice.selectors.getData,
  (user) => user?.compiledPermissions ?? [],
);
