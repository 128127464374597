import { SavedViewMergedWithFields } from 'core/users/types';

import { SaveViewFormValues, Fields } from './types';
import { DEFAULT_VALUES } from './constants';


export const getDefaultValues = (view: SavedViewMergedWithFields | null | undefined): SaveViewFormValues => {
  if (!view) {
    return DEFAULT_VALUES;
  }

  return {
    [Fields.Name]: view[Fields.Name],
  };
};
