import LionstudiosApi from 'services/lionstudios-api';

import {
  FetchDataResolvedPayload,
  RequestPayload,
} from './types';

import {
  dataResponseModel,
  requestModel,
  breakdownRequestModel,
} from './models';
import { API_ENDPOINTS_BY_PREFIXES } from './constants';


const Service = {
  fetchData: async (payload: RequestPayload): Promise<FetchDataResolvedPayload> => {
    const { tabKey, breakdown: breakdownPayload } = payload;
    const body = requestModel(payload);

    const [responseData, responseTotal] = await Promise.all([
      LionstudiosApi.post(API_ENDPOINTS_BY_PREFIXES[tabKey], body),
      LionstudiosApi.post(`${API_ENDPOINTS_BY_PREFIXES[tabKey]}/total`, body),
    ]);

    const breakdown = breakdownPayload[tabKey];
    let breakdownResponse = null;

    if (breakdown) {
      const breakdownRequestBody = breakdownRequestModel(responseData.data, payload, breakdown);
      breakdownResponse = await LionstudiosApi.post(API_ENDPOINTS_BY_PREFIXES[tabKey], breakdownRequestBody);
    }

    return dataResponseModel(
      responseData.data,
      responseTotal.data,
      tabKey,
      breakdownResponse?.data || {},
      breakdown,
    );
  },
};


export default Service;
