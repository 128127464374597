import { createSelector } from 'reselect';

import { State } from 'core/types';
import { Filter } from 'core/settings/types';
import { filterBasicDataSlice } from 'core/settings/slices';

import { Entities, CurrentFilter, Config } from './types';
import { KEY } from './constants';
import { getFiltersBasedOnPublishMode } from './utils';


const getLocalState = (state: State) => state[KEY];

const getCurrentConfigState = (state: State) => getLocalState(state).currentConfig;
const getQueryConfigState = (state: State) => getLocalState(state).queryConfig;
const getSelectedFiltersValuesState = (state: State) => getLocalState(state).selectedFiltersValues;
const getIsFiltersExpandedState = (state: State) => getLocalState(state).isFiltersExpanded;

export const getIsQueryConfigLoaded = (state: State) => getQueryConfigState(state).isLoaded;
export const getIsQueryConfigSaving = (state: State) => getQueryConfigState(state).isSaving;
export const getIsQueryConfigEmpty = (state: State) => getQueryConfigState(state).isEmpty;

export const getIsFiltersExpanded = getIsFiltersExpandedState;

export const getDataStateByType = createSelector(
  [
    getLocalState,
    (state, type: Entities) => type,
  ],
  (state, type) => state[type],
);

export const getCurrentConfig = createSelector(
  getCurrentConfigState,
  (config) => config,
);

export const getSelectedFiltersValues = createSelector(
  getSelectedFiltersValuesState,
  (filters) => filters,
);

export const getFiltersListBasedOnPublishMode = createSelector(
  filterBasicDataSlice.selectors.getData,
  getCurrentConfig,
  (filtersList: Filter[], { publishMode }: Config) => {
    if (!filtersList) {
      return [];
    }

    return getFiltersBasedOnPublishMode(filtersList, publishMode);
  },
);

export const getDefaultFilters = createSelector(
  getFiltersListBasedOnPublishMode,
  (filtersList: Filter[]) => filtersList.filter(({ isDefault, values }) => isDefault || values.length !== 0),
);

const getFiltersListWithConfigValues = createSelector(
  getFiltersListBasedOnPublishMode,
  getCurrentConfig,
  (filtersList: Filter[], { filter: appliedFilters }: Config) => {
    const filterMergedWithAppliedValues = filtersList.map((filter) => {
      const appliedFilter = appliedFilters.find(({ id }) => id === filter.id);

      if (!appliedFilter) {
        return filter;
      }

      return {
        ...filter,
        values: [
          ...appliedFilter.values, // TODO: check with publish mode options ?
        ],
      };
    });

    return filterMergedWithAppliedValues;
  },
);

export const getSelectedFilters = createSelector(
  getSelectedFiltersValues,
  getFiltersListWithConfigValues,
  (selectedFiltersValues: CurrentFilter[], filtersListWithConfigValues: Filter[]) => {
    const defaultFiltersListWithConfigValues = filtersListWithConfigValues.filter(({ isDefault, values }) => isDefault || values.length !== 0);

    const defaultListMergedWithSelectedFilters = selectedFiltersValues.reduce<Filter[]>((result, { id, values }) => {
      const defaultFilterIndex = result.findIndex(({ id: defaultFilterId }) => defaultFilterId === id);

      if (defaultFilterIndex !== -1) {
        result[defaultFilterIndex] = {
          ...result[defaultFilterIndex],
          values,
        };
      } else {
        const filter = filtersListWithConfigValues.find(({ id: filterId }) => filterId === id);

        if (filter) {
          result.push({
            ...filter,
            values,
          });
        }
      }

      return result;
    }, [...defaultFiltersListWithConfigValues]);

    if (selectedFiltersValues.length) {
      return defaultListMergedWithSelectedFilters.filter(({ id }) => selectedFiltersValues.find(({ id: selectedFilterId }) => id === selectedFilterId));
    }

    return defaultListMergedWithSelectedFilters;
  },
);
