import { Payload as BasePayload, RejectedPayload as BaseRejectedPayload } from 'types/redux';

import { createFetchingSlice } from 'core/fetching';
import { GetFetchingSliceTypes } from 'core/fetching/types';

import { waitForUserSession } from 'core/app/sagas';

import UsersService from './service';
import { KEY } from './constants';
import { userModel, savedUserViewsModels, createViewRequestModel } from './models';
import { // eslint-disable-line import/no-cycle
  handleAuthorizedUserSuccess,
  fetchSavedUserFilters,
  fetchSavedUserViews,
  handleUserViewCreate,
} from './sagas';
import {
  getCreateFilterSuccessMessage,
  getCreateViewSuccessMessage,
  getDeleteViewSuccessMessage,
  getUpdateViewSuccessMessage,
} from './utils';
import {
  fetchUserViewsResolvedExtendedReducer,
  deleteUserViewResolvedExtendedReducer,
  createUserViewResolvedExtendedReducer,
} from './extended-reducer';
import {
  SliceKeys,
  User,
  CreateUserFilterPayload,
  CreateUserFilterResolvedPayload,
  FetchSavedUserFiltersResolvedPayload,
  FetchUserPayload,
  DeleteUserFilterPayload,
  DeleteUserFilterResolvedPayload,
  UpdateUserFilterPayload,
  UpdateUserFilterResolvedPayload,
  FetchSavedUserViewsResolvedPayload,
  DeleteUserFilterMeta,
  CreateUserViewPayload,
  CreateUserViewResolvedPayload,
  DeleteUserViewPayload,
  DeleteUserViewResolvedPayload,
  UpdateUserViewPayload,
  UpdateUserViewResolvedPayload,
  CreateUserViewMeta,
} from './types';


export const authorizedUserSlice = createFetchingSlice<
SliceKeys,
BasePayload,
User
>({
  storeKey: KEY,
  sliceKey: SliceKeys.AuthorizedUser,
  serviceMethod: UsersService.fetchAuthorizedUser,
  models: [userModel, null],
  sagas: [waitForUserSession, handleAuthorizedUserSuccess],
});

export const createUserFilterSlice = createFetchingSlice<
SliceKeys,
CreateUserFilterPayload,
CreateUserFilterResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.CreateUserFilter,
  serviceMethod: UsersService.createUserFilter,
  models: [null, null],
  sagas: [waitForUserSession, fetchSavedUserFilters],
  getSuccessMessage: getCreateFilterSuccessMessage,
});

export const fetchSavedUserFiltersSlice = createFetchingSlice<
SliceKeys,
FetchUserPayload,
FetchSavedUserFiltersResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.SavedUserFilters,
  serviceMethod: UsersService.fetchUserFilters,
  models: [null, null],
  sagas: [waitForUserSession, null],
});

export const deleteSavedUserFilterSlice = createFetchingSlice<
SliceKeys,
DeleteUserFilterPayload,
DeleteUserFilterResolvedPayload,
BaseRejectedPayload,
DeleteUserFilterMeta
>({
  storeKey: KEY,
  sliceKey: SliceKeys.DeleteUserFilter,
  serviceMethod: UsersService.deleteUserFilter,
  models: [null, null],
  sagas: [waitForUserSession, fetchSavedUserFilters],
  savePayload: true,
});

export const updateSavedUserFilterSlice = createFetchingSlice<
SliceKeys,
UpdateUserFilterPayload,
UpdateUserFilterResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.UpdateUserFilter,
  serviceMethod: UsersService.updateUserFilter,
  models: [null, null],
  sagas: [waitForUserSession, fetchSavedUserFilters],
  savePayload: true,
});

export const fetchSavedUserViewsSlice = createFetchingSlice<
SliceKeys,
FetchUserPayload,
FetchSavedUserViewsResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.SavedUserViews,
  serviceMethod: UsersService.fetchUserViews,
  models: [savedUserViewsModels, null],
  sagas: [waitForUserSession, null],
  extendReducer: [
    null,
    fetchUserViewsResolvedExtendedReducer,
    null,
  ],
});

export const createUserViewSlice = createFetchingSlice<
SliceKeys,
CreateUserViewPayload,
CreateUserViewResolvedPayload,
BaseRejectedPayload,
CreateUserViewMeta
>({
  storeKey: KEY,
  sliceKey: SliceKeys.CreateUserView,
  serviceMethod: UsersService.createUserView,
  models: [null, createViewRequestModel],
  sagas: [waitForUserSession, handleUserViewCreate],
  getSuccessMessage: getCreateViewSuccessMessage,
  extendReducer: [
    null,
    createUserViewResolvedExtendedReducer,
    null,
  ],
});

export const deleteUserViewSlice = createFetchingSlice<
SliceKeys,
DeleteUserViewPayload,
DeleteUserViewResolvedPayload,
BaseRejectedPayload,
DeleteUserFilterMeta
>({
  storeKey: KEY,
  sliceKey: SliceKeys.DeleteUserView,
  serviceMethod: UsersService.deleteUserView,
  models: [null, null],
  sagas: [waitForUserSession, fetchSavedUserViews],
  getSuccessMessage: getDeleteViewSuccessMessage,
  extendReducer: [
    null,
    deleteUserViewResolvedExtendedReducer,
    null,
  ],
});

export const updateUserViewSlice = createFetchingSlice<
SliceKeys,
UpdateUserViewPayload,
UpdateUserViewResolvedPayload
>({
  storeKey: KEY,
  sliceKey: SliceKeys.UpdateUserView,
  serviceMethod: UsersService.updateUserView,
  models: [null, null],
  sagas: [waitForUserSession, fetchSavedUserViews],
  savePayload: true,
  getSuccessMessage: getUpdateViewSuccessMessage,
});


export type SliceType = (
  GetFetchingSliceTypes<typeof authorizedUserSlice> |
  GetFetchingSliceTypes<typeof createUserFilterSlice> |
  GetFetchingSliceTypes<typeof fetchSavedUserFiltersSlice> |
  GetFetchingSliceTypes<typeof deleteSavedUserFilterSlice> |
  GetFetchingSliceTypes<typeof updateSavedUserFilterSlice> |
  GetFetchingSliceTypes<typeof createUserViewSlice> |
  GetFetchingSliceTypes<typeof fetchSavedUserViewsSlice> |
  GetFetchingSliceTypes<typeof deleteUserViewSlice> |
  GetFetchingSliceTypes<typeof updateUserViewSlice>
);
