import { getName, registerLocale } from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

import { UNKNOWN_CODE, additionalCountryNames, customCodesMap } from './constants';
import { Code, Country } from './types';


registerLocale(enLocale);

export const getCountry = (code: Code): Country => {
  if (!code || code === UNKNOWN_CODE) {
    return {
      id: code ? code.toLowerCase() : null,
      name: `Unknown (${code})`,
      isUnknown: true,
    };
  }

  const customCode = customCodesMap[code as keyof typeof customCodesMap];
  const id = (customCode || code).toLowerCase();
  const name = getName(id, 'en');
  const countryName = name || additionalCountryNames[code];

  if (!countryName) {
    return {
      id,
      name: `Unknown (${code})`,
      isUnknown: true,
    };
  }

  const extendedName = customCode ? `${countryName} (${code})` : countryName;

  return {
    id,
    name: extendedName,
    isUnknown: Boolean(!name && countryName),
  };
};

export const convertCode = (code: Code): Code => {
  if (!code) {
    return null;
  }

  const customCode = customCodesMap[code as keyof typeof customCodesMap];

  if (customCode) {
    return customCode as Code;
  }

  if (code.length > 2) {
    return code.slice(0, 2) as Code;
  }

  return code;
};
