import React, { memo } from 'react';

import PlatformIcon from 'components/common/platform-icon';
import ChannelIcon from 'components/common/channel-icon';
import AdaptiveText from 'components/common/adaptive-text';
import Link from 'components/common/link';

import { Props } from './types';
import { Wrapper, ChannelIconWrapper, PlatformIconWrapper } from './styles';


const OfferName: React.FunctionComponent<Props> = memo(({ row, appName, platform, channel, onRowClick }) => (
  <Wrapper>
    <ChannelIconWrapper>
      <ChannelIcon channel={channel} />
    </ChannelIconWrapper>

    <PlatformIconWrapper>
      <PlatformIcon platform={platform} />
    </PlatformIconWrapper>

    <AdaptiveText onClick={() => onRowClick(row)}>
      <Link>
        {appName}
      </Link>
    </AdaptiveText>
  </Wrapper>
));


OfferName.displayName = 'OfferName';


export default OfferName;
