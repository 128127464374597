import { DateRangeRelativeFilter } from 'core/dashboards/types';


export const KEYS = {
  FROM: 'from',
  TO: 'to',
};

export const SHORTCUT_LABELS = {
  [DateRangeRelativeFilter.TODAY]: 'Today',
  [DateRangeRelativeFilter.YESTERDAY]: 'Yesterday',
  [DateRangeRelativeFilter.LAST_7_DAYS]: 'Last 7 days (Yesterday)',
  [DateRangeRelativeFilter.LAST_7_DAYS_TODAY]: 'Last 7 days (Today)',
  [DateRangeRelativeFilter.MONTH_TO_DATE]: 'Month to date',
  [DateRangeRelativeFilter.LAST_MONTH]: 'Previous month',
};
