import { AxiosResponse } from 'axios';

import { Action, ActionCreators } from 'types/redux';


export enum Types {
  INIT = 'APP/INIT',
  HANDLE_ERROR = 'APP/HANDLE_ERROR',
  HANDLE_HTTP_ERROR = 'APP/HANDLE_HTTP_ERROR',
}

export interface AppActions extends ActionCreators<Types, Payload | null> {
  init: () => Action<Types, null, null>;
  handleError: () => Action<Types, null, null>;
  handleHttpError: (payload: HttpErrorPayload) => Action<Types, HttpErrorPayload, null>;
}

export interface HttpErrorPayload {
  response: AxiosResponse;
}

export type Payload = HttpErrorPayload;
