import React, { memo } from 'react';

import IconButton from '@mui/material/IconButton';

import { ReactComponent as AddIcon } from 'assets/icons/add.svg';

import { Props } from './types';


const AddButton: React.FunctionComponent<Props> = memo(({ onClick }) => (
  <IconButton
    onClick={onClick}
    aria-label="Add"
  >
    <AddIcon
      fontSize="inherit"
      color="inherit"
    />
  </IconButton>
));


AddButton.displayName = 'AddButton';


export default AddButton;
