import { createActions } from 'utils/redux';

import { AuthActions, SignInPayload, SignInWithOktaPayload, Types, Payload } from './types';


export const PREFIX = 'AUTH';

export const actions = createActions<Types, Payload, AuthActions>({
  [Types.SIGN_IN_WITH_OKTA]: (payload: SignInWithOktaPayload) => payload,
  [Types.SIGN_IN]: (payload: SignInPayload) => payload,
  [Types.AUTH_SUCCESS]: () => null,
  [Types.LOAD_AUTH]: () => null,
  [Types.LOGOUT]: () => null,
}, {
  prefix: PREFIX,
});


export default actions;
