import { FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Container, FormControl, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft } from '@mui/icons-material';

import { actions as adminPermissionTemplatesAction } from 'core/admin/permission-templates/actions';
import { getPermissionTemplate, getPermissionTemplateError, getPermissionTemplateIsLoading } from 'core/admin/permission-templates/selectors';
import AdminPermissionTemplateService from 'core/admin/permission-templates/service';
import { PermissionToggleList } from 'core/admin/permission-templates/types';
import { UserRole, UserRoleDisplay } from 'core/users/types';
import { BootstrapInput, BootstrapLabel } from 'components/common/bootstrap-input/styles';
import { ROUTES } from 'containers/router/constants';
import theme from 'containers/theme-provider/theme';
import SnackbarService from 'services/snackbar';
import { isNumber } from 'utils';


type PermissionTemplateForm = {
  name: string;
  role: UserRole;
  permissionList: string[];
};

const PermissionTemplatesEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const permissionTemplateId = params.permissionTemplateId;
  const isEdit = !!permissionTemplateId;

  const permissionTemplate = useSelector(getPermissionTemplate);
  const isLoadingPermissionTemplate = useSelector(getPermissionTemplateIsLoading);
  const permissionTemplateError = useSelector(getPermissionTemplateError);

  const [isLoading, setIsLoading] = useState(false); // submit btn loading
  const [values, setValues] = useState<PermissionTemplateForm>({
    name: '',
    role: UserRole.RegularUser,
    permissionList: [],
  });

  // fetch if editing
  useEffect(() => {
    if (permissionTemplateId) {
      dispatch(adminPermissionTemplatesAction.fetchOne({ permissionTemplateId: parseInt(permissionTemplateId) }));
    }
  }, [permissionTemplateId, dispatch]);

  // set fetched data to state
  useEffect(() => {
    if (isEdit && permissionTemplate) {
      setValues({
        name: permissionTemplate.name,
        role: permissionTemplate.role,
        permissionList: permissionTemplate.permissionList,
      });
    }
  }, [permissionTemplate, isEdit, setValues]);

  // handle fetch user error
  useEffect(() => {
    if (permissionTemplateError) {
      dispatch(adminPermissionTemplatesAction.fetchOneReset());
      navigate(ROUTES.PERMISSIONS_INDEX);
    }
  }, [permissionTemplateError, navigate, dispatch]);

  // handle general input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleSelectChange = (prop: keyof PermissionTemplateForm) => (event: SelectChangeEvent<string | unknown>, child: ReactNode) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handlePermissionListChange = (key: string) => (event: SelectChangeEvent<string | unknown>) => {
    const currentIndex = values.permissionList.indexOf(key);
    const newList = [...values.permissionList];
    if (event.target.value) { // yes
      if (currentIndex === -1) {
        newList.push(key);
      }
    } else { // no
      if (currentIndex >= 0) {
        newList.splice(currentIndex, 1);
      }
    }
    setValues({
      ...values,
      permissionList: newList,
    });
  };

  const roleSelection = useMemo<Array<{ key: UserRole; label: string }>>(() => {
    const arr = [];
    for (const role in UserRole) {
      if (isNumber(role)) {
        arr.push({
          key: role as unknown as UserRole,
          label: UserRoleDisplay[role as unknown as UserRole],
        });
      }
    }
    return arr;
  }, []);

  const handleClickBack = useCallback(() => {
    navigate(ROUTES.PERMISSIONS_INDEX);
  }, [navigate]);

  // remove permission of other role
  const cleanPermissionList = useCallback(() => {
    const rolePermissionList = PermissionToggleList[values.role].flatMap((o) => o.permissions.map((p) => p.key));
    const newList = [...values.permissionList];
    values.permissionList.forEach((p) => {
      if (rolePermissionList.indexOf(p) === -1) {
        newList.splice(newList.indexOf(p), 1);
      }
    });
    return newList;
  }, [values]);

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault();
    if (isEdit && !permissionTemplate) {
      return;
    }
    setIsLoading(true);
    const data = { ...values };
    data.permissionList = cleanPermissionList();
    (
      isEdit ? AdminPermissionTemplateService.edit({ permissionTemplateId: parseInt(permissionTemplateId), data }) :
        AdminPermissionTemplateService.create({ data })
    )
      .then(() => {
        navigate(ROUTES.PERMISSIONS_INDEX);
      }).catch((e: any) => {
        setIsLoading(false);
        SnackbarService.showError(
          e?.response?.data ?? (e?.message ?? 'Error'),
          {
            vertical: 'bottom',
            horizontal: 'left',
          },
        );
      });
  }, [values, navigate, isEdit, permissionTemplate, permissionTemplateId]);

  const inputSkeleton = useMemo(() => (
    <Skeleton
      height={50}
      sx={{ marginTop: '6px' }}
    />
  ), []);

  return (
    <Container
      maxWidth="lg"
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        color={theme.palette.grey[800]}
        marginBottom="8px"
      >{isEdit ? 'Edit' : 'Create'} Permission Template</Typography>
      <Paper
        elevation={3}
      >
        <Box
          component="form"
          autoComplete="off"
          padding={2}
          onSubmit={handleSubmit}
        >
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
          >
            <FormControl
              variant="standard"
              fullWidth
              sx={{ marginBottom: '12px' }}
            >
              <BootstrapLabel shrink>Name</BootstrapLabel>
              {
                isLoadingPermissionTemplate ? inputSkeleton :
                  <BootstrapInput
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    required
                  />
              }
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ marginBottom: '12px' }}
            >
              <BootstrapLabel shrink>Access Role</BootstrapLabel>
              {
                isLoadingPermissionTemplate ? inputSkeleton :
                  <BootstrapInput
                    as={Select}
                    value={values.role}
                    onChange={handleSelectChange('role')}
                  >
                    {
                      roleSelection.map((item) => (
                        <MenuItem
                          value={item.key}
                          key={item.key}
                        >{item.label}</MenuItem>
                      ))
                    }
                  </BootstrapInput>
              }
            </FormControl>
          </Stack>

          <Typography
            variant="h2"
            fontWeight="bold"
            color={theme.palette.grey[800]}
            borderBottom="1px solid #E8EAEE"
            marginBottom="8px"
          >Permission Togglers</Typography>
          <div style={{ marginLeft: '8px' }} >
            {
              PermissionToggleList[values.role as UserRole].map((section, i) => (
                <div
                  key={i}
                >
                  <Typography
                    variant="h2"
                    fontWeight="bold"
                    color={theme.palette.grey[500]}
                    borderBottom="1px solid #E8EAEE"
                    marginBottom="8px"
                  >{section.label}</Typography>
                  <Stack
                    direction="row"
                    gap={2}
                  >
                    {
                      section.permissions.map((permission, idx) => (
                        <FormControl
                          key={idx}
                          variant="standard"
                          sx={{
                            marginBottom: '12px',
                            width: '25%',
                          }}
                        >
                          <BootstrapLabel shrink>{permission.label}</BootstrapLabel>
                          {
                            isLoadingPermissionTemplate ? inputSkeleton :
                              <BootstrapInput
                                as={Select}
                                value={values.permissionList.indexOf(permission.key) === -1 ? 0 : 1}
                                onChange={handlePermissionListChange(permission.key)}
                              >
                                <MenuItem
                                  value={0}
                                >No</MenuItem>
                                <MenuItem
                                  value={1}
                                >Yes</MenuItem>
                              </BootstrapInput>
                          }
                        </FormControl>
                      ))
                    }
                  </Stack>
                </div>
              ))
            }
          </div>

          <Stack
            direction="row"
            justifyContent="end"
          >
            <Button
              variant="text"
              startIcon={<ChevronLeft />}
              onClick={handleClickBack}
            >Back</Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isLoading}
              disabled={isEdit && isLoadingPermissionTemplate}
            >Submit</LoadingButton>
          </Stack>

        </Box>
      </Paper>
    </Container>
  );
};

PermissionTemplatesEdit.displayName = 'PermissionTemplatesEdit';

export default PermissionTemplatesEdit;
