import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';

import { DateRangeFilterConverted, DateRangeRelativeFilter } from 'core/dashboards/types';
import { getDateRangeForRelativeFilter } from 'core/dashboards/utils';


export const getDefaultValue = (value: DateRangeFilterConverted | undefined, relativeValue?: DateRangeRelativeFilter): DateRange<Date> => {
  if (relativeValue) {
    return getDateRangeForRelativeFilter(relativeValue) as DateRange<Date>;
  }

  if (!value) {
    return getDateRangeForRelativeFilter(DateRangeRelativeFilter.LAST_7_DAYS) as DateRange<Date>;
  }

  return [value.from, value.to];
};
