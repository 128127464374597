import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import App from 'containers/app';


Modal.setAppElement('#root');

// eslint-disable-next-line import/no-named-as-default-member
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
