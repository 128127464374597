import React, { memo, useState, useMemo, useCallback } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { OtherFilter } from 'core/settings/types';

import TreeViewList from 'components/common/tree-view-list';
import SearchInput from 'components/common/search-input';

import { EXPAND_ALL_SEARCH_LENGTH } from './constants';
import { groupFiltersByCategory, getSelected } from './utils';
import { Wrapper, Title, ButtonWrapper, StyledButton, TreeViewListWrapper, StyledSearchInput } from './styles';
import { Props } from './types';


const DashboardFiltersListModal: React.FunctionComponent<Props> = memo(({ onCancel, onSubmit, filterList }) => {
  const [searchText, setSearchText] = useState<string>('');
  const [selected, setSelected] = useState(() => getSelected(filterList));

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  const filteredFilterList = useMemo(() => {
    if (!searchText.trim().length) {
      return filterList;
    }

    return filterList.filter(({ name }) => name.trim().toLowerCase().includes(searchText.trim().toLowerCase()));
  }, [searchText, filterList]);

  const handleSelect = useCallback((nextSelected: string[]) => {
    setSelected(nextSelected);
  }, []);

  const handleSubmit = useCallback(() => {
    const nextSelectedFilters = selected.map((id) => filterList.find((filter) => String(filter.id) === id));
    onSubmit(nextSelectedFilters as OtherFilter[]);
    onCancel();
  }, [selected, onSubmit, onCancel, filterList]);

  const groupedFilterList = useMemo(() => groupFiltersByCategory(filteredFilterList), [filteredFilterList]);

  return (
    <Wrapper>
      <Title
        as={Typography}
        variant="body2"
        color="primary"
      >
        Add Other filters
      </Title>

      <StyledSearchInput
        as={SearchInput}
        value={searchText}
        onChange={handleSearchChange}
        autoFocus
      />

      {
        groupedFilterList.length ? (
          <TreeViewListWrapper>
            <TreeViewList
              selected={selected}
              list={groupedFilterList}
              parentLabelKey="categoryName"
              childrenLabelKey="name"
              childrenKey="filters"
              parentNodeKey="category"
              childrenNodeKey="id"
              onSelect={handleSelect}
              expandAll={searchText.length >= EXPAND_ALL_SEARCH_LENGTH}
            />
          </TreeViewListWrapper>
        ) : (
          <Typography>No options</Typography>
        )
      }

      <ButtonWrapper>
        <StyledButton
          variant="contained"
          color="primary"
          as={Button}
          onClick={handleSubmit}
        >
          Add Filters
        </StyledButton>

        <StyledButton
          variant="outlined"
          color="primary"
          as={Button}
          onClick={onCancel}
        >
          Cancel
        </StyledButton>
      </ButtonWrapper>
    </Wrapper>
  );
});


DashboardFiltersListModal.displayName = 'DashboardFiltersListModal';


export default DashboardFiltersListModal;
