export const KEY = 'settings';

export const PREFIX = KEY.toUpperCase();

export const BASE_URL = 'settings';

export const CUSTOM_FILTER_NAMES = {
  SKU_APP_NAME: 'sku.APP_NAME',
  SKU_PLATFORM: 'sku.PLATFORM',
  SKU_PLATFORMS: 'sku.PLATFORMS',
  GEO: 'geo.COUNTRY',
};

export const CUSTOM_FILTER_FIELDS = {
  [CUSTOM_FILTER_NAMES.SKU_APP_NAME]: {
    NAME: 'sku.APP_NAME',
    PLATFORM: 'sku.PLATFORM',
  },
};

export const SKU_PLATFORM_NULL_OPTION = {
  id: 'NULL',
  name: 'Unknown',
};

export const OPTION_FIELDS = {
  NORMAL: 'options',
  TEST: 'testOptions',
};

export const MARKETING_FEED_ID = 'MARKETING';

export const COUNTRY_BREAKDOWN_VALUE = 'measure.COUNTRY';

export const FEED_NO_DATA_ERROR_TEXT = 'There is no data yet. Please, try again later.';
