export const TYPES = {
  ARRAY: 'array',
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
};

export const ALLOWED_COMPLEX_TYPES = [
  TYPES.ARRAY,
];
