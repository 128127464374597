import { styled } from '@mui/material/styles';

import { PADDING_BASE } from 'containers/theme-provider/constants';


export const CIRCLE_SIZE = 20;
export const CIRCLE_SPACING = PADDING_BASE;


export const Wrapper = styled('div')(({ theme }) => ({

}));

export const ChromePickerStyles = {
  default: {
    picker: {
      boxShadow: 'none',
    },
  },
};

export const SaveButton = styled('div')(({ theme }) => ({
  width: '100%',
  height: '30px',
}));
