import {
  GridRowParams,
  GridValueFormatterParams,
  GridPinnedColumns,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridRowGroupingModel,
  GridApi,
  GridRowClassNameParams,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridValidRowModel,
} from '@mui/x-data-grid-premium';

import {
  GroupingResolvedPayload,
  GroupingOptionModel,
  BreakdownOption,
  ViewColumn,
  Breakdown,
  Value,
} from 'core/settings/types';
import { SavedViewMergedWithFields } from 'core/users/types';
import { getFakeView as getFakeViewBase, convertMergedViewToSavedView } from 'core/users/utils';
import { DataNode, Entities, Summary, Sorting, ColumnFilterModel, CurrentFilter, Platform } from 'core/dashboards/types';
import { BACK_END_KEYS } from 'core/dashboards/constants';
import { getPageSizeByBreakdown } from 'core/dashboards/utils';
import { CUSTOM_FILTER_NAMES } from 'core/settings/constants';

import { GridConfig } from 'components/common/data-grid/types';

import { withPrefix, isValueExists, isArray } from 'utils';

import { CUSTOM_PARENT_BREAKDOWN_ROW_CLASS } from 'containers/theme-provider/components-overrides/data-grid';

// TODO: move this util somewhere else ?
import { getTabKeyByIndex } from 'pages/dashboard/utils'; // eslint-disable-line import/no-cycle
import { DEFAULT_AGGREGATION_FUNC, DEFAULT_COLUMN_FIELD, CUSTOM_FILTERS_FIELDS_MAP } from './constants';
import { GroupingParams, GroupingConfig, BreakdownParams, GridBreakdownConfig } from './types';
import { getColumnPropsByType } from './config';  // eslint-disable-line import/no-cycle


export const getGroupingConfig = (currentTab: number, groupingConfig: GroupingResolvedPayload): {
  tabKey: Entities | null;
  config:  Array<GroupingOptionModel> | null;
} => {
  const tabKey = getTabKeyByIndex(currentTab);
  const defaultValue = {
    tabKey: null,
    config: null,
  };

  if (!tabKey) {
    return defaultValue;
  }

  return {
    tabKey,
    config: groupingConfig?.[tabKey],
  };
};

export const getGroupByKey = (key: string | null, currentTab: number, groupingConfig: GroupingResolvedPayload | null): GroupingOptionModel | null => {
  if (!key || !groupingConfig) {
    return null;
  }

  const { config } = getGroupingConfig(currentTab, groupingConfig);

  if (!config) {
    return null;
  }

  return config.find((grouping) => grouping.key === key) || null;
};

export const isGroupingRow = (row: GridRowParams): boolean => !Object.keys(row).length;

export const getDefaultView = (views: SavedViewMergedWithFields[] | null) => views?.find((view) => view.isDefault) || null;

export const getBasicView = (views: SavedViewMergedWithFields[] | null) => views?.find((view) => !view.isRemovable) || null;

export const getActiveView = (views: SavedViewMergedWithFields[] | null, savedView: SavedViewMergedWithFields | null) => {
  const activeView = views?.find((view) => view.id === savedView?.id);

  if (!activeView) {
    return getDefaultView(views);
  }

  return activeView;
};

export const getSkuNameExportFormatter = (data: DataNode[]) => ({ value, id }: GridValueFormatterParams<string>) => {
  const currentRow = data.find((row) => row.id === id);

  if (!currentRow) {
    return value;
  }

  return `${currentRow[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)]} ${value}`;
};

export const getOfferNameExportFormatter = (data: DataNode[]) => ({ value, id }: GridValueFormatterParams<string>) => {
  const currentRow = data.find((row) => row.id === id);

  if (!currentRow) {
    return value;
  }

  return `${currentRow[withPrefix(Entities.Campaigns, BACK_END_KEYS.CHANNEL)]} ${value} ${currentRow[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)]}`;
};

export const getCampaignCountryExportFormatter = (data: DataNode[]) => ({ value, id }: GridValueFormatterParams<string>) => {
  const currentRow = data.find((row) => row.id === id);

  if (!currentRow) {
    return value;
  }

  return `${currentRow[withPrefix(Entities.Geos, BACK_END_KEYS.COUNTRY)]} ${currentRow[withPrefix(Entities.Campaigns, BACK_END_KEYS.NAME)]}`;
};

export const getFakeView = (views: SavedViewMergedWithFields[], view: SavedViewMergedWithFields | null): SavedViewMergedWithFields | null => {
  if (!view) {
    return null;
  }

  const otherCopies = views.filter(({ isCustom }) => isCustom);
  const nextNameString = `Copy of ${view.name}`;
  const nextNameIncrementString = otherCopies.length === 0 ? '' : ` (${otherCopies.length + 1})`;
  const nextName = `${nextNameString}${nextNameIncrementString}`;
  const savedView = convertMergedViewToSavedView(view);
  const fakeView = getFakeViewBase(savedView, {
    name: nextName,
    isRemovable: true,
    isEdited: true,
  });

  return {
    ...fakeView,
    value: { ...view.value },
  };
};

export const getConvertMergedFields = (view: SavedViewMergedWithFields): string[] => view.value.fields.map(({ key }) => key);

export const getPinnedColumns = (pinnedColumns: string[]): GridPinnedColumns => ({
  left: [
    GRID_CHECKBOX_SELECTION_COL_DEF.field,
    ...pinnedColumns,
  ],
});

export const getViewPinnedColumns = (view: SavedViewMergedWithFields | null): GridPinnedColumns => getPinnedColumns(view?.value?.pinnedColumns || []);

export const getAggregationModel = (data: DataNode[], summary: Summary | null, grouping: string | null) => {
  if (!summary || !data?.length) {
    return {};
  }

  let model;
  if (grouping) {
    model = summary.groups && Object.keys(summary.groups).length ? Object.keys(Object.values(summary.groups)[0]) : undefined;
  } else {
    model = summary.measures ? Object.keys(summary.measures) : undefined;
  }

  if (!model) {
    return {};
  }

  return model.reduce<Record<string, string>>((result, key) => {
    result[key] = DEFAULT_AGGREGATION_FUNC;
    return result;
  }, {
    [DEFAULT_COLUMN_FIELD]: DEFAULT_AGGREGATION_FUNC,
  });
};

export const getAggregationPositionByGroupingModel = (rowGroupingModel: GridRowGroupingModel | undefined) => () => rowGroupingModel?.length ? 'inline' : 'footer';

export const isRowSelectableForBreakdown = ({ row }: GridRowParams) => row.isParent;
export const isRowSelectableForGroupping = ({ row }: GridRowParams) => Boolean(Object.values(row).length);

export const getActiveBreakdown = (breakdownConfig: Breakdown[], breakdown: BreakdownOption['key'] | null): BreakdownOption | null => {
  if (!breakdown || !breakdownConfig) {
    return null;
  }

  const activeBreakdown = breakdownConfig.reduce<BreakdownOption | null>((result, { options }) => {
    if (result) {
      return result;
    }

    const option = options.find(({ key }) => key === breakdown);

    return option || result;
  }, null);

  return activeBreakdown;
};

export const getRowsPerPageOptions = (breakdown: BreakdownOption['key'] | null): { rowsPerPageOptions: [number] } | null => {
  if (!breakdown) {
    return null;
  }

  return {
    rowsPerPageOptions: [getPageSizeByBreakdown(breakdown, 0)],
  };
};

export const filterGridPinnedColumns = (pinnedColumns: GridPinnedColumns | undefined | null): string[] => pinnedColumns?.left?.filter((column) => column !== GRID_CHECKBOX_SELECTION_COL_DEF.field) || [];

export const getGroupingPinnedColumns = (nextPinnedColumns: string[] | undefined): string[] => {
  if (!nextPinnedColumns || !nextPinnedColumns.length) {
    return [];
  }

  const filteredColumns = nextPinnedColumns.filter((column) => (column !== DEFAULT_COLUMN_FIELD));

  if (filteredColumns.includes(GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD)) {
    if (filteredColumns.length === 1) {
      return [];
    }

    return filteredColumns;
  }

  return [
    GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
    ...filteredColumns,
  ];
};

export const filterViewPinnedColumns = (pinnedColumns: string[]): string[] => pinnedColumns.filter((pinnedColumn) => pinnedColumn !== GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD);

export const getGridRowGroupingConfig = <C extends GridConfig<any>>({ grouping, gridApi, gridConfig, defaultLeafField, defaultGroupName, pinnedColumns }: GroupingParams<C>): GroupingConfig => {
  let updatedConfig;

  if (gridConfig.columns.find(({ field }) => field === grouping)) {
    updatedConfig = gridConfig;
  } else {
    updatedConfig = {
      ...gridConfig,
      columns: [...gridConfig.columns, {
        field: grouping,
        isHidden: true,
      }],
    };
  }

  updatedConfig = {
    ...updatedConfig,
    rows: updatedConfig.rows.map((row) => {
      const groupingValue = row[grouping as keyof DataNode];

      if (isValueExists(groupingValue)) {
        return row;
      }

      return {
        ...row,
        [grouping as keyof DataNode]: defaultGroupName,
      };
    }),
  };


  let leafField;
  if (!updatedConfig?.columns?.length) {
    leafField = defaultLeafField;
  } else {
    const [firstColumn, secondColumn] = updatedConfig.columns;
    const isFirstColumnIsIndexColumn = firstColumn.field === defaultLeafField;

    if (isFirstColumnIsIndexColumn && secondColumn) {
      leafField = secondColumn.field;
    } else {
      leafField = firstColumn.field;
    }
  }

  const leafColumn = (gridApi as GridApi).getColumn?.(leafField) || null;

  const columnVisibilityModel = {
    [leafField]: false,
    [defaultLeafField]: false,
    ...updatedConfig.columns.reduce<Record<string, boolean>>((result, column) => {
      if (column.isHidden) {
        result[column.field] = false;
      }

      return result;
    }, {}),
  };

  const groupingColDef = {
    leafField,
    fieldOverride: leafField,
    renderHeader: leafColumn?.renderHeader,
    cellClassName: leafColumn?.cellClassName,
    sortable: false,
    isSortingForcedEnabled: true,
  };

  const result = {
    columnVisibilityModel,
    groupingColDef,
    rowGroupingModel: [grouping],
    disableRowGrouping: false,
    config: updatedConfig,
    pinnedColumns: getPinnedColumns(getGroupingPinnedColumns(filterGridPinnedColumns(pinnedColumns))),
    isRowSelectable: isRowSelectableForGroupping,
  };

  return result;
};

export const getBreakdownParentRowClassName = ({ row }: GridRowClassNameParams): string => {
  if (row.isParent) {
    return CUSTOM_PARENT_BREAKDOWN_ROW_CLASS;
  }

  return '';
};

export const getGridBreakdownConfig = <C extends GridConfig<any>>({ breakdown, viewConfiguration, currentTab, gridConfig, data, onRowClick, gridPinnedColumns, onCellEdit, onCellRestart }: BreakdownParams<C>): GridBreakdownConfig<C> => {
  const tabKey = getTabKeyByIndex(currentTab);
  const field = Object.values(viewConfiguration || {}).reduce<ViewColumn | null>((result, fieldsMap) => {
    if (result) {
      return result;
    }

    const searchableField = Object.values(fieldsMap).find(({ key }) => key === breakdown);

    return searchableField || result;
  }, null);

  let config = gridConfig;
  let pinnedColumns = gridPinnedColumns?.left || [];

  if (field !== null) {
    const breakdownColumn = {
      field: field.key,
      headerTitle: field.name,
      headerSubTitle: field.source,
      filterable: false,
      sortable: false,
      primary: field.primary,
      width: field.width,
      isPinLocked: true,
      ...getColumnPropsByType(tabKey, data, field.type, field.rounding, () => onRowClick, () => {}, onCellEdit, onCellRestart),
    };

    const [indexColumn, ...restColumns] = gridConfig.columns;

    const updatedColumns = [
      {
        ...indexColumn,
        isPinLocked: true,
      },
      breakdownColumn,
      ...restColumns,
    ];

    config = {
      ...config,
      columns: updatedColumns,
    };

    if (pinnedColumns.length <= 1 ) {
      pinnedColumns = [
        ...pinnedColumns,
        DEFAULT_COLUMN_FIELD,
        field.key,
      ];
    } else {
      pinnedColumns = [
        ...pinnedColumns.slice(0, 2),
        ...(pinnedColumns.includes(field.key) ? [] : [field.key]),
        ...pinnedColumns.slice(2),
      ];
    }
  }

  return {
    config,
    breakdown,
    pinnedColumns: {
      left: pinnedColumns,
    },
    isRowSelectable: isRowSelectableForBreakdown,
    getRowClassName: getBreakdownParentRowClassName,
  };
};

export const getActiveSortingTitles = (view: SavedViewMergedWithFields | null, sortModel: Sorting[] | undefined): string[] => {
  if (!view || !sortModel?.length) {
    return [];
  }

  return sortModel.reduce<string[]>((result, { field }) => {
    const sortField = view.value.fields.find(({ key }) => key === field);

    if (sortField) {
      result.push(`${sortField.name} (${sortField.source})`);
    }

    return result;
  }, []);
};

export const getActiveFilterTitles = (view: SavedViewMergedWithFields | null, filterModel: ColumnFilterModel | undefined): string[] => {
  if (!view || !filterModel?.items?.length) {
    return [];
  }

  return filterModel.items.reduce<string[]>((result, { columnField }) => {
    const sortField = view.value.fields.find(({ key }) => key === columnField);

    if (sortField) {
      result.push(`${sortField.name} (${sortField.source})`);
    }

    return result;
  }, []);
};

export const getFiltersValuesMap = (filters: CurrentFilter[]): Record<string, CurrentFilter['values']> => {
  const filterValues = filters.reduce<Record<string, CurrentFilter['values']>>((result, { id, values }) => {
    result[id] = values;
    return result;
  }, {} as Record<string, CurrentFilter['values']>);

  const customValues = Object.entries(CUSTOM_FILTERS_FIELDS_MAP).reduce<Record<string, CurrentFilter['values']>>((result, [customField, field]) => {
    result[customField] = filterValues[field];
    return result;
  }, {} as Record<string, CurrentFilter['values']>);

  return {
    ...filterValues,
    ...customValues,
  };
};

export const getAvailableFilters = (filters: CurrentFilter[]): string[] => ([
  ...filters.map(({ id }) => String(id)),
  ...Object.keys(CUSTOM_FILTERS_FIELDS_MAP),
]);

const getSkuAppNameFilterOptionId = (field: string, row: GridValidRowModel) => `${row[field]}.${row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)]}`;
const getCustomSkuAppNameFilterOptionId = (value: string, platform: string) => `${value}.${platform}`;

const getCustomFilterValue = (values: string[], field: string, row: GridValidRowModel) => ({
  [CUSTOM_FILTER_NAMES.SKU_APP_NAME]: () => {
    const platform = row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)] || row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORMS)];

    if (isArray(platform)) {
      const [value] = values;

      return platform.map((platformValue: string) => ({
        value,
        platform: platformValue,
        id: getCustomSkuAppNameFilterOptionId(value, platformValue),
        name: value,
      }));
    }

    return values.map((value) => ({
      value,
      platform: row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)],
      id: getSkuAppNameFilterOptionId(field, row),
      name: value,
    }));
  },
});

export const getFilterValueExtractor = (values: string[], field: string, row: GridValidRowModel): { value: string | number | boolean | null; platform?: Platform; id?: string; name?: string }[] => {
  const customFilterValue = getCustomFilterValue(values, field, row)[field];

  if (!customFilterValue) {
    return values.map((value) => ({
      value,
      id: value,
      name: value,
    }));
  }

  return customFilterValue();
};

const getDefaultFilterValueComparator = (field: string, row: GridValidRowModel) => ({ id }: Value) => id === row[field];

const getCustomFilterValueComparator = (field: string, row: GridValidRowModel) => ({
  [CUSTOM_FILTER_NAMES.SKU_APP_NAME]: ({ id }: Value) => {
    const platforms = row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORMS)];

    if (platforms) {
      return (platforms as string[]).reduce<boolean>((result, platform) => {
        if (result) {
          return result;
        }
        const customId = getCustomSkuAppNameFilterOptionId(row[field], platform);

        return customId === id;
      }, false);
    }
    return id === getSkuAppNameFilterOptionId(field, row);
  },
  [CUSTOM_FILTER_NAMES.SKU_PLATFORMS]: ({ id }: Value) => row[field] && row[field].includes(id),
});

export const getFilterValueComparator = (field: string, row: GridValidRowModel): ((value: Value) => boolean) => {
  const customFilterValueComparator = getCustomFilterValueComparator(field, row)[field];

  if (!customFilterValueComparator) {
    return getDefaultFilterValueComparator(field, row);
  }

  return customFilterValueComparator;
};

const getCellSelectedCustomValue = (field: string, row: GridValidRowModel): Record<string, () => { field: string; value: string[] }> => ({
  [withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORMS)]: () => ({
    field: withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM),
    value: row[field].map(String),
  }),
});

export const getCellSelectedValue = (field: string, row: GridValidRowModel): { field: string; value: string[] } => {
  const getCustomValue = getCellSelectedCustomValue(field, row)[field];

  if (!getCustomValue) {
    return {
      field,
      value: [String(row[field])],
    };
  }

  return getCustomValue();
};
