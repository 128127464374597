import React, { memo } from 'react';

import UnselectIconButton from 'components/common/unselect-icon-button';

import { Props } from './types';

import { 
  Wrapper,
  ContentWrapper,
} from './styles';


const SelectableContainer: React.FunctionComponent<Props> = memo(({ children, isSelected }) => (
  <Wrapper>
    <ContentWrapper
      $isSelected={Boolean(isSelected)}
    >
      {children}
    </ContentWrapper>
  
    {isSelected && (
      <UnselectIconButton />
    )}
  </Wrapper>
));


SelectableContainer.displayName = 'SelectableContainer';


export default SelectableContainer;
