import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

export const ContentWrapper = styled('div')(() => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));
