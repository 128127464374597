import React, { memo } from 'react';

import { PLATFORMS_ICONS } from 'core/dashboards/constants';

import { Props } from './types';


const PlatformIcon: React.FunctionComponent<Props> = memo(({ platform }) => {
  const IconComponent = PLATFORMS_ICONS[platform] || null;
  return IconComponent && <IconComponent />;
});


PlatformIcon.displayName = 'PlatformIcon';


export default PlatformIcon;
