import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2.5),
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.15)',
  borderRadius: theme.shape.borderRadius,
  minWidth: 270,
}));

export const Title = styled('p')(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
}));

export const TreeViewListWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(-2.5),
  marginRight: theme.spacing(-2.5),
}));

export const StyledSearchInput = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1.25),
}));

export const StyledButton = styled('div')({
  width: 110,
  height: 30,
});
