import { styled } from '@mui/material/styles';

import { buttonClasses } from '@mui/material/Button';

import { StyledButtonProps } from './types';


export const StyledButton = styled('div')<StyledButtonProps>(({ theme, $isPopperOpen }) => ({
  padding: theme.spacing(1, 2.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 41,
  borderRadius: 0,
  color: theme.palette.common.black,
  position: 'relative',
  zIndex: 30,
  background: theme.palette.common.white,

  ...theme.typography.body1,

  '& svg': {
    fill: $isPopperOpen ? theme.palette.primary.main : theme.palette.grey[300],
    transition: theme.transitions.create('fill'),
  },

  '&:hover': {
    backgroundColor: theme.palette.common.white,

    '& svg': {
      fill: $isPopperOpen ? theme.palette.primary.main : theme.palette.grey[500],
    },
  },

  [`.${buttonClasses.startIcon}`]: {
    marginLeft: 0,
    marginRight: theme.spacing(2.125),
  },
}));

export const StyledPopper = styled('div')({
  zIndex: 30,
});
