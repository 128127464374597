import { UserRole } from 'core/users/types';
import { Action, ActionCreator } from 'types/redux';


export enum Types {
  FETCH_ALL = 'ADMIN/PERMISSION_TEMPLATES/FETCH_ALL',
  FETCH_ONE = 'ADMIN/PERMISSION_TEMPLATES/FETCH_ONE',
  FETCH_ONE_RESET = 'ADMIN/PERMISSION_TEMPLATES/FETCH_ONE_RESET',
}

export interface Actions extends ActionCreator<Types, Payload, null> {
  fetchAll: () => Action<Types, null, null>;
  fetchOne: (payload: FetchOnePermissionTemplatePayload) => Action<Types, Payload, null>;
  fetchOneReset: () => Action<Types, null, null>;
}

export type PermissionTemplateForm = {
  name: string;
  role: UserRole;
  // isDefault: boolean;
  permissionList: string[];
};

export type CreatePermissionTemplatePayload = {
  data: PermissionTemplateForm;
};

export type EditPermissionTemplatePayload = {
  data: PermissionTemplateForm;
  permissionTemplateId: number;
};

export type FetchOnePermissionTemplatePayload = {
  permissionTemplateId: number;
};

export type RejectedPayload = {
  error: any;
};

export type Payload = FetchOnePermissionTemplatePayload & EditPermissionTemplatePayload;

export type PermissionTemplate = {
  id: number;
  name: string;
  role: UserRole;
  isDefault: boolean;
  permissionList: string[];
};

export type PermissionTemplateList = PermissionTemplate[];

export type State = {
  fetchAll: {
    data: PermissionTemplateList;
    isLoading: boolean;
    error: any;
  };
  fetchOne: {
    data: PermissionTemplate | null;
    isLoading: boolean;
    error: any;
  };
};

export type FetchAllResolvedPayload = PermissionTemplateList;
export type FetchOneResolvedPayload = PermissionTemplate;

// const for pages

// -- List of permission to display in toggle
// used in permission_template edit & user edit
// all roles need be defined here (compile error otherwise),
// this is on purpose to prevent undefined error on page rendering
export const PermissionToggleList: {
  [key in UserRole]: Array<{
    label: string; // section label
    permissions: Array<{ // permissions array
      key: string; // permission key
      label: string; // permission label
    }>;
  }>
} = {
  [UserRole.RegularUser]: [],
  [UserRole.Admin]: [
    {
      label: 'Users',
      permissions: [
        { key: 'editUser', label: 'Can Edit User' },
      ],
    },
  ],
  [UserRole.SuperAdmin]: [],
};
