import { createStore, applyMiddleware, compose, Store } from 'redux';
import { persistStore, Persistor } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { AxiosResponse } from 'axios';
import { RootStateOrAny } from 'react-redux';

import { persistReducer } from 'utils/redux';

import { State } from 'core/types';
import rootReducer from 'core/reducer';
import rootSagas from 'core/sagas';
import { rootPersistConfig } from 'core/persist';
import appActions from 'core/app/actions';
import { setResponseHandler } from 'services/lionstudios-api';


const persistedReducer = persistReducer<RootStateOrAny & State>(rootPersistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  sagaMiddleware,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore (): { store: Store<State>; persistor: Persistor } {
  const store: Store<State> = createStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
  const persistor: Persistor = persistStore(store);

  // NOTE: uncomment the line below to reset the app's cache
  // persistor.purge();

  setResponseHandler((response: AxiosResponse) => {
    store.dispatch(appActions.handleHttpError({ response }));
  });

  rootSagas.map(sagaMiddleware.run);

  return {
    store,
    persistor,
  };
}
