import { Channels } from 'core/dashboards/types';


export const getBudgetText = (value: string | null, channel: Channels, isGeoBudget: boolean) => {
  if (value === undefined) {
    return null;
  }

  if (value === null) {
    if ((channel === Channels.FACEBOOK || channel === Channels.TIKTOK)) {
      return 'Ad Set';
    }

    if (channel === Channels.APPLOVIN && isGeoBudget) {
      return 'Geo';
    }

    return '\u2014';
  }

  return value;
};
