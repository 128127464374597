import shallowequal from 'shallowequal';
import update from 'immutability-helper';
import { GridRowModel } from '@mui/x-data-grid-premium';

import { PARENT_ENTITIES, CHILDREN_ENTITIES } from 'core/dashboards/constants';
import { Config, Entities, CurrentFilter } from 'core/dashboards/types';
import { UpdateUserViewPayload, SavedViewMergedWithFields } from 'core/users/types';
import { getDefaultViewConfig } from 'core/users/utils';
import { FilterType } from 'core/settings/types';

import { isArray } from 'utils';

import { getFilterValueExtractor } from 'components/dashboard/grid/utils'; // eslint-disable-line import/no-cycle
import { CUSTOM_FILTERS_FIELDS_MAP } from 'components/dashboard/grid/constants';

import { tabConfig } from './config'; // eslint-disable-line import/no-cycle


export const getTabKeyByIndex = (index: number): Entities => {
  const config = Object.entries(tabConfig)[index];

  const [key] = config;

  return key as Entities;
};

export const getTabIndexByKey = (tabKey: Entities): number => {
  const index = Object.keys(tabConfig).findIndex((key) => key === tabKey);

  return index;
};

export const isParentSelectionChanged = (selected: Record<string, any[]>, previousSelected: Record<string, any[]>, tabKey: string) => {
  const updatedSelection = Object.entries(selected).find(([key, selection]) => {
    if (!shallowequal(selection, previousSelected[key])) {
      return true;
    }
  }) as [string, any[]];

  if (!updatedSelection) {
    return false;
  }

  const [updatedSelectionKey] = updatedSelection;

  if (!PARENT_ENTITIES[tabKey].includes(updatedSelectionKey)) {
    return false;
  }

  return true;
};

export const getUpdatedView = (view: SavedViewMergedWithFields, data: UpdateUserViewPayload['data']): UpdateUserViewPayload['data'] => ({
  ...data,
  value: {
    ...(view.value || {}),
    fields: view.value?.fields?.map(({ key }) => key) || [],
    pinnedColumns: view.value?.pinnedColumns || [],
    config: view.value?.config ? view.value?.config : getDefaultViewConfig(view.entity as Entities),
  },
});

export const getOtherSelection = (key: string, resetOther?: boolean) => {
  if (resetOther) {
    return Object.values(Entities).filter((entity) => entity !== key).reduce((result, entity) => {
      result[entity] = { $set: [] };
      return result;
    }, {} as any);
  }

  return CHILDREN_ENTITIES[key].reduce((result, childrenKey) => {
    result[childrenKey] = { $set: [] };
    return result;
  }, {} as any);
};

export const addFilterToCurrentConfig = (filterId: string, filterValues: string[] | null, config: Config): Config => {
  if (filterValues === null) {
    return config;
  }

  const filter = {
    id: filterId,
    type: FilterType.STRING,
    values: filterValues.map((value) => ({
      id: value,
      name: value,
      include: true,
    })),
  };

  let nextConfig;
  const filterIndex = config.filter.findIndex(({ id }) => id === filterId);
  if (filterIndex === -1) {
    nextConfig = update(config, {
      filter: { $push: [filter] },
    });
  } else {
    nextConfig = update(config, {
      filter: {
        [filterIndex]: { $set: filter },
      },
    });
  }

  return nextConfig;
};


export const mergeFilters = (value: string[] | null, filterId: string, include: boolean, filters: CurrentFilter[], availableFilters: CurrentFilter[], row: GridRowModel): CurrentFilter[] => {
  let filterIndex = filters.findIndex(({ id }) => id === filterId);
  let targetFilter;
  if (filterIndex === -1) {
    filterIndex = filters.length;
    targetFilter = availableFilters.find(({ id }) => id === filterId);
  } else {
    targetFilter = filters[filterIndex];
  }

  if (targetFilter === undefined) {
    return filters;
  }

  const nextFilters = [...filters];
  const shouldReset = !value;
  const customField = Object.entries(CUSTOM_FILTERS_FIELDS_MAP).find(([customKey, fieldKey]) => fieldKey === filterId);
  const rowValue = row[filterId] || (customField && row[customField[0]]);

  if (shouldReset) {
    nextFilters[filterIndex] = {
      ...targetFilter,
      values: targetFilter.values.filter(({ id }) => {
        if (isArray(rowValue)) {
          return !rowValue.includes(id);
        }

        const [extractedRowValue] = getFilterValueExtractor([rowValue], filterId, row);

        return id !== extractedRowValue.id;
      }),
    };

    return nextFilters;
  }

  const values = getFilterValueExtractor(value as string[], filterId, row);

  const filter = {
    id: filterId,
    type: FilterType.STRING,
    values: values.map((filterValue) => ({
      ...filterValue,
      include,
    })),
  };

  if (filterIndex === -1) {
    return filters;
  } else {
    const nextFiltersValues = targetFilter.values.filter(({ id }) => !filter.values.find(({ id: filterValueId }) => filterValueId === id));

    nextFilters[filterIndex] = {
      ...targetFilter,
      values: [
        ...nextFiltersValues,
        ...filter.values as CurrentFilter['values'],
      ],
    };
  }

  return nextFilters;
};
