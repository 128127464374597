import { styled } from '@mui/material/styles';

import { tabsClasses } from '@mui/material/Tabs';

import { StyledTabProps, StyledTabsProps } from './types';


export const StyledTab = styled('button')<StyledTabProps>(({ theme, $isActive, $backgroundColor }) => ({
  maxWidth: '100%',
  minHeight: 0,
  flex: 1,
  textTransform: 'none',
  position: 'relative',
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1.75, 1.5),
  height: 48,

  ...theme.typography.subtitle1,

  color: theme.palette.common.black,
  backgroundColor: $isActive ? $backgroundColor : theme.palette.grey[50],

  '&:hover': {
    ...($isActive ? { backgroundColor: $backgroundColor } : {}),
  },

  '& > svg': {
    marginBottom: 0,
    lineHeight: 1,
    width: 24,
    height: 24,
    fontSize: 24,
    marginRight: theme.spacing(0.75),
    transition: theme.transitions.create('fill'),
  },
}));

export const StyledTabs = styled('div')<StyledTabsProps>(({ theme, $color }) => ({
  [`.${tabsClasses.indicator}`]: {
    backgroundColor: $color,
    height: 3,
  },

  '.Mui-disabled': {
    cursor: 'not-allowed',
  },

  [StyledTab]: {
    marginRight: theme.spacing(1),

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export const StyledPaper = styled('div')({
  boxShadow: 'none',
});

export const SelectedIndicatorStyles = {
  position: 'absolute',
  right: 10,
  top: '50%',
  transform: 'translateY(-50%)',
  fontSize: 13,
  minHeight: 24,
  minWidth: 24,
};
