import { styled } from '@mui/material/styles';

import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import { StyledLinkProps } from './types';


export const HEADER_MIN_HEIGHT = 49;//60;


export const Wrapper = styled('div')(({ theme }) => ({
  // backgroundColor: theme.palette.common.black,
  backgroundColor: '#0b0c0f',
  // padding: theme.spacing(1.375, 5),
  padding: theme.spacing('0', '15px'),
  minHeight: HEADER_MIN_HEIGHT,
  position: 'relative',
  zIndex: 6,
}));

export const StyledLink = styled('div')<StyledLinkProps>(({ theme, $isActive }) => ({
  marginLeft: 26,
  textDecoration: 'none',
  borderBottom: '2px solid transparent',
  transition: theme.transitions.create('border'),
  cursor: 'pointer',
  color: theme.palette.text.secondary,
  // ...theme.typography.h1,
  ...theme.typography.subtitle1,

  ...($isActive ? {
    borderBottomColor: theme.palette.common.white,
  } : {}),

  '&.active, &:hover': {
    borderBottomColor: theme.palette.common.white,
  },

  '&:first-child': {
    marginLeft: 0,
  },
}));


export const LogoutLink = styled(StyledLink)<StyledLinkProps>(({ theme, $isActive }) => ({
  color: theme.palette.grey[500],

  '&.active, &:hover': {
    color: theme.palette.common.white,
    borderBottomColor: theme.palette.common.white,
  },
}));

export const SearchWrapper = styled('div')(({ theme }) => ({
  minWidth: 250,
  position: 'relative',
  zIndex: 1,
  marginRight: theme.spacing(5),

  [`.${inputBaseClasses.formControl}`]: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: theme.shape.borderRadius as number / 3,
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.body1.fontSize,

    [`.${outlinedInputClasses.notchedOutline}`]: {
      border: 0,
    },
  },
}));

export const IssueButton = styled('div')(({ theme }) => ({
  marginRight: 26,
  backgroundColor: theme.palette.error.light,
  height: 'initial',
  width: 'initial',
  padding: '6px 16px',

  '&:hover': {
    backgroundColor: theme.palette.error.main,
  },
}));
