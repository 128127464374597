import { createInstance } from '@jonkoops/matomo-tracker-react';
import MatomoTracker, { types } from '@jonkoops/matomo-tracker';

import config from 'config';


class AnalyticsService {
  instance: MatomoTracker;

  userId: string | number | null;

  queue: (() => void)[];

  constructor () {
    this.instance = createInstance({
      urlBase: config.ANALYTICS_URL,
      siteId: Number(config.ANALYTICS_SITE_ID),
      disabled: config.DISABLE_ANALYTICS === 'true',
    });

    this.userId = null;

    this.queue = [];
  }

  setUserId (userId: string | number) {
    this.instance.pushInstruction('setUserId', userId);
    this.userId = userId;

    this.invokeQueue();
  }

  // TODO: add more methods
  trackPageView (params?: types.TrackPageViewParams, shouldWaitForUserId = true) {
    const track = this.instance.trackPageView.bind(this.instance, params);
    if (this.userId === null && shouldWaitForUserId) {
      this.queue.push(track);
    } else {
      track();
    }
  }

  trackEvent (params: types.TrackEventParams, shouldWaitForUserId = true) {
    const track = this.instance.trackEvent.bind(this.instance, params);
    if (this.userId === null && shouldWaitForUserId) {
      this.queue.push(track);
    } else {
      track();
    }
  }

  invokeQueue () {
    this.queue.forEach((track) => track());
  }
}


export default new AnalyticsService();
