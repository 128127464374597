import { put, call, takeEvery, ActionPattern } from 'redux-saga/effects';

import SnackbarService from 'services/snackbar';

import { isFunction } from 'utils';
import { resolvedAction, rejectedAction, isFetchingAction } from 'utils/redux';
import { Action } from 'types/redux';

import { ENV } from 'constants/index';


import { FETCHING_SLICES_COLLECTION } from './index';


function* terminateFetchingAction (action: Action) {
  const { type, payload } = action;

  try {
    const config = FETCHING_SLICES_COLLECTION[type];

    if (!config) {
      throw new Error(`Fetching slice config not found for action type: ${type}`);
    }

    const { serviceMethod, models, sagas, getSuccessMessage } = config;

    if (!serviceMethod || !isFunction(serviceMethod) ) {
      throw new Error(`Service method not found for fetching action type: ${type}`);
    }

    const [beforeRequestSaga, afterRequestSaga] = sagas || [];

    if (beforeRequestSaga) {
      yield call(beforeRequestSaga, action, config);
    }

    const [responseModel, requestModel] = models || [];

    const requestPayload = requestModel && isFunction(requestModel) ? requestModel(payload) : payload;

    const data: Awaited<ReturnType<typeof serviceMethod>> = yield call(serviceMethod, requestPayload);

    const responsePayload = responseModel && isFunction(responseModel) ? responseModel(data) : data;


    if (afterRequestSaga) {
      yield call(afterRequestSaga, action, config, responsePayload);
    }

    yield put(resolvedAction<typeof type>(type, responsePayload));

    if (getSuccessMessage) {
      SnackbarService.showSuccess(getSuccessMessage(payload, responsePayload));
    }
  } catch (error) {
    const { message } = error as Error;

    SnackbarService.showError(message);

    yield put(rejectedAction<typeof type>(type, { message }));

    if (process.env.REACT_APP_CONFIG_ENV === ENV.DEV) {
      console.warn(error);
    }
  }
}

function* watcher () {
  yield takeEvery(((action: Action): boolean => isFetchingAction(action)) as ActionPattern<Action>, terminateFetchingAction);
}

export default [
  watcher,
];
