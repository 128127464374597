import React, { useCallback } from 'react';

// import { ReactComponent as LogoImage } from 'assets/images/logo.svg';
import { ReactComponent as LogoImage } from 'assets/images/logo-lm.svg';

import { LogoElement } from './styles';


const Logo: React.FunctionComponent = () => {
  const handleClick = useCallback(() => {}, []);

  return (
    <LogoElement
      onClick={handleClick}
    >
      <LogoImage />
    </LogoElement>
  );
};


Logo.displayName = 'Logo';


export default Logo;
