import { OtherFilter } from 'core/settings/types';


export const groupFiltersByCategory = (list: OtherFilter[]) => Object.values(list.reduce((result: any, filter) => {
  if (!result[filter.categoryName]) {
    result[filter.categoryName] = {
      category: filter.category,
      categoryName: filter.categoryName,
      filters: [],
    };
  }

  result[filter.categoryName].filters.push(filter);
  return result;
}, {}));

export const getSelected = (list: OtherFilter[]) => list.filter(({ selected }) => selected).map((filter) => String(filter.id));
