import { memo, FunctionComponent } from 'react';

import { Props } from './types';

import { StyledTooltip } from './styles';


const NotReadyTooltip: FunctionComponent<Props> = memo(({ children, ...otherProps }) => (
  <StyledTooltip
    title="Change is not permitted"
    placement="top"
    arrow
    {...otherProps}
  >
    {children}
  </StyledTooltip>
));


NotReadyTooltip.displayName = 'NotReadyTooltip';


export default NotReadyTooltip;
