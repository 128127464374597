import { Entities } from 'core/dashboards/types';
import { SavedViewMergedWithFields } from 'core/users/types';
import { getDefaultViewConfig, getFakeView } from 'core/users/utils';


export const getActiveView = (activeView: SavedViewMergedWithFields | null, views: SavedViewMergedWithFields[] | null, type: Entities): SavedViewMergedWithFields => {
  if (activeView) {
    return activeView;
  }

  const defaultView = views?.find(({ isRemovable }) => !isRemovable) || null;

  if (!defaultView) {
    const fakeView = getFakeView(null, {
      name: '',
      isRemovable: true,
      entity: type,
      isCustom: false,
    });

    // TODO: is it correct ?
    return {
      ...fakeView,
      value: {
        fields: [],
        pinnedColumns: [],
        config: getDefaultViewConfig(type),
        heatmap: [],
      },
    };
  }

  return defaultView;
};
