import { Components, alpha } from '@mui/material/styles';


const overrides: Components['MuiBackdrop'] = {
  styleOverrides: {
    root: {
      backgroundColor: alpha('#2D2D2D', 0.3),
      zIndex: 20,
    },
    invisible: {
      backgroundColor: 'transparent',
    },
  },
};


export default overrides;
