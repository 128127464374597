import { State } from 'core/types';
import { KEY } from './constants';


const getLocalState = (state: State) => state[KEY];

export const getPermissionTemplates = (state: State) => getLocalState(state).fetchAll.data;
export const getPermissionTemplatesIsLoading = (state: State) => getLocalState(state).fetchAll.isLoading;
export const getPermissionTemplatesError = (state: State) => getLocalState(state).fetchAll.error;

export const getPermissionTemplate = (state: State) => getLocalState(state).fetchOne.data;
export const getPermissionTemplateIsLoading = (state: State) => getLocalState(state).fetchOne.isLoading;
export const getPermissionTemplateError = (state: State) => getLocalState(state).fetchOne.error;
