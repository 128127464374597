import { styled } from '@mui/material/styles';

import { DefaultIconProps, WrapperProps } from './types';


export const ActionsWrapper = styled('div')(({ theme }) => ({
  width: 'auto',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  background: 'linear-gradient(270deg, #FFFFFF 80%, rgba(255, 255, 255, 0) 100%)',
  transition: theme.transitions.create('opacity'),
  paddingLeft: theme.spacing(1),
  opacity: 0,
}));


export const Wrapper = styled('div')<WrapperProps>(({ theme, $isDisabled }) => ({
  width: '100%',
  margin: theme.spacing(-1, 0),
  padding: theme.spacing(1, 0),
  height: '44px',
  opacity: $isDisabled ? 0.5 : 1,

  [`&:hover ${ActionsWrapper}`]: {
    opacity: $isDisabled ? 0 : 1,
  },
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const DefaultIconWrapper = styled('div')<DefaultIconProps>(({ theme, $isActive, $isDisabled }) => {
  const activeStyles = {
    color: theme.palette.primary.main,
  };

  return {
    opacity: $isDisabled ? 0 : 1,

    '& svg': {
      fontSize: '20px',
      color: '#D9D9D9',
      ...($isActive ? activeStyles : {}),
    },
    
    '&:hover': {
      '& svg': activeStyles,
    },
  };
});


export const ActionIconWrapper = styled('div')(({ theme }) => ({
  '& svg': {
    color: theme.palette.grey[400],
  },

  '&:hover': {
    '& svg': {
      color: theme.palette.grey[500],
    },
  },
}));

export const DeleteIconWrapper = styled(ActionIconWrapper)(({ theme }) => ({
  
}));

export const EditIconWrapper = styled(ActionIconWrapper)(({ theme }) => ({
  '& svg': {
    fontSize: '18px',
  },
}));
