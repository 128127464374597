import { Components, Theme } from '@mui/material/styles';


const overrides: Components['MuiPopover'] = {
  styleOverrides: {
    paper: (props) => {
      const localTheme = props.theme as Theme;

      return {
        padding: localTheme.spacing(2),
        ...localTheme.typography.body1,
      };
    },
  },
};


export default overrides;
