import appSagas from './app/sagas';
import authSagas from './auth/sagas';
import fetchingSagas from './fetching/sagas';
import settingsSagas from './settings/sagas';
import dashboardsSagas from './dashboards/sagas';
import usersSagas from './users/sagas';
import adminUsersSagas from './admin/users/sagas';
import adminPermissionTemplatesSagas from './admin/permission-templates/sagas';
import adminActivityLogsSagas from './admin/activity-logs/sagas';
import adminAppSettingsSagas from './admin/appsettings/sagas';


const sagas = [
  ...appSagas,
  ...authSagas,
  ...fetchingSagas,
  ...settingsSagas,
  ...dashboardsSagas,
  ...usersSagas,
  ...adminUsersSagas,
  ...adminPermissionTemplatesSagas,
  ...adminActivityLogsSagas,
  ...adminAppSettingsSagas,
];


export default sagas;
