import { memo } from 'react';

import {
  useGridApiContext,
  GridPanelFooter,
  useGridRootProps,
} from '@mui/x-data-grid-premium';
import LoadingButton from '@mui/lab/LoadingButton';

import { StyledButton } from './styles';
import { GridFilterFooterProps } from './types';


const GridFilterFooter: React.FunctionComponent<GridFilterFooterProps> = memo((({ onAddNewFilter, onReset, onCancel, onSubmit, isSubmitLoading }) => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  return (
    <GridPanelFooter>
      <div>
        {
          !rootProps.disableMultipleColumnsFiltering && (
            <>
              <StyledButton
                as={rootProps.components.BaseButton}
                onClick={onAddNewFilter}
                variant="outlined"
                color="primary"
                {...rootProps.componentsProps?.baseButton}
              >
                + {apiRef.current.getLocaleText('filterPanelAddFilter')}
              </StyledButton>

              <StyledButton
                as={rootProps.components.BaseButton}
                onClick={onReset}
                variant="outlined"
                color="secondary"
                {...rootProps.componentsProps?.baseButton}
              >
                Reset All
              </StyledButton>
            </>
          )
        }
      </div>

      <div>
        <StyledButton
          as={rootProps.components.BaseButton}
          onClick={onCancel}
          variant="outlined"
          color="secondary"
          {...rootProps.componentsProps?.baseButton}
        >
          Cancel
        </StyledButton>

        <StyledButton
          as={LoadingButton}
          onClick={onSubmit}
          variant="contained"
          loading={isSubmitLoading}
          {...rootProps.componentsProps?.baseButton}
        >
          Apply
        </StyledButton>
      </div>
    </GridPanelFooter>
  );
}));


GridFilterFooter.displayName = 'GridFilterFooter';


export default GridFilterFooter;
