import { TYPES } from 'hooks/use-query-params/constants';

import { QUERY_CONFIG_PARAM_NAME, DEBUG_PARAM_NAME, BACK_END_KEYS } from 'core/dashboards/constants';
import { Entities } from 'core/dashboards/types';


export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_PAGE = 0;

export const QUERY_PARAMS = {
  TAB: 't',
  QUERY_CONFIG: QUERY_CONFIG_PARAM_NAME,
  DEBUG: DEBUG_PARAM_NAME,
};

export const QUERY_PARAMS_WITH_TYPES = {
  [QUERY_PARAMS.TAB]: TYPES.NUMBER,
  [QUERY_PARAMS.QUERY_CONFIG]: TYPES.STRING,
  [QUERY_PARAMS.DEBUG]: TYPES.NUMBER,
};

export const ENTITIES_BY_COUNT_ROW_KEYS = {
  [BACK_END_KEYS.ADS]: Entities.Ads,
  [BACK_END_KEYS.CAMPAIGNS]: Entities.Campaigns,
  [BACK_END_KEYS.CHANNELS]: Entities.Offers,
};

export const ALLOWED_COUNT_ROW_TABS = [Entities.Creatives];

export const FILTER_ID_BY_COUNT_ROW_NEXT_TAB = {
  [Entities.Ads]: 'ad.ID',
  [Entities.Campaigns]: 'campaign.ID',
  [Entities.Offers]: 'campaign.CHANNEL',
};
