import update from 'immutability-helper';
import { combineActions, handleActions, persistReducer, rejected, resolved } from 'utils/redux';
import { Action } from 'types/redux';
import { Types as AuthTypes } from 'core/auth/types';
import { createDefaultPaginatedResponse } from 'core/admin/types';
import { FetchAllResolvedPayload, FetchOneResolvedPayload, Payload, RejectedPayload, State, Types } from './types';
import persistConfig from './persist';


const defaultState: State = {
  fetchAll: {
    data: createDefaultPaginatedResponse(),
    isLoading: false,
    error: null,
  },
  fetchOne: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const reducer = handleActions<State, Types, Action<Types, Payload>>({
  [Types.FETCH_ALL]: (state: State): State => (
    update(state, {
      fetchAll: {
        isLoading: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved<Types>(Types.FETCH_ALL)]: (state: State, { payload }: Action<Types, FetchAllResolvedPayload>): State => (
    update(state, {
      fetchAll: {
        isLoading: { $set: false },
        data: { $set: payload },
      },
    })
  ),
  [rejected<Types>(Types.FETCH_ALL)]: (state: State, { payload: { error } }: Action<Types, RejectedPayload>): State => (
    update(state, {
      fetchAll: {
        isLoading: { $set: false },
        error: { $set: error },
      },
    })
  ),

  [Types.FETCH_ONE]: (state: State): State => (
    update(state, {
      fetchOne: {
        isLoading: { $set: true },
        error: { $set: null },
      },
    })
  ),
  [resolved<Types>(Types.FETCH_ONE)]: (state: State, { payload }: Action<Types, FetchOneResolvedPayload>): State => (
    update(state, {
      fetchOne: {
        isLoading: { $set: false },
        data: { $set: payload },
      },
    })
  ),
  [rejected<Types>(Types.FETCH_ONE)]: (state: State, { payload: { error } }: Action<Types, RejectedPayload>): State => (
    update(state, {
      fetchOne: {
        isLoading: { $set: false },
        error: { $set: error },
      },
    })
  ),
  [Types.FETCH_ONE_RESET]: (state: State): State => (
    update(state, {
      fetchOne: {
        isLoading: { $set: false },
        data: { $set: null },
        error: { $set: null },
      },
    })
  ),

  [combineActions<AuthTypes>(
    rejected<AuthTypes>(AuthTypes.LOAD_AUTH),
    rejected<AuthTypes>(AuthTypes.SIGN_IN),
    AuthTypes.LOGOUT,
  )]: (): State => defaultState,
}, defaultState);

export default persistReducer<State>(persistConfig, reducer as any);
