import { memo, FunctionComponent } from 'react';

import { ReactComponent as AdjustIcon } from 'assets/icons/adjust-icon.svg';

import Link from 'components/common/link';

import { Props } from './types';
import { Wrapper } from './styles';


const DashboardAdjustLink: FunctionComponent<Props> = memo(({ value, formattedValue }) => (
  <>
    {value ? (
      <Wrapper
        as={Link}
        to={formattedValue || ''}
        isExternal
      >
        <AdjustIcon />
      </Wrapper>
    ) : formattedValue}
  </>
));


DashboardAdjustLink.displayName = 'DashboardAdjustLink';


export default DashboardAdjustLink;
