
import update, { Spec } from 'immutability-helper';
import { createSelector, Selector } from 'reselect';
import toConstantCase from 'to-constant-case';

import { State } from 'core/types';

import { createAction, fetching, resolved, rejected } from 'utils/redux';
import {
  ReducerMap,
  FetchingActionCreator,
  Payload as BasePayload,
  Meta as BaseMeta,
  DefaultMeta,
  FetchingAction,
  RejectedPayload as BaseRejectedPayload,
  ResolvedAction,
  RejectedAction,
  AsyncActions,
  Action,
} from 'types/redux';

import { DEFAULT_META } from './constants';
import { FetchingSliceConfig, FetchingSliceState, BaseLocalState, FetchingSlice } from './types';

// TODO: fix prefix name for enum type
// TODO: add sagas
// TODO: add persist
// TODO: add default payload
// TODO: add custom values to initial reducer (like data = [] not null)
// TODO: types are lost with selectors
// TODO: selector default value
export const FETCHING_SLICES_COLLECTION: Record<string, FetchingSliceConfig> = {};

export function createFetchingReducer <
  LocalState extends BaseLocalState,
  Type extends string,
  Payload extends BasePayload = BasePayload,
  ResolvedPayload extends BasePayload = BasePayload,
  RejectedPayload extends BaseRejectedPayload = BaseRejectedPayload,
  Meta extends BaseMeta = BaseMeta,
  ResolvedMeta extends BaseMeta = BaseMeta,
  RejectedMeta extends BaseMeta = BaseMeta,
> (type: Type, sliceKey: string, savePayload: boolean, extendReducer: FetchingSliceConfig['extendReducer'] = [null, null, null]): {
  defaultState: BaseLocalState;
  reducer: ReducerMap<LocalState, string, AsyncActions<Type, Payload, ResolvedPayload, RejectedPayload, Meta, ResolvedMeta, RejectedMeta>>;
} {
  const defaultState: FetchingSliceState<ResolvedPayload | null, Payload | null> = {
    data: null,
    isFetching: false,
    error: null,
  };

  const [extendedReducer, resolvedExtendedReducer, rejectedExtendedReducer] = extendReducer;

  const reducer =  {
    [type]: (state: LocalState, action: Action<Type, Payload, Meta>) => {
      const { payload, meta } = action;
      const nextMeta: DefaultMeta = meta || DEFAULT_META;

      return update(state, {
        [sliceKey]: {
          ...(nextMeta.useFetching ? {
            isFetching: { $set: true },
          } : {}),
          ...(savePayload ? {
            payload: { $set: payload },
          } : {}),
        },
        ...(extendedReducer ? extendedReducer(state, action as any) : {}), // TODO: fix types
      } as Spec<LocalState, any>);
    },
    [resolved(type)]: (state: LocalState, action: ResolvedAction<Type, ResolvedPayload, ResolvedMeta>) => {
      const { payload } = action;

      return update(state, {
        [sliceKey]: {
          data: { $set: payload },
          isFetching: { $set: false },
          message: { $set: null },
          ...(savePayload ? {
            payload: { $set: undefined },
          } : {}),
        },
        ...(resolvedExtendedReducer ? resolvedExtendedReducer(state, action as any) : {}), // TODO: fix types
      } as Spec<LocalState, any>);
    },
    [rejected(type)]: (state: LocalState, action: RejectedAction<Type, RejectedPayload, RejectedMeta>) => {
      const { payload: { message } } = action;

      return update(state, {
        [sliceKey]: {
          message: { $set: message },
          isFetching: { $set: false },
          ...(savePayload ? {
            payload: { $set: undefined },
          } : {}),
        },
        ...(rejectedExtendedReducer ? rejectedExtendedReducer(state, action as any) : {}), // TODO: fix types
      } as Spec<LocalState, any>);
    },
  };

  return {
    reducer,
    defaultState: {
      [sliceKey]: defaultState,
    },
  };
}

export function createFetchingAction<Type extends string, Payload, Meta> (type: Type): FetchingActionCreator<Type, Payload, Meta> {
  return (payload?: Payload, meta?: Meta) => createAction<Type, Payload, Meta>(
    type,
    payload,
    {
      ...(meta || DEFAULT_META) as Meta,
    },
  ) as FetchingAction<Type, Payload, Meta>;
}

export const getIsFetching = <LocalState extends BaseLocalState>(storeKey: keyof State, sliceKey: keyof LocalState) => createSelector<[Selector<State, boolean>], boolean>(
  (baseState: State) => {
    const state = baseState[storeKey] as unknown as LocalState;
    const localState = state[sliceKey];

    return localState.isFetching;
  },
  (isFetching) => isFetching,
);

export const getData = <LocalState extends BaseLocalState, ResolvedPayload>(storeKey: keyof State, sliceKey: keyof LocalState) => createSelector<[Selector<State, ResolvedPayload>], ResolvedPayload>(
  (baseState: State) => {
    const state = baseState[storeKey] as unknown as LocalState;
    const localState = state[sliceKey];

    return localState.data;
  },
  (data) => data,
);

export const getPayload = <LocalState extends BaseLocalState, Payload>(storeKey: keyof State, sliceKey: keyof LocalState) => createSelector<[Selector<State, Payload>], Payload>(
  (baseState: State) => {
    const state = baseState[storeKey] as unknown as LocalState;
    const localState = state[sliceKey];

    return localState.payload;
  },
  (payload) => payload,
);

export function getSelectors <LocalState extends BaseLocalState, Payload = null, ResolvedPayload = null> (storeKey: keyof State, sliceKey: keyof LocalState) {
  return {
    getData: getData<LocalState, ResolvedPayload>(storeKey, sliceKey),
    getIsFetching: getIsFetching<LocalState>(storeKey, sliceKey),
    getPayload: getPayload<LocalState, Payload>(storeKey, sliceKey),
  };
}

export function createFetchingSlice<
  SliceKeys extends string,
  Payload extends BasePayload = BasePayload,
  ResolvedPayload extends BasePayload = BasePayload,
  RejectedPayload extends BaseRejectedPayload = BaseRejectedPayload,
  Meta extends BaseMeta = BaseMeta,
  ResolvedMeta extends BaseMeta = BaseMeta,
  RejectedMeta extends BaseMeta = BaseMeta,
> (config: FetchingSliceConfig<Payload, ResolvedPayload, RejectedPayload, Meta, ResolvedMeta, RejectedMeta>) {
  const { storeKey, sliceKey, savePayload, extendReducer } = config;
  const type = fetching(`${toConstantCase(storeKey)}/${toConstantCase(sliceKey)}`);

  type Type = typeof type;
  type LocalState = State[typeof storeKey] extends BaseLocalState ? State[typeof storeKey][SliceKeys] : any; // TODO: fix any type

  FETCHING_SLICES_COLLECTION[type] = config as unknown as FetchingSliceConfig;

  const slice: FetchingSlice<LocalState, Type, Payload, ResolvedPayload, RejectedPayload, Meta, ResolvedMeta, RejectedMeta> = {
    type,
    resolvedType: resolved(type),
    rejectedType: rejected(type),
    action: createFetchingAction<Type, Payload, Meta>(type),
    selectors: getSelectors<LocalState, Payload, ResolvedPayload>(storeKey, sliceKey) as any, // TODO: fix any type
    ...createFetchingReducer<LocalState, Type, Payload, ResolvedPayload, RejectedPayload, Meta, ResolvedMeta, RejectedMeta>(type, sliceKey, Boolean(savePayload), extendReducer as FetchingSliceConfig['extendReducer']),
  };

  return slice;
}
