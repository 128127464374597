import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import update from 'immutability-helper';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { MenuItem } from '@mui/material';

// import authActions from 'core/auth/actions';
import dashboardActions from 'core/dashboards/actions';
import { getCurrentConfig } from 'core/dashboards/selectors';
import { PUBLISH_MODES, QUERY_CONFIG_PARAM_NAME } from 'core/dashboards/constants';
import { getAuthorizedUserRole } from 'core/users/selectors';
import { UserRole } from 'core/users/types';

import Logo from 'components/common/logo';
import Link from 'components/common/link';
import EnvBadge from 'components/common/env-badge';
import DataStatus from 'components/common/data-status';
import { ROUTES } from 'containers/router/constants';

import { removeQueryParamFromHistory } from 'utils/history';

import { ISSUE_FORM_URL } from './constants';
import { Wrapper, StyledLink, LogoutLink, IssueButton } from './styles';

import { Props } from './types';
import UserMenu from '../user-menu';
import HeaderDropdown from '../header-dropdown';


const Header: React.FunctionComponent<Props> = memo(({ isAuthorized, isAuthorizedUserAdmin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentConfig = useSelector(getCurrentConfig);
  const authRole = useSelector(getAuthorizedUserRole);

  /*
  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);
  */

  const handlePublishModeChange = useCallback((nextPublishMode) => {
    if (location.pathname !== ROUTES.DASHBOARD) {
      navigate(ROUTES.DASHBOARD);
    }
    const nextConfig = update(currentConfig, {
      publishMode: { $set: nextPublishMode },
      filter: { $set: [] },
    });

    dispatch(dashboardActions.setCurrentConfig({ config: nextConfig }));
  }, [currentConfig, dispatch, navigate, location]);

  const handleResetConfig = useCallback(() => {
    if (location.pathname !== ROUTES.DASHBOARD) {
      navigate(ROUTES.DASHBOARD);
    }
    removeQueryParamFromHistory(QUERY_CONFIG_PARAM_NAME);
    dispatch(dashboardActions.resetConfig());
  }, [dispatch, navigate, location]);

  return (
    <Wrapper
      as={Stack}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div
          onClick={handleResetConfig}
        >
          <Logo />
        </div>

        {
          isAuthorized && <DataStatus />
        }

        {
          isAuthorized && PUBLISH_MODES.map(({ id, label }) => (
            <StyledLink
              key={id}
              onClick={() => handlePublishModeChange(id)}
              $isActive={id === currentConfig.publishMode && location.pathname == ROUTES.DASHBOARD}
            >
              {label}
            </StyledLink>
          ))
        }

        {
          isAuthorized && (
            <>
              <StyledLink
                as={Link}
                to="https://bidder.lionstudios.cc/bids"
                isExternal
              >
                  Bids
              </StyledLink>

              <IssueButton
                as={Button as any} // NOTE: seems like Button type doesn't have "target" prop
                variant="contained"
                href={ISSUE_FORM_URL}
                target="_blank"
                sx={{ marginLeft: '26px', marginRight: '0' }}
              >
                Report an issue
              </IssueButton>
            </>
          )
        }

        {/*
        <EnvBadge />
        */}

      </Stack>

      {
        isAuthorized && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/*
            <IssueButton
              as={Button as any} // NOTE: seems like Button type doesn't have "target" prop
              variant="contained"
              href={ISSUE_FORM_URL}
              target="_blank"
            >
              Report an issue
            </IssueButton>
            */}

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {/* {
                isAuthorizedUserAdmin && (
                  <StyledLink
                    to={`/${ROUTES.SETTINGS}`}
                    as={NavLink}
                  >
                    Settings
                  </StyledLink>
                )
              } */}

              {/*
              <StyledLink
                as={Link}
                to="https://bidder.lionstudios.cc/bids"
                isExternal
              >
                Bids
              </StyledLink>

              <LogoutLink
                onClick={handleLogout}
              >
                Sign Out
              </LogoutLink>
              */}

              {
                (authRole === UserRole.Admin || authRole === UserRole.SuperAdmin) && (
                  <HeaderDropdown
                    buttonLabel='Users'
                  >
                    <MenuItem>
                      <StyledLink
                        to={ROUTES.USERS_INDEX}
                        as={NavLink}
                        sx={{ width: '100%' }}
                      >
                        Users
                      </StyledLink>
                    </MenuItem>
                    <MenuItem>
                      <StyledLink
                        to={ROUTES.ACTIVITIES_INDEX}
                        as={NavLink}
                        sx={{ width: '100%' }}
                      >
                        User Activity
                      </StyledLink>
                    </MenuItem>
                    {
                      (authRole === UserRole.SuperAdmin) && (
                        <MenuItem>
                          <StyledLink
                            to={ROUTES.PERMISSIONS_INDEX}
                            as={NavLink}
                            sx={{ width: '100%' }}
                          >
                            Permissions
                          </StyledLink>
                        </MenuItem>
                      )
                    }
                  </HeaderDropdown>
                )
              }
              {
                (authRole === UserRole.SuperAdmin) && (
                  <StyledLink
                    to={ROUTES.APPSETTINGS_INDEX}
                    as={NavLink}
                  >
                    App Settings
                  </StyledLink>
                )
              }
              <UserMenu/>
            </Stack>
          </Stack>
        )
      }
    </Wrapper>
  );
});


Header.displayName = 'Header';


export default Header;
