import { SavedFilter } from 'core/users/types';

import { SaveFilterFormValues, Fields } from './types';
import { DEFAULT_VALUES } from './constants';


export const getDefaultValues = (filter: SavedFilter | null | undefined): SaveFilterFormValues => {
  if (!filter) {
    return DEFAULT_VALUES;
  }

  return {
    [Fields.Name]: filter[Fields.Name],
    [Fields.Description]: filter[Fields.Description],
  };
};
