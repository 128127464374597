import { all, put, call, take, select, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { FetchingAction, Action, DefaultMeta } from 'types/redux';
import { resolved, rejected } from 'utils/redux';
import AnalyticsService from 'services/analytics';
import SnackbarService from 'services/snackbar';
import { Types as AppTypes } from 'core/app/types';
import { FetchingSliceConfig } from 'core/fetching/types';
import { DEFAULT_META } from 'core/fetching/constants';
import { Entities, Types as DashboardTypes, GetConfigFromUrlResolvedPayload } from 'core/dashboards/types';
import { getIsQueryConfigLoaded } from 'core/dashboards/selectors';

import { User, Meta, Payload, CreateUserViewMeta, SavedViewMergedWithFields } from './types';
import { getViewsFromQueryConfig } from './utils';
import usersActions from './actions';
import { // eslint-disable-line import/no-cycle
  authorizedUserSlice,
  fetchSavedUserFiltersSlice,
  fetchSavedUserViewsSlice,
  SliceType,
} from './slices';

/*
 * Sagas
 */

export function* fetchSavedUserFilters (action?: Action<SliceType, Payload, Meta>): SagaIterator<void> {
  try {
    let user: User = yield select(authorizedUserSlice.selectors.getData);

    if (!user) {
      yield take(authorizedUserSlice.resolvedType);
      user = yield select(authorizedUserSlice.selectors.getData);
    }

    const meta = action?.meta;

    const listMeta: DefaultMeta = {
      useFetching: meta ? meta.useListFetching : DEFAULT_META.useFetching,
    };

    yield put(fetchSavedUserFiltersSlice.action({ userId: user?.id }, listMeta));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);
  }
}

export function* fetchSavedUserViews (): SagaIterator<void> {
  try {
    let user: User = yield select(authorizedUserSlice.selectors.getData);

    const isQueryConfigLoaded = yield select(getIsQueryConfigLoaded);

    const requiredEffects = [
      ...(!user ? [take(authorizedUserSlice.resolvedType)] : []),
      ...(!isQueryConfigLoaded ? [take([resolved<DashboardTypes>(DashboardTypes.GET_CONFIG_FROM_QUERY), rejected<DashboardTypes>(DashboardTypes.GET_CONFIG_FROM_QUERY)])] : []),
    ];

    if (!isQueryConfigLoaded) {
      yield put(usersActions.setSavedViewsLoaded()); // NOTE: don't know why but we need it
    }

    if (requiredEffects.length) {
      yield all(requiredEffects);
    }

    if (!user) {
      user = yield select(authorizedUserSlice.selectors.getData);
    }

    yield put(fetchSavedUserViewsSlice.action({ userId: user?.id }));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);
  }
}

export function* removeViewCopyAfterCreate (action: Action<SliceType, Payload, CreateUserViewMeta>): SagaIterator<void> {
  try {
    const { meta } = action;

    if (!meta?.data) {
      return;
    }

    const { data } = meta;

    const payload = {
      view: data as unknown as SavedViewMergedWithFields,
      entity: data.entity as Entities,
    };

    yield put(usersActions.deleteView(payload));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);
  }
}


export function* handleUserViewCreate (action: Action<SliceType, Payload, Meta>): SagaIterator<void> {
  yield all([
    call(fetchSavedUserViews),
    call(removeViewCopyAfterCreate, action),
  ]);
}

export function* handleAuthorizedUserSuccess (action: FetchingAction, config: FetchingSliceConfig, user: User): SagaIterator<void> {
  AnalyticsService.setUserId(user?.email);
}

export function* handleGetConfigFromQuery ({ payload: { data } }: Action<DashboardTypes, GetConfigFromUrlResolvedPayload>): SagaIterator<void> {
  try {
    if (!data) {
      return;
    }

    const views = getViewsFromQueryConfig(data);

    yield put(usersActions.setSharedViews(views));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);
  }
}

function* init () {
  yield all([
    put(authorizedUserSlice.action()),
    call(fetchSavedUserFilters),
    call(fetchSavedUserViews),
  ]);
}

/*
 * Watchers
 */

function* getQueryConfigWatcher () {
  yield takeLatest(resolved<DashboardTypes>(DashboardTypes.GET_CONFIG_FROM_QUERY), handleGetConfigFromQuery);
}

function* initWatcher () {
  yield take(AppTypes.INIT);
  yield call(init);
}


export default [
  initWatcher,
  getQueryConfigWatcher,
];
