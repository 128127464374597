import { COLUMN_WIDTH } from 'components/common/data-grid/constants';
import AdaptiveText from 'components/common/adaptive-text';
import Link from 'components/common/link';
import Platforms from 'components/common/platforms';
import PlatformIcon from 'components/common/platform-icon';
import PlatformContainer from 'components/common/platform-container';
import DashboardSwitch from 'components/dashboard/switch';
import ChannelIcon from 'components/common/channel-icon';
import DashboardDeliveryStatus from 'components/dashboard/delivery-status';
import DashboardBudgetText from 'components/dashboard/budget-text';
import CampaignBudgetText from 'components/dashboard/campaign-budget-text';
import GeoCampaignContainer from 'components/dashboard/geo-campaign-container';
import DashboardAdjustLink from 'components/dashboard/adjust-link';
import DashboardListCountLink from 'components/dashboard/list-count-link';
import OfferName from 'components/common/offer-name';
import RowSummaryTitle from 'components/common/row-summary-title';
import DashboardLinkContainer from 'components/dashboard/link-container';
import { GridColumn } from 'components/common/data-grid/types';
import Country from 'components/common/country';
import { Code } from 'components/common/country/types';
import { EditableCellCallback } from 'components/common/data-grid/components/editable-cell/types';

import { CUSTOM_RELATIVE_POSITION_CELL_CLASS } from 'containers/theme-provider/components-overrides/data-grid';

import { ReactComponent as SaleforceIcon } from 'assets/icons/saleforce-icon.svg';

import { ColumnTypes, ViewColumn } from 'core/settings/types';
import { Channels, DataNode, DeliveryStatus, Entities, Platform, CommonEntities } from 'core/dashboards/types';
import { BACK_END_KEYS } from 'core/dashboards/constants';

import { withPrefix, isObjectEmpty, isValueExists } from 'utils';
import {
  formatNumber,
  formatPrice,
  formatNumberToPercentage,
  formatSafely,
  formatDuration,
} from 'utils/formatters';
import {
  formatBackendTimestampToDateTimeString,
  formatBackendTimestampToDateString,
  formatBackendDayToDayString,
  getStringRangeByWeekNumber,
  getMonthNameByNumber,
} from 'utils/date';

import { DEFAULT_COLUMN_FIELD } from './constants';
import { OnRowClick, DashGridConfig, OnCountRowClick } from './types';
import { getSkuNameExportFormatter, getOfferNameExportFormatter, getCampaignCountryExportFormatter } from './utils'; // eslint-disable-line import/no-cycle


const columnsPropsByType = (
  type: Entities,
  data: DataNode[],
  rounding: number | null,
  onRowClick: OnRowClick<DataNode>,
  onCountRowClick: OnCountRowClick,
  onCellEdit: EditableCellCallback,
  onCellRestart: EditableCellCallback,
): Record<ColumnTypes, Partial<GridColumn<DataNode>>> => ({
  [ColumnTypes.String]: {
    type: 'string',
    valueFormatter: formatSafely(),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Link]: {
    type: 'string',
    renderCell: ({ value, row }) => (
      <AdaptiveText
        onClick={() => onRowClick(row)}
      >
        <Link>{value}</Link>
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Number]: {
    type: 'number',
    valueFormatter: formatSafely(formatNumber, { rounding }),
    renderCell: ({ formattedValue, colDef: { align } }) => (
      <AdaptiveText align={align}>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.NonemptyNumber]: {
    type: 'number',
    valueFormatter: formatSafely(formatNumber, { customEmptySymbol: 'n/a', rounding }),
    renderCell: ({ formattedValue, colDef: { align } }) => (
      <AdaptiveText align={align}>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Price]: {
    type: 'number',
    valueFormatter: formatSafely(formatPrice, { rounding }),
    columnType: ColumnTypes.Price,
    onCellEdit,
    onCellRestart,
    renderCell: ({ formattedValue, colDef: { align } }) => (
      <AdaptiveText align={align}>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Percentage]: {
    type: 'number',
    valueFormatter: formatSafely(formatNumberToPercentage, { rounding }),
    renderCell: ({ formattedValue, colDef: { align } }) => (
      <AdaptiveText align={align}>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Boolean]: {
    type: 'boolean',
    headerAlign: 'center',
    align: 'center',
    columnType: ColumnTypes.Boolean,
    onCellEdit,
    onCellRestart,
    renderCell: ({ value, field, row }) => (
      <DashboardSwitch
        value={value}
        onChange={(nextValue) => onCellEdit(nextValue, field, row)}
        disabled
      />
    ),
  },
  [ColumnTypes.Channel]: {
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) => (
      <ChannelIcon channel={value} />
    ),
  },
  [ColumnTypes.Sku]: {
    type: 'string',
    renderCell: ({ value, row }) => (
      <PlatformContainer
        platform={row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)] as Platform}
      >
        <AdaptiveText>
          {value}
        </AdaptiveText>
      </PlatformContainer>
    ),
  },
  [ColumnTypes.SkuLink]: {
    type: 'string',
    valueFormatter: getSkuNameExportFormatter(data),
    renderCell: ({ value, row }) => (
      <PlatformContainer
        platform={row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)] as Platform}
      >
        <AdaptiveText onClick={() => onRowClick(row)}>
          <Link>
            {value}
          </Link>
        </AdaptiveText>
      </PlatformContainer>
    ),
  },
  [ColumnTypes.Delivery]: {
    type: 'string',
    renderCell: ({ row }) => {
      const originalValue = row[withPrefix(type, BACK_END_KEYS.DELIVERY_STATUS)];
      const originalStatus = row[withPrefix(type, BACK_END_KEYS.STATUS)];
      const campaignValue = row[withPrefix(Entities.Campaigns, BACK_END_KEYS.DELIVERY_STATUS)];
      const campaignStatus = row[withPrefix(Entities.Campaigns, BACK_END_KEYS.STATUS)];
      const value = isValueExists(originalValue) ? originalValue : campaignValue;
      const status = isValueExists(originalStatus) ? originalStatus : campaignStatus;

      return (
        <DashboardDeliveryStatus
          value={value as DeliveryStatus}
          status={status as boolean}
          type={Entities.AdSets}
        />
      );
    },
  },
  [ColumnTypes.Date]: {
    align: 'left',
    headerAlign: 'left',
    type: 'date',
    valueFormatter: formatSafely(formatBackendTimestampToDateString),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.DateTime]: {
    align: 'left',
    headerAlign: 'left',
    type: 'dateTime',
    valueFormatter: formatSafely(formatBackendTimestampToDateTimeString),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Budget]: {
    type: 'number',
    renderCell: ({ value, row, colDef: { align } }) => (
      <DashboardBudgetText
        value={value}
        realValue={row[withPrefix(Entities.AdSets, BACK_END_KEYS.BUDGET)] as number | null}
        campaignValue={row[withPrefix(Entities.Campaigns, BACK_END_KEYS.BUDGET)] as number}
        align={align}
        rounding={rounding}
        isGeoBudget={row[withPrefix(Entities.Campaigns, BACK_END_KEYS.IS_GEO_BUDGET)] as boolean}
        channel={row[withPrefix(Entities.Campaigns, BACK_END_KEYS.CHANNEL)] as Channels}
      />
    ),
  },
  [ColumnTypes.CampaignBudget]: {
    type: 'number',
    valueFormatter: formatSafely(formatPrice, { rounding, skipNullCheck: true }),
    renderCell: ({ formattedValue, row, colDef: { align } }) => (
      <CampaignBudgetText
        value={formattedValue}
        channel={row[withPrefix(Entities.Campaigns, BACK_END_KEYS.CHANNEL)] as Channels}
        align={align}
        isGeoBudget={row[withPrefix(Entities.Campaigns, BACK_END_KEYS.IS_GEO_BUDGET)] as boolean}
      />
    ),
  },
  [ColumnTypes.Creative]: {
    type: 'string',
    valueFormatter: formatSafely(),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Platform]: {
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) => (
      <PlatformIcon
        platform={value}
      />
    ),
  },
  [ColumnTypes.Platforms]: {
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    renderCell: ({ value }) => (
      <Platforms
        platforms={value}
      />
    ),
  },
  [ColumnTypes.OfferLink]: {
    type: 'string',
    valueFormatter: getOfferNameExportFormatter(data),
    renderCell: ({ row }) => (
      <OfferName
        platform={row[withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM)] as Platform}
        appName={row[withPrefix(Entities.Apps, BACK_END_KEYS.NAME)] as string}
        row={row}
        channel={row[withPrefix(Entities.Campaigns, BACK_END_KEYS.CHANNEL)] as Channels}
        onRowClick={onRowClick}
      />
    ),
  },
  [ColumnTypes.ChannelLink]: {
    type: 'string',
    align: 'center',
    headerAlign: 'center',
    cellClassName: CUSTOM_RELATIVE_POSITION_CELL_CLASS,
    renderCell: ({ row, value }) => (
      <DashboardLinkContainer
        value={
          (
            row[withPrefix(row.entity, BACK_END_KEYS.CHANNEL_LINK)] || row[withPrefix(Entities.Campaigns, BACK_END_KEYS.CHANNEL_LINK)]
          ) as string
        }
      >
        <ChannelIcon channel={value} />
      </DashboardLinkContainer>
    ),
  },
  [ColumnTypes.SalesforceLink]: {
    type: 'string',
    valueFormatter: formatSafely(),
    cellClassName: CUSTOM_RELATIVE_POSITION_CELL_CLASS,
    renderCell: ({ row, formattedValue }) => (
      <DashboardLinkContainer
        value={row[withPrefix(Entities.Apps, BACK_END_KEYS.SALESFORCE_LINK)] as string}
        icon={SaleforceIcon}
        iconSize={22}
        iconColor="#73C3E8"
        iconActiveColor="#39A4D4"
      >
        <AdaptiveText>
          {formattedValue}
        </AdaptiveText>
      </DashboardLinkContainer>
    ),
  },
  [ColumnTypes.Country]: {
    align: 'left',
    headerAlign: 'left',
    renderCell: ({ value, row }) => {
      if (isObjectEmpty(row)) { // TODO: refactor
        return null;
      }

      return (
        <Country
          code={value}
        />
      );
    },
  },
  [ColumnTypes.Day]: {
    align: 'left',
    headerAlign: 'left',
    type: 'date',
    valueFormatter: formatSafely(formatBackendDayToDayString),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Week]: {
    align: 'left',
    headerAlign: 'left',
    type: 'date',
    valueFormatter: formatSafely(getStringRangeByWeekNumber, ''),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.Month]: {
    align: 'left',
    headerAlign: 'left',
    type: 'date',
    valueFormatter: formatSafely(getMonthNameByNumber, ''),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.CampaignCountry]: {
    align: 'left',
    headerAlign: 'left',
    type: 'string',
    filterable: false,
    valueFormatter: getCampaignCountryExportFormatter(data),
    renderCell: ({ row }) => {
      if (isObjectEmpty(row)) { // TODO: refactor
        return null;
      }

      return (
        <GeoCampaignContainer
          geo={row[withPrefix(Entities.Geos, BACK_END_KEYS.COUNTRY)] as Code}
        >
          {row[withPrefix(Entities.Campaigns, BACK_END_KEYS.NAME)]}
        </GeoCampaignContainer>
      );
    },
  },
  [ColumnTypes.AdjustLink]: {
    align: 'center',
    headerAlign: 'left',
    type: 'string',
    valueFormatter: formatSafely(),
    renderCell: ({ formattedValue, value }) => (
      <DashboardAdjustLink
        value={value}
        formattedValue={formattedValue}
      />
    ),
  },
  [ColumnTypes.Duration]: {
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    valueFormatter: formatSafely(formatDuration),
    renderCell: ({ formattedValue }) => (
      <AdaptiveText>
        {formattedValue}
      </AdaptiveText>
    ),
  },
  [ColumnTypes.CampaignList]: {
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    valueFormatter: formatSafely(),
    renderCell: ({ value, formattedValue, row }) => (
      <DashboardListCountLink
        value={value}
        formattedValue={formattedValue}
        onClick={() => onCountRowClick(row[withPrefix(CommonEntities.Measure, BACK_END_KEYS.CAMPAIGNS)] as (string[] | null), BACK_END_KEYS.CAMPAIGNS as keyof typeof BACK_END_KEYS)}
      />
    ),
  },
  [ColumnTypes.AdList]: {
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    valueFormatter: formatSafely(),
    renderCell: ({ value, formattedValue, row }) => (
      <DashboardListCountLink
        value={value}
        formattedValue={formattedValue}
        onClick={() => onCountRowClick(row[withPrefix(CommonEntities.Measure, BACK_END_KEYS.ADS)] as (string[] | null), BACK_END_KEYS.ADS as keyof typeof BACK_END_KEYS)}
      />
    ),
  },
  [ColumnTypes.ChannelList]: {
    align: 'left',
    headerAlign: 'left',
    type: 'number',
    valueFormatter: formatSafely(),
    renderCell: ({ value, formattedValue, row }) => (
      <DashboardListCountLink
        value={value}
        formattedValue={formattedValue}
        onClick={() => onCountRowClick(row[withPrefix(CommonEntities.Measure, BACK_END_KEYS.CHANNELS)] as (string[] | null), BACK_END_KEYS.CHANNELS as keyof typeof BACK_END_KEYS)}
      />
    ),
  },
});

export const getColumnPropsByType = (
  entityType: Entities,
  data: DataNode[],
  type: ColumnTypes,
  rounding: number | null,
  onRowClick: OnRowClick<DataNode>,
  onCountRowClick: OnCountRowClick,
  onCellEdit: EditableCellCallback,
  onCellRestart: EditableCellCallback,
): Partial<GridColumn<DataNode>> => {
  if (!Object.values(ColumnTypes).includes(type)) {
    return columnsPropsByType(entityType, data, rounding, onRowClick, onCountRowClick || (() => {}), onCellEdit, onCellRestart)[ColumnTypes.String];
  }

  return columnsPropsByType(entityType, data, rounding, onRowClick, onCountRowClick || (() => {}), onCellEdit, onCellRestart)[type];
};

const getDefaultColumns = (): DashGridConfig['columns'] => [
  {
    field: DEFAULT_COLUMN_FIELD,
    headerName: '#',
    headerTitle: '#',
    align: 'center',
    headerAlign: 'center',
    width: COLUMN_WIDTH.EXTRA_SM,
    type: 'number',
    renderCell: ({ value, aggregation, row }) => {
      if (aggregation && aggregation.hasCellUnit) {
        return <RowSummaryTitle />;
      }

      if (!row.isParent) {
        return '';
      }

      return value + 1;
    },
    sortable: false,
    filterable: false,
  },
];

export const getConfig = (
  type: Entities, fields: ViewColumn[] | undefined,
  data: DataNode[],
  onRowClick: OnRowClick<DataNode>,
  onCountRowClick: OnCountRowClick,
  onCellEdit: EditableCellCallback,
  onCellRestart: EditableCellCallback,
): DashGridConfig => ({
  rows: data || [],
  columns: fields?.length ? [
    ...getDefaultColumns(),
    ...fields.map((field) => ({
      field: field.key,
      headerTitle: field.name,
      headerSubTitle: field.source,
      filterable: true,
      sortable: true,
      width: field.width,
      primary: field.primary,
      description: field.description,
      ...getColumnPropsByType(type, data, field.type, field.rounding, onRowClick, onCountRowClick, onCellEdit, onCellRestart),
    })),
  ] : [],
});
