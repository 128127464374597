import React, { memo, useCallback, useState, ChangeEvent, useEffect } from 'react';

import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { Props, Fields } from './types';
import { getDefaultValues } from './utils';
import { Wrapper, StyledInput, FormWrapper, StyledTitle } from './styles';


const DashboardSaveFilterModal: React.FunctionComponent<Props> = memo(({
  onSave,
  onClose,
  isOpen,
  filter,
}) => {
  const [isEditMode, setIsEditMode] = useState(Boolean(filter));
  const [values, setValues] = useState(() => getDefaultValues(filter));

  const setEditMode = useCallback(() => {
    if (filter) {
      setIsEditMode(true);
    }
  }, [filter]);

  const setCreateMode = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, field: Fields) => {
    setValues((prevValues) => ({
      ...prevValues,
      [field]: event.target.value,
    }));
  }, []);

  const handleSaveSubmit = useCallback(() => {
    onSave(values, isEditMode);
    onClose();
  }, [onSave, onClose, values, isEditMode]);

  useEffect(() => {
    setValues(getDefaultValues(isEditMode ? filter : null));
  }, [filter, isEditMode]);

  useEffect(() => {
    setIsEditMode(Boolean(filter));
  }, [filter]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <Wrapper
        as={Paper}
      >
        <Stack
          direction="row"
        >
          <StyledTitle
            as={Typography}
            variant="h2"
            onClick={setEditMode}
            $isActive={isEditMode}
            $isDisabled={!filter}
          >
            Save current
          </StyledTitle>

          <StyledTitle
            as={Typography}
            variant="h2"
            $isActive={!isEditMode}
            onClick={setCreateMode}
          >
            Save as new
          </StyledTitle>
        </Stack>

        <FormWrapper>
          <StyledInput
            as={TextField}
            value={values[Fields.Name]}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event, Fields.Name)}
            placeholder="Name"
            fullWidth
          />

          <StyledInput
            as={TextField}
            value={values[Fields.Description]}
            onChange={(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => handleChange(event, Fields.Description)}
            placeholder="Description"
            rows={3}
            multiline
            fullWidth
            $isMultiline
          />
        </FormWrapper>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            onClick={handleSaveSubmit}
            disabled={!values[Fields.Name]}
          >
            Save
          </Button>
        </Stack>
      </Wrapper>
    </Modal>
  );
});


DashboardSaveFilterModal.displayName = 'DashboardSaveFilterModal';


export default DashboardSaveFilterModal;
