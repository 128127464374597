import { SnackbarConfig } from 'containers/snackbar/types';

import { MODES } from './constants';


class SnackbarService {
  subscriber: ((...params: any) => void) | null;

  queue: SnackbarConfig | null;

  constructor () {
    this.subscriber = null;
    this.queue = null;
  }

  /**
   * Public methods
   */

  showError (message: SnackbarConfig['message'], position?: SnackbarConfig['position']) {
    this.show({
      mode: MODES.ERROR,
      message,
      position,
    });
  }

  showSuccess (message: SnackbarConfig['message'], position?: SnackbarConfig['position']) {
    this.show({
      mode: MODES.SUCCESS,
      message,
      position,
    });
  }

  show (config: SnackbarConfig) {
    return new Promise((resolve) => {
      this.dispatchChanges(config, resolve);
    });
  }

  subscribe (callback: (...params: any) => void) {
    this.subscriber = callback;
    if (this.queue) {
      this.show(this.queue);
      this.queue = null;
    }

    return () => {
      this.subscriber = null;
    };
  }

  /**
   * Private methods
   */

  dispatchChanges (...params: any) {
    if (!this.subscriber) {
      // throw new Error('There is no subscriber');
      console.log('SnackBarService do not have subscriber');
      this.queue = params[0] ?? null;
      return;
    }

    this.subscriber(...params);
  }
}


const service = new SnackbarService();

export default service;
