import React, { memo, forwardRef } from 'react';
import clsx from 'clsx';

import { useTreeItem } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';

import { StyledParentTreeItem } from './styles';
import { TreeViewParentItemProps } from './types';


const TreeViewParentItem: React.FunctionComponent<TreeViewParentItemProps> = memo(forwardRef((props, ref) => {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    preventSelection,
  } = useTreeItem(nodeId);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    preventSelection(event);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    handleExpansion(event);
  };

  const icon = iconProp || expansionIcon || displayIcon;

  return (
    <StyledParentTreeItem
      ref={ref as React.Ref<HTMLDivElement>}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.disabled]: disabled,
        [classes.focused]: focused,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <div className={classes.iconContainer}>{icon}</div>

      <Typography
        variant="subtitle1"
        color="inherit"
      >
        {label}
      </Typography>
    </StyledParentTreeItem>
  );
}));


TreeViewParentItem.displayName = 'TreeViewParentItem';


export default TreeViewParentItem;
