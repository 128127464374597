import { styled } from '@mui/material/styles';

import { SelectedFilterProps } from './types';


export const Wrapper = styled('div')(() => ({
  maxHeight: '90vh',
}));

export const FilterActions = styled('div')(({ theme }) => ({
  width: '35%',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  background: 'linear-gradient(270deg, #F6F9FE 75.25%, rgba(246, 249, 254, 0) 100%)',
  transition: theme.transitions.create('opacity'),
  paddingRight: theme.spacing(0.5),
  opacity: 0,
}));

export const StyledSavedFilter = styled('div')<SelectedFilterProps>(({ theme, $isSelected, $isDisabled, $isActive = true }) => ({
  padding: theme.spacing(1.5),
  transition: theme.transitions.create('background-color'),
  backgroundColor: $isSelected ? theme.palette.action.hover : 'transparent',
  cursor: ($isDisabled || !$isActive) ? 'default' : 'pointer',
  opacity: $isDisabled ? 0.5 : 1,
  position: 'relative',
  
  ...($isActive ? {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  } : {}),

  [`&:hover ${FilterActions}`]: {
    opacity: 1,
  },
}));

export const FilterTitle = styled('div')<SelectedFilterProps>(({ theme, $isSelected }) => ({
  color: $isSelected ? theme.palette.primary.main : theme.palette.common.black,
  ...theme.typography.body2,
}));

export const FilterDescription = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.grey[500],

  ...theme.typography.caption,
}));

export const FilterButton = styled('div')(({ theme }) => ({
  height: '30px', // TODO: extract to theme
  width: 'auto',
  minWidth: 0,
  paddingRight: theme.spacing(1.25),
  paddingLeft: theme.spacing(1.25),
  marginRight: theme.spacing(0.5),
}));

export const FilterIconButton = styled('div')(() => ({
  '& svg': {
    color: '#C6C6C6',
  },
}));
