
import LionstudiosApi from 'services/lionstudios-api';

import { versionsStyles, welcomeStyles } from './styles';
import { BACKEND_VERSION_URL } from './constants';


class VersioningService {
  uiBuildVersion: string | null;

  uiBuildDate: string | null;

  backendBuildVersion: string | null;

  backendBuildDate: string | null;

  constructor () {
    this.uiBuildVersion = process.env.REACT_APP_BUILD_SHA || null;
    this.uiBuildDate = process.env.REACT_APP_BUILD_DATE || null;

    this.backendBuildVersion = null;
    this.backendBuildDate = null;

    if (process.env.REACT_APP_DISABLE_VERSIONING_PRINT !== 'true') {
      this.printToConsole();
    }
  }

  async printToConsole () {
    console.log('%cThe most amazing Front-End Developer welcomes you to his console!', welcomeStyles);

    if (this.backendBuildVersion === null) {
      await this.fetchBackendBuildVersion();
    }

    console.log(`%cUI: ${this.uiBuildVersion} | ${this.uiBuildDate}`, versionsStyles);
    console.log(`%cBACKEND: ${this.backendBuildVersion} | ${this.backendBuildDate}`, versionsStyles);
  }

  async fetchBackendBuildVersion () {
    try {
      console.log('Fetching backend version...');
      const { data } = await LionstudiosApi.get(BACKEND_VERSION_URL);

      this.backendBuildVersion = data.BUILD_SHA;
      this.backendBuildDate = data.BUILD_DATE;
    } catch (e: any) {
      console.error('Failed to fetch Backend Version', e?.message);
    }
  }
}


export default new VersioningService();
