import { SavedViewMergedWithFields } from 'core/users/types';


export interface Props {
  onSave: (values: SaveViewFormValues, isEditMode: boolean) => void;
  onClose: () => void;
  isOpen: boolean;
  view: SavedViewMergedWithFields | null;
}

export interface TabProps {
  $isActive: boolean;
}

export interface TabsWrapperProps {
  $isOnlyOneElement?: boolean;
}

export interface StyledTitleProps {
  $isActive: boolean;
  $isDisabled?: boolean;
}

export enum Fields {
  Name = 'name',
}

export type SaveViewFormValues = Record<Fields, string>;
