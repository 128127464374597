import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import ChevronRight from '@mui/icons-material/ChevronRight';

import theme from 'containers/theme-provider/theme';

import SelectedIndicator from 'components/common/selected-indicator';

import { StyledButton, StyledIcon, SelectedIndicatorStyles } from './styles';

import { Props } from './types';


const ExpandButton: React.FunctionComponent<Props> = ({ value = false, label, onToggle, disabled, activeItemsCount, onReset, useAnimationDelay = true }) => {
  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    onToggle(!value, event);
  }, [value, onToggle]);

  return (
    <StyledButton
      as={Button}
      variant="outlined"
      color="primary"
      onClick={handleClick}
      disabled={disabled}
      endIcon={(
        <>
          {Boolean(activeItemsCount) && onReset && (
            <SelectedIndicator
              color={theme.palette.primary.main}
              colorAlpha={1}
              sx={SelectedIndicatorStyles}
              onReset={onReset}
            >
              {activeItemsCount}
            </SelectedIndicator>
          )}

          <StyledIcon
            as={ChevronRight}
            $isExpanded={value}
          />
        </>
      )}
      $isExpanded={value}
      $useAnimationDelay={useAnimationDelay}
    >
      {label}
    </StyledButton>
  );
};


ExpandButton.displayName = 'ExpandButton';


export default ExpandButton;
