import { createSelector, Selector } from 'reselect';

import { State } from 'core/types';
import { State as AuthState } from './types';


const getLocalState = (state: State) => state.auth;
const getTokenState = (state: State) => getLocalState(state).token;
const getIsTokenValidState = (state: State) => getLocalState(state).isTokenValid;
const getIsFetchingState = (state: State) => getLocalState(state).isFetching;
const getRedirectUrlState = (state: State) => getLocalState(state).redirectUrl;


export const getToken = createSelector<[Selector<State, AuthState['token']>], AuthState['token']>(
  getTokenState,
  (token) => token,
);

export const getIsFetching = createSelector<[Selector<State, AuthState['isFetching']>], AuthState['isFetching']>(
  getIsFetchingState,
  (isFetching) => isFetching,
);

export const getIsAuthorized = createSelector<[Selector<State, AuthState['token']>], boolean>(
  getToken,
  (token) => Boolean(token),
);

export const getIsTokenValid = createSelector<[Selector<State, AuthState['isTokenValid']>], AuthState['isTokenValid']>(
  getIsTokenValidState,
  (isTokenValid) => isTokenValid,
);

export const getRedirectUrl = createSelector<[Selector<State, AuthState['redirectUrl']>], AuthState['redirectUrl']>(
  getRedirectUrlState,
  (redirectUrl) => redirectUrl,
);
