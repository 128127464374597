import * as yup from 'yup';
import { endOfDay } from 'date-fns';


const INVALID_DATE = 'Invalid Date';
const FROM_DATE_ERROR = 'From should be earlier or equal To';

export const ValidationSchema = yup.object().shape({
  'from': yup.date().required().typeError(INVALID_DATE)
    .when(
      'to',
      (to, schema) => to && !Number.isNaN(Date.parse(to)) ? schema.max(to, FROM_DATE_ERROR) : schema.max(endOfDay(new Date())),
    ),
  'to': yup.date().required().typeError(INVALID_DATE).max(endOfDay(new Date())),
});
