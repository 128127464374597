import { DateRangeRelativeFilter } from 'core/dashboards/types';


export const DIVIDER = '\xa0\xa0-\xa0\xa0';

export const LABELS = {
  [DateRangeRelativeFilter.TODAY]: {
    startLabel: 'Today',
    endLabel: 'Today',
  },
  [DateRangeRelativeFilter.YESTERDAY]: {
    startLabel: 'Yesterday',
    endLabel: 'Yesterday',
  },
  [DateRangeRelativeFilter.LAST_7_DAYS]: {
    startLabel: '-7 days',
    endLabel: 'Yesterday',
  },
  [DateRangeRelativeFilter.LAST_7_DAYS_TODAY]: {
    startLabel: '-6 days',
    endLabel: 'Today',
  },
  [DateRangeRelativeFilter.MONTH_TO_DATE]: {
    startLabel: 'First day of this month',
    endLabel: 'Yesterday',
  },
  [DateRangeRelativeFilter.LAST_MONTH]: {
    startLabel: 'First day of last month',
    endLabel: 'Last day of last month',
  },
};

export const DEFAULT_LABEL = 'Date Range';
