import { memo, FunctionComponent } from 'react';

import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { ViewConfigButtonProps } from './types';

import { StyledViewConfigButton } from './styles';


const ViewConfigButton: FunctionComponent<ViewConfigButtonProps> = memo(({ children, onClick, isActive, isRounded }) => (
  <StyledViewConfigButton
    as={Button}
    endIcon={isActive ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    variant="contained"
    onClick={onClick}
    $isActive={isActive}
    $isRounded={isRounded}
  >
    {children}
  </StyledViewConfigButton>
));


ViewConfigButton.displayName = 'ViewConfigButton';


export default ViewConfigButton;
