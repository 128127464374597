import { styled } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import { TabProps, TabsWrapperProps } from './types';


export const Wrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '440px',
  minHeight: '204px',
  padding: theme.spacing(3.5),
}));

export const FormWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(1.25, 0, 2.5, 0),
}));

export const StyledInput = styled('div')(({ theme }) => ({
  [`.${inputBaseClasses.root}`]: {
    height: '38px',
    backgroundColor: theme.palette.grey[50],

    ...theme.typography.body1,
    color: theme.palette.common.black,

    [`.${inputBaseClasses.input}::placeholder`]: {
      color: 'inherit',
    },
  },

  [`.${outlinedInputClasses.notchedOutline}`]: {
    display: 'none',
  },
}));

export const Tab = styled('div')<TabProps>(({ theme, $isActive }) => ({
  ...theme.typography.body1,
  height: '38px',
  backgroundColor: $isActive ? theme.palette.background.default : theme.palette.grey[50],
  color: $isActive ? theme.palette.primary.main : theme.palette.grey[500],
  border: `1px solid ${$isActive ? theme.palette.primary.main : theme.palette.grey[50]}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: $isActive ? 'default' : 'pointer',
  flex: 1,
  transition: theme.transitions.create(['color', 'border', 'backgroundColor']),
}));

export const TabsWrapper = styled('div')<TabsWrapperProps>(({ theme, $isOnlyOneElement }) => ({
  [`& ${Tab}:first-child`]: {
    ...($isOnlyOneElement ? {
      borderRadius: '8px',
    } : {
      borderRadius: '8px 0px 0px 8px',
    }),
  },

  [`& ${Tab}:last-child`]: {
    ...($isOnlyOneElement ? {
      borderRadius: '8px',
    } : {
      borderRadius: '0px 8px 8px 0px',
    }),
  },
}));
