import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(5, 0),
}));
