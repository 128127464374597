import { styled } from '@mui/material/styles';

import { StyledContentWrapperProps } from './types';


export const Wrapper = styled('div')({
  position: 'relative',
  width: '100%',
  cursor: 'pointer',
});

export const ContentWrapper = styled('div')<StyledContentWrapperProps>(({ theme, $isSelected }) => ({
  position: 'relative',
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  paddingRight: theme.spacing(2),
  color: $isSelected ? theme.palette.primary.main : 'inherit',

  ...($isSelected ? {
    '&:before': {
      content: '""',
      width: '45px',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      background: 'linear-gradient(90deg, rgba(255,255,255,0.5) 0%, rgba(255,255,255,1) 100%)',
    },
  } : {}),
}));

