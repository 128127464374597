/* eslint-disable react-hooks/rules-of-hooks */
import { memo, useCallback, FunctionComponent, SyntheticEvent, Fragment, MouseEvent, useState, useMemo } from 'react';
import classNames from 'classnames';

import ReactMarkdown from 'react-markdown';

import { GridEvents, GridColumnHeaderSortIcon, useGridApiContext, gridClasses } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import LockIcon from '@mui/icons-material/Lock';
import Popover, { PopoverPosition, PopoverProps } from '@mui/material/Popover';

import { DEBUG_PARAM_NAME } from 'core/dashboards/constants';

import { getQueryParamFromHistory } from 'utils/history' ;

import { CUSTOM_HEADER_SORTED_CLASS, CUSTOM_HEADER_FILTERED_CLASS } from 'containers/theme-provider/components-overrides/data-grid';

import List from 'components/common/list';

import FilterIconButton from '../filter-icon-button';
import { DEFAULT_SORTING_ORDER } from '../../constants';

import { HEATMAP_POPOVER_ANCHOR_ORIGIN, HEATMAP_POPOVER_TRANSFORM_ORIGIN } from './constants';
import { getContextMenuOptions } from './config';
import { getPinnedTooltipText, getDescriptionWithTitle } from './utils';
import { Props, ContextMenuKeys } from './types';
import {
  ExtendedHeaderWrapper,
  ExtendedHeaderTitleWrapper,
  ExtendedHeaderFilterWrapper,
  HeaderTitleWrapper,
  HeaderSubTitle,
  PinIconWrapper,
  HeaderTitleContent,
} from './styles';


const ExtendedHeader: FunctionComponent<Props> = memo(({
  title,
  subTitle,
  children,
  isExtended = false,
  colDef,
  renderHeader,
  sort,
  onFilterClick,
  filter,
  isFetchingColumn,
  isPinned,
  isPinLocked,
  onPinClick,
  field,
  isHeatmapActive,
  onHeatmapChange,
  type,
  description = '',
  ...rest
}) => {
  const apiRef = useGridApiContext();

  const [contextMenuCoordinates, setContextMenuCoordinates] = useState<PopoverPosition | null>(null);
  const [heatmapPickerAnchor, setHeatmapPickerAnchor] = useState<HTMLElement | null>(null);

  const isDebug = getQueryParamFromHistory(DEBUG_PARAM_NAME);

  const nameNode = renderHeader ? renderHeader({ colDef, field, ...rest }) : (
    <div className={gridClasses.columnHeaderTitle}>
      {title ?? (colDef.fieldOverride || colDef.field)}
    </div>
  );

  const descriptionWithTitle = getDescriptionWithTitle(title ?? (colDef.fieldOverride || colDef.field), subTitle, description, !!isDebug, colDef.fieldOverride || colDef.field);

  const headerTitle = (
    <HeaderTitleWrapper>
      {
        isFetchingColumn ? (
          <>
            <Typography
              variant="body2"
            >
              <Skeleton
                variant="text"
                animation="pulse"
                width="70%"
              />
            </Typography>

            <Typography
              variant="caption"
            >
              <Skeleton
                variant="text"
                animation="pulse"
                width="50%"
              />
            </Typography>
          </>
        ) : (
          <Tooltip
            title={<ReactMarkdown linkTarget="_blank">{descriptionWithTitle}</ReactMarkdown>}
            placement="top"
            arrow
          >
            <HeaderTitleContent>
              {nameNode}

              {
                subTitle && (
                  <HeaderSubTitle>
                    {subTitle}
                  </HeaderSubTitle>
                )
              }
            </HeaderTitleContent>
          </Tooltip>
        )
      }
    </HeaderTitleWrapper>
  );

  if (!isExtended) {
    return headerTitle;
  }
  // NOTE: isExtended can't be changed dynamically, thats why we can use hooks in wrong order
  const handleWrapperClick = useCallback((event: SyntheticEvent) => {
    event.stopPropagation();
  }, []);

  const handleSortClick = useCallback((event: SyntheticEvent) => {
    if (!colDef.sortable && !colDef.isSortingForcedEnabled) {
      return;
    }

    apiRef.current.publishEvent(
      GridEvents.columnHeaderClick,
      apiRef.current.getColumnHeaderParams(colDef.fieldOverride || colDef.field),
      event as any,
    );
  }, []);

  const handlePinClick = useCallback(() => {
    if (!isPinLocked) {
      onPinClick(field, !isPinned);
    }
  }, [field, onPinClick, isPinned, isPinLocked]);

  const handleContextMenuClose = useCallback(() => {
    setContextMenuCoordinates(null);
  }, []);

  const handleHeatmapClose = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setHeatmapPickerAnchor(null);
  }, []);

  const handleHeatmapClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setHeatmapPickerAnchor(event.currentTarget);
  }, []);

  const heatmapPopoverProps = useMemo<PopoverProps>(() => ({
    anchorEl: heatmapPickerAnchor,
    open: Boolean(heatmapPickerAnchor),
    onClose: handleHeatmapClose,
    anchorOrigin: HEATMAP_POPOVER_ANCHOR_ORIGIN,
    transformOrigin: HEATMAP_POPOVER_TRANSFORM_ORIGIN,
  }), [handleHeatmapClose, heatmapPickerAnchor]);


  const handleMenuItemClick = useCallback((event: MouseEvent<HTMLElement>, key: ContextMenuKeys) => {
    switch (key) {
      case ContextMenuKeys.Heatmap:
        handleHeatmapClick(event);
        break;

      default:
        handleContextMenuClose();
    }
  }, []);

  const handleHeatmapChange = useCallback((color: string | null) => {
    onHeatmapChange(color, field);
    setHeatmapPickerAnchor(null);
    handleContextMenuClose();
  }, [
    onHeatmapChange,
    field,
    handleContextMenuClose,
  ]);

  const contextMenuOptions = useMemo(() => getContextMenuOptions(
    handleMenuItemClick,
    handleHeatmapChange,
    isHeatmapActive,
    type === 'number',
    heatmapPopoverProps,
  ), [
    handleMenuItemClick,
    isHeatmapActive,
    type,
    heatmapPopoverProps,
    handleHeatmapChange,
  ]);

  const handleContextMenuClick = useCallback((event: MouseEvent) => {
    if (!contextMenuOptions.length) {
      return;
    }

    event.preventDefault();

    const nextValue = contextMenuCoordinates ? null : {
      left: event.clientX,
      top: event.clientY,
    };

    setContextMenuCoordinates(nextValue);
  }, [contextMenuCoordinates, contextMenuOptions]);

  const pinnedTooltipText = getPinnedTooltipText(isPinned);

  return (
    <>
      <ExtendedHeaderWrapper
        onClick={handleWrapperClick}
        onContextMenu={handleContextMenuClick}
        className={classNames({
          [CUSTOM_HEADER_SORTED_CLASS]: Boolean(sort),
          [CUSTOM_HEADER_FILTERED_CLASS]: Boolean(filter),
        })}
      >
        <ExtendedHeaderTitleWrapper>
          {headerTitle}

          <Tooltip
            title={pinnedTooltipText}
            placement="top"
            arrow
          >
            <PinIconWrapper
              $isActive={isPinned}
              $isLocked={isPinLocked}
              onClick={handlePinClick}
            >
              <LockIcon />
            </PinIconWrapper>
          </Tooltip>
        </ExtendedHeaderTitleWrapper>

        <ExtendedHeaderFilterWrapper>
          <div>
            {
              colDef.filterable && (
                <FilterIconButton
                  field={colDef.fieldOverride || colDef.field}
                  onFilterClick={onFilterClick}
                />
              )
            }
          </div>

          <div onClick={handleSortClick}>
            {
              (colDef.sortable || colDef.isSortingForcedEnabled) && (
                <GridColumnHeaderSortIcon
                  direction={sort?.sortDirection}
                  index={sort?.sortIndex}
                  sortingOrder={DEFAULT_SORTING_ORDER}
                />
              )
            }
          </div>
        </ExtendedHeaderFilterWrapper>
      </ExtendedHeaderWrapper>

      <Popover
        open={Boolean(contextMenuCoordinates)}
        onClose={handleContextMenuClose}
        anchorPosition={contextMenuCoordinates || undefined}
        anchorReference="anchorPosition"
      >
        <List
          isSearchable={false}
          items={contextMenuOptions}
        />
      </Popover>
    </>
  );
});


ExtendedHeader.displayName = 'ExtendedHeader';


export default ExtendedHeader;
