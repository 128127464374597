import { useEffect, useRef, useState } from 'react';

import { getShouldSkipValueDefault } from './utils';


export default function useOriginal <T> (config: { value: T; shouldUseForceUpdate?: boolean; getShouldSkipValue?: (value: T, refValue: T | null) => boolean }) {
  const ref = useRef<T | null>(null);

  const {
    value,
    getShouldSkipValue = getShouldSkipValueDefault,
    shouldUseForceUpdate = false,
  } = config;

  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    if (getShouldSkipValue(value, ref.current)) {
      return;
    }

    ref.current = value;

    if (shouldUseForceUpdate) {
      setForceUpdate((prevForceUpdate) => !prevForceUpdate);
    }
  }, [value]);

  return ref.current;
}
