import { styled } from '@mui/material/styles';

import { StyledListItemProps } from './types';


export const Wrapper = styled('div')(() => ({

}));

export const SearchWrapper = styled('div')(({  theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const StyledListItem = styled('div')<StyledListItemProps>(({ theme, $isActive }) => ({
  ...($isActive ? {
    color: theme.palette.primary.main,
  } : {}),
}));
