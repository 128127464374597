import { styled } from '@mui/material/styles';
import { Status } from 'core/settings/types';

import { StatusState, MessageState, HeaderState } from '../types';


export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 32,
  padding: theme.spacing(0, 1.25),
}));

export const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '35%',
}));

export const Title = styled('div')(({ theme }) => ({
  color: theme.palette.grey['400'],
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(1.25),
}));

export const ValueWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '55%',
});

export const Separator = styled('div')(({ theme }) => ({
  height: 4,
  width: 4,
  borderRadius: 4,
  backgroundColor: theme.palette.grey['400'],
  margin: theme.spacing(0, 1.25),
}));

export const LastRunWrapper = styled('div')<MessageState>(({ $withMessage }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '45%',
  cursor: $withMessage ? 'pointer' : 'default',
}));

export const LastRunLabel = styled('p')<StatusState & HeaderState>(({ theme, $status, $isHeader }) => {
  let color = theme.palette.background.default;
  if ($isHeader) {
    color = theme.palette.grey['400'];
  } else if ($status === Status.FAILED) {
    color = theme.palette.error.light;
  }

  return {
    color,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };
});

export const NextRunLabel = styled('p')<HeaderState & StatusState>(({ theme, $status, $isHeader }) => {
  let color = theme.palette.background.default;
  if ($isHeader) {
    color = theme.palette.grey['400'];
  } else if ($status === Status.RUNNING || $status === Status.WAITING) {
    color = theme.palette.success.main;
  }

  return {
    color,
    width: '45%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };
});

export const InfoIcon = styled('div')(({ theme }) => ({
  color: theme.palette.error.light,
  cursor: 'pointer',
  width: 12,
  marginRight: theme.spacing(0.5),
}));
