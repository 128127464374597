import { Entities } from 'core/dashboards/types';

import { BackendSavedView, FetchSavedUserViewsResolvedPayload, User, UserResponse, CreateUserViewPayload, UserRole, UserRoleDisplay } from './types';


export const userModel = ({ id, email, fullName, role, compiledPermissions }: UserResponse): User => ({
  id,
  email,
  name: fullName,
  role,
  roleDisplay: UserRoleDisplay[role],
  compiledPermissions,
});

export const savedUserViewsModels = (
  response: Array<BackendSavedView>,
): FetchSavedUserViewsResolvedPayload => response.sort((view1, view2) => view1.id - view2.id).reduce<FetchSavedUserViewsResolvedPayload>((res, savedView) => { // TODO: remove sort after BE fix ?
  const entity = savedView.entity.toLocaleLowerCase() as Entities;
  const view = {
    ...savedView,
    entity,
  };

  if (!res[entity]) {
    res[entity] = [view];
  } else {
    res[entity]?.push(view);
  }

  return res;
}, {});

export const createViewRequestModel = ({ data: { id, isCustom, isDefault, ...view }, ...rest }: CreateUserViewPayload): CreateUserViewPayload => ({
  ...rest,
  data: {
    ...view,
    isDefault: false,
  },
});
