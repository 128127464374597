import { styled, keyframes, css, alpha } from '@mui/material/styles';

import globalTheme from 'containers/theme-provider/theme';
import { Status } from 'core/settings/types';

import { StatusState, ArrowIconProps } from './types';


const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${alpha(globalTheme.palette.error.light, 0.4)};
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

export const Wrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(5),
  marginRight: theme.spacing(2.5),
}));

export const Button = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StatusIcon = styled('div')<StatusState>(({ theme, $status }) => ({
  color: $status === Status.FAILED ? theme.palette.error.light : theme.palette.grey['400'],

  ...($status ? {
    transform: 'scale(1)',
    borderRadius: '50%',
    boxShadow: `0 0 0 ${theme.palette.error.light}`,
  } : {}),
}), ({ $status }) => $status === Status.FAILED ? css`animation: ${pulse} 1.5s infinite` : '');

export const ArrowIcon = styled('div')<ArrowIconProps>(({ theme, $isOpen, $isHidden }) => ({
  opacity: $isHidden ? 0 : 1,
  color: theme.palette.grey['400'],
  transform: `rotate(${$isOpen ? -180 : 0}deg)`,
  transition: theme.transitions.create('transform'),
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

export const Title = styled('p')<StatusState>(({ theme, $status }) => ({
  ...theme.typography.subtitle1,

  color: $status === Status.FAILED ? theme.palette.error.light : theme.palette.grey['400'],
  minWidth: 95,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const Subtitle = styled('p')(({ theme }) => ({
  ...theme.typography.caption,

  color: theme.palette.grey['400'],
  // position: 'absolute',
  // bottom: 5,
}));

export const SubtitleBasic = styled('p')(({ theme }) => ({
  ...theme.typography.caption,

  color: theme.palette.grey['400'],
}));

export const PopperContainer = styled('div')(({ theme }) => ({
  // background: theme.palette.common.black,
  backgroundColor: '#0b0c0f',
  width: 430,
}));
