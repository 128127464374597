import { Values, Value, Options, Option } from './types';


export const getOptionLabel = (option: Option): string => option.name;

export const getExtendedOptions = (options: Options): Values => options.map((option) => ({
  ...option,
  include: true,
}));

export const isOptionEqualToValue = (option: Option, value: Value) => value.id === option.id;

export const filterOptionsForAsyncAutocomplete = <T>(options: T) => options;
