import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';

import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import AnalyticsService from 'services/analytics';
import 'services/versioning';

// https://github.com/remix-run/react-router/issues/8481 , update react-router-dom lib when this feature will be stable
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import configureStore from 'core';

import Router from 'containers/router';
import history from 'containers/router/history';
import AppStateProvider from 'containers/app-state-provider';
import ThemeProvider from 'containers/theme-provider';
import Snackbar from 'containers/snackbar';

import './index.css';


const { store, persistor } = configureStore();

const App = () => (
  <MatomoProvider value={AnalyticsService.instance}>
    <HistoryRouter history={history}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider>
          <Provider store={store}>
            <PersistGate
              loading={null}
              persistor={persistor}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AppStateProvider>
                  <Router />

                  <Snackbar />
                </AppStateProvider>
              </LocalizationProvider>
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </StyledEngineProvider>
    </HistoryRouter>
  </MatomoProvider>
);


App.displayName = 'App';


export default App;
