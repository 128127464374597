import { memo, FunctionComponent, useCallback, MouseEvent } from 'react';

import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { Props } from './types';
import { Wrapper, ActionsWrapper, TitleWrapper, DefaultIconWrapper, DeleteIconWrapper, EditIconWrapper } from './styles';


const DashboardSavedViewSelector: FunctionComponent<Props> = memo(({ view, isDisabled, onDefaultClick, onDeleteClick, onEditClick }) => {
  const handleDefaultClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (!view.isDefault && !isDisabled) {
      onDefaultClick(view);
    }
  }, [onDefaultClick, view, isDisabled]);

  const handleDeleteClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (!isDisabled) {
      onDeleteClick(view);
    }
  }, [onDeleteClick, view, isDisabled]);

  const handleEditClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (onEditClick) {
      onEditClick(view);
    }
  }, [onEditClick, view]);

  return (
    <Wrapper
      as={Stack}
      direction="row"
      alignItems="center"
      $isDisabled={isDisabled}
    >
      <DefaultIconWrapper
        as={IconButton}
        onClick={handleDefaultClick}
        disabled={view.isCustom}
        $isActive={view.isDefault}
        $isDisabled={view.isCustom}
      >
        <StarIcon />
      </DefaultIconWrapper>

      <TitleWrapper>
        {view.name}
      </TitleWrapper>

      <ActionsWrapper
        as={Stack}
        direction="row"
        alignItems="center"
      >
        {onEditClick && (
          <EditIconWrapper
            as={IconButton}
            onClick={handleEditClick}
          >
            <EditIcon />
          </EditIconWrapper>
        )}

        {view.isRemovable && (
          <DeleteIconWrapper
            as={IconButton}
            onClick={handleDeleteClick}
          >
            <DeleteForeverIcon />
          </DeleteIconWrapper>
        )}
      </ActionsWrapper>
    </Wrapper>
  );
});


DashboardSavedViewSelector.displayName = 'DashboardSavedViewSelector';


export default DashboardSavedViewSelector;
