import { ChangeEvent, FormEvent, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Button, FormControl, InputLabel, OutlinedInput, Typography } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';
import { LoadingButton } from '@mui/lab';

import { getIsAuthorized } from 'core/auth/selectors';
import AuthLayout from 'components/auth/layout';
import { ROUTES } from 'containers/router/constants';
import LionstudiosApi from 'services/lionstudios-api';
import SnackbarService from 'services/snackbar';

import Background from 'assets/images/sign-in-okta-bg.png';
import FormBackground from 'assets/images/sign-in-okta-form-bg.svg';


const ForgotPassword = memo(() => {
  const navigate = useNavigate();

  const isAuthorized = useSelector(getIsAuthorized);
  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleClickBackSignIn = useCallback(() => {
    navigate(ROUTES.SIGN_IN);
  }, [navigate]);

  const handleSubmitForgotPassword = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    if (!email.length) { return; }
    try {
      setLoading(true);
      const response = await LionstudiosApi.post('forgot-password', {
        email: email,
      });

      navigate(ROUTES.SIGN_IN);
      SnackbarService.showSuccess(
        'Please check your inbox, reset password instruction has been sent to your email.',
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    } catch (e: any) {
      SnackbarService.showError(
        e?.response?.data?.error ?? (e?.message ?? 'Error'),
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
      setLoading(false);

    }

  }, [navigate, email]);

  return (
    <AuthLayout
      image={Background}
      formImage={FormBackground}
    >
      <Box
        component="form"
        onSubmit={handleSubmitForgotPassword}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: '0 8px' }}
      >
        <Typography
          variant="h2"
          sx={{ mb: '20px' }}
        >
          Reset Your Password
        </Typography>
        <FormControl
          sx={{ m: 1, width: '250px' }}
          variant="outlined"
        >
          <InputLabel
            htmlFor="outlined-email"
            sx={{ color: 'currentColor', backgroundColor: '#fff', outline: '#fff solid 6px' }}
          >Email</InputLabel>
          <OutlinedInput
            id="outlined-email"
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </FormControl>
        <LoadingButton
          type="submit"
          variant="outlined"
          sx={{
            fontSize: 18,
            width: '250px',
            fontWeight: 500,
            height: 56,
            alignSelf: 'center',
            margin: '8px auto',
            display: 'flex',
          }}
          loading={loading}
          loadingPosition="start"
          startIcon={<KeyIcon />}
        >Reset Password</LoadingButton>
      </Box>

      <Button
        variant="text"
        size="small"
        sx={{
          alignSelf: 'center',
          margin: '4px auto',
          display: 'flex',
        }}
        onClick={handleClickBackSignIn}
      >
        Back to Sign In
      </Button>
    </AuthLayout>
  );
});

ForgotPassword.displayName = 'ForgotPassword';

export default ForgotPassword;
