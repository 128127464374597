import update from 'immutability-helper';

import { Action } from 'types/redux';
import { handleActions, persistReducer, resolved, rejected, combineActions } from 'utils/redux';

import { Types as AuthTypes } from 'core/auth/types';

import persistConfig from './persist';
import {
  State,
  BaseState,
  Types,
  Config,
  ConfigPayload,
  Payload,
  GetConfigFromUrlResolvedPayload,
  PublishModes,
  FetchDataResolvedPayload,
  FetchDataRejectedPayload,
  Entities,
  DateRangeRelativeFilter,
  FiltersPayload,
  IsFiltersExpandedPayload,
} from './types';
import { getDefaultListState, getDateRangeFilterObject } from './utils';
import { DEFAULT_PAGE } from './constants';


const partialDefaultState: BaseState = {
  data: [],
  total: 0,
  isFetching: true,
  error: null,
  summary: null,
};

const defaultConfig: Config = {
  filter: [],
  dateRangeFilter: getDateRangeFilterObject(DateRangeRelativeFilter.LAST_7_DAYS),
  dateRangeRelativeFilter: DateRangeRelativeFilter.LAST_7_DAYS,
  selected: getDefaultListState<string[]>([]),
  page: getDefaultListState<number>(DEFAULT_PAGE),
  publishMode: PublishModes.Published,
};

const defaultState: State = {
  [Entities.Apps]: partialDefaultState,
  [Entities.Skus]: partialDefaultState,
  [Entities.Offers]: partialDefaultState,
  [Entities.Campaigns]: partialDefaultState,
  [Entities.AdSets]: partialDefaultState,
  [Entities.Ads]: partialDefaultState,
  [Entities.Creatives]: partialDefaultState,
  [Entities.Geos]: partialDefaultState,

  currentConfig: defaultConfig,
  selectedFiltersValues: defaultConfig.filter,
  isFiltersExpanded: false,
  queryConfig: {
    isLoaded: false,
    isFetching: false,
    isSaving: false,
    isEmpty: true,
  },
};

const reducer = handleActions<State, Types, Action<Types, Payload>>({
  [Types.FETCH_DATA]: (state: State, { payload: { tabKey } }): State => (
    update(state, {
      [tabKey]: {
        isFetching: { $set: true },
      },
    })
  ),
  [resolved<Types>(Types.FETCH_DATA)]: (state: State, { payload: { total, list, summary, tabKey } }: Action<Types, FetchDataResolvedPayload>): State => (
    update(state, {
      [tabKey]: {
        isFetching: { $set: false },
        total: { $set: total },
        summary: { $set: summary },
        data: { $set: list },
      },
    })
  ),
  [rejected<Types>(Types.FETCH_DATA)]: (state: State, { payload: { tabKey } }: Action<Types, FetchDataRejectedPayload>): State => (
    update(state, {
      [tabKey]: {
        isFetching: { $set: false },
      },
    })
  ),

  [Types.SET_CURRENT_CONFIG]: (state: State, { payload: { config } }: Action<Types, ConfigPayload>): State => (
    update(state, {
      currentConfig: { $set: config },
    })
  ),

  [Types.SET_FILTERS]: (state: State, { payload: { filters } }: Action<Types, FiltersPayload>): State => (
    update(state, {
      selectedFiltersValues: { $set: filters },
    })
  ),

  [Types.SET_IS_FILTERS_EXPANDED]: (state: State, { payload: { isExpanded } }: Action<Types, IsFiltersExpandedPayload>): State => (
    update(state, {
      isFiltersExpanded: { $set: isExpanded },
    })
  ),

  [Types.GET_CONFIG_FROM_QUERY]: (state: State): State => update(state, {
    queryConfig: {
      isFetching: { $set: true },
    },
  }),

  [resolved<Types>(Types.GET_CONFIG_FROM_QUERY)]: (state: State, { payload: { data } }: Action<Types, GetConfigFromUrlResolvedPayload>): State => update(state, {
    ...(data !== null ? {
      currentConfig: { $set: data },
    } : {}),
    queryConfig: {
      isFetching: { $set: false },
      isLoaded: { $set: true },
      isEmpty: { $set: !data },
    },
  }),

  [rejected<Types>(Types.GET_CONFIG_FROM_QUERY)]: (state: State): State => update(state, {
    queryConfig: {
      isFetching: { $set: false },
      isLoaded: { $set: true },
    },
  }),

  [Types.RESET_CONFIG]: (state: State): State => update(state, {
    currentConfig: { $set: defaultConfig },
  }),

  [combineActions<AuthTypes>(
    rejected<AuthTypes>(AuthTypes.LOAD_AUTH),
    rejected<AuthTypes>(AuthTypes.SIGN_IN),
    AuthTypes.LOGOUT,
  )]: (): State => defaultState,
}, defaultState);


export default persistReducer<State>(persistConfig, reducer as any);
