import { memo, FunctionComponent, useCallback, useState } from 'react';

import Switch from 'components/common/switch';
import NotReadyTooltip from 'components/common/not-ready-tooltip';

import { Props } from './types';


const DashboardSwitch: FunctionComponent<Props> = memo(({ value: initialValue, onChange, ...otherProps }) => {
  const [switchValue, setSwitchValue] = useState<boolean | null | undefined>(initialValue);
  const isValueExists = switchValue !== null;
  const isDisabled = !isValueExists;
  const value = isValueExists ? switchValue : false;

  const handleChange = useCallback((nextValue: boolean) => {
    setSwitchValue(nextValue);

    if (onChange) {
      onChange(nextValue);
    }
  }, [onChange]);

  if (value === undefined) {
    return null;
  }

  return (
    <NotReadyTooltip>
      <div>
        <Switch
          value={value}
          disabled={isDisabled}
          readOnly={isDisabled}
          onChange={handleChange}
          {...otherProps}
        />
      </div>
    </NotReadyTooltip>
  );
});


DashboardSwitch.displayName = 'DashboardSwitch';


export default DashboardSwitch;
