import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
}));

export const CountryWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  borderRight: '1px solid rgba(0, 0, 0, 0.2)',
  minWidth: '55px',
  maxWidth: '55px',
}));
