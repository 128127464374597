import { useSearchParams } from 'react-router-dom';
import { TypedObject } from 'types';

import { parseParams } from './utils';


export default function useQueryParams (params: TypedObject<any>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = parseParams(searchParams, params);

  // TODO: memoize params somehow ?

  return [query, setSearchParams];
}
