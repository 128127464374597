import { memo, useState, useMemo, useCallback, FunctionComponent } from 'react';
import hexToRgba from 'hex-to-rgba';

import {
  LicenseInfo,
  GridSlotsComponent,
  GridSlotsComponentsProps,
  GridPreferencePanelsValue,
  gridPreferencePanelStateSelector,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails,
  gridFilterStateSelector,
  GridRowClassNameParams,
} from '@mui/x-data-grid-premium';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { ReactComponent as ArrowUpIcon } from 'assets/icons/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down.svg';

import { HeatmapModel } from 'core/dashboards/types';
import { TEST_FEATURES_PARAM_NAME } from 'core/dashboards/constants';

import { getQueryParamFromHistory } from 'utils/history' ;

import appConfig from 'config';

import NoRows from './components/no-rows';
import ExtendedHeader from './components/extended-header';
import { ExtendedGridColumnHeaderParams } from './components/extended-header/types';
import GridPanel from './components/grid-panel';
import GridFilterPanel from './components/grid-filter-panel';
import LoadingRow from './components/loading-row';
import EditableCell from './components/editable-cell';
import SelectableCell from './components/selectable-cell';
import {
  Props,
  GridColumn,
  ColumnsHeatmap,
} from './types';
import {
  PAGE_SIZES,
  DEFAULT_PAGE_SIZE,
  HEADER_HEIGHT,
  EXTENDED_HEADER_HEIGHT,
  GRID_ROW_FETCHING_CLASS,
  GRID_ROW_DISABLED_CLASS,
  ROW_HEIGHT,
  DEFAULT_SORTING_ORDER,
  EXPERIMENTAL_FEATURES,
  UNGROUPED_SUMMARY_FIELD,
} from './constants';
import {
  getFilterOperators,
  gridSortColumnLookup,
  defaultSortComparator,
  getHeaderName,
  generateFakeRowsFromColumns,
  generateFakeColumns,
  gridFilterColumnLookup,
  getPinnedColumns,
  getHeatMapOpacity,
  getHeatmaps,
} from './utils';

import {
  Wrapper,
  StyledDataGrid,
  HeatMapCellWrapperStyles,
} from './styles';


LicenseInfo.setLicenseKey(appConfig.MUI_PRO_LICENSE_KEY);


const GRID_COMPONENTS: Partial<GridSlotsComponent> = {
  NoRowsOverlay: NoRows,
  ColumnSortedAscendingIcon: ArrowUpIcon,
  ColumnSortedDescendingIcon: ArrowDownIcon,
  Panel: GridPanel,
  FilterPanel: GridFilterPanel,
  FilterPanelDeleteIcon: DeleteForeverIcon,
  LoadingOverlay: () => null,
};

const DataGrid: FunctionComponent<Props> = memo(({
  config,
  isFetching,
  onSelectionChange = () => {},
  selection,
  getIsRowFetching = () => {},
  getRowClassName = () => {},
  checkboxSelection = true,
  onPageSizeChange = () => {},
  onPageChange = () => {},
  page,
  pageSize,
  rowCount,
  disableClientSorting = false,
  extendedHeader = false,
  sortModel,
  rowGroupingModel,
  onRowClick = () => {},
  apiRef,
  groupingColDef,
  pinnedColumns,
  onPinnedColumnsChange,
  aggregationInfo,
  defaultColumnField,
  defaultGroupName,
  columnVisibilityModel = {},
  breakdown,
  onHeatmapChange,
  heatmapModel,
  isStickyHeader = false,
  disabledRows = [],
  availableFilters,
  onCellSelect,
  filtersValues,
  getCellFilterComparator,
  ...otherProps
}) => {
  const { rows, columns } = config;

  const isTestMode = getQueryParamFromHistory(TEST_FEATURES_PARAM_NAME);

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLElement | null>(null);
  const [targetFilterColumnField, setTargetFilterColumnField] = useState<string | null>(null);

  const getRowClassNameForGrid = useCallback((props: GridRowClassNameParams): string => {
    if (!getRowClassName && !getIsRowFetching) {
      return '';
    }

    const classes = [];

    if (getRowClassName) {
      classes.push(getRowClassName(props));
    }

    if (getIsRowFetching) {
      classes.push(getIsRowFetching(props) ? GRID_ROW_FETCHING_CLASS : '');
    }

    if (disabledRows?.length && disabledRows.includes(props.row.id)) {
      classes.push(GRID_ROW_DISABLED_CLASS);
    }

    return classes.join(' ');
  }, [getRowClassName, getIsRowFetching, disabledRows]);

  const rowsPerPageOptions = useMemo(() => {
    if (rows.length > PAGE_SIZES[PAGE_SIZES.length - 1]) {
      return [...PAGE_SIZES, rows.length];
    }

    return PAGE_SIZES;
  }, [rows.length]);

  const handleFilterClick = useCallback((nextFilterButtonEl: HTMLElement | null, field: string) => {
    const {
      open,
      openedPanelValue,
    } = gridPreferencePanelStateSelector(apiRef.current.state);

    if (open && openedPanelValue === GridPreferencePanelsValue.filters && nextFilterButtonEl === filterButtonEl) {
      apiRef.current.hideFilterPanel();
    } else {
      setFilterButtonEl(nextFilterButtonEl);
      setTargetFilterColumnField(field);
      apiRef.current.showPreferences(GridPreferencePanelsValue.filters);
    }
  }, [apiRef, filterButtonEl]);

  const handleRowClick = useCallback((...args: [GridRowParams, MuiEvent<React.MouseEvent>, GridCallbackDetails]) => {
    onRowClick(...args, apiRef.current);
  }, [onRowClick, apiRef]);

  const componentProps = useMemo<GridSlotsComponentsProps>(() => ({
    panel: {
      anchorEl: filterButtonEl,
    },
    filterPanel: {
      targetFilterColumnField,
    },
  }), [filterButtonEl, targetFilterColumnField]);

  const handlePinClick = useCallback((field: string, isPinned: boolean) => {
    const nextPinnedColumns = getPinnedColumns(columns as GridColumn[], pinnedColumns.left || [], field, isPinned);

    onPinnedColumnsChange({
      left: nextPinnedColumns,
    });
  }, [pinnedColumns, onPinnedColumnsChange, columns]);

  const handleHeatmapChange = useCallback((color: string | null, field: string) => {
    const filteredHeatmapModel = heatmapModel.filter((heatmap) => heatmap.field !== field);
    const nextHeatmapModel = color ? [
      ...filteredHeatmapModel,
      {
        field,
        color,
      },
    ] : filteredHeatmapModel;

    onHeatmapChange(nextHeatmapModel);
  }, [heatmapModel, onHeatmapChange]);

  const getExtendedRenderHeader = useCallback((column: GridColumn) => (params: ExtendedGridColumnHeaderParams) => { // eslint-disable-line react/display-name
    const { headerTitle, headerSubTitle, renderHeader, field, isFetching: isFetchingColumn, isPinLocked, type, description } = column;
    const { colDef: { fieldOverride } } = params;

    const sortColumnLookup = gridSortColumnLookup(apiRef.current.getSortModel());
    const filterColumnLookup = gridFilterColumnLookup(gridFilterStateSelector(apiRef.current.state).filterModel);
    const isPinned = apiRef.current.isColumnPinned(fieldOverride || field);
    const isHeatmapActive = Boolean(heatmapModel.find((heatmap) => heatmap.field === (fieldOverride || field)));

    return (
      <ExtendedHeader
        title={headerTitle}
        subTitle={headerSubTitle}
        isExtended={extendedHeader}
        renderHeader={renderHeader}
        sort={sortColumnLookup[fieldOverride || field]}
        filter={filterColumnLookup[fieldOverride || field]}
        onFilterClick={handleFilterClick}
        isFetchingColumn={!!isFetchingColumn}
        isPinned={isPinned}
        isPinLocked={isPinLocked}
        onPinClick={handlePinClick}
        {...params}
        field={fieldOverride || field}
        isHeatmapActive={isHeatmapActive}
        onHeatmapChange={handleHeatmapChange}
        type={type}
        description={description}
      />
    );
  }, [
    extendedHeader,
    handleFilterClick,
    apiRef,
    handlePinClick,
    heatmapModel,
    handleHeatmapChange,
  ]);

  const heatmaps = useMemo<ColumnsHeatmap | null>(() => getHeatmaps(heatmapModel, columns, rows), [heatmapModel, columns, rows]);

  const renderHeatMap = useCallback((opacity: number | string, color: HeatmapModel['color']) => (
    <div
      style={{
        ...HeatMapCellWrapperStyles,
        backgroundColor: hexToRgba(color, opacity),
      } as any}
    />
  ), []);

  const getExtendedRenderCell = useCallback((column: GridColumn) => (params: GridRenderCellParams) => { // eslint-disable-line react/display-name
    const { renderCell, field, onCellEdit, onCellRestart, columnType } = column;
    const { rowNode: { children, isAutoGenerated, groupingKey, parent }, aggregation, row, value } = params;
    const { isParent } = row;
    const isGroupingRow = isAutoGenerated && !!children;
    const isCellSelectable = availableFilters.includes(field);

    const heatmap = heatmaps && heatmaps[field];
    const heatmapOpacity = getHeatMapOpacity(heatmap, value);

    if (aggregation && aggregation.hasCellUnit && aggregationInfo) {
      let aggregatedValue;

      if (isGroupingRow) {
        aggregatedValue = aggregationInfo.groups?.[groupingKey === defaultGroupName ? UNGROUPED_SUMMARY_FIELD : groupingKey as string]?.[field];
      } else {
        aggregatedValue = aggregationInfo.measures?.[field];
      }

      if (aggregatedValue === undefined && field !== defaultColumnField) {
        return '';
      }

      return (
        <>
          {
            renderCell && renderCell({
              ...params,
              formattedValue: column.valueFormatter ? column.valueFormatter({ value: aggregatedValue, api: params.api, field: params.field }) : aggregatedValue,
            })
          }
        </>
      );
    }

    // TODO: optimize?
    const [groupingField] = rowGroupingModel || [];
    if (parent && params.value === defaultGroupName && groupingField === field) {
      return (
        <>
          {
            renderCell && renderCell({
              ...params,
              formattedValue: column.valueFormatter ? column.valueFormatter({ value: null, api: params.api, field: params.field }) : null,
            })
          }
          {heatmap && renderHeatMap(heatmapOpacity, heatmap.color)}
        </>
      );
    }

    if (breakdown && ((column.primary && !isParent) || (isParent && field === breakdown))) {
      return '';
    }

    if (breakdown && field === breakdown && isParent === undefined) {
      return '';
    }

    let cell = (
      <>
        {renderCell && renderCell(params)}
        {heatmap && renderHeatMap(heatmapOpacity, heatmap.color)}
      </>
    );

    if (onCellEdit && columnType && onCellRestart && isTestMode) {
      const isCellDisabled = disabledRows.includes(row.id);

      cell = (
        <EditableCell
          cellElement={cell}
          columnType={columnType}
          cellValue={value}
          field={field}
          row={row}
          isDisabled={isCellDisabled}
          onSubmit={onCellEdit}
          onRestart={onCellRestart}
        />
      );
    }

    if (isCellSelectable) {
      const fieldFilterValue = filtersValues[field];
      const filterValueComparator = getCellFilterComparator(field, row);
      const cellFilterValue = fieldFilterValue && fieldFilterValue.find(filterValueComparator);

      cell = (
        <SelectableCell
          cellElement={cell}
          cellValue={value}
          field={field}
          row={row}
          onSelect={onCellSelect}
          filterValue={cellFilterValue || null}
        />
      );
    }

    return cell;
  }, [
    aggregationInfo,
    defaultColumnField,
    defaultGroupName,
    rowGroupingModel,
    breakdown,
    heatmaps,
    renderHeatMap,
    disabledRows,
    isTestMode,
    availableFilters,
    onCellSelect,
    filtersValues,
    getCellFilterComparator,
  ]);

  const gridColumns = useMemo(() => (columns.length ? columns : generateFakeColumns()).map((column: GridColumn) => {
    const { isFetching: isFetchingColumn } = column;

    const extendedRenderCell = getExtendedRenderCell(column);
    const extendedRenderHeader =  getExtendedRenderHeader(column);

    return {
      filterOperators: getFilterOperators(column.type),
      headerName: getHeaderName(column),
      ...column,
      renderCell: isFetching || isFetchingColumn ? LoadingRow : extendedRenderCell,
      renderHeader: extendedRenderHeader,
      ...(disableClientSorting ? {
        sortComparator: defaultSortComparator,
      } : {}),
      ...(extendedHeader ? { hideSortIcons: true } : {} ),
    };
  }), [columns, disableClientSorting, getExtendedRenderCell, getExtendedRenderHeader, extendedHeader, isFetching]);

  const gridGroupingColDef = useMemo(() => {
    if (!isFetching) {
      return groupingColDef;
    }

    return {
      ...groupingColDef,
      renderCell: LoadingRow,
    };
  }, [groupingColDef, isFetching]);

  return (
    <Wrapper>
      <StyledDataGrid
        apiRef={apiRef}
        rows={isFetching ? generateFakeRowsFromColumns(gridColumns, rowGroupingModel) : rows}
        columns={gridColumns}
        pageSize={pageSize || DEFAULT_PAGE_SIZE}
        page={page}
        selectionModel={selection}
        onSelectionModelChange={onSelectionChange}
        rowsPerPageOptions={rowsPerPageOptions}
        onPageSizeChange={onPageSizeChange}
        onPageChange={onPageChange}
        loading={isFetching}
        headerHeight={extendedHeader ? EXTENDED_HEADER_HEIGHT : HEADER_HEIGHT}
        getRowClassName={getRowClassNameForGrid}
        checkboxSelection={checkboxSelection}
        components={GRID_COMPONENTS}
        rowHeight={ROW_HEIGHT}
        rowGroupingModel={rowGroupingModel}
        pagination
        disableColumnMenu
        disableSelectionOnClick
        autoHeight
        disableColumnReorder
        disableColumnPinning
        disableColumnResize
        disableColumnSelector
        disableDensitySelector
        disableRowGrouping
        disableExtendRowFullWidth
        $isExtendedHeader={extendedHeader}
        sortModel={sortModel}
        componentsProps={componentProps}
        onRowClick={handleRowClick}
        keepNonExistentRowsSelected
        rowCount={rowCount || 0}
        groupingColDef={gridGroupingColDef}
        pinnedColumns={pinnedColumns}
        onPinnedColumnsChange={onPinnedColumnsChange}
        sortingOrder={DEFAULT_SORTING_ORDER}
        experimentalFeatures={EXPERIMENTAL_FEATURES}
        columnVisibilityModel={columnVisibilityModel}
        $isStickyHeader={isStickyHeader}
        {...otherProps}
        {...(extendedHeader ? { disableColumnFilter: true } : {})}
      />
    </Wrapper>
  );
});


DataGrid.displayName = 'DataGrid';


export default DataGrid;
