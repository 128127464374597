import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import NotFoundImage from 'assets/images/404.svg';

import { ROUTES } from 'containers/router/constants';

import { Wrapper, StyledText } from './styles';


const NotFound = () => {
  const navigate = useNavigate();

  const handleBackHomeClick = useCallback(() => {
    navigate(ROUTES.DASHBOARD, { replace: true });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrapper>
      <Stack
        alignItems="center"
        justifyContent="space-between"
      >
        <img
          src={NotFoundImage}
          alt="404"
        />

        <StyledText as={Typography}>Oops! We can’t find that page! </StyledText>

        <Button onClick={handleBackHomeClick}>Back Home</Button>
      </Stack>
    </Wrapper>
  );
};

export default NotFound;
