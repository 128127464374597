import { memo, FunctionComponent } from 'react';

import AdaptiveText from 'components/common/adaptive-text';
import Country from 'components/common/country';

import { Props } from './types';
import { Wrapper, CountryWrapper } from './styles';


const GeoCampaignContainer: FunctionComponent<Props> = memo(({ children, geo }) => (
  <Wrapper>
    <CountryWrapper>
      <Country
        code={geo}
        useCountryName={false}
      />
    </CountryWrapper>

    <AdaptiveText>
      {children}
    </AdaptiveText>
  </Wrapper>
));


GeoCampaignContainer.displayName = 'GeoCampaignContainer';


export default GeoCampaignContainer;
