import { PopoverOrigin } from '@mui/material/Popover';

import { PADDING_BASE } from 'containers/theme-provider/constants';


export const BREAKDOWN_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: -PADDING_BASE * 2,
};

export const BREAKDOWN_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};
