import { Entities, Platform as PlatformTypes } from 'core/dashboards/types'; // eslint-disable-line import/no-cycle
import { FetchingSliceState } from 'core/fetching/types'; // eslint-disable-line import/no-cycle


export type BackEndGroupingKey = `${Entities}.${string}`;

export interface BackEndGroupingModel {
  key: BackEndGroupingKey;
  label: string;
}

export type GroupingResponsePayload = {
  [key in Entities]: Array<BackEndGroupingModel>;
};

export interface GroupingOptionModel {
  prefix: Entities;
  key: string | null;
  title: string;
}

export type GroupingResolvedPayload = {
  [key in Entities]: Array<GroupingOptionModel>;
};

export const enum Status {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  RUNNING = 'RUNNING',
  WAITING = 'WAITING',
}

export interface FeedStatus {
  message: string | null;
  status: Status | null;
}

export interface FeedEntity {
  id: string;
  lastRun: number | null;
  nextRun: number | null;
  status: FeedStatus | null;
}

export interface Feed {
  lastRun: number | null;
  nextRun: number | null;
  status: FeedStatus;
  feeds: FeedEntity[] | null;
}

export enum SliceKeys {
  Grouping = 'grouping',
  FilterBasicData = 'filterBasicData',
  ViewConfiguration = 'viewConfiguration',
  DataStatus = 'dataStatus',
  Breakdown = 'breakdown',
}

export interface State {
  [SliceKeys.Grouping]: FetchingSliceState<GroupingResolvedPayload>;
  [SliceKeys.FilterBasicData]: FetchingSliceState<Filter[]>;
  [SliceKeys.ViewConfiguration]: FetchingSliceState<ViewColumn[]>;
  [SliceKeys.DataStatus]: FetchingSliceState<Feed | null>;
  [SliceKeys.Breakdown]: FetchingSliceState<BreakdownResolvedPayload>;
}

// TODO: refactor this types!
export interface BaseOption {
  id: string | number;
  name: string;
  value?: string | number;
  label?: string;
}

export interface SkuAppNameOption extends BaseOption {
  platform: PlatformTypes;
}

export interface NumberOption extends BaseOption {
  label: string;
}

export interface BaseValue extends BaseOption {
  include?: boolean;
  value?: number | string;
}

export interface SkuAppNameValue extends BaseValue {
  platform: PlatformTypes;
}

export interface NumberValue extends BaseValue {
  label: string;
}

export type Option = BaseOption | SkuAppNameOption | NumberOption;

export type Value = BaseValue | SkuAppNameValue | NumberValue;

export interface Filter {
  name: string;
  category: string;
  categoryName: string;
  isDefault: boolean;
  options: Option[];
  testOptions: Option[];
  values: Value[];
  id: string | number;
  type: FilterType;
  isAsync: boolean;
  entity: string;
  field: string;
}

export interface OtherFilter extends Filter {
  selected: boolean;
}

export type FilterBasicDataResolvedPayload = Filter[];

export enum FilterType {
  STRING = 'STRING',
  OBJECT = 'OBJECT',
  NUMBER = 'NUMBER',
}

export enum Types {
  // TODO: find better way for prefixes
  SET_GRID_HIDDEN_COLUMNS = 'SETTINGS/SET_GRID_HIDDEN_COLUMNS',
}

export type ViewColumn = {
  category: string;
  name: string;
  source: string;
  description: string;
  key: string;
  type: ColumnTypes;
  primary: boolean;
  width?: number;
  available: boolean;
  rounding: number | null;
};

export enum ColumnTypes {
  String = 'STRING',
  Number = 'NUMBER',
  NonemptyNumber = 'NONEMPTY_NUMBER',
  Price = 'PRICE',
  Percentage = 'PERCENTAGE',
  Link = 'LINK',
  Boolean = 'BOOLEAN',
  Sku = 'SKU',
  SkuLink = 'SKU_LINK',
  Channel = 'CHANNEL',
  Delivery = 'DELIVERY',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Budget = 'BUDGET',
  CampaignBudget = 'CAMPAIGN_BUDGET',
  Creative = 'CREATIVE',
  Platform = 'PLATFORM',
  Platforms = 'PLATFORM_ARRAY',
  OfferLink = 'OFFER_LINK',
  ChannelLink = 'CHANNEL_LINK',
  SalesforceLink = 'SALESFORCE_LINK',
  Country = 'COUNTRY',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  CampaignCountry = 'CAMPAIGN_COUNTRY',
  AdjustLink = 'ADJUST_LINK',
  Duration = 'DURATION',
  CampaignList = 'CAMPAIGN_LIST',
  AdList = 'AD_LIST',
  ChannelList = 'CHANNEL_LIST',
}

export type ViewColumns = {
  [key in Entities]?: Record<string, ViewColumn>;
};

export interface EntityConfig {
  name: Entities;
  type: ColumnTypes;
  primary: boolean;
  width: number;
  available: boolean;
  rounding: number | null;
}

export type ViewConfigurationResponseData = (ViewColumn & {
  entity: EntityConfig[];
})[];

export type ViewConfigurationResolvedPayload = ViewColumns;

export type DataStatusResolvedPayload = Feed;

export interface BreakdownOption {
  label: string;
  key: string;
}

export interface Breakdown {
  label: string;
  options: BreakdownOption[];
}

export type BreakdownResolvedPayload = {
  [key in Entities]: Breakdown[];
};
