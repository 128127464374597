import { memo, FunctionComponent } from 'react';

import Stack from '@mui/material/Stack';

import { Props } from './types';
import { STATUS_ICONS, STATUS_DESCRIPTION, DISABLED_STATUS_DESCRIPTION, TITLE_BY_TYPE } from './constants';
import { Wrapper, IconWrapper, StyledTooltip } from './styles';


const DashboardDeliveryStatus: FunctionComponent<Props> = memo(({ value, status, type }) => {
  if (!value) {
    return null;
  }

  const Icon = STATUS_ICONS[value];

  const title = TITLE_BY_TYPE[type];
  const getDescription = STATUS_DESCRIPTION[value];
  const getDisabledDescription = DISABLED_STATUS_DESCRIPTION[value];
  const description = getDescription(title);
  const disabledDescription = getDisabledDescription(title);
  const descriptionValue = status ? description : (disabledDescription || description);

  return (
    <StyledTooltip
      arrow
      placement="top"
      title={descriptionValue || ''}
      $isVisible={Boolean(descriptionValue)}
    >
      <Wrapper
        as={Stack}
        direction="row"
        alignItems="center"
        $status={status}
        $value={value}
      >
        <IconWrapper>
          {Icon && (
            <Icon />
          )}
        </IconWrapper>

        {value?.toLocaleLowerCase()}
      </Wrapper>
    </StyledTooltip>
  );
});


DashboardDeliveryStatus.displayName = 'DashboardDeliveryStatus';


export default DashboardDeliveryStatus;
