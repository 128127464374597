import React, { memo, useState, useEffect, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ReactComponent as DefaultIcon } from 'assets/icons/feeds/default-icon.svg';

import { Status } from 'core/settings/types';

import { ICONS, LABELS, HEADER_ICON, HEADER_LABEL } from './constants';
import { getTimeDiffString, getTimeDiffLabel } from '../utils';
import {
  Wrapper,
  TitleWrapper,
  Title,
  IconWrapper,
  ValueWrapper,
  Separator,
  NextRunLabel,
  LastRunLabel,
  InfoIcon,
  LastRunWrapper,
} from './styles';
import { Props } from './types';


const DataStatusRow: React.FunctionComponent<Props> = memo(({ isHeader = false, id, status, lastRun, nextRun }) => {
  const label = isHeader ? HEADER_LABEL : (LABELS[id as keyof typeof LABELS] || id);
  const IconComponent = isHeader ? HEADER_ICON : (ICONS[id as keyof typeof LABELS] || DefaultIcon);

  const [countdowns, setCountdowns] = useState(() => isHeader ? {} : ({
    lastRun: getTimeDiffString(lastRun, true),
    nextRun: getTimeDiffString(nextRun),
  }));

  const lastRunLabel = useMemo(() => {
    if (isHeader) {
      return 'Last update';
    }

    return getTimeDiffLabel(countdowns.lastRun, '', ' ago');
  }, [countdowns.lastRun, isHeader]);

  const nextRunLabel = useMemo(() => {
    if (isHeader) {
      return 'Next sync in';
    }

    if ((status?.status === Status.RUNNING) || (status?.status === Status.WAITING)) {
      return 'Running';
    }

    return getTimeDiffLabel(countdowns.nextRun);
  }, [countdowns.nextRun, isHeader, status?.status]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdowns({
        lastRun: getTimeDiffString(lastRun, true),
        nextRun: getTimeDiffString(nextRun),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [lastRun, nextRun]);

  return (
    <Wrapper>
      <TitleWrapper>
        {
          IconComponent && (
            <IconWrapper>
              <IconComponent />
            </IconWrapper>
          )
        }

        <Title
          as={Typography}
          variant="body1"
        >
          {label}
        </Title>
      </TitleWrapper>

      <ValueWrapper>
        <Tooltip
          title={status?.message || ''}
          placement="top"
          arrow
        >
          <LastRunWrapper
            $withMessage={!!status?.message}
          >
            {
              status?.message && (
                <InfoIcon
                  as={InfoOutlinedIcon}
                />
              )
            }
            <LastRunLabel
              as={Typography}
              variant={isHeader ? 'body1' : 'body2'}
              $status={status?.status}
              $isHeader={isHeader}
            >
              {lastRunLabel}
            </LastRunLabel>
          </LastRunWrapper>

        </Tooltip>


        <Separator />

        <NextRunLabel
          as={Typography}
          variant="body1"
          $isHeader={isHeader}
          $status={status?.status}
        >
          {nextRunLabel}
        </NextRunLabel>
      </ValueWrapper>
    </Wrapper>
  );
});


DataStatusRow.displayName = 'DataStatusRow';


export default DataStatusRow;
