import LionstudiosApi from 'services/lionstudios-api';

import { BASE_URL, FILTERS_URL, VIEWS_URL } from './constants';
import {
  UserResponse,
  CreateUserFilterPayload,
  FetchUserPayload,
  FetchSavedUserFiltersResolvedPayload,
  DeleteUserFilterPayload,
  DeleteUserFilterResolvedPayload,
  UpdateUserFilterPayload,
  UpdateUserFilterResolvedPayload,
  FetchSavedUserViewsResolvedPayload,
  CreateUserViewPayload,
  DeleteUserViewPayload,
  DeleteUserViewResolvedPayload,
  UpdateUserViewPayload,
  UpdateUserViewResolvedPayload,
  BackendSavedView,
} from './types';


const UsersService = {
  fetchAuthorizedUser: async () => (await LionstudiosApi.get<UserResponse>(`${BASE_URL}/me`)).data,

  createUserFilter: async ({ userId, data }: CreateUserFilterPayload) => (await LionstudiosApi.post<null>(`${BASE_URL}/${userId}/${FILTERS_URL}`, data)).data,

  fetchUserFilters: async ({ userId }: FetchUserPayload) => (await LionstudiosApi.get<FetchSavedUserFiltersResolvedPayload>(`${BASE_URL}/${userId}/${FILTERS_URL}`)).data,

  deleteUserFilter: async ({ userId, filterId }: DeleteUserFilterPayload): Promise<DeleteUserFilterResolvedPayload> => {
    await LionstudiosApi.delete<null>(`${BASE_URL}/${userId}/${FILTERS_URL}/${filterId}`);

    return { filterId };
  },

  updateUserFilter: async ({ userId, filterId, data }: UpdateUserFilterPayload): Promise<UpdateUserFilterResolvedPayload> => {
    await LionstudiosApi.post<null>(`${BASE_URL}/${userId}/${FILTERS_URL}/${filterId}`, data);

    return { filterId };
  },

  fetchUserViews: async ({ userId }: FetchUserPayload) => (await LionstudiosApi.get<FetchSavedUserViewsResolvedPayload>(`${BASE_URL}/${userId}/${VIEWS_URL}`)).data,

  createUserView: async ({ userId, data }: CreateUserViewPayload) => (await LionstudiosApi.put<BackendSavedView>(`${BASE_URL}/${userId}/${VIEWS_URL}`, data)).data,

  deleteUserView: async (payload: DeleteUserViewPayload): Promise<DeleteUserViewResolvedPayload> => {
    const { userId, viewId } = payload;
    await LionstudiosApi.delete<null>(`${BASE_URL}/${userId}/${VIEWS_URL}/${viewId}`);

    return payload;
  },

  updateUserView: async (payload: UpdateUserViewPayload): Promise<UpdateUserViewResolvedPayload> => {
    const { userId, viewId, data } = payload;
    await LionstudiosApi.post<null>(`${BASE_URL}/${userId}/${VIEWS_URL}/${viewId}`, data);

    return payload;
  },
};


export default UsersService;
