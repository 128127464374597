import React, { memo } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import { 
  Wrapper,
  StyledIconButton,
} from './styles';


const UnselectIconButton: React.FunctionComponent<IconButtonProps> = memo((props) => (
  <Wrapper>
    <StyledIconButton
      as={IconButton}
      size="small"
      {...props}
    >
      <CloseOutlinedIcon
        fontSize="inherit"
      />
    </StyledIconButton>
  </Wrapper>
));


UnselectIconButton.displayName = 'UnselectIconButton';


export default UnselectIconButton;
