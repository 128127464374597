import { Components } from '@mui/material/styles';


const overrides: Components['MuiAutocomplete'] = {
  styleOverrides: {
    option: {
      height: 48,
    },
    loading: {
      color: '#2D2D2D',
      height: 40,
      paddingTop: 0,
      paddingBottom: 0,
      lineHeight: '40px',
    },
  },
};


export default overrides;
