export const welcomeStyles = [
  'font-family: Inter, Helvetica Neue,sans-serif',
  'font-size: 24px',
  'text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)',
  'color: #red',
  'padding: 10px',
  'border-radius: 8px',
].join(';');


export const versionsStyles = [
  'font-family: "Courier New", Courier, monospace',
  'font-size: 18px',
  'background-color: #0D0208',
  'color: #00FF41',
  'padding: 10px',
  'font-weight: bold',
].join(';');
