import { takeEvery, take, fork, cancel, select, put } from 'redux-saga/effects';
import { Task } from 'redux-saga';

import { resolved } from 'utils/redux';
import { Action } from 'types/redux';

import { RESPONSE_STATUSES } from 'constants/http';

import authActions from 'core/auth/actions';
import { Types as AuthTypes } from 'core/auth/types';
import { getIsTokenValid } from 'core/auth/selectors';

import { Types as AppTypes, HttpErrorPayload } from './types';

/*
 * Sagas
 */

function* commonErrorHandler () {
  // TODO: handle somehow
}

function* httpErrorHandler ({ payload: { response } }: Action<AppTypes, HttpErrorPayload> ) {
  if (response && (response.status === RESPONSE_STATUSES.UNAUTHORIZED)) {
    yield put(authActions.logout());
  }
}

export function* subscribeSagaToUserSession (saga: GeneratorFunction, ...params: any[]) {
  while (true) {
    yield take(AuthTypes.AUTH_SUCCESS);

    const task: Task = yield fork<GeneratorFunction>(saga, ...params);

    yield take(resolved<AuthTypes>(AuthTypes.LOGOUT));

    yield cancel(task);
  }
}

export function* waitForUserSession () {
  const isTokenValid: ReturnType<typeof getIsTokenValid> = yield select(getIsTokenValid);

  if (!isTokenValid) {
    yield take(AuthTypes.AUTH_SUCCESS);
  }
}

/*
 * Watchers
 */


function* commonErrorHandlerWatcher () {
  yield takeEvery(AppTypes.HANDLE_ERROR, commonErrorHandler);
}

function* httpErrorHandlerWatcher () {
  yield takeEvery(AppTypes.HANDLE_HTTP_ERROR, httpErrorHandler);
}


export default [
  commonErrorHandlerWatcher,
  httpErrorHandlerWatcher,
];
