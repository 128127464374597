import { Components, Theme } from '@mui/material/styles';

import { switchClasses } from '@mui/material/Switch';



const overrides: Components['MuiSwitch'] = {
  styleOverrides: {
    root: (props) => {
      const localTheme = props.theme as Theme;

      return {
        width: 28,
        height: 16,
        padding: 0,

        [`& .${switchClasses.switchBase}`]: {
          padding: 0,
          margin: 1,

          [`&.${switchClasses.checked}`]: {
            transform: 'translateX(12px)',
            color: '#fff',

            [`& + .${switchClasses.track}`]: {
              opacity: 1,
              border: 0,
            },

            [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
              opacity: 0.5,
            },
          },

          [`&.${switchClasses.disabled} .${switchClasses.thumb}`]: {
            color: localTheme.palette.grey[100],
          },

          [`&.${switchClasses.disabled} + .${switchClasses.track}`]: {
            opacity: 0.7,
          },
        },
        [`& .${switchClasses.thumb}`]: {
          boxSizing: 'border-box',
          width: 14,
          height: 14,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
        },

        [`& .${switchClasses.track}`]: {
          borderRadius: 28 / 2,
          backgroundColor: localTheme.palette.grey[300],
          opacity: 1,
        },
      };
    },
  },
};


export default overrides;
