import React, { memo, useState, useCallback, useEffect } from 'react';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import TreeViewParentItem from './parent-item';
import TreeViewChildrenItem from './children-item';

import { StyledIcon } from './styles';
import { Props } from './types';


const TreeViewList: React.FunctionComponent<Props> = memo(({ selected, list, parentLabelKey, childrenLabelKey, childrenKey, childrenNodeKey, parentNodeKey, onSelect, expandAll }) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleSelect = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    const [nodeId] = nodeIds;
    if (!nodeId) {
      return;
    }

    const indexOfSelected = selected.indexOf(nodeId);
    const nextSelected = selected.slice();

    if (indexOfSelected !== -1) {
      nextSelected.splice(indexOfSelected, 1);
    } else {
      nextSelected.push(nodeId);
    }

    onSelect(nextSelected);
  }, [selected, onSelect]);

  const handleExpand = useCallback((event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  }, []);

  const CustomChildrenTreeItem = useCallback((props: any) => (
    <TreeItem
      ContentComponent={TreeViewChildrenItem}
      {...props}
    />
  ), []);

  const CustomParentTreeItem = useCallback((props: any) => (
    <TreeItem
      ContentComponent={TreeViewParentItem}
      {...props}
    />
  ), []);

  useEffect(() => {
    setExpanded(expandAll ? list.map((item) => item[parentNodeKey]) : []);
  }, [expandAll, list, parentNodeKey]);

  return (
    <TreeView
      defaultCollapseIcon={<StyledIcon as={ExpandMoreIcon} />}
      defaultExpandIcon={<StyledIcon as={ChevronRightIcon} />}
      multiSelect
      onNodeSelect={handleSelect}
      onNodeToggle={handleExpand}
      selected={selected}
      expanded={expanded}
    >
      {
        list.map((item, index) => (
          <CustomParentTreeItem
            key={index}
            nodeId={item[parentNodeKey]}
            label={item[parentLabelKey]}
          >
            {
              item[childrenKey].map((childrenItem: any) => (
                <CustomChildrenTreeItem
                  key={childrenItem[childrenNodeKey]}
                  nodeId={childrenItem[childrenNodeKey]}
                  label={childrenItem[childrenLabelKey]}
                />
              ))
            }
          </CustomParentTreeItem>
        ))
      }
    </TreeView>
  );
});


TreeViewList.displayName = 'TreeViewList';


export default TreeViewList;
