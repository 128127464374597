import { styled } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';


export const StyledInput = styled('div')(({ theme }) => ({
  // marginBottom: theme.spacing(1.25),

  [`.${inputBaseClasses.root}`]: {
    height: 34,
    backgroundColor: theme.palette.grey[50],

    ...theme.typography.body1,
    color: theme.palette.grey[500],

    [`.${inputBaseClasses.input}::placeholder`]: {
      opacity: 1,
      color: 'inherit',
    },
  },

  [`.${outlinedInputClasses.notchedOutline}`]: {
    display: 'none',
  },
}));

export const StyledInputAdornment = styled('div')(({ theme }) => ({
  '& svg': {
    fontSize: '22px',
    color: theme.palette.grey[400],
  },
}));
