import { PersistedState } from 'redux-persist';

// eslint-disable-next-line @typescript-eslint/ban-types
export type Payload = Object | null | undefined;

export type Meta = DefaultMeta | null | undefined;

export type RejectedPayload = { message?: string };

export type PayloadCreator<P> = (...args: P[]) => Payload;

export type PayloadHandler<P> = Array<PayloadCreator<P>> | PayloadCreator<P>;

export type PayloadHandlers<T extends string, P> = Record<T, PayloadHandler<P>>;

// eslint-disable-next-line @typescript-eslint/ban-types
export type DefaultMeta <T = {}> = T & {
  useFetching?: boolean;
};

export interface Action<
  T extends string = string,
  P extends Payload = Payload,
  M extends Meta = Meta,
> {
  readonly type: T;
  readonly payload: P;
  readonly meta?: M;
}

export type ResolvedAction<
  T extends string = string,
  P extends Payload = Payload,
  M extends Meta = Meta,
> = Action<Resolved<T>, P, M>;

export type RejectedAction<
  T extends string = string,
  P extends RejectedPayload = RejectedPayload,
  M extends Meta = Meta,
> = Action<Rejected<T>, P, M>;

export type FetchingAction<
  T extends string = string,
  P extends Payload = Payload,
  M extends Meta = Meta,
> = Action<Fetching<T>, P, M>;

export type AsyncActions<
  T extends string = string,
  P extends Payload = Payload,
  ResP extends Payload = Payload,
  RejP extends RejectedPayload = RejectedPayload,
  M extends Meta = Meta,
  ResM extends Meta = Meta,
  RejM extends Meta = Meta,
> = Action<T, P, M> & ResolvedAction<T, ResP, ResM> & RejectedAction<T, RejP, RejM>;

export type ActionCreator<
  T extends string,
  P extends Payload = Payload,
  M extends Meta = Meta,
> = (payload?: P | any, meta?: M | any) => Action<T, Partial<P>, M>; // TODO: improve 'any' type

export type FetchingActionCreator<
  T extends string,
  P extends Payload = Payload,
  M extends Meta = Meta,
> = (payload?: P, meta?: M) => FetchingAction<T, Partial<P>, M>; // TODO: improve 'any' type

export type ActionCreators<
  T extends string,
  P extends Payload = Payload,
  M extends Meta = Meta,
> = Record<string, ActionCreator<T, P, M>>;

export type Reducer <State, Actions extends Action> = {
  (state: State, action: Actions): State;
  key?: string;
};

export type ReducerMap<State, Types extends string, Actions extends Action> = {
  [key in Types]?: (state: State, action: Actions) => State;
};

export type PersistedReducer<State, Actions extends Action> = Reducer<State & PersistedState, Actions>;

export enum FetchingActionTypes {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  FETCHING = 'FETCHING_ACTION',
}

export type Resolved<Types extends string> = `${Types}_${FetchingActionTypes.SUCCESS}`;

export type Rejected<Types extends string> = `${Types}_${FetchingActionTypes.FAILED}`; // TODO: use / instead of _

export type Fetching<Types extends string> = `${FetchingActionTypes.FETCHING}/${Types}`;

export type Selector <State, Data> = (state: State) => Data;
