import { emit } from '@rsql/emitter';
import { ComparisonNode, ExpressionNode } from '@rsql/ast';

import { DATE_RANGE_FILTER_KEY, PUBLISH_MODE_FILTER_KEY } from 'core/dashboards/constants';
import { ColumnFilterModel, CurrentFilter, DateRangeFilter, PublishModes } from 'core/dashboards/types';
import { FilterType } from 'core/settings/types';

import { getUTCDateFromTimestamp } from 'utils/date';
import { isOperatorWithoutValue } from 'utils/grid';

import builder from './builder';
import CustomExpressions from './custom-expressions';
import { GRID_OPERATOR_TO_RSQL, GRID_LOGICAL_OPERATOR_TO_RSQL } from './constants';


class RsqlExpression {
  expressions: (ComparisonNode | ExpressionNode)[];

  filters: CurrentFilter[] | null;

  dateRangeFilter: DateRangeFilter | null;

  selected: Record<string, string[]> | null;

  columnFilter: ColumnFilterModel | null;

  publishMode: PublishModes | null;


  constructor (filters: CurrentFilter[] | null, dateRangeFilter: DateRangeFilter | null, selected: Record<string, string[]> | null, columnFilter: ColumnFilterModel | null, publishMode: PublishModes | null) {
    this.filters = filters || [];
    this.dateRangeFilter = dateRangeFilter;
    this.selected = selected;
    this.columnFilter = columnFilter;
    this.publishMode = publishMode;
    this.expressions = [];

    this.addFilterExpressions();
    this.addColumnFilterExpressions();

    this.addDateRangeFilterExpressions();
    this.addSelectedExpressions();
    this.addPublishMode();
  }

  addFilterExpressions () {
    if (this.filters === null || !this.filters.length) {
      return;
    }

    this.filters.filter(({ values }) => !!values.length).forEach(({ values, id, type }) => {
      if (CustomExpressions[id]) {
        this.expressions.push(CustomExpressions[id](values as any));
        return;
      }

      switch (type) {
        case FilterType.NUMBER:
          this.addNumberFilterExpressions(values, id);
          break;
        default:
          this.addStringFilterExpressions(values, id);
      }
    });
  }

  addStringFilterExpressions (values: CurrentFilter['values'], id: CurrentFilter['id']) {
    const included: string[] = [];
    const excluded: string[] = [];

    values.forEach((value) => {
      if (value.include) {
        included.push(String(value.id));
      } else {
        excluded.push(String(value.id));
      }
    });

    if (included.length) {
      this.expressions.push(builder.in(String(id), included));
    }

    if (excluded.length) {
      this.expressions.push(builder.out(String(id), excluded));
    }
  }

  addNumberFilterExpressions (values: CurrentFilter['values'], id: CurrentFilter['id']) {
    values.forEach(({ value, id: operatorValue }) => {
      if ((value === undefined || value === '') && !isOperatorWithoutValue(operatorValue)) {
        return null;
      }

      const operator = GRID_OPERATOR_TO_RSQL[operatorValue as keyof typeof GRID_OPERATOR_TO_RSQL] || null;
      if (!operator) {
        console.error('Wrong operator passed to RSQL builder', operatorValue);
        return null;
      }

      this.expressions.push(operator(String(id), String(value)));
    });

  }

  addDateRangeFilterExpressions () {
    if (this.dateRangeFilter === null) {
      return;
    }

    const { from, to } = this.dateRangeFilter;
    const dateExpressions = [];

    if (from) {
      const fromTimestamp = Math.round(getUTCDateFromTimestamp(from) as number / 1000) ;
      dateExpressions.push(builder.ge(DATE_RANGE_FILTER_KEY, fromTimestamp));
    }

    if (to) {
      const toTimestamp = Math.round(getUTCDateFromTimestamp(to, true) as number / 1000);
      dateExpressions.push(builder.le(DATE_RANGE_FILTER_KEY, toTimestamp));
    }

    this.expressions.push(builder.and(...dateExpressions));
  }

  addSelectedExpressions () {
    if (this.selected === null) {
      return;
    }

    Object.entries(this.selected).forEach(([key, values]) => {
      if (values.length) {
        // NOTE: trim is used to remove space at the end of id which was added to prevent sorting object keys with keys as a numbers
        this.expressions.push(builder.in(key, values.map((value) => value.trim())));
      }
    });
  }

  addColumnFilterExpressions () {
    if (this.columnFilter === null || !this.columnFilter.items.length) {
      return;
    }

    const columnFilterExpression = this.columnFilter.items.map(({ columnField, value, operatorValue }) => {
      if ((value === undefined || value === '') && !isOperatorWithoutValue(operatorValue)) {
        return null;
      }

      const operator = GRID_OPERATOR_TO_RSQL[operatorValue as keyof typeof GRID_OPERATOR_TO_RSQL] || null;
      if (!operator) {
        console.error('Wrong operator passed to RSQL builder', operatorValue);
        return null;
      }

      return operator(columnField, value);
    }).filter((item) => item !== null);

    if (columnFilterExpression.length) {
      const logicalOperator = GRID_LOGICAL_OPERATOR_TO_RSQL[this.columnFilter.linkOperator as keyof typeof GRID_LOGICAL_OPERATOR_TO_RSQL] || builder.and;
      this.expressions.push(logicalOperator(...columnFilterExpression as ComparisonNode[]));
    }
  }

  addPublishMode () {
    if (this.publishMode === null) {
      return;
    }

    const operator = builder.eq;
    let value;
    switch (this.publishMode) {
      case PublishModes.Published:
        value = 'PUBLISHED';
        break;
      case PublishModes.Unpublished:
      default:
        value = 'TEST';
    }

    this.expressions.push(operator(PUBLISH_MODE_FILTER_KEY, value));
  }

  toString () {
    if (this.expressions.length) {
      return emit(builder.and(...this.expressions));
    }

    return null;
  }
}


export default RsqlExpression;
