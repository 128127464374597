import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  height: 51,
  padding: theme.spacing(0.5, 2.5, 0.5, 1),
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.background.default}`,
}));

export const СheckboxSkeleton = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(1.125),
  marginRight: theme.spacing(1.125),
  borderRadius: 2,
}));

export const LabelWrapper = styled('p')(({ theme }) => ({
  width: 100,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));

export const TagWrapper = styled('p')(({ theme }) => ({
  width: 200,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(2),
}));
