import React, { memo, useCallback, MouseEvent } from 'react';

import Skeleton from '@mui/material/Skeleton';

import Dropdown from 'components/common/dropdown';
import { ButtonProps as DropdownButtonProps, DropdownChildAsFunctionProps } from 'components/common/dropdown/types';
import { ListItemProps } from 'components/common/list/types';
import DashboardSavedViews from 'components/dashboard/saved-views';
import ViewConfigButton from 'components/dashboard/grid-header/view-config-button'; // TODO: extract this button somewhere

import { Props } from './types';
import { Wrapper, DropdownStyles } from './styles';


const DashboardSavedViewsDropdown: React.FunctionComponent<Props> = memo(({ views, activeView, renderListFooter, buttonProps = {} }) => {
  const renderSavedViewsButton = useCallback((props: DropdownButtonProps, isOpen: boolean) => (
    <ViewConfigButton
      isActive={isOpen}
      isRounded={!activeView}
      {...props}
      {...buttonProps}
    >
      {
        activeView ? activeView.name : (
          <Skeleton
            variant="text"
            animation="pulse"
            width="70%"
          />
        )
      }
    </ViewConfigButton>
  ), [activeView, buttonProps]);

  const renderSavedViewsElement = useCallback((props: DropdownChildAsFunctionProps) => (
    <Wrapper>
      <DashboardSavedViews
        views={{
          ...views,
          items: views.items.map((item) => ({
            ...item,
            onClick: (event: MouseEvent<HTMLElement>) => {

              if (item.onClick) {
                item.onClick(event, props);
              }
            },
            ...(item.renderContent ? {
              renderContent: (data: ListItemProps) => (item.renderContent as (item: ListItemProps, ...args: any[]) => JSX.Element)(data, props),
            } : {}),
          })),
        }}
        // TODO: add skeleton
      />

      {renderListFooter ? renderListFooter(props) : null}
    </Wrapper>
  ), [views, renderListFooter]);

  return (
    <Dropdown
      renderButton={renderSavedViewsButton}
      sx={DropdownStyles}
    >
      {renderSavedViewsElement}
    </Dropdown>
  );
});


DashboardSavedViewsDropdown.displayName = 'DashboardSavedViewsDropdown';


export default DashboardSavedViewsDropdown;
