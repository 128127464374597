import { GridFilterModel } from '@mui/x-data-grid-premium';

import { Action, ActionCreators } from 'types/redux';

import { Filter, BreakdownOption } from 'core/settings/types'; // eslint-disable-line import/no-cycle


export enum Types {
  // TODO: find better way for prefixes
  FETCH_DATA = 'DASHBOARDS/FETCH_DATA',
  SET_CURRENT_CONFIG = 'DASHBOARDS/SET_CURRENT_CONFIG',
  SET_FILTERS = 'DASHBOARDS/SET_FILTERS',
  SET_IS_FILTERS_EXPANDED = 'DASHBOARDS/SET_IS_FILTERS_EXPANDED',
  GET_CONFIG_FROM_QUERY = 'DASHBOARDS/GET_CONFIG_FROM_QUERY',
  SET_CONFIG_TO_QUERY = 'DASHBOARDS/SET_CONFIG_TO_QUERY',
  RESET_CONFIG = 'DASHBOARDS/RESET_CONFIG',
}

export interface Actions extends ActionCreators<Types, Payload | null, null> {
  fetchData: (payload: RequestPayload) => Action<Types, RequestPayload, null>;
  setCurrentConfig: (payload: ConfigPayload) => Action<Types, ConfigPayload, null>;
  getConfigFromQuery: () => Action<Types, null, null>;
  setConfigToQuery: (payload: ConfigPayload) => Action<Types, ConfigPayload, null>;
  resetConfig: () => Action<Types, null, null>;
  setFilters: (payload: FiltersPayload) => Action<Types, FiltersPayload, null>;
  setIsFiltersExpanded: (payload: IsFiltersExpandedPayload) => Action<Types, IsFiltersExpandedPayload, null>;
}

export enum Entities {
  Apps = 'app',
  Skus = 'sku',
  Offers = 'offer',
  Campaigns = 'campaign',
  AdSets = 'adset',
  Ads = 'ad',
  Creatives = 'creative',
  Geos = 'geo',
}

export enum CommonEntities {
  Measure = 'measure',
}

export interface DataNode {
  id: string;
  index: number;
  entity: Entities;
  isParent: boolean;

  [key: string]: string | number | boolean | null;
}

export interface Summary {
  measures: Record<string, number>;
  groups: Record<string, Record<string, number>>;
}

export interface BaseState {
  isFetching: boolean;
  data: DataNode[];
  total: number;
  error: string | null;
  summary: Summary | null;
}

export interface State {
  [Entities.Apps]: BaseState;
  [Entities.Skus]: BaseState;
  [Entities.Offers]: BaseState;
  [Entities.Campaigns]: BaseState;
  [Entities.AdSets]: BaseState;
  [Entities.Ads]: BaseState;
  [Entities.Creatives]: BaseState;
  [Entities.Geos]: BaseState;

  currentConfig: Config;
  selectedFiltersValues: Config['filter'];
  isFiltersExpanded: boolean;
  queryConfig: {
    isLoaded: boolean;
    isFetching: boolean;
    isSaving: boolean;
    isEmpty: boolean;
  };
}

export type PaginationValue = number;

export interface PaginationPayload {
  pageSize: Record<string, PaginationValue>;
  page: Record<string, PaginationValue>;
}

export interface RequestPaginationPayload {
  limit: number;
  offset: number;
}

export interface FilterPayload {
  filter: CurrentFilter[] | null;
  dateRangeFilter: DateRangeFilter;
}

export interface Sorting {
  field: string;
  sort: 'asc' | 'desc';
}

export type ColumnFilterModel = GridFilterModel;

export interface ColumnFilterPayload {
  columnFilter: Record<string, ColumnFilterModel>;
}

export type SortingValue = Sorting[] | never[];

export interface SortingPayload {
  sorting: Record<string, SortingValue>;
}

export interface RequestSortingPayload {
  sort: {
    field: string;
    order: 'asc' | 'desc';
  };
}

export interface SelectedPayload {
  selected: Record<string, string[]>;
}

export interface TabKeyPayload {
  tabKey: Entities;
}

export type GroupValue = string | null;

export interface GroupPayload {
  group: Record<string, GroupValue>;
}

export interface BreakdownPayload {
  breakdown: Record<string, BreakdownValue>;
}

export type BreakdownValue = BreakdownOption['key'] | null;

export interface PublishModePayload {
  publishMode: PublishModes;
}

export interface ForcedSelectionPayload {
  isForcedSelection: boolean;
}

export type RequestPayload = PaginationPayload & FilterPayload & SortingPayload & SelectedPayload & GroupPayload & ColumnFilterPayload & TabKeyPayload & PublishModePayload & BreakdownPayload & ForcedSelectionPayload;

export interface ConfigPayload {
  config: Config;
}

export interface FiltersPayload {
  filters: Config['filter'];
}

export interface IsFiltersExpandedPayload {
  isExpanded: boolean;
}

export type Payload = RequestPayload & ConfigPayload & FiltersPayload & IsFiltersExpandedPayload;

export type FetchDataResolvedPayload  = {
  total: number;
  list: Record<string, number | string | boolean>[];
  tabKey: Entities;
  summary: Record<string, number>;
};

export type FetchDataRejectedPayload  = {
  tabKey: Entities;
};

export type GetConfigFromUrlResolvedPayload = {
  data: Config | null;
};

export enum Platform {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  IOS_CN = 'IOS_CN',
}

export enum Channels {
  TIKTOK = 'TIKTOK',
  GOOGLEADS = 'GOOGLEADS',
  FACEBOOK = 'FACEBOOK',
  UNITY = 'UNITY',
  MINTEGRAL = 'MINTEGRAL',
  APPLOVIN = 'APPLOVIN',
  ADJOE = 'ADJOE',
}

export enum Statuses {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum AdSetStatuses {
  DELIVERY_OK = 'ADGROUP_STATUS_DELIVERY_OK',
  CAMPAIGN_DISABLE = 'ADGROUP_STATUS_CAMPAIGN_DISABLE',
  // TODO: add others
}

export enum AdSetBidStrategies {
  CUSTOM = 'BID_TYPE_CUSTOM',
  // TODO: add others
}

export enum AdSetBudgetModes {
  DAY = 'BUDGET_MODE_DAY',
  // TODO: add others
}

export interface BackEndResponse {
  total: number;
  data: Array<any>;
}

export enum PublishModes {
  Published  = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED',
}

export interface ViewConfig {
  group: GroupValue;
  pageSize: PaginationValue;
  sorting: SortingValue;
  columnFilter: ColumnFilterModel;
  breakdown: BreakdownValue;
}

type ValueByEntity <T> = {
  [key in Entities]: T;
};

export type ViewsConfig = ValueByEntity<{
  config: ViewConfig;
  fields: string[];
  pinnedColumns: string[];
  heatmap: HeatmapModel[];
}>;

export interface Config {
  dateRangeFilter: DateRangeFilter;
  dateRangeRelativeFilter?: DateRangeRelativeFilter;
  filter: CurrentFilter[];
  selected: SelectedPayload['selected'];
  page: PaginationPayload['page'];
  publishMode: PublishModes;
  views?: ViewsConfig;
}

export enum DateRangeRelativeFilter {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_7_DAYS_TODAY = 'LAST_7_DAYS_TODAY',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  LAST_MONTH = 'LAST_MONTH',
}

export interface DateRangeFilter {
  from: number;
  to: number;
}

export interface DateRangeFilterConverted {
  from: Date;
  to: Date;
}

export type CurrentFilter = Pick<Filter, 'id' | 'values' | 'type'>;

export enum DeliveryStatus {
  INACTIVE = 'INACTIVE',
  UNCLASSIFIED = 'UNCLASSIFIED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  WARNING = 'WARNING',
}

export interface HeatmapModel {
  field: string;
  color: string;
}
