import { all, put, call, take } from 'redux-saga/effects';

import { Types as AppTypes } from 'core/app/types';
import {
  dataStatusSlice,
  filterBasicDataSlice,
  groupingSlice,
  viewConfigurationSlice,
  breakdownSlice,
} from './slices';

/*
 * Sagas
 */

function* init () {
  yield all([
    put(filterBasicDataSlice.action()),
    put(viewConfigurationSlice.action()),
    put(groupingSlice.action()),
    put(dataStatusSlice.action()),
    put(breakdownSlice.action()),
  ]);
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(AppTypes.INIT);
  yield call(init);
}

export default [
  initWatcher,
];
