import React, { memo, forwardRef } from 'react';
import clsx from 'clsx';

import { useTreeItem } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import { StyledChildrenTreeItem } from './styles';
import { TreeViewChildrenItemProps } from './types';


const TreeViewChildrenItem: React.FunctionComponent<TreeViewChildrenItemProps> = memo(forwardRef((props, ref) => {
  const {
    classes,
    className,
    label,
    nodeId,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleSelection,
  } = useTreeItem(nodeId);

  return (
    <StyledChildrenTreeItem
      ref={ref as React.Ref<HTMLDivElement>}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.disabled]: disabled,
        [classes.focused]: focused,
      })}
      onClick={handleSelection}
    >
      <Checkbox
        checked={selected}
        size="small"
      />

      <Typography
        variant="body1"
        color={selected ? 'primary' : 'inherit'}
      >
        {label}
      </Typography>
    </StyledChildrenTreeItem>
  );
}));


TreeViewChildrenItem.displayName = 'TreeViewChildrenItem';


export default TreeViewChildrenItem;
