import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';


export const GridPanelRoot = styled(Popper, {
  name: 'MuiDataGrid',
  slot: 'Panel',
  overridesResolver: (props, styles) => styles.panel,
})(({ theme }) => ({
  zIndex: theme.zIndex.modal,
}));

export const GridPaperRoot = styled(Paper, {
  name: 'MuiDataGrid',
  slot: 'Paper',
  overridesResolver: (props, styles) => styles.paper,
})(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  minWidth: 575,
  maxHeight: 450,
  display: 'flex',
}));
