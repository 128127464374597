import { State } from 'core/types';
import { KEY } from './constants';


const getLocalState = (state: State) => state[KEY];

export const getUsers = (state: State) => getLocalState(state).fetchAll.data;
export const getUsersIsLoading = (state: State) => getLocalState(state).fetchAll.isLoading;
export const getUsersError = (state: State) => getLocalState(state).fetchAll.error;

export const getUser = (state: State) => getLocalState(state).fetchOne.data;
export const getUserIsLoading = (state: State) => getLocalState(state).fetchOne.isLoading;
export const getUserError = (state: State) => getLocalState(state).fetchOne.error;
