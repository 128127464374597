import { memo, FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';

import { Props } from './types';

import { StyledLink } from './styles';


const Link: FunctionComponent<Props> = memo(({ children, to = '', isExternal = false, element = NavLink, ...otherProps }) => (
  isExternal ? (
    <StyledLink
      href={to}
      target="_blank"
      {...otherProps}
    >
      {children}
    </StyledLink>
  ) : (
    <StyledLink
      to={to}
      as={element}
      {...otherProps}
    >
      {children}
    </StyledLink>
  )
));


Link.displayName = 'Link';


export default Link;
