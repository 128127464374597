import { styled } from '@mui/material/styles';


export const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  cursor: 'pointer',
  ...theme.typography.body1,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));
