export const CATEGORIES = [
  {
    name: 'By type',
    field: 'category',
  },
  {
    name: 'By source',
    field: 'source',
  },
];
