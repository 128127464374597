import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const InfoIcon = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  width: 16,
  marginRight: theme.spacing(0.625),
}));
