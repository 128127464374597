import { styled } from '@mui/material/styles';
import { menuItemClasses } from '@mui/material/MenuItem';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { svgIconClasses } from '@mui/material/SvgIcon';

import { StyledViewConfigButtonProps, EditViewButtonProps, StyledActionButtonProps } from './types';


export const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(1.75, 2),
  ...theme.typography.body1,
}));

export const ActionsWrapper = styled('div')(() => ({

}));

export const BulkActionsButton = styled('div')(() => ({
  justifyContent: 'space-around',
}));

export const StyledViewConfigButton = styled('div')<StyledViewConfigButtonProps>(({ theme, $isActive, $isRounded }) => ({
  backgroundColor: $isActive ? theme.palette.action.hover : theme.palette.grey[50],
  color: $isActive ? theme.palette.common.black : theme.palette.grey[500],
  minWidth: 200,
  width: 'auto',
  justifyContent: 'space-between',

  ...($isRounded ? {} : {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }),

  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },

  [`& svg.${svgIconClasses.root}`]: {
    fontSize: '22px',
  },
}));

export const StyledActionButton = styled('div')<StyledActionButtonProps>(({ theme, $sm }) => ({
  width: 'auto',
  minWidth: 0,
  marginLeft: theme.spacing(1),
  ...($sm ? {
    height: '30px',
  } : {}),
}));

export const ActionPopperInner = styled('div')(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const StyledList = styled('div')(() => ({
  width: '236px',
}));

export const ActionsMenu = styled(StyledList)(({ theme }) => ({
  [`& .${menuItemClasses.root}`]: {
    justifyContent: 'flex-start',

    '&:hover': {
      '& svg': {
        // TODO: extract to dashboard styles

        // color: theme.palette.primary.main,
      },
    },
  },
}));

export const ClearButton = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1.5),
  height: '30px', // TODO: extract to theme
}));

export const AppliedActionsWrapper = styled(ActionsWrapper)(() => ({
  flex: 1,
}));

export const AppliedActionWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(3),
  maxWidth: '200px',
}));

export const AppliedActionTextWrapper = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  color: theme.palette.grey[500],
}));

export const AppliedActionTextInner = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  color: theme.palette.common.black,
}));

export const AppliedActionText = styled('span')(({ theme }) => ({
  ...theme.typography.body2,

  maxWidth: '100%',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const AppliedActionIconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  cursor: 'pointer',

  '& svg': {
    fontSize: '16px',
  },
}));

export const AppliedActionTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`.${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.warning.main,
    textAlign: 'center',
  },

  [`.${tooltipClasses.arrow}`]: {
    color: theme.palette.warning.main,
  },
}));

export const AppliedActionDescriptionTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`.${tooltipClasses.tooltip}`]: {
    backgroundColor: '#676767',
    textAlign: 'center',
    ...theme.typography.body1,
  },

  [`.${tooltipClasses.arrow}`]: {
    color: '#676767',
  },
}));

export const AppliedActionResetButton = styled('div')(({ theme }) => ({
  padding: 0,
  color: theme.palette.grey[300],
  fontSize: '17px',
  marginLeft: theme.spacing(1),
}));

export const CreateViewButton = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1.25),
}));

export const SaveViewButton = styled('div')(({ theme }) => ({
  marginRight: '1px',
  borderColor: '#E4EDFF',
  backgroundColor: '#E4EDFF',
  minWidth: '60px',
  width: '60px',
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,

  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

export const EditViewButton = styled('div')<EditViewButtonProps>(({ theme, $isRounded }) => ({
  marginRight: '1px',
  minWidth: '38px',
  width: '38px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 0,
  cursor: 'pointer',
  borderColor: theme.palette.grey[50],
  backgroundColor: theme.palette.grey[50],

  ...($isRounded ? {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
  } : {}),

  '& svg': {
    color: theme.palette.primary.main,
    fontSize: '16px',
  },

  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,

    '& svg': {
      color: theme.palette.common.white,
    },
  },
}));

export const DeleteViewButton = styled(EditViewButton)(({ theme }) => ({
  '& svg': {
    color: theme.palette.grey[400],
    fontSize: '18px',
  },
}));

export const BreakdownOption = styled('div')(({ theme }) => ({
  width: '100%',
  margin: theme.spacing(-1, 0),
  padding: theme.spacing(1, 0),
}));
