import { Components as ComponentsType } from '@mui/material/styles';

import MuiAlertOverride from './alert';
import MuiAutocompleteOverride from './autocomplete';
import MuiBackdropOverride from './backdrop';
import MuiButtonOverride from './button';
import MuiCheckboxOverride from './checkbox';
import MuiDataGridOverride from './data-grid';
import MuiListOverride from './list';
import MuiPickerStaticWrapperOverride from './picker-static-wrapper';
import MuiPopoverOverride from './popover';
import MuiSwitchOverride from './switch';
import MuiTablePaginationOverride from './table-pagination';
import MuiTooltipOverride from './tooltip';
import MuiTypographyOverride from './typography';
import MuiToggleButtonOverride from './toggle-button';


const Components: ComponentsType = {
  MuiAlert: MuiAlertOverride,
  MuiAutocomplete: MuiAutocompleteOverride,
  MuiBackdrop: MuiBackdropOverride,
  MuiButton: MuiButtonOverride,
  MuiCheckbox: MuiCheckboxOverride,
  MuiDataGrid: MuiDataGridOverride,
  MuiList: MuiListOverride,
  MuiPickerStaticWrapper: MuiPickerStaticWrapperOverride,
  MuiPopover: MuiPopoverOverride,
  MuiSwitch: MuiSwitchOverride,
  MuiTablePagination: MuiTablePaginationOverride,
  MuiTooltip: MuiTooltipOverride,
  MuiTypography: MuiTypographyOverride,
  MuiToggleButton: MuiToggleButtonOverride,
};


export default Components;
