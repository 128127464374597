import { createActions } from 'utils/redux';
import { RequestParameters } from 'core/admin/types';

import { PREFIX } from './constants';
import { Actions, FetchOneActivityLogPayload, Payload, Types } from './types';



export const actions = createActions<Types, Payload, Actions>({
  [Types.FETCH_ALL]: (payload: RequestParameters) => payload,
  [Types.FETCH_ONE]: (payload: FetchOneActivityLogPayload) => payload,
  [Types.FETCH_ONE_RESET]: () => null,

}, {
  prefix: PREFIX,
});


export default actions;
