import { UserStatus } from 'core/users/types';
import LionstudiosApi from 'services/lionstudios-api';
import { CreateUserPayload, EditUserPayload, FetchOneUserPayload } from './types';


const AdminUsersService = {
  fetchAll: async () => (await LionstudiosApi.get('users')).data,
  fetchOne: async ({ userId }: FetchOneUserPayload) => (await LionstudiosApi.get(`users/${userId}`)).data,
  createUser: async (payload: CreateUserPayload) => {
    const { data } = payload;
    await LionstudiosApi.post<null>('users', data);
    return payload;
  },
  editUser: async (payload: EditUserPayload) => {
    const { userId, data } = payload;
    await LionstudiosApi.post<null>(`users/${userId}`, data);
    return payload;
  },
  updateStatus: async (payload: { userId: number; data: { status: UserStatus } }) => {
    const { userId, data } = payload;
    await LionstudiosApi.post<null>(`users/${userId}/status`, data);
    return payload;
  },
  resetPassword: async (payload: { userId: number }) => {
    const { userId } = payload;
    await LionstudiosApi.post<null>(`users/${userId}/reset-password`);
    return payload;
  },
  autocomplete: async (value: string) => (await LionstudiosApi.get<{ key: string; label: string }[]>('admin/autocomplete/user', { params: { value } })).data,
};

export default AdminUsersService;
