import { styled } from '@mui/material/styles';

import { ContentProps } from './types';


export const Wrapper = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

export const Content = styled('div')<ContentProps>(({ theme, $isAuthorized }) => ({
  padding: $isAuthorized ? theme.spacing(2, 3) : 0,
  backgroundColor: $isAuthorized ? theme.palette.background.default : theme.palette.common.white,
  flex: 1,
}));
