import React, { memo, useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { alpha, ClickAwayListener, MenuItem, MenuList, Popper, Skeleton } from '@mui/material';
import Fade, { FadeProps } from '@mui/material/Fade';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

import authActions from 'core/auth/actions';
import { authorizedUserSlice } from 'core/users/slices';
import theme from 'containers/theme-provider/theme';

import { ArrowIcon, ButtonFlex, PopperContainer } from './style';


const UserMenu: React.FunctionComponent = memo(() => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const user = useSelector(authorizedUserSlice.selectors.getData);
  const isLoadingUser = useSelector(authorizedUserSlice.selectors.getIsFetching);

  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleClickAway = useCallback(() => setIsOpen(false), []);

  const handleLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        ref={wrapperRef}
        style={{ marginLeft: '26px' }}
      >
        <ButtonFlex
          onClick={toggleIsOpen}
        >
          <AccountCircleOutlinedIcon
            fontSize='small'
            sx={{ color: '#fff', margin: '0 4px' }}
          />
          <div
            style={{ textAlign: 'center', fontSize: '14px', color: '#fff' }}
          >
            {
              (!user || isLoadingUser) ? (
                <>
                  <Skeleton
                    variant={'text'}
                    sx={{
                      backgroundColor: alpha(theme.palette.background.default, 0.4),
                      width: '100px',
                    }}
                  />
                  <Skeleton
                    variant={'text'}
                    sx={{
                      backgroundColor: alpha(theme.palette.background.default, 0.4),
                      width: '100px',
                    }}
                  />
                </>
              ) : (
                <>
                  <div style={{ fontWeight: 'bold' }}>{user?.name}</div>
                  <div>{user?.roleDisplay}</div>
                </>
              )
            }
          </div>
          <ArrowIcon
            as={ArrowDropDownIcon}
            $isOpen={isOpen}
          />
        </ButtonFlex>
        <Popper
          open={isOpen}
          anchorEl={wrapperRef.current}
          placement="bottom-end"
          transition
          disablePortal
        >
          {({ TransitionProps }: { TransitionProps: FadeProps }) => (
            <Fade
              {...TransitionProps}
              timeout={theme.transitions.duration.standard}
            >
              <PopperContainer>
                <MenuList
                  sx={{
                    padding: '8px 0',
                    '& .MuiMenuItem-root': {
                      padding: '6px 16px',
                      borderBottom: 0,

                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                        color: 'inherit',
                      },
                    },
                  }}
                >
                  <MenuItem
                    disabled={true}
                  >
                    {user?.email}
                  </MenuItem>
                  {/* pending
                  <MenuItem>User Activity</MenuItem>
                  */}
                  <MenuItem onClick={handleLogout} >Sign Out</MenuItem>
                </MenuList>
              </PopperContainer>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
});

UserMenu.displayName = 'UserMenu';

export default UserMenu;
