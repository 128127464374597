import { FunctionComponent, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, MenuItem, MenuList } from '@mui/material';

import AdminAppSettingService from 'core/admin/appsettings/service';
import { actions as adminAppSettingsAction } from 'core/admin/appsettings/actions';
import Dropdown from 'components/common/dropdown';
import { ButtonProps as DropdownButtonProps } from 'components/common/dropdown/types';
import ConfirmationModal from 'components/common/confirmation-modal';
import { ROUTES } from 'containers/router/constants';
import SnackbarService from 'services/snackbar';

import { Props } from './types';


const AppSettingsRowAction: FunctionComponent<Props> = memo(({ appSetting }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickEdit = useCallback(() => {
    navigate(ROUTES.APPSETTINGS_EDIT.replace(':appSettingId', appSetting.id.toString()));
  }, [appSetting, navigate]);

  const [isOpenDeleteConfirmationModal, setIsOpenDeleteConfirmationModal] = useState(false);
  const handleClickDelete = useCallback(() => {
    setIsOpenDeleteConfirmationModal(true);
  }, [setIsOpenDeleteConfirmationModal]);
  const handleCloseDeleteConfirmationModal = useCallback(() => {
    setIsOpenDeleteConfirmationModal(false);
  }, [setIsOpenDeleteConfirmationModal]);
  const handleSubmitDelete = useCallback(() => {
    AdminAppSettingService.delete({
      appSettingId: appSetting.id,
    }).then(() =>{
      SnackbarService.showSuccess(
        `App Setting ${appSetting.name} deleted.`,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
      dispatch(adminAppSettingsAction.fetchAll());
    }).catch((e: any) =>{
      const message = e.response?.data ?? (e as Error).message;
      SnackbarService.showError(
        message,
        {
          vertical: 'bottom',
          horizontal: 'left',
        },
      );
    });
    handleCloseDeleteConfirmationModal();
  }, [appSetting, handleCloseDeleteConfirmationModal, dispatch]);

  const renderButton = useCallback((props: DropdownButtonProps) => (
    <IconButton
      {...props}
    >
      <MenuIcon />
    </IconButton>
  ), []);

  return (
    <>
      <Dropdown
        renderButton={renderButton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx:{ padding: '4px 0' },
        }}
      >
        <MenuList
          sx={{
            padding: '0',
            '& .MuiMenuItem-root': {
              padding: '4px 12px',
              borderBottom: 0,

              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                color: 'inherit',
              },
            },
          }}
        >
          <MenuItem onClick={handleClickEdit} >Edit</MenuItem>
          <MenuItem onClick={handleClickDelete}>Delete</MenuItem>
        </MenuList>

      </Dropdown>
      <ConfirmationModal
        isOpen={isOpenDeleteConfirmationModal}
        onClose={handleCloseDeleteConfirmationModal}
        onSubmit={handleSubmitDelete}
        title={`Delete ${appSetting.name}`}
        confirmText="Confirm"
      />
    </>
  );
});

AppSettingsRowAction.displayName = 'AppSettingsRowAction';

export default AppSettingsRowAction;
