import React, { memo, useCallback, useRef, useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Popper from '@mui/material/Popper';
import Fade, { FadeProps } from '@mui/material/Fade';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { dataStatusSlice } from 'core/settings/slices';

import theme from 'containers/theme-provider/theme';

import Row from './row';
import Skeleton from './skeleton';

import { POPPER_OPTIONS } from './constants';
import { getUTCTimeString, getLastSuccessDate } from './utils';
import {
  Wrapper,
  Button,
  TitleWrapper,
  Title,
  Subtitle,
  PopperContainer,
  StatusIcon,
  ArrowIcon,
  SubtitleBasic,
} from './styles';


const DataStatus: React.FunctionComponent = memo(() => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const dataStatus = useSelector(dataStatusSlice.selectors.getData);
  const isFetching = useSelector(dataStatusSlice.selectors.getIsFetching);

  const [isOpen, setIsOpen] = useState(false);
  const [serverTime, setServerTime] = useState(() => getUTCTimeString());

  const lastSuccessDate = useMemo(() => getLastSuccessDate(dataStatus?.lastRun || null), [dataStatus]);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleClickAway = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    const serverTimeInterval = setInterval(() => {
      const nextServerTime = getUTCTimeString();
      setServerTime(nextServerTime);
    }, 1000);

    const fetchDataInterval = setInterval(() => {
      dispatch(dataStatusSlice.action());
    }, 60 * 1000);

    return () => {
      clearInterval(serverTimeInterval);
      clearInterval(fetchDataInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Wrapper
        ref={wrapperRef}
      >
        <Tooltip
          title={dataStatus?.status?.message || ''}
          placement="right"
          arrow
        >
          <Button
            onClick={toggleIsOpen}
          >
            {/*
            <StatusIcon
              as={AccessTimeFilledIcon}
              $status={dataStatus?.status?.status}
            />
            */}

            <TitleWrapper>
              <Title
                as={Typography}
                $status={dataStatus?.status?.status}
              >
                {serverTime}
              </Title>

              {
                lastSuccessDate && (
                  <Subtitle>
                    all data up to {lastSuccessDate}
                  </Subtitle>
                )
              }
            </TitleWrapper>

            <ArrowIcon
              as={ArrowDropDownIcon}
              $isOpen={isOpen}
              $isHidden={!dataStatus?.feeds && !isFetching}
            />

          </Button>
        </Tooltip>

        {
          (dataStatus?.feeds || isFetching) && (
            <Popper
              open={isOpen}
              anchorEl={wrapperRef.current}
              popperOptions={POPPER_OPTIONS}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps }: { TransitionProps: FadeProps }) => (
                <Fade
                  {...TransitionProps}
                  timeout={theme.transitions.duration.standard}
                >
                  <PopperContainer>
                    {/*
                      lastSuccessDate && (
                        <SubtitleBasic
                          sx={{ marginLeft: '40px' }}
                        >
                          all data up to {lastSuccessDate}
                        </SubtitleBasic>
                      )
                    */}
                    <Row
                      isHeader
                    />

                    {
                      isFetching && !dataStatus && (
                        <Skeleton />
                      )
                    }

                    {
                      dataStatus?.feeds?.map((feed) => (
                        <Row
                          key={feed.id}
                          {...feed}
                        />
                      )) || null
                    }
                  </PopperContainer>
                </Fade>
              )}
            </Popper>
          )
        }

      </Wrapper>
    </ClickAwayListener>
  );
});


DataStatus.displayName = 'DataStatus';


export default DataStatus;
