import { AutocompleteChangeReason } from '@mui/material/Autocomplete';


export const CHANGE_REASONS: Record<string, AutocompleteChangeReason> = {
  CREATE: 'createOption',
  SELECT: 'selectOption',
  REMOVE: 'removeOption',
};

export const NUMBER_OPTIONS = [ // TODO: add operators from rsql?
  {
    name: '< less',
    id: '<',
    label: '<',
  },
  {
    name: '= equal',
    id: '=',
    label: '=',
  },
  {
    name: '> greater',
    id: '>',
    label: '>',
  },
  {
    name: '≤ less or equal',
    id: '<=',
    label: '≤',
  },
  {
    name: '≠ not equal',
    id: '!=',
    label: '≠',
  },
  {
    name: '≥ greater or equal',
    id: '>=',
    label: '≥',
  },
];
