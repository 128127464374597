import React, { memo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { Props } from './types';
import { Wrapper, StyledSkeleton } from './styles';


const DataStatusSkeleton: React.FunctionComponent<Props> = memo(({ size = 5 }) => (
  <>
    {
      Array(size).fill(null).map((_item, index) => (
        <Wrapper
          key={index}
        >
          <Typography
            variant="body1"
            width="35%"
          >
            <StyledSkeleton
              as={Skeleton}
              variant="text"
              animation="pulse"
              width="100%"
            />
          </Typography>

          <Typography
            variant="body1"
            width="55%"
          >
            <StyledSkeleton
              as={Skeleton}
              variant="text"
              animation="pulse"
              width="100%"
            />
          </Typography>
        </Wrapper>
      ))
    }
  </>
));


DataStatusSkeleton.displayName = 'DataStatusSkeleton';


export default DataStatusSkeleton;
