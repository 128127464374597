import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(() => ({
  width: '236px',
}));

export const DropdownStyles = {
  mt: 1,
};
