import RsqlExpression from 'services/rsql';
import { TypedObject, UnknownObject } from 'types';

import { withPrefix, groupArrayByKey } from 'utils';

import {
  PaginationPayload,
  FilterPayload,
  SortingPayload,
  RequestSortingPayload,
  DeliveryStatus,
  BackEndResponse,
  SelectedPayload,
  ColumnFilterPayload,
  RequestPayload,
  GroupPayload,
  TabKeyPayload,
  RequestPaginationPayload,
  PublishModePayload,
  ForcedSelectionPayload,
  DataNode,
  Entities,
} from './types';
import {
  PARENT_ENTITIES,
  CHILDREN_ENTITIES,
  BACK_END_KEYS,
  SKIP_SORTING_BREAKDOWN_KEYS,
} from './constants';
import { getSafeId } from './utils';


export const idModel = (data: Record<string, any>, tabKey: Entities) => getSafeId(data[withPrefix(tabKey, BACK_END_KEYS.ID)]);
export const breakdownIdModel = (data: Record<string, any>, tabKey: Entities, breakdownKey: string) => `${idModel(data, tabKey)}-${data[breakdownKey]}`;

export const getDeliveryStatus = (value: DeliveryStatus | null | '' ): DeliveryStatus => {
  if (!value) {
    return DeliveryStatus.UNCLASSIFIED;
  }

  return DeliveryStatus[value];
};

export const dataModel = (data: Record<string, any>, index: number, tabKey: Entities, isParent: boolean): DataNode => ({
  index,
  id: idModel(data, tabKey),
  entity: tabKey,
  isParent,

  ...data,
  [BACK_END_KEYS.DELIVERY_STATUS]: getDeliveryStatus(data[withPrefix(tabKey, BACK_END_KEYS.DELIVERY_STATUS)]),
  [BACK_END_KEYS.BUDGET]: data[withPrefix(tabKey, BACK_END_KEYS.BUDGET)] ? Number(data[withPrefix(tabKey, BACK_END_KEYS.BUDGET)]) : null,
});

export const dataResponseModel = (
  { data }: BackEndResponse,
  { data: { total, groups, measures } }: any,
  tabKey: Entities,
  { data: breakdownData }: { data: BackEndResponse['data'] },
  breakdownKey: string | null,
): any => {
  const idKey = withPrefix(tabKey, BACK_END_KEYS.ID);
  const grouppedData = breakdownData ? groupArrayByKey<any>(breakdownData, idKey) : null;

  const list: DataNode[] = [];
  data.forEach((item, index) => {
    list.push(dataModel(item, index, tabKey, true));

    grouppedData?.[item[idKey] as keyof typeof grouppedData]?.sort((itemA: any, itemB: any) => {
      if (SKIP_SORTING_BREAKDOWN_KEYS.includes(breakdownKey as string)) {
        return 0;
      }

      const breakdownValueA = itemA[breakdownKey as string];
      const breakdownValueB = itemB[breakdownKey as string];

      return String(breakdownValueB).localeCompare(String(breakdownValueA));
    }).forEach((breakdownItem: any) => {
      list.push({
        ...dataModel(breakdownItem, index, tabKey, false),
        id: breakdownIdModel(breakdownItem, tabKey, breakdownKey as string),
      });
    });
  });

  return {
    total,
    summary: {
      groups,
      measures,
    },
    list,
  };
};

export const paginationRequestModel = ({ pageSize, page, tabKey }: PaginationPayload & TabKeyPayload): RequestPaginationPayload => ({
  limit: pageSize[tabKey],
  offset: pageSize[tabKey] * page[tabKey],
});

export const selectedModel = (selected: SelectedPayload['selected'], tabKey: Entities, isForcedSelection?: boolean) => {
  if (isForcedSelection) {
    const selection = CHILDREN_ENTITIES[tabKey].reduce((result, parentKey) => {
      result[withPrefix(parentKey, BACK_END_KEYS.ID)] = selected[parentKey];
      return result;
    }, {} as TypedObject<string[]>);

    return {
      ...selection,
      [withPrefix(tabKey, BACK_END_KEYS.ID)]: selected[tabKey],
    };
  }
  
  return PARENT_ENTITIES[tabKey].reduce((result, parentKey) => {
    result[withPrefix(parentKey, BACK_END_KEYS.ID)] = selected[parentKey];
    return result;
  }, {} as TypedObject<string[]>);
};

export const filterRequestModel = ({ filter, dateRangeFilter, selected, tabKey, columnFilter, publishMode, isForcedSelection }: FilterPayload & SelectedPayload & ColumnFilterPayload & PublishModePayload & TabKeyPayload & ForcedSelectionPayload): any => {
  const filteredSelected = selectedModel(selected, tabKey, isForcedSelection);

  return ({
    filter: new RsqlExpression(filter, dateRangeFilter, filteredSelected, columnFilter[tabKey], publishMode).toString(),
  });
};

export const sortingRequestModel = ({ sorting, tabKey }: SortingPayload & TabKeyPayload): RequestSortingPayload | UnknownObject => {
  if (!sorting[tabKey]?.length) {
    return {};
  }

  return {
    sort: sorting[tabKey].map(({ sort, ...item }) => ({
      ...item,
      order: sort.toUpperCase(),
    })),
  };
};

export const groupingRequestModel = ({ group, tabKey }: GroupPayload & TabKeyPayload): GroupPayload | UnknownObject => {
  if (!group[tabKey]) {
    return {};
  }

  return {
    group: group[tabKey],
  };
};

export const breakdownRequestModel = (response: any, { filter, dateRangeFilter, sorting, tabKey, columnFilter, publishMode }: RequestPayload, breakdown: string) => {
  const filteredSelected = {
    [withPrefix(tabKey, BACK_END_KEYS.ID)]: response.data.map((item: any) => idModel(item, tabKey)),
  };

  return ({
    breakdown,
    filter: new RsqlExpression(filter, dateRangeFilter, filteredSelected, columnFilter[tabKey], publishMode).toString(),
    ...sortingRequestModel({ sorting, tabKey }),
  });
};

export const requestModel = ({ pageSize, page, filter, dateRangeFilter, sorting, selected, tabKey, group, columnFilter, publishMode, isForcedSelection }: RequestPayload): any => ({
  ...paginationRequestModel({ pageSize, page, tabKey }),
  ...filterRequestModel({ filter, dateRangeFilter, selected, columnFilter, publishMode, tabKey, isForcedSelection }),
  ...sortingRequestModel({ sorting, tabKey }),
  ...groupingRequestModel({ group, tabKey }),
});
