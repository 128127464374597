import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation';
import { alpha, Theme } from '@mui/material/styles';
import { typographyClasses } from '@mui/material/Typography';


const overrides: PickerComponents['MuiPickerStaticWrapper'] = {
  styleOverrides: {
    root: (props) => {
      const localTheme = props.theme as Theme;

      return {
        backgroundColor: localTheme.palette.grey[50],
        borderRadius: localTheme.shape.borderRadius,

        [`& .${typographyClasses.caption}`]: { // TODO: wait until https://github.com/mui/mui-x/issues/4552 implemented
          color: alpha(localTheme.palette.common.black, 0.5),
        },

        '& > div > div > div:not(:last-of-type)': { // NOTE: one more amazing lifehack to customize that shit
          borderWidth: 3,
          borderColor: localTheme.palette.common.white,
        },

        '.PrivatePickersSlideTransition-root': {
          minHeight: 264,
        },

        [`& span.${typographyClasses.subtitle1}`]: {
          ...localTheme.typography.body2,
        },
      };
    },
  },
};


export default overrides;
