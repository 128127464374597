import { styled } from '@mui/material/styles';

import { treeItemClasses } from '@mui/lab/TreeItem';


export const StyledIcon = styled('div')(({ theme }) => ({
  width: 18,
  height: 18,
  color: theme.palette.grey[500],
}));

export const StyledParentTreeItem = styled('div')(({ theme }) => ({
  [`&.${treeItemClasses.content}`]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    height: 38,

    '&:hover, &.Mui-selected, &.Mui-focused': {
      backgroundColor: 'inherit',
    },
  },

  [`& + .${treeItemClasses.group}`]: {
    margin: 0,
  },
}));

export const StyledChildrenTreeItem = styled('div')(({ theme }) => ({
  [`&.${treeItemClasses.content}`]: {
    paddingLeft: theme.spacing(3.375),
    paddingRight: theme.spacing(2.5),
    height: 34,
    transition: theme.transitions.create(['background-color']),

    '&.Mui-focused': {
      backgroundColor: 'inherit',
    },

    '&:hover, &.Mui-selected, &.Mui-selected': {
      backgroundColor: theme.palette.action.hover,
    },
  },

  [`.${treeItemClasses.label}`]: {
    display: 'flex',
    alignItems: 'center',
  },
}));
