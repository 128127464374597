import React from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import theme from './theme';


const ThemeProvider: React.FunctionComponent = ({ children = null }) => (
  <MuiThemeProvider theme={theme}>
    {children}
  </MuiThemeProvider>
);


ThemeProvider.displayName = 'ThemeProvider';


export default ThemeProvider;

