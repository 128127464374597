import { formatInTimeZone } from 'date-fns-tz';
import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';

import { TimeDiff } from './types';


export const getUTCTimeString = (timestamp = Date.now()) => {
  const date = new Date(timestamp);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  return `UTC ${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const getTimeDiffString = (timestamp: number | null | undefined, reverse = false): TimeDiff | null => {
  if (!timestamp) {
    return null;
  }

  const earlierDate = reverse ? new Date() : new Date(timestamp);
  const lateDate = reverse ? new Date(timestamp) : new Date();

  const daysDiff = differenceInDays(earlierDate, lateDate);
  if (daysDiff) {
    const hoursDiff = differenceInHours(earlierDate, lateDate);
    const hoursDiffWithoutDayDiff = Math.abs(hoursDiff) - (24 * daysDiff);
    const hoursString = hoursDiffWithoutDayDiff ? ` ${hoursDiffWithoutDayDiff}h` : '';

    return {
      isPast: daysDiff < 0,
      diff: `${daysDiff}d${hoursString}`,
    };
  }

  const hoursDiff = differenceInHours(earlierDate, lateDate);
  if (hoursDiff) {
    const minutesDiff = differenceInMinutes(earlierDate, lateDate);
    const minutesDiffWithoutHoursDiff = Math.abs(minutesDiff) - (60 * hoursDiff);
    const minutesString = minutesDiffWithoutHoursDiff ? `:${minutesDiffWithoutHoursDiff < 10 ? `0${minutesDiffWithoutHoursDiff}` : minutesDiffWithoutHoursDiff}m` : '';

    return {
      isPast: hoursDiff < 0,
      diff: `${hoursDiff}h${minutesString}`,
    };
  }

  const minutesDiff = differenceInMinutes(earlierDate, lateDate);
  return {
    isPast: minutesDiff < 0,
    diff: `${minutesDiff}m`,
  };
};

export const getLastSuccessDate = (lastSuccess: number | null) => {
  if (!lastSuccess) {
    return null;
  }

  return formatInTimeZone(new Date(lastSuccess), 'UTC', 'MMM d');
};

export const getTimeDiffLabel = (timeDiff: TimeDiff | null | undefined, prefix = '', postfix = '') => {
  if (timeDiff && !timeDiff.isPast) {
    return `${prefix}${timeDiff.diff}${postfix}`;
  }

  return '\u2014';
};
