import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  'svg + svg': {
    marginLeft: theme.spacing(0.625),
  },
}));
