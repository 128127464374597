import { SagaIterator } from 'redux-saga';
import { rejectedAction, resolvedAction } from 'utils/redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { waitForUserSession } from 'core/app/sagas';
import SnackbarService from 'services/snackbar';
import AdminPermissionTemplatesService from './service';
import { Types as AdminPermissionTemplatesTypes, FetchAllResolvedPayload, FetchOneResolvedPayload, Actions } from './types';


function* fetchAll (): SagaIterator<void> {
  try {
    yield call(waitForUserSession);

    const data = (yield call(AdminPermissionTemplatesService.fetchAll)) as FetchAllResolvedPayload;
    yield put(resolvedAction<AdminPermissionTemplatesTypes, FetchAllResolvedPayload>(AdminPermissionTemplatesTypes.FETCH_ALL, data));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);
    yield put(rejectedAction<AdminPermissionTemplatesTypes>(AdminPermissionTemplatesTypes.FETCH_ALL, {
      error: message,
    }));
  }
}

function* fetchOne ({ payload }: ReturnType<Actions['fetchOne']>): SagaIterator<void> {
  try {
    yield call(waitForUserSession);

    const data = (yield call(AdminPermissionTemplatesService.fetchOne, payload)) as FetchOneResolvedPayload;
    yield put(resolvedAction<AdminPermissionTemplatesTypes, FetchOneResolvedPayload>(AdminPermissionTemplatesTypes.FETCH_ONE, data));
  } catch (error: any) {
    const message = error.response?.data ?? (error as Error).message;
    SnackbarService.showError(message);
    yield put(rejectedAction<AdminPermissionTemplatesTypes>(AdminPermissionTemplatesTypes.FETCH_ONE, {
      error: message,
    }));
  }
}

function* fetchAllWatcher () {
  yield takeLatest(AdminPermissionTemplatesTypes.FETCH_ALL, fetchAll);
}
function* fetchOneWatcher () {
  yield takeLatest(AdminPermissionTemplatesTypes.FETCH_ONE, fetchOne);
}

export default [
  fetchAllWatcher,
  fetchOneWatcher,
];
