import update from 'immutability-helper';

import { handleActions, combineActions, persistReducer, resolved, rejected } from 'utils/redux';
import { Action } from 'types/redux';

import persistConfig from './persist';

import {
  Types,
  State,
  ResolvedAuthPayload,
  RejectedAuthPayload,
} from './types';


const defaultState: State = {
  isFetching: false,
  token: null,
  isTokenValid: false,
  redirectUrl: null,
};


const reducer = handleActions<State, Types>({
  [Types.AUTH_SUCCESS]: (state: State): State => (
    update(state, {
      redirectUrl: { $set: null },
    })
  ),

  [combineActions<Types>(
    Types.SIGN_IN,
    Types.LOAD_AUTH,
    Types.SIGN_IN_WITH_OKTA,
  )]: (state: State): State => (
    update(state, {
      isFetching: { $set: true },
    })
  ),

  [combineActions<Types>(
    resolved<Types>(Types.SIGN_IN),
    resolved<Types>(Types.LOAD_AUTH),
    Types.SIGN_IN_WITH_OKTA,
  )]: (state: State, { payload: { token } }: Action<Types, ResolvedAuthPayload>): State => (
    update(state, {
      isFetching: { $set: false },
      token: { $set: token },
      isTokenValid: { $set: true },
    })
  ),

  [combineActions<Types>(
    rejected<Types>(Types.SIGN_IN),
    rejected<Types>(Types.LOAD_AUTH),
  )]: (state: State, { payload: { redirectUrl } = { redirectUrl: null } }: Action<Types, RejectedAuthPayload>): State => ({
    ...defaultState,
    redirectUrl: redirectUrl || state.redirectUrl,
  }),

  [Types.LOGOUT]: (): State => defaultState,
}, defaultState);


export default persistReducer<State>(persistConfig, reducer);
