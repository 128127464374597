import { styled, alpha } from '@mui/material/styles';

import { HEADER_HEIGHT, HEADER_FILTER_HEIGHT } from '../../constants';

import { PinIconWrapperProps } from './types';


export const HeaderTitleWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
}));

export const HeaderSubTitle = styled('div')(({ theme }) => ({
  maxWidth: '100%',

  ...theme.typography.caption,
  color: theme.palette.grey[500],

  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

export const ExtendedHeaderTitleWrapper = styled('div')(({ theme }) => ({
  height: HEADER_HEIGHT,
  borderBottom: `1px solid ${theme.palette.background.default}`,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
}));

export const ExtendedHeaderFilterWrapper = styled('div')(({ theme }) => ({
  height: HEADER_FILTER_HEIGHT,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: theme.transitions.create(['background-color']),

  '&:hover': {
    backgroundColor: '#F2F7FF', // TODO: move to design kit?
  },
}));

export const PinIconWrapper = styled('div')<PinIconWrapperProps>(({ theme, $isActive, $isLocked }) => {
  const iconColor = $isLocked ? alpha(theme.palette.error.main, 0.5) : (
    $isActive ? alpha(theme.palette.primary.main, 0.5) : theme.palette.grey[400]
  );
  const iconHoverColor = $isLocked ? theme.palette.error.main : (
    $isActive ? theme.palette.primary.main : theme.palette.grey[500]
  );

  return {
    position: 'absolute',
    width: '12px',
    height: '12px',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    cursor: $isLocked ? 'default' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: $isActive ? 1 : 0,
    transition: theme.transitions.create('opacity'),

    '& svg': {
      fontSize: '12px',
      color: iconColor,
    },

    '&:hover': {
      '& svg': {
        color: iconHoverColor,
      },
    },
  };
});

export const ExtendedHeaderWrapper = styled('div')(() => ({
  width: '100%',

  '&:hover': {
    [PinIconWrapper]: {
      opacity: 1,
    },
  },
}));

export const ContextMenuListItem = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  position: 'relative',
  width: '100%',
}));


export const HeaderTitleContent = styled('div')(({ theme }) => ({
  width: '100%',
}));
