import { OtherFilter, Filter } from 'core/settings/types';


export const getOtherFilters = (appliedFilters: Filter[], allFilters: Filter[]): OtherFilter[] => allFilters.filter(({ isDefault }) => !isDefault).map((filter) => {
  const selected = !!appliedFilters.find(({ id }) => id === filter.id);
  return {
    ...filter,
    selected,
  };
});

export const getNextFilters = (nextFilters: Filter[], appliedFilters: Filter[]) => {
  const defaultFilters = appliedFilters.filter(({ isDefault }) => isDefault);
  const filters = nextFilters.map((nextFilter) => {
    const appliedFilter = appliedFilters.find((filter) => filter.id === nextFilter.id);
    if (appliedFilter) {
      return {
        ...nextFilter,
        values: appliedFilter.values,
      };
    }

    return nextFilter;
  });

  return defaultFilters.concat(filters);
};
