import { memo, useCallback, FunctionComponent } from 'react';

import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';

import { Props } from './types';
import { rerenderComparator } from './utils';
import {
  ButtonsWrapper,
  ButtonWrapper,
  Button,
} from './styles';


const SelectableCell: FunctionComponent<Props> = memo(({
  cellElement,
  cellValue,
  field,
  row,
  onSelect,
  filterValue,
}) => {
  const handleSelect = useCallback(() => {
    onSelect(cellValue, field, true, row);
  }, [
    onSelect,
    field,
    row,
    cellValue,
  ]);

  const handleSelectExclude = useCallback(() => {
    onSelect(cellValue, field, false, row);
  }, [
    onSelect,
    field,
    row,
    cellValue,
  ]);

  const handleReset = useCallback(() => {
    onSelect(null, field, false, row);
  }, [
    onSelect,
    field,
    row,
  ]);

  return (
    <>
      {cellElement}

      <ButtonsWrapper
        $isSelected={Boolean(filterValue)}
      >
        {(!filterValue || !filterValue.include) && (
          <ButtonWrapper>
            <Button
              as={IconButton}
              onClick={handleSelect}
            >
              <AddIcon
                fontSize="inherit"
                color="inherit"
              />
            </Button>
          </ButtonWrapper>
        )}

        {(!filterValue || filterValue.include) && (
          <ButtonWrapper>
            <Button
              as={IconButton}
              onClick={handleSelectExclude}
            >
              <RemoveIcon
                fontSize="inherit"
                color="inherit"
              />
            </Button>
          </ButtonWrapper>
        )}

        {filterValue && (
          <ButtonWrapper>
            <Button
              as={IconButton}
              onClick={handleReset}
            >
              <CloseIcon
                fontSize="inherit"
                color="inherit"
              />
            </Button>
          </ButtonWrapper>
        )}
      </ButtonsWrapper>
    </>
  );
}, rerenderComparator);


SelectableCell.displayName = 'SelectableCell';


export default SelectableCell;
