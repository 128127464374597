import { alpha, styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 32,
  padding: theme.spacing(0, 1.25),
}));

export const StyledSkeleton = styled('div')(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.4),
}));
