import { styled } from '@mui/material/styles';

import { LabelProps } from './types';


export const StyledIcon = styled('div')(({ theme }) => ({
  transition: theme.transitions.create(['color']),
  color: theme.palette.grey[500],
  marginLeft: theme.spacing(1),
}));

export const StyledButton = styled('button')(({ theme }) => ({
  minWidth: 150,
  width: 'auto',
  height: 40,
  border: 'none',
  backgroundColor: theme.palette.common.white,
  justifyContent: 'space-between',
  alignItems: 'center',
  textTransform: 'none',
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  transition: theme.transitions.create('color'),
  ...theme.typography.subtitle2,

  '&:hover': {
    color: theme.palette.primary.main,
    border: 'none',
    backgroundColor: theme.palette.common.white,

    [StyledIcon]: {
      color: theme.palette.primary.main,
    },
  },
}));

export const PaperStyles = {
  paddingTop: 1,
};


export const LabelWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
}));

export const Label = styled('div')<LabelProps>(({ theme, $isStyled }) => ({
  ...($isStyled ? {
    color: theme.palette.primary.main,
  } : {}),
}));

export const SubLabel = styled('div')(({ theme }) => ({
  ...theme.typography.caption,
  lineHeight: 1,
}));
