import { FormEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ChevronLeft } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, FormControl, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material';

import { ROUTES } from 'containers/router/constants';
import { actions as adminUsersAction } from 'core/admin/users/actions';
import { actions as adminPermissionTemplatesAction } from 'core/admin/permission-templates/actions';
import { UserPermissionOverrideList, UserRole, UserRoleDisplay } from 'core/users/types';
import { UserForm as UserFormPayload } from 'core/admin/users/types';
import { BootstrapInput, BootstrapLabel } from 'components/common/bootstrap-input/styles';
import { PermissionToggleList } from 'core/admin/permission-templates/types';
import { getAuthorizedUserRole } from 'core/users/selectors';
import { getPermissionTemplates, getPermissionTemplatesError, getPermissionTemplatesIsLoading } from 'core/admin/permission-templates/selectors';
import theme from 'containers/theme-provider/theme';
import AdminUsersService from 'core/admin/users/service';
import { getUser, getUserError, getUserIsLoading } from 'core/admin/users/selectors';
import SnackbarService from 'services/snackbar';
import { isNumber } from 'utils';



type UserForm = {
  email: string;
  fullName: string;
  role: UserRole;
  permissionTemplateId: number | string;
  permissionList: UserPermissionOverrideList;
};

const UsersEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const userId = params.userId;
  const isEdit = !!userId;

  const authRole = useSelector(getAuthorizedUserRole);

  const user = useSelector(getUser);
  const isLoadingUser = useSelector(getUserIsLoading);
  const userError = useSelector(getUserError);
  const permissionTemplates = useSelector(getPermissionTemplates);
  const isLoadingPermissionTemplates = useSelector(getPermissionTemplatesIsLoading);
  const permissionTemplatesError = useSelector(getPermissionTemplatesError);

  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<UserForm>({
    email: '',
    fullName: '',
    role: UserRole.RegularUser,
    permissionTemplateId: '',
    permissionList: {},
  });

  // fetch user if editing an user
  useEffect(() => {
    dispatch(adminPermissionTemplatesAction.fetchAll());
    if (userId) {
      dispatch(adminUsersAction.fetchOne({ userId: parseInt(userId) }));
    }
  }, [userId, dispatch]);

  // set user data to state
  useEffect(() => {
    if (isEdit && user) {
      if (user.role === UserRole.SuperAdmin && authRole !== UserRole.SuperAdmin ) {
        dispatch(adminUsersAction.fetchOneReset());
        navigate(ROUTES.USERS_INDEX);
      } else {
        setValues({
          email: user.email,
          fullName: user.fullName,
          role: user.role,
          permissionTemplateId: user.permissionTemplateId ?? '',
          permissionList: user.permissionList ?? {},
        });
      }
    }
  }, [user, isEdit, setValues, navigate]);

  // handle fetch user error
  useEffect(() => {
    if (userError || permissionTemplatesError) {
      dispatch(adminUsersAction.fetchOneReset());
      navigate(ROUTES.USERS_INDEX);
    }
  }, [userError, permissionTemplatesError, navigate, dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };
  const handleSelectChange = (prop: keyof UserForm) => (event: SelectChangeEvent<string | unknown>, child: ReactNode) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handlePermissionListChange = (key: string) => (event: SelectChangeEvent<string | unknown>) => {
    const newList: UserPermissionOverrideList = { ...values.permissionList };

    switch (event.target.value) {
      case 0: // override no
        newList[key] = false;
        break;
      case 1: // override yes
        newList[key] = true;
        break;
      case 2: // auto
        delete newList[key];
        break;
      default:
        break;
    }

    setValues({
      ...values,
      permissionList: newList,
    });
  };

  const roleSelection = useMemo<Array<{ key: UserRole; label: string }>>(() => {
    const arr = [];
    for (const role in UserRole) {
      if (isNumber(role)) {
        const r = role as unknown as UserRole;
        if (r == UserRole.SuperAdmin && authRole !== UserRole.SuperAdmin) {
          continue;
        }
        arr.push({
          key: r,
          label: UserRoleDisplay[r],
        });
      }
    }
    return arr;
  }, []);

  const permissionTemplateByRoleSelection = useMemo(() => {
    const o: { [key in UserRole]: Array<{ key: number; label: string }> } = {
      [UserRole.RegularUser]: [],
      [UserRole.Admin]: [],
      [UserRole.SuperAdmin]: [],
    };
    permissionTemplates.forEach((p) => {
      o[p.role].push({
        key: p.id,
        label: p.name,
      });
    });
    return o;
  }, [permissionTemplates]);

  const targetPermissionList = useMemo(() => {
    let tempTarget: string[] = [];
    if (values.permissionTemplateId) {
      for (let i = 0; i < permissionTemplates.length; i++) {
        const p = permissionTemplates[i];
        if (p.id === values.permissionTemplateId) {
          tempTarget = p.permissionList;
          break;
        }
      }
    } else {
      // default persmission template by role if no permission template selected
      for (let i = 0; i < permissionTemplates.length; i++) {
        const p = permissionTemplates[i];
        if (p.isDefault && p.role == values.role) {
          tempTarget = p.permissionList;
          break;
        }
      }
    }

    return tempTarget;
  }, [permissionTemplates, values.role, values.permissionTemplateId]);

  const handleClickBack = useCallback(() => {
    navigate(ROUTES.USERS_INDEX);
  }, [navigate]);

  // remove permission of other role
  const cleanPermissionList = useCallback(() => {
    const rolePermissionList = PermissionToggleList[values.role].flatMap((o) => o.permissions.map((p) => p.key));
    const newList = { ...values.permissionList };
    Object.keys(newList).forEach((p) => {
      if (rolePermissionList.indexOf(p) === -1) {
        delete newList[p];
      }
    });
    return newList;
  }, [values]);

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault();
    if (isEdit && !user) {
      return;
    }
    setIsLoading(true);
    const data: UserFormPayload = {
      ...values,
      permissionTemplateId: isNumber(values.permissionTemplateId) ? values.permissionTemplateId as number : null,
    };
    data.permissionList = cleanPermissionList();
    (
      isEdit ? AdminUsersService.editUser({ userId: parseInt(userId), data }) :
        AdminUsersService.createUser({ data })
    )
      .then(() => {
        navigate(ROUTES.USERS_INDEX);
      }).catch((e: any) => {
        setIsLoading(false);
        SnackbarService.showError(
          e?.response?.data ?? (e?.message ?? 'Error'),
          {
            vertical: 'bottom',
            horizontal: 'left',
          },
        );
      });
  }, [values, navigate, isEdit, user, userId]);

  const inputSkeleton = useMemo(() => (
    <Skeleton
      height={50}
      sx={{ marginTop: '6px' }}
    />
  ), []);

  return (
    <Container
      maxWidth="lg"
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        color={theme.palette.grey[800]}
        marginBottom="8px"
      >{isEdit ? 'Edit' : 'Create'} User</Typography>
      <Paper
        elevation={3}
      >
        <Box
          component="form"
          autoComplete="off"
          padding={2}
          onSubmit={handleSubmit}
        >
          <Typography
            variant="h2"
            fontWeight="bold"
            color={theme.palette.grey[800]}
            borderBottom="1px solid #E8EAEE"
            marginBottom="8px"
          >General</Typography>
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
          >
            <div
              style={{
                padding: '8px',
                width: '100%',
              }}
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: '12px' }}
              >
                <BootstrapLabel shrink>Name</BootstrapLabel>
                {
                  isLoadingUser ? inputSkeleton :
                    <BootstrapInput
                      value={values.fullName}
                      name="fullName"
                      onChange={handleChange}
                      required
                    />
                }
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: '12px' }}
              >
                <BootstrapLabel shrink>Email</BootstrapLabel>
                {
                  isLoadingUser ? inputSkeleton :
                    <BootstrapInput
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      type="email"
                      required
                    />
                }
              </FormControl>
            </div>
            <div
              style={{
                padding: '8px',
                width: '100%',
              }}
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: '12px' }}
              >
                <BootstrapLabel shrink>User Access Role</BootstrapLabel>
                {
                  isLoadingUser ? inputSkeleton :
                    <BootstrapInput
                      as={Select}
                      value={values.role}
                      onChange={handleSelectChange('role')}
                    >
                      {
                        roleSelection.map((item) => (
                          <MenuItem
                            value={item.key}
                            key={item.key}
                          >{item.label}</MenuItem>
                        ))
                      }
                    </BootstrapInput>
                }
              </FormControl>
              <FormControl
                variant="standard"
                fullWidth
                sx={{ marginBottom: '12px' }}
              >
                <BootstrapLabel shrink>Permission Template</BootstrapLabel>
                {
                  (isLoadingUser || isLoadingPermissionTemplates) ? inputSkeleton :
                    <BootstrapInput
                      as={Select}
                      value={values.permissionTemplateId}
                      onChange={handleSelectChange('permissionTemplateId')}
                    >
                      <MenuItem value={''} >default</MenuItem>
                      {
                        permissionTemplateByRoleSelection[values.role].map((item) => (
                          <MenuItem
                            value={item.key}
                            key={item.key}
                          >{item.label}</MenuItem>
                        ))
                      }
                    </BootstrapInput>
                }
              </FormControl>
            </div>
          </Stack>

          <Typography
            variant="h2"
            fontWeight="bold"
            color={theme.palette.grey[800]}
            borderBottom="1px solid #E8EAEE"
            marginBottom="8px"
          >Permission Togglers</Typography>
          <div style={{ marginLeft: '8px' }} >
            {
              PermissionToggleList[values.role].map((section, i) => (
                <div
                  key={i}
                >
                  <Typography
                    variant="h2"
                    fontWeight="bold"
                    color={theme.palette.grey[500]}
                    borderBottom="1px solid #E8EAEE"
                    marginBottom="8px"
                  >{section.label}</Typography>
                  <Stack
                    direction="row"
                    gap={2}
                  >
                    {
                      section.permissions.map((permission, idx) => (
                        <FormControl
                          key={idx}
                          variant="standard"
                          sx={{
                            marginBottom: '12px',
                            width: '25%',
                          }}
                        >
                          <BootstrapLabel shrink>{permission.label}</BootstrapLabel>
                          {
                            isLoadingUser ? inputSkeleton :
                              <BootstrapInput
                                as={Select}
                                value={values.permissionList[permission.key] === undefined ? 2 : ( values.permissionList[permission.key] ? 1 : 0)}
                                onChange={handlePermissionListChange(permission.key)}
                              >
                                <MenuItem
                                  value={0}
                                >No</MenuItem>
                                <MenuItem
                                  value={1}
                                >Yes</MenuItem>
                                <MenuItem
                                  value={2}
                                >Auto: {targetPermissionList.indexOf(permission.key) === -1 ? 'No' : 'Yes'}</MenuItem>
                              </BootstrapInput>
                          }
                        </FormControl>
                      ))
                    }
                  </Stack>
                </div>
              ))
            }
          </div>

          <Typography
            variant="h2"
            fontWeight="bold"
            color={theme.palette.grey[800]}
            borderBottom="1px solid #E8EAEE"
            marginBottom="8px"
          >Attribution Changes</Typography>

          <Stack
            direction="row"
            justifyContent="end"
          >
            <Button
              variant="text"
              startIcon={<ChevronLeft />}
              onClick={handleClickBack}
            >Back</Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isLoading}
              disabled={isEdit && isLoadingUser}
            >Submit</LoadingButton>
          </Stack>

        </Box>
      </Paper>
    </Container>
  );
};

UsersEdit.displayName = 'UsersEdit';

export default UsersEdit;
