import { memo, FunctionComponent, useMemo, useCallback, useRef, MouseEvent } from 'react';

import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PopoverActions } from '@mui/material/Popover';

import { isObjectDeepChanged } from 'utils';
import useOriginal from 'hooks/use-original';

import Dropdown from 'components/common/dropdown';
import { Props as DropdownProps, DropdownChildAsFunctionProps } from 'components/common/dropdown/types';
import List from 'components/common/list';
import { ListItemProps, RenderContentCallback } from 'components/common/list/types';
import DashboardSavedViewsDropdown from 'components/dashboard/saved-views-dropdown';

import ActionButton from './action-button';
import AppliedAction from './applied-action';

import { BREAKDOWN_ANCHOR_ORIGIN, BREAKDOWN_TRANSFORM_ORIGIN } from './constants';
import { extractListKey } from './utils';
import { Props } from './types';

import {
  Wrapper,
  ActionsWrapper,
  BulkActionsButton,
  ActionsMenu,
  ClearButton,
  StyledList,
  AppliedActionsWrapper,
  CreateViewButton,
  SaveViewButton,
  EditViewButton,
  DeleteViewButton,
  BreakdownOption,
} from './styles';


const DataGridHeader: FunctionComponent<Props> = memo(({
  bulkActions,
  breakdownActions,
  groupingActions,
  onBreakdownReset,
  activeGrouping,
  onGroupingReset,
  isGroupingWarningVisible,
  onExportClick,
  savedViews,
  activeView,
  activeBreakdown,
  onViewSave,
  onViewEdit,
  onViewAdd,
  onViewDelete,
  activeSorting,
  onSortingReset,
  activeFilters,
  onFiltersReset,
}) => {
  const breakdownPopoverActions = useRef<PopoverActions>(null);

  const originalActiveView = useOriginal({
    value: activeView,
    shouldUseForceUpdate: true,
  });

  const isActiveViewChanged = useMemo(() => {
    if (!activeView || !originalActiveView) {
      return false;
    }

    return isObjectDeepChanged(originalActiveView.value, activeView.value);
  }, [activeView]);

  const handleListSearchChange = useCallback(() => {
    if (breakdownPopoverActions.current) {
      breakdownPopoverActions.current.updatePosition();
    }
  }, []);

  const renderBulkActionsButton = useCallback<DropdownProps['renderButton']>((props, isOpen) => {
    const icon =  isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />;

    return (
      <BulkActionsButton
        as={Button}
        variant='outlined'
        endIcon={icon}
        disabled
        {...props}
      >
        Bulk Actions
      </BulkActionsButton>
    );
  }, []);

  const bulkActionsElement = useMemo(() => (
    bulkActions && bulkActions.length && (
      <ActionsWrapper>
        <Dropdown
          renderButton={renderBulkActionsButton}
        >
          <List
            items={bulkActions}
            extractItemKey={extractListKey}
          />
        </Dropdown>
      </ActionsWrapper>
    )
  ), [bulkActions, renderBulkActionsButton]);

  const renderActionButton = useCallback((children, props = {}) => (
    <ActionButton {...props}>
      {children}
    </ActionButton>
  ), []);

  const renderGroupingButton = useCallback((props) => {
    const nextProps = {
      ...props,
      isActive: Boolean(activeGrouping),
    };

    return renderActionButton('Grouping', nextProps);
  }, [renderActionButton, activeGrouping]);

  const renderBreakdownButton = useCallback((props) => {
    const nextProps = {
      ...props,
      isActive: Boolean(activeBreakdown),
    };

    return renderActionButton('Breakdown', nextProps);
  }, [renderActionButton, activeBreakdown]);

  const breakdownActionsItems = useMemo<Props['breakdownActions'] | null>(() => {
    if (!breakdownActions) {
      return null;
    }

    return {
      ...breakdownActions,
      items: breakdownActions.items.map((item) => {
        const { nestedActions, ...otherItemProps } = item;

        if (!nestedActions || !nestedActions.items.length) {
          return item;
        }

        return {
          ...otherItemProps,
          renderContent: (itemProps, { close }: DropdownChildAsFunctionProps) => (
            <Dropdown
              action={breakdownPopoverActions}
              renderButton={({ onClick }) => (
                <BreakdownOption
                  as={Stack}
                  direction="row"
                  onClick={onClick}
                >
                  {item.renderContent ? item.renderContent(itemProps) : item.title}
                </BreakdownOption>
              )}
              anchorOrigin={BREAKDOWN_ANCHOR_ORIGIN}
              transformOrigin={BREAKDOWN_TRANSFORM_ORIGIN}
              disablePortal
            >
              {(dropdownProps: DropdownChildAsFunctionProps) => {
                const nestedActionsItems = nestedActions.items.map((nestedActionsItem) => ({
                  ...nestedActionsItem,
                  onClick: (event: MouseEvent<HTMLElement>) => {
                    const closeCallback = () => {
                      dropdownProps.close();
                      close();
                    };

                    if (nestedActionsItem.onClick) {
                      nestedActionsItem.onClick(event, { close: closeCallback });
                    }
                  },
                }));

                return (
                  <StyledList
                    as={List}
                    extractItemKey={extractListKey}
                    items={nestedActionsItems}
                    isSearchable={nestedActions.isSearchable}
                    onSearchChange={handleListSearchChange}
                  />
                );
              }}
            </Dropdown>
          ),
        };
      }),
    };
  }, [breakdownActions]);

  const renderBreakdownActionsElement = useCallback((dropdownProps: DropdownChildAsFunctionProps) => {
    const nestedBreakdownActionsItems = breakdownActionsItems?.items.map((breakdownActionsItem) => ({
      ...breakdownActionsItem,
      renderContent: (itemProps: ListItemProps) => (breakdownActionsItem.renderContent as RenderContentCallback)(itemProps, dropdownProps),
    }));

    return (
      nestedBreakdownActionsItems ? (
        <div>
          <ActionsMenu
            as={List}
            extractItemKey={extractListKey}
            items={nestedBreakdownActionsItems}
          />

          <ClearButton
            as={Button}
            variant="contained"
            disabled={!activeBreakdown}
            onClick={() => {
              onBreakdownReset();
              dropdownProps.close();
            }}
          >
            Clear Breakdown
          </ClearButton>
        </div>
      ) : null
    );
  }, [breakdownActionsItems, onBreakdownReset, activeBreakdown]);

  const renderGroupingActionsElement = useCallback(({ close }: DropdownChildAsFunctionProps) => {
    const groupingActionsItems = groupingActions?.items.map((groupingActionsItem) => ({
      ...groupingActionsItem,
      onClick: (event: MouseEvent<HTMLElement>) => {
        if (groupingActionsItem.onClick) {
          groupingActionsItem.onClick(event);
        }
        close();
      },
    }));

    return (
      groupingActions ? (
        <StyledList
          as={List}
          extractItemKey={extractListKey}
          items={groupingActionsItems}
          isSearchable={groupingActions.isSearchable}
          onSearchChange={handleListSearchChange}
        />
      ) : null
    );
  }, [groupingActions]);

  const renderSavedViewsFooter = useCallback(({ close }: DropdownChildAsFunctionProps) => (
    <CreateViewButton
      as={Button}
      onClick={() => {
        close();
        onViewAdd();
      }}
      variant="contained"
      color="primary"
    >
      Create new view
    </CreateViewButton>
  ), [onViewAdd]);

  const exportActionElement = useMemo(() => renderActionButton('Export', { onClick: onExportClick }), [onExportClick]);

  const activeSortingString = activeSorting.join(', ');

  const activeFiltersString = activeFilters.join(', ');

  return (
    <Wrapper
      as={Stack}
      direction="row"
      justifyContent='space-between'
    >
      {bulkActionsElement}

      <AppliedActionsWrapper
        as={Stack}
        direction="row"
      >
        {activeGrouping && (
          <AppliedAction
            actionTitle={activeGrouping.title}
            onReset={onGroupingReset}
            isWarningVisible={isGroupingWarningVisible}
          >
            Grouped by
          </AppliedAction>
        )}

        {activeBreakdown && (
          <AppliedAction
            actionTitle={activeBreakdown.label}
            onReset={onBreakdownReset}
            isWarningVisible={false}
          >
            Breakdown by
          </AppliedAction>
        )}

        {activeSorting.length ? (
          <AppliedAction
            actionTitle={activeSortingString}
            actionDescription={`Sorted by: ${activeSortingString}`}
            onReset={onSortingReset}
            isWarningVisible={false}
          >
            Sorted by
          </AppliedAction>
        ) : null}

        {activeFilters.length ? (
          <AppliedAction
            actionTitle={activeFiltersString}
            actionDescription={`Filtered by: ${activeFiltersString}`}
            onReset={onFiltersReset}
            isWarningVisible={false}
          >
            Filtered by
          </AppliedAction>
        ) : null}
      </AppliedActionsWrapper>

      <ActionsWrapper
        as={Stack}
        direction="row"
      >
        <Stack
          direction="row"
        >
          {activeView?.isEdited && (isActiveViewChanged || activeView?.isShared) && (
            <SaveViewButton
              as={Button}
              variant="outlined"
              color="primary"
              onClick={onViewSave}
            >
              Save
            </SaveViewButton>
          )}

          {activeView && (
            <EditViewButton
              as={Button}
              variant="outlined"
              color="primary"
              onClick={onViewEdit}
              $isRounded={!(isActiveViewChanged || activeView?.isShared) || !activeView.isEdited}
            >
              <EditIcon />
            </EditViewButton>
          )}

          {activeView?.isEdited && activeView?.isCustom && (
            <DeleteViewButton
              as={Button}
              variant="outlined"
              color="primary"
              onClick={onViewDelete}
              $isRounded={false}
            >
              <DeleteForeverIcon />
            </DeleteViewButton>
          )}

          <DashboardSavedViewsDropdown
            views={savedViews}
            activeView={activeView}
            renderListFooter={renderSavedViewsFooter}
          />
        </Stack>

        <Stack
          direction="row"
        >
          <Dropdown
            renderButton={renderGroupingButton}
          >
            {renderGroupingActionsElement}
          </Dropdown>

          <Dropdown
            renderButton={renderBreakdownButton}
          >
            {renderBreakdownActionsElement}
          </Dropdown>

          {exportActionElement}

          {/* {renderActionButton('Refresh')}

          {renderActionButton('More')} */}
        </Stack>
      </ActionsWrapper>
    </Wrapper>
  );
});


DataGridHeader.displayName = 'DataGridHeader';


export default DataGridHeader;
