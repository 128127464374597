import { styled } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

import { StyledInputProps, StyledTitleProps } from './types';


export const Wrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  minHeight: '278px',
  padding: theme.spacing(3.5),
}));

export const FormWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(2.5, 0),
}));

export const StyledInput = styled('div')<StyledInputProps>(({ theme, $isMultiline }) => ({
  marginBottom: theme.spacing(1.25),

  [`.${inputBaseClasses.root}`]: {
    height: $isMultiline ? 'auto' : 38,
    backgroundColor: theme.palette.grey[50],

    ...theme.typography.body1,
    color: theme.palette.common.black,

    [`.${inputBaseClasses.input}::placeholder`]: {
      color: 'inherit',
    },
  },

  [`.${outlinedInputClasses.notchedOutline}`]: {
    display: 'none',
  },
}));


export const StyledTitle = styled('div')<StyledTitleProps>(({ theme, $isActive, $isDisabled }) => {
  const disabledColor = $isDisabled ? theme.palette.common.black : null;
  const color = disabledColor || ($isActive ? theme.palette.common.black : theme.palette.primary.main);
  const opacity = $isDisabled ? 0.5 : 1;
  const disabledCursor = $isDisabled ? 'not-allowed' : null;
  const cursor = disabledCursor || ($isActive ? 'default' : 'pointer');

  return {
    marginRight: theme.spacing(2.5),
    cursor,
    color,
    opacity,

    '&:last-child': {
      marginRight: 0,
    },
  };
});
