import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { SliceKeys, State } from './types';
import { KEY } from './constants';


const persistConfig: PersistConfig<State> = {
  key: KEY,
  storage,
  blacklist: [
    SliceKeys.DataStatus,
  ],
};


export default persistConfig;
