import { typographyClasses } from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { WrapperProps } from './types';


export const InfoIcon = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  width: 16,
}));

export const ActionButton = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  cursor: 'pointer',
  width: 16,
  padding: 0,
  marginLeft: theme.spacing(0.625),

  svg: {
    width: 16,
  },
}));

export const Wrapper = styled('div')<WrapperProps>(({ theme, $isAdded, $isAddable, $isFullWidthAction, $isPrimary }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: $isAdded ? theme.palette.primary.main : theme.palette.grey['50'],
  borderRadius: theme.shape.borderRadius,
  height: 30,
  paddingLeft: theme.spacing(1.25),
  paddingRight: theme.spacing(1.25),
  transition: theme.transitions.create('background-color'),

  '& + &': {
    marginTop: theme.spacing(0.625),
  },

  [`.${typographyClasses.root}`]: {
    transition: theme.transitions.create('color'),
  },

  [InfoIcon]: {
    color: $isAdded ? theme.palette.common.white : theme.palette.secondary.main,
    transition: theme.transitions.create('color'),
  },

  [ActionButton]: {
    color: $isAddable ? ($isAdded ? theme.palette.common.white : theme.palette.primary.main) : theme.palette.grey['300'],
    transition: theme.transitions.create('color'),

    '&:hover': {
      color: $isAddable ? ($isAdded ? theme.palette.common.white : theme.palette.primary.main) : theme.palette.grey['400'],
    },
  },
  ...($isFullWidthAction && !$isPrimary ? {
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: $isAdded ? '#004FE5' : theme.palette.primary.main,

      [`.${typographyClasses.root}`]: {
        color: theme.palette.common.white,
      },

      [InfoIcon]: {
        color: theme.palette.common.white,
      },

      [ActionButton]: {
        color: theme.palette.common.white,
      },
    },
  } : {}),
}));

export const ContentWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
