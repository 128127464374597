import { ENV } from 'constants/index';

import { Config } from './types';

import dev from './dev';
import prod from './prod';


const config: Record<ENV, Config> = {
  dev,
  prod,
};

export default config[(process.env.REACT_APP_CONFIG_ENV || ENV.DEV) as ENV];
