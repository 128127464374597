import builder from '@rsql/builder';


const LIKE = '=lk=';
const NOT_LIKE = '=nlk=';

export * from '@rsql/ast';
export { LIKE, NOT_LIKE };


export default {
  ...builder,
  startsWith (selector: string, value: string | number) {
    return builder.comparison(selector, LIKE, `${value}%`);
  },
  endsWith (selector: string, value: string | number) {
    return builder.comparison(selector, LIKE, `%${value}`);
  },
  contains (selector: string, value: string | number) {
    return builder.comparison(selector, LIKE, `%${value}%`);
  },
  notContains (selector: string, value: string | number) {
    return builder.comparison(selector, NOT_LIKE, `%${value}%`);
  },
  isEmpty (selector: string) {
    return builder.eq(selector, 'NULL');
  },
  isNotEmpty (selector: string) {
    return builder.neq(selector, 'NULL');
  },
};
