import {
  GridFilterOperator,
  GridFilterInputValue,
  getGridStringOperators,
  getGridNumericOperators,
  getGridDateOperators,
  getGridBooleanOperators,
  getGridSingleSelectOperators,
} from '@mui/x-data-grid-premium';

import { CUSTOM_OPERATORS } from 'core/dashboards/constants';


const IS_NOT_EQUAL_STRING_OPERATOR: GridFilterOperator = {
  ...CUSTOM_OPERATORS.IS_NOT,

  getApplyFilterFn: (filterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    return () => true; // TODO: is it correct ? We have BE filtering anyway
  },
  InputComponent: GridFilterInputValue,
  InputComponentProps: {
    type: 'string',
  },
};

const NOT_CONTAINS_STRING_OPERATOR: GridFilterOperator = {
  ...CUSTOM_OPERATORS.NOT_CONTAINS,

  getApplyFilterFn: (filterItem) => {
    if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
      return null;
    }

    return () => true; // TODO: is it correct ? We have BE filtering anyway
  },
  InputComponent: GridFilterInputValue,
  InputComponentProps: {
    type: 'string',
  },
};

export const STRING_OPERATORS = [
  ...getGridStringOperators(),
  IS_NOT_EQUAL_STRING_OPERATOR,
  NOT_CONTAINS_STRING_OPERATOR,
];
export const NUMBER_OPERATORS = getGridNumericOperators();
export const DATE_OPERATORS = getGridDateOperators();
export const DATE_TIME_OPERATORS = getGridDateOperators(true);
export const BOOLEAN_OPERATORS = getGridBooleanOperators();
export const SINGLE_SELECT_OPERATORS = getGridSingleSelectOperators();
