import { GridLinkOperator } from '@mui/x-data-grid-premium';
import builder from './builder';


export const GRID_OPERATOR_TO_RSQL = {
  '=': builder.eq,
  '!=': builder.neq,
  '>': builder.gt,
  '>=': builder.ge,
  '<': builder.lt,
  '<=': builder.le,
  'isEmpty': builder.isEmpty,
  'isNotEmpty': builder.isNotEmpty,
  'contains': builder.contains,
  'notContains': builder.notContains,
  'equals': builder.eq,
  'startsWith': builder.startsWith,
  'endsWith': builder.endsWith,
  'is': builder.eq,
  'not': builder.neq,
  'after': builder.gt,
  'onOrAfter': builder.ge,
  'before': builder.lt,
  'onOrBefore': builder.le,
};

export const GRID_LOGICAL_OPERATOR_TO_RSQL = {
  [GridLinkOperator.Or]: builder.or,
  [GridLinkOperator.And]: builder.and,
};
