import { styled } from '@mui/material/styles';

import { HEADER_MIN_HEIGHT } from 'components/common/header/styles';
import { BUTTON_MIN_HEIGHT } from 'containers/theme-provider/components-overrides/button';


export const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.75),
  width: 700,
  height: '100vh',
}));

export const Header = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1.75),
  marginLeft: theme.spacing(-1.75),
  marginRight: theme.spacing(-1.75),
  borderBottom: `2px solid ${theme.palette.background.default}`,
  height: HEADER_MIN_HEIGHT,
  backgroundColor: theme.palette.common.white,
  position: 'sticky',
  top: 0,
  zIndex: 1,
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
}));

export const ColumnsConfigWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  overflow: 'auto',
  minHeight: `calc(100% - ${HEADER_MIN_HEIGHT})`,

  [ContentWrapper]: {
    width: '50%',

    '&:first-child': {
      borderRight: `1px solid ${theme.palette.background.default}`,
      paddingRight: theme.spacing(2),
    },

    '&:last-child': {
      borderLeft: `1px solid ${theme.palette.background.default}`,
      paddingLeft: theme.spacing(2),
    },
  },
}));

export const SubmitButton = styled('div')(({ theme }) => ({
  width: '120px',
  minWidth: '120px',
  marginRight: theme.spacing(1.25),
}));

export const CloseButton = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.white,
  borderRadius: '8px',
  height: BUTTON_MIN_HEIGHT,
  width: BUTTON_MIN_HEIGHT,

  '&:hover': {
    backgroundColor: theme.palette.grey[400],
  },

  '& svg': {
    fontSize: '20px',
  },
}));

export const CreateViewButton = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1.25),
}));


export const SaveButton = styled('div')(({ theme }) => ({
  color: theme.palette.primary.main,
  width: 'auto',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));

export const SavedViewsDropdownWrapper = styled('div')(({ theme }) => ({
  width: '50%',
  paddingRight: theme.spacing(2),

  '& button': {
    width: '100%',
  },
}));

export const SavedViewsHeaderActionsWrapper = styled('div')(({ theme }) => ({
  width: '50%',
}));
