import { memo } from 'react';

import AdaptiveText from 'components/common/adaptive-text';

import { getBudgetText } from './utils';
import { Props } from './types';


const CampaignBudgetText: React.FunctionComponent<Props> = memo(({ value, channel, isGeoBudget = false, ...otherProps }) => (
  <AdaptiveText {...otherProps}>
    {getBudgetText(value, channel, isGeoBudget)}
  </AdaptiveText>
));


CampaignBudgetText.displayName = 'CampaignBudgetText';


export default CampaignBudgetText;
