

export type RequestParameters = {
  page: number;
  perPage: number;
  sorts: SortParameters[];
  filters: FilterParameters[];
};

export type SortParameters = {
  field: string;
  direction: SortDirection | null;
};

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type FilterParameters = {
  field: string;
  operator: FilterOperator;
  value: string;
};

export enum FilterOperator {
  EQUAL = 'eq',
  GREATER = 'gt',
  GREATER_OR_EQUAL = 'gte',
  LESS = 'lt',
  LESS_OR_EQUAL = 'lte',
  CONTAINS = 'contains',
  IN = 'in',
}

export type PaginatedResponse<T> = {
  data: Array<T>;
  page: number;
  perPage: number;
  total: number;
};

export const createDefaultPaginatedResponse = <T>(): PaginatedResponse<T> => ({
  data: [],
  page: 1,
  perPage: 50,
  total: -1,
});
