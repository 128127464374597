import { memo, FunctionComponent, useCallback } from 'react';

import Button from '@mui/material/Button';

import { DateRangeRelativeFilter } from 'core/dashboards/types';

import { ShortcutsProps } from './types';
import { SHORTCUT_LABELS } from './constants';
import { ShortcutsWrapper, ShortcutButton } from './styles';


const DateRangePickerShortcuts: FunctionComponent<ShortcutsProps> = memo(({ value, onSelect }) => {
  const handleSelect = useCallback((nextValue) => {
    onSelect(nextValue);
  }, [onSelect]);

  return (
    <ShortcutsWrapper>
      {
        Object.keys(DateRangeRelativeFilter).map((relativeValue) => (
          <ShortcutButton
            as={Button}
            key={relativeValue}
            variant="text"
            onClick={() => handleSelect(relativeValue)}
            $isSelected={relativeValue === value}
          >
            {SHORTCUT_LABELS[relativeValue as DateRangeRelativeFilter]}
          </ShortcutButton>
        ))
      }
    </ShortcutsWrapper>
  );
});


DateRangePickerShortcuts.displayName = 'DateRangePickerShortcuts';


export default DateRangePickerShortcuts;
