import { ColumnTypes } from 'core/settings/types';

import { ElementByType, InputTypeByColumnType } from './types';


export const inputAdornmentByType: ElementByType = {
  [ColumnTypes.Price]: '$',
  [ColumnTypes.Percentage]: '%',
};

export const inputTypeByColumnType: InputTypeByColumnType = {
  [ColumnTypes.Price]: 'number',
  [ColumnTypes.Percentage]: 'number',
};
