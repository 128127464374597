import { isValueExists } from 'utils';


export const formatSafely = (formatter?: any, params: any = {}) => ({ value }: any = {}) => {
  const { customEmptySymbol, skipNullCheck = false, ...rest } = params;
  if (!skipNullCheck && value === null) {
    return isValueExists(customEmptySymbol) ? customEmptySymbol : '\u2014';
  }

  if (!isValueExists(value)) {
    return value;
  }

  if (!formatter) {
    return value;
  }

  return formatter({ value, ...rest });
};

export const formatNumber = ({ value, rounding }: any) => new Intl.NumberFormat([], {
  minimumFractionDigits: rounding,
  maximumFractionDigits: rounding,
}).format(value);

export const formatPrice = ({ value, rounding }: any) => `${value < 0 ? '-' : ''}$${formatNumber({ value: Math.abs(value), rounding })}`;

export const formatNumberToPercentage = ({ value, rounding }: any) => `${isValueExists(value) ? formatNumber({ value: Number(value) * 100, rounding }) : 0}%`;

export const formatDuration = ({ value }: { value: number }): string => `${value}s`;

export const formatListCount = ({ value }: { value: string[] }): number => value.length;
