import { GridFilterModel, GridFilterItem, GridApi } from '@mui/x-data-grid-premium';

import { getRandomId } from 'utils';
import { isOperatorWithoutValue } from 'utils/grid';


const cleanFilterItem = (item: GridFilterItem, apiRef: React.MutableRefObject<GridApi>) => {
  const cleanItem: GridFilterItem = { ...item };

  if (cleanItem.id == null) {
    cleanItem.id = getRandomId();
  }

  if (cleanItem.operatorValue == null) {
    // we select a default operator
    const column = apiRef.current.getColumn(cleanItem.columnField);
    cleanItem.operatorValue = column && column!.filterOperators![0].value!;
  }

  return cleanItem;
};

export const getInitialState = (filterModel: GridFilterModel, disableMultipleColumnsFiltering: boolean, targetFilterColumnField: string, apiRef: React.MutableRefObject<GridApi>) => {
  if (targetFilterColumnField) {
    const filterItemsWithValue = filterModel.items.filter((item) => item.value !== undefined || isOperatorWithoutValue(item.operatorValue));

    let newFilterItems: GridFilterItem[];
    const filterItemOnTarget = filterItemsWithValue.find(
      (item) => item.columnField === targetFilterColumnField,
    );

    if (filterItemOnTarget) {
      newFilterItems = filterItemsWithValue;
    } else if (disableMultipleColumnsFiltering) {
      newFilterItems = [cleanFilterItem({ columnField: targetFilterColumnField }, apiRef)];
    } else {
      newFilterItems = [
        ...filterItemsWithValue,
        cleanFilterItem({ columnField: targetFilterColumnField }, apiRef),
      ];
    }

    return {
      ...filterModel,
      items: newFilterItems,
    };
  }

  return filterModel;
};
