import React from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getIsAuthorized } from 'core/auth/selectors';
// import { getIsAuthorizedUserAdmin } from 'core/users/selectors';
import { getAuthorizedUserPermissions, getAuthorizedUserRole } from 'core/users/selectors';
import { UserRole } from 'core/users/types';

import PageLayout from 'components/common/page-layout';

import Dashboard from 'pages/dashboard';
import NotFound from 'pages/not-found';
// import SignInOkta from 'pages/auth/sign-in-okta';
// import SignInOktaRedirect from 'pages/auth/sign-in-okta-redirect';
import SignIn from 'pages/auth/sign-in';
import ForgotPassword from 'pages/auth/forgot-password';
import ResetPassword from 'pages/auth/reset-password';
import UsersIndex from 'pages/admin/users';
import UsersEdit from 'pages/admin/users/edit';
import PermissionsIndex from 'pages/admin/permissions';
import PermissionTemplatesEdit from 'pages/admin/permissions/edit';
import ActivitiesIndex from 'pages/admin/activities';
import AppSettingsIndex from 'pages/admin/appsettings';
import AppSettingsEdit from 'pages/admin/appsettings/edit';

import { ROUTES } from './constants';


const Router: React.FunctionComponent = () => {
  const isAuthorized = useSelector(getIsAuthorized);
  // const isAuthorizedUserAdmin = useSelector(getIsAuthorizedUserAdmin);
  const authRole = useSelector(getAuthorizedUserRole);
  const authPermissions = useSelector(getAuthorizedUserPermissions);

  const location = useLocation();

  return (
    <PageLayout
      isAuthorized={isAuthorized}
      isAuthorizedUserAdmin={false}
    >
      <Routes>

        {
          isAuthorized && (
            <>
              <Route
                path={ROUTES.DASHBOARD}
                element={<Dashboard />}
              />

              <Route
                path={ROUTES.NOT_FOUND}
                element={<NotFound />}
              />

              {
                (authRole === UserRole.Admin || authRole === UserRole.SuperAdmin) && (
                  <>
                    <Route
                      path={ROUTES.USERS_INDEX}
                      element={<UsersIndex />}
                    />

                    <Route
                      path={ROUTES.ACTIVITIES_INDEX}
                      element={<ActivitiesIndex />}
                    />
                  </>
                )
              }
              {
                (authRole === UserRole.SuperAdmin || authPermissions.indexOf('editUser') !== -1) && (
                  <Route
                    path={ROUTES.USERS_EDIT}
                    element={<UsersEdit />}
                  />
                )
              }
              {
                (authRole === UserRole.SuperAdmin) && (
                  <>
                    <Route
                      path={ROUTES.USERS_CREATE}
                      element={<UsersEdit />}
                    />

                    <Route
                      path={ROUTES.PERMISSIONS_INDEX}
                      element={<PermissionsIndex />}
                    />
                    <Route
                      path={ROUTES.PERMISSIONS_CREATE}
                      element={<PermissionTemplatesEdit />}
                    />
                    <Route
                      path={ROUTES.PERMISSIONS_EDIT}
                      element={<PermissionTemplatesEdit />}
                    />
                    <Route
                      path={ROUTES.APPSETTINGS_INDEX}
                      element={<AppSettingsIndex />}
                    />
                    <Route
                      path={ROUTES.APPSETTINGS_CREATE}
                      element={<AppSettingsEdit />}
                    />
                    <Route
                      path={ROUTES.APPSETTINGS_EDIT}
                      element={<AppSettingsEdit />}
                    />
                  </>
                )
              }

            </>
          )
        }

        {/*
        <Route
          path={ROUTES.SIGN_IN_OKTA}
          element={<SignInOkta />}
        />

        <Route
          path={ROUTES.SIGN_IN_OKTA_REDIRECT}
          element={<SignInOktaRedirect />}
        />
        */}

        <Route
          path={ROUTES.SIGN_IN}
          element={<SignIn />}
        />

        <Route
          path={ROUTES.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />

        <Route
          path={ROUTES.RESET_PASSWORD}
          element={<ResetPassword />}
        />

        <Route
          path="*"
          element={(
            <Navigate
              to={isAuthorized ? ROUTES.NOT_FOUND : ROUTES.SIGN_IN}
              state={location}
            />
          )}
        />

      </Routes>
    </PageLayout>
  );
};


export default Router;
