import React, { memo, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { Props } from './types';
import { Wrapper, Label, IncludeButton, DeleteButton } from './styles';


const Tag: React.FunctionComponent<Props> = memo(({ value, onDelete, index, onIncludeChange, renderLabel, disableInclude }) => {
  const { include, name } = value;

  const handleIncludeChange = useCallback(() => {
    if (onIncludeChange) {
      onIncludeChange(!include, index);
    }
  }, [include, index, onIncludeChange]);

  const StartIcon = include ? AddCircleIcon : RemoveCircleIcon;

  return (
    <Wrapper $isIncluded={include || !!disableInclude}>
      {
        !disableInclude && (
          <IncludeButton
            as={IconButton}
            aria-label={include ? 'Exclude' : 'Include'}
            size="small"
            onClick={handleIncludeChange}
            disableRipple
          >
            <StartIcon
              fontSize="inherit"
              color="inherit"
            />
          </IncludeButton>
        )
      }

      <Label
        as={Typography}
        variant="body1"
      >
        {renderLabel ? renderLabel(value) : name}
      </Label>

      <DeleteButton
        as={IconButton}
        aria-label="Delete"
        size="small"
        onClick={onDelete}
        disableRipple
      >
        <CancelIcon
          fontSize="inherit"
          color="inherit"
        />
      </DeleteButton>
    </Wrapper>
  );
});


Tag.displayName = 'Tag';


export default Tag;
