import { Config } from './types';


const config: Config = {
  MUI_PRO_LICENSE_KEY: process.env.REACT_APP_MUI_PRO_LICENSE_KEY as string,
  API_URL: process.env.REACT_APP_API_URL as string,
  ANALYTICS_URL: process.env.REACT_APP_ANALYTICS_URL as string,
  ANALYTICS_SITE_ID: process.env.REACT_APP_ANALYTICS_SITE_ID as string,
  OKTA_LOGIN_URL: process.env.REACT_APP_OKTA_LOGIN_URL as string,
  DISABLE_ANALYTICS: process.env.REACT_APP_DISABLE_ANALYTICS as string,
};


export default config;
