import { createActions } from 'utils/redux';

import {
  Actions,
  Types,
  Payload,
  ConfigPayload,
  RequestPayload,
  FiltersPayload,
  IsFiltersExpandedPayload,
} from './types';
import { PREFIX } from './constants';


export const actions = createActions<Types, Payload, Actions>({
  [Types.FETCH_DATA]: (payload: RequestPayload) => payload,
  [Types.SET_CURRENT_CONFIG]: (payload: ConfigPayload) => payload,
  [Types.GET_CONFIG_FROM_QUERY]: () => null,
  [Types.SET_CONFIG_TO_QUERY]: (payload: ConfigPayload) => payload,
  [Types.RESET_CONFIG]: () => null,
  [Types.SET_FILTERS]: (payload: FiltersPayload) => payload,
  [Types.SET_IS_FILTERS_EXPANDED]: (payload: IsFiltersExpandedPayload) => payload,
}, {
  prefix: PREFIX,
});


export default actions;
