import React, { forwardRef, useCallback, useState, useMemo } from 'react';
import clsx from 'clsx';

import { generateUtilityClasses } from '@mui/material';
import Fade, { FadeProps } from '@mui/material/Fade';

import ClickAwayListener from '@mui/material/ClickAwayListener';

import { useGridApiContext } from '@mui/x-data-grid-premium';
import { isEscapeKey } from '@mui/x-data-grid/utils/keyboardUtils';

import theme from 'containers/theme-provider/theme';

import { GridPanelRoot, GridPaperRoot } from './styles';
import { GridPanelClasses, GridPanelProps } from './types';


export const gridPanelClasses = generateUtilityClasses<keyof GridPanelClasses>('MuiDataGrid', [
  'panel',
  'paper',
]);

const GridPanel = forwardRef<HTMLDivElement, GridPanelProps>((props, ref) => {
  const { children, className, classes: classesProp, ...other } = props;
  const apiRef = useGridApiContext();
  const classes = gridPanelClasses;

  const [isPlaced, setIsPlaced] = useState(false);

  const modifiers = useMemo(() => [
    {
      name: 'flip',
      enabled: false,
    },
    {
      name: 'preventOverflow',
      enabled: true,
    },
    {
      name: 'offset',
      options: {
        offset: [-11, 2],
      },
    },
    {
      name: 'isPlaced',
      enabled: true,
      phase: 'main' as const,
      fn: () => {
        setIsPlaced(true);
      },
      effect: () => () => {
        setIsPlaced(false);
      },
    },
  ], []);

  const handleClickAway = useCallback(() => {
    apiRef.current.hidePreferences();
  }, [apiRef]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (isEscapeKey(event.key)) {
      apiRef.current.hidePreferences();
    }
  }, [apiRef]);

  const anchorEl = apiRef.current.columnHeadersContainerElementRef?.current;

  if (!anchorEl) {
    return null;
  }

  return (
    <GridPanelRoot
      ref={ref}
      placement="bottom-start"
      className={clsx(className, classes.panel)}
      anchorEl={anchorEl}
      modifiers={modifiers}
      transition
      {...other}
    >
      {({ TransitionProps }: { TransitionProps: FadeProps }) => (
        <Fade
          {...TransitionProps}
          timeout={theme.transitions.duration.standard}
        >
          <div>
            <ClickAwayListener
              mouseEvent="onMouseUp"
              onClickAway={handleClickAway}
            >
              <GridPaperRoot
                className={classes.paper}
                elevation={8}
                onKeyDown={handleKeyDown}
              >
                {isPlaced && children}
              </GridPaperRoot>
            </ClickAwayListener>
          </div>
        </Fade>
      )}
    </GridPanelRoot>
  );
});


GridPanel.displayName = 'GridPanel';


export default GridPanel;
