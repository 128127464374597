import { memo, FunctionComponent } from 'react';

import Tooltip from '@mui/material/Tooltip';

import AdaptiveText from 'components/common/adaptive-text';

import { getTooltipText, getBudgetText } from './utils';
import { Props } from './types';


const DashboardBudgetText: FunctionComponent<Props> = memo(({ value, campaignValue, realValue, rounding, channel, isGeoBudget = false, ...otherProps }) => (
  <Tooltip
    title={getTooltipText(realValue, campaignValue, channel, isGeoBudget)}
    placement="top"
    arrow
  >
    <div>
      <AdaptiveText {...otherProps}>
        {getBudgetText(value, campaignValue, channel, isGeoBudget, rounding)}
      </AdaptiveText>
    </div>
  </Tooltip>
));


DashboardBudgetText.displayName = 'DashboardBudgetText';


export default DashboardBudgetText;
