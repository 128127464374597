import { Components, Theme } from '@mui/material/styles';
import { menuItemClasses } from '@mui/material/MenuItem';


const overrides: Components['MuiList'] = {
  styleOverrides: {
    root: (props) => {
      const localTheme = props.theme as Theme;

      return {
        padding: 0,

        [`& .${menuItemClasses.root}`]: {
          minWidth: 160,
          padding: localTheme.spacing(1, 0),
          borderBottom: '1px solid #E8EAEE',
          justifyContent: 'space-between',
          ...localTheme.typography.subtitle1,

          '&:last-child': {
            borderBottom: 0,
          },

          '&:hover': {
            backgroundColor: 'inherit',
            color: localTheme.palette.primary.main,
          },
        },
      };
    },
  },
};


export default overrides;
