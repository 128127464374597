import LionstudiosApi from 'services/lionstudios-api';
import { CreateAppSettingPayload, EditAppSettingPayload, FetchOneAppSettingPayload } from './types';


const AdminAppSettingService = {
  fetchAll: async () => (await LionstudiosApi.get('appsettings')).data,
  fetchOne: async ({ appSettingId }: FetchOneAppSettingPayload) => (
    await LionstudiosApi.get(`appsettings/${appSettingId}`)
  ).data,
  create: async (payload: CreateAppSettingPayload) => {
    const { data } = payload;
    await LionstudiosApi.post<null>('appsettings', data);
    return payload;
  },
  edit: async (payload: EditAppSettingPayload) => {
    const { appSettingId, data } = payload;
    await LionstudiosApi.post<null>(`appsettings/${appSettingId}`, data);
    return payload;
  },
  setDefault: async ({ appSettingId }: { appSettingId: number }) => {
    await LionstudiosApi.post<null>(`appsettings/${appSettingId}/set-default`);
    return { appSettingId };
  },
  delete: async ({ appSettingId }: { appSettingId: number }) => {
    await LionstudiosApi.delete<null>(`appsettings/${appSettingId}`);
    return { appSettingId };
  },
};

export default AdminAppSettingService;
