import { styled } from '@mui/material/styles';

import { HEADER_MIN_HEIGHT } from 'components/common/header/styles';

import { FormWrapperProps } from './types';


export const Wrapper = styled('div')(() => ({
  height: '100%',
}));

export const FormWrapper = styled('div')<FormWrapperProps>(({ $formImage }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: $formImage ? `url('${$formImage}')` : 'inherit',
  backgroundSize: 'contain',
  backgroundPosition: 'bottom left',
  flex: 1,
}));

export const FormInner = styled('div')(() => ({
  width: '50%',
  marginTop: `-${HEADER_MIN_HEIGHT}`,
}));

export const BackgroundWrapper = styled('div')({
  flex: 1,
  lineHeight: 0,
  position: 'relative',
  overflow: 'hidden',

  '& img': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    minHeight: '100%',
    minWidth: '100%',
    transform: 'translate(-50%, -50%)',
    userDrag: 'none',
  },
});

export const StyledTitle = styled('p')({
  textAlign: 'center',
});

export const StyledDescription = styled('p')(({ theme }) => ({
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: theme.spacing(2),
  maxWidth: 350,

  ...theme.typography.body2,
}));
