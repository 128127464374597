import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';

import { DeliveryStatus, Entities } from 'core/dashboards/types';


export const STATUS_ICONS: Record<DeliveryStatus, any> = {
  [DeliveryStatus.INACTIVE]: InfoOutlinedIcon,
  [DeliveryStatus.UNCLASSIFIED]: ErrorOutlineIcon,
  [DeliveryStatus.ERROR]: ErrorOutlineIcon,
  [DeliveryStatus.PENDING]: ModeStandbyIcon,
  [DeliveryStatus.ACTIVE]: CheckCircleOutlineIcon,
  [DeliveryStatus.WARNING]: WarningAmberIcon,
};

export const STATUS_DESCRIPTION: Record<DeliveryStatus, (title: string) => string> = {
  [DeliveryStatus.INACTIVE]: (title: string) => `The ${title} is either completed or paused, in both case, no longer spending`,
  [DeliveryStatus.ERROR]: (title: string) => `The ${title} encounters an error which blocks spending`,
  [DeliveryStatus.PENDING]: (title: string) => `The ${title} or ad set is not running yet. It is in review or scheduled to run at a later date`,
  [DeliveryStatus.ACTIVE]: (title: string) => `The ${title} is spending as expected`,
  [DeliveryStatus.WARNING]: (title: string) => `The ${title} has an issue that does not block spending, limited delivery`,
  [DeliveryStatus.UNCLASSIFIED]: () => 'Unclassified',
};

export const DISABLED_STATUS_DESCRIPTION: Record<DeliveryStatus, (title: string) => string | null> = {
  [DeliveryStatus.INACTIVE]: (title: string) => `The ${title} is OFF, meaning there is no delivery`,
  [DeliveryStatus.ERROR]: () => null,
  [DeliveryStatus.PENDING]: () => null,
  [DeliveryStatus.ACTIVE]: () => null,
  [DeliveryStatus.WARNING]: () => null,
  [DeliveryStatus.UNCLASSIFIED]: () => null,
};

export const TITLE_BY_TYPE: Record<Entities.Campaigns | Entities.AdSets | Entities.Ads, string> = {
  [Entities.Campaigns]: 'Campaign',
  [Entities.AdSets]: 'AdSet',
  [Entities.Ads]: 'Ad',
};
