import React, { memo, useMemo, useCallback, useState } from 'react';
import update from 'immutability-helper';

import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { Filter } from 'core/settings/types';

import DashboardSaveFilterModal from 'components/dashboard/save-filter-modal';
import { SaveFilterFormValues } from 'components/dashboard/save-filter-modal/types';

import DashboardFilterRow from '../filter-row';
import DashboardOtherFiltersRow from '../other-filters-row';
import DashboardFiltersSkeleton from '../filters-skeleton';

import { ContentWrapper, CollapseStyles, Divider, Footer, ButtonWrapper, ResetButton } from './styles';
import { getOtherFilters, getNextFilters } from './utils';
import { Props } from './types';


const DashboardFilters: React.FunctionComponent<Props> = memo(({
  initialFilters,
  appliedFilters,
  appliedSavedFilter,
  onChange,
  onReset,
  onSubmit,
  onSave,
  onUpdate,
  isExpanded,
  isFetching,
  isSaveFetching,
  isSaveDisabled,
  publishMode,
}) => {
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const handleSaveModalClose = useCallback(() => {
    setIsSaveModalOpen(false);
  }, []);

  const handleSave = useCallback(() => {
    setIsSaveModalOpen(true);
  }, []);

  const handleSaveSubmit = useCallback((values: SaveFilterFormValues, isEditMode: boolean) => {
    if (isEditMode) {
      onUpdate(values);
    } else {
      onSave(values);
    }
    handleSaveModalClose();
  }, [onSave, onUpdate, handleSaveModalClose]);

  const otherFilters = useMemo(() => getOtherFilters(appliedFilters, initialFilters || []), [appliedFilters, initialFilters]);

  const handleAddFilters = useCallback((nextFilters: Filter[]) => {
    const concatedNextFilters = getNextFilters(nextFilters, appliedFilters);
    onChange(concatedNextFilters);
  }, [appliedFilters, onChange]);

  const handleDeleteFilter = useCallback((id: Filter['id']) => {
    const index = appliedFilters.findIndex((filter) => filter.id === id);
    const nextFilters = update(appliedFilters, { $splice: [[index, 1]] });
    onChange(nextFilters);
  }, [appliedFilters, onChange]);

  const handleResetFilter = useCallback((id: Filter['id']) => {
    const index = appliedFilters.findIndex((filter) => filter.id === id);
    const nextFilters = update(appliedFilters, {
      [index]: {
        values: { $set: [] },
      },
    });
    onChange(nextFilters);
  }, [appliedFilters, onChange]);

  const handleFilterChange = useCallback((id: Filter['id'], nextValues: Filter['values']) => {
    const filterIndex = appliedFilters.findIndex((filter) => filter.id === id);

    const nextFilters = update(appliedFilters, {
      [filterIndex]: {
        values: { $set: nextValues },
      },
    });

    onChange(nextFilters);
  }, [appliedFilters, onChange]);

  const handleResetAll = useCallback(() => {
    onReset();
  }, [onReset]);

  return (
    <>
      <Collapse
        in={isExpanded}
        sx={CollapseStyles}
      >
        <>
          <Divider />

          <ContentWrapper>

            {
              isFetching ? (
                <DashboardFiltersSkeleton />
              ) : (
                <>
                  {
                    appliedFilters.map((filter) => (
                      <DashboardFilterRow
                        key={filter.id}
                        onChange={handleFilterChange}
                        onDelete={handleDeleteFilter}
                        onReset={handleResetFilter}
                        publishMode={publishMode}
                        {...filter}
                      />
                    ))
                  }

                  <DashboardOtherFiltersRow
                    filterList={otherFilters}
                    onAddFilters={handleAddFilters}
                  />
                </>
              )
            }

            <Footer>
              <ButtonWrapper
                as={Stack}
                direction="row"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isFetching}
                  onClick={onSubmit}
                >
                  Apply
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  disabled={isSaveDisabled || isSaveFetching}
                  onClick={handleSave}
                >
                  Save filter set
                </Button>

                {appliedSavedFilter && (
                  <Typography
                    variant="body1"
                    color="secondary"
                  >
                    {`Current filter set: ${appliedSavedFilter.name}`}
                  </Typography>
                )}
              </ButtonWrapper>

              <ResetButton
                as={Button}
                variant="text"
                color="primary"
                endIcon={<RestartAltIcon />}
                onClick={handleResetAll}
              >
                Reset All Settings
              </ResetButton>
            </Footer>

          </ContentWrapper>
        </>
      </Collapse>

      <DashboardSaveFilterModal
        isOpen={isSaveModalOpen}
        onClose={handleSaveModalClose}
        onSave={handleSaveSubmit}
        filter={appliedSavedFilter}
      />
    </>
  );
});


DashboardFilters.displayName = 'DashboardFilters';


export default DashboardFilters;
