import { createActions } from 'utils/redux';

import { AppActions, Types, HttpErrorPayload, Payload } from './types';


export const PREFIX = 'APP';

export const actions = createActions<Types, Payload, AppActions>({
  [Types.INIT]: () => null,
  [Types.HANDLE_ERROR]: () => null,
  [Types.HANDLE_HTTP_ERROR]: (payload: HttpErrorPayload) => payload,
}, {
  prefix: PREFIX,
});

export default actions;
