import React, { memo, useCallback } from 'react';

import Button from '@mui/material/Button';

import theme from 'containers/theme-provider/theme';

import SelectedIndicator from 'components/common/selected-indicator';

import { StyledTab, SelectedIndicatorStyles } from './styles';
import { TabProps } from './types';


const Tab: React.FunctionComponent<TabProps> = memo(({ title, renderIcon, value, onClick, isActive, selectedCount, onSelectionChange, tabKey, color, backgroundColor }) => {

  const handleClick = useCallback((event) => {
    if (onClick) {
      onClick(event, value);
    }
  }, [onClick, value]);

  const handleResetSelection = useCallback(() => {
    onSelectionChange([], tabKey);
  }, [onSelectionChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledTab
      as={Button}
      onClick={handleClick}
      $isActive={isActive}
      $backgroundColor={backgroundColor}
    >
      {
        renderIcon ? renderIcon(isActive ? color : theme.palette.grey[300]) : null
      }

      {title}

      {
        selectedCount !== 0 && (
          <SelectedIndicator
            color={color}
            colorAlpha={1}
            isActive={isActive}
            sx={SelectedIndicatorStyles}
            onReset={handleResetSelection}
          >
            {selectedCount}
          </SelectedIndicator>
        )
      }
    </StyledTab>
  );
});


Tab.displayName = 'Tab';


export default Tab;
