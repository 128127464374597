import { styled } from '@mui/material/styles';

import { ActionTitleWrapperProps } from './types';


export const ActionIconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.grey[300],

  '& svg': {
    fontSize: '18px',
  },
}));

export const ActionTitleWrapper = styled('div')<ActionTitleWrapperProps>(({ theme, $isActive }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '&:hover svg': {
    color: theme.palette.primary.main,
  },

  '&, & svg, &:hover svg': {
    color: $isActive ? theme.palette.primary.main : 'inherit',
  },
}));

export const BreakdownActionIconWrapper = styled(ActionIconWrapper)(({ theme }) => ({
  marginRight: theme.spacing(0.5),
}));
