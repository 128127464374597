import React, { memo, useCallback, useState, useMemo, useEffect } from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import {
  Wrapper,
  TabWrapper,
  StyledCollapse,
} from './styles';

import { Props } from './types';
import { getGrouppedList, getInitialCategory, getCategoryFieldByName } from './utils';
import { ALL_CATEGORY } from './constants';


const TabList: React.FunctionComponent<Props> = memo(({ // TODO: refactor!!!
  data,
  categories,
  withAllTab,
  renderItem,
  renderCategory,
  noOptionsElement,
  expandAll,
  onCategoryChange = () => {},
}) => {
  const grouppedList = useMemo(() => getGrouppedList(data, categories, withAllTab), [data, categories, withAllTab]);

  const [currentCategory, setCurrentCategory] = useState(() => getInitialCategory(grouppedList));
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleCategoryChange = useCallback((event, nextCategory) => {
    if (nextCategory === null) {
      return;
    }

    if (!expandAll) {
      setExpanded([]);
    } else if (nextCategory !== ALL_CATEGORY) {
      const categoryField = getCategoryFieldByName(nextCategory, categories);
      if (categoryField) {
        setExpanded(data.map((item) => item[categoryField])); // TODO: move it somewhere
      }
    }

    setCurrentCategory(nextCategory);
  }, [categories, data, expandAll]);

  const handleExpandToggle = useCallback((subCategoryToToggle) => {
    if (expanded.includes(subCategoryToToggle)) {
      setExpanded(expanded.filter((subCategory) => subCategoryToToggle !== subCategory));
      return;
    }

    setExpanded([...expanded, subCategoryToToggle]);
  }, [expanded]);

  const list = useMemo(() => {
    if (!data.length) {
      return noOptionsElement;
    }

    if (currentCategory === ALL_CATEGORY) {
      return data.map((item) => renderItem(item));
    }

    return Object.entries(grouppedList[currentCategory]).sort((entryA, entryB) => {
      const [categoryNameA] = entryA;
      const [categoryNameB] = entryB;
      return categoryNameA.localeCompare(categoryNameB);
    }).map(([categoryName, categoryList]) => (
      <React.Fragment key={categoryName}>
        {renderCategory(categoryName, categoryList as any[], handleExpandToggle, expanded.includes(categoryName))}

        <StyledCollapse
          in={expanded.includes(categoryName)}
        >
          {(categoryList as any[]).map((item) => renderItem(item))}
        </StyledCollapse>
      </React.Fragment>
    ));
  }, [currentCategory, grouppedList, noOptionsElement, data, renderItem, renderCategory, handleExpandToggle, expanded]);

  useEffect(() => {
    if (currentCategory === ALL_CATEGORY) {
      return;
    }

    if (!expandAll) {
      setExpanded([]);
      return;
    }

    const categoryField = (categories.find(({ name }) => name === currentCategory) as any).field;
    setExpanded(data.map((item) => item[categoryField])); // TODO: expand on tab change
  }, [expandAll]);

  useEffect(() => {
    const categoryField = getCategoryFieldByName(currentCategory, categories);
    
    onCategoryChange(categoryField);
  }, [currentCategory]);

  return (
    <Wrapper>
      <TabWrapper>
        <ToggleButtonGroup
          value={currentCategory}
          exclusive
          fullWidth
          onChange={handleCategoryChange}
        >
          {
            Object.keys(grouppedList).map((category) => (
              <ToggleButton
                key={category}
                value={category}
                selected={currentCategory === category}
              >
                {category}
              </ToggleButton>
            ))
          }
        </ToggleButtonGroup>
      </TabWrapper>

      {list}
    </Wrapper>
  );
});


TabList.displayName = 'TabList';


export default TabList;
