import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import appActions from 'core/app/actions';


const AppStateProvider: React.FunctionComponent = ({ children = null }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appActions.init());
  }, [dispatch]);

  return children as React.ReactElement;
};


AppStateProvider.displayName = 'AppStateProvider';


export default AppStateProvider;
