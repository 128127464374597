import { MouseEvent } from 'react';

import { SavedViewMergedWithFields } from 'core/users/types';

import { Props as DataGridHeaderProps, ListItemProps } from 'components/dashboard/grid-header/types';
import DashboardSavedViewSelector from 'components/dashboard/saved-view-selector';
import { Props as SavedViewSelectorProps } from 'components/dashboard/saved-view-selector/types';
import { Props as DashboardGridProps } from 'components/dashboard/grid/types';
import { DropdownChildAsFunctionProps } from 'components/common/dropdown/types';


export const getSavedViews = (
  savedViews: SavedViewMergedWithFields[] | null,
  activeView: SavedViewMergedWithFields | null,
  isViewsFetching: boolean,
  processedView: SavedViewMergedWithFields | null,
  onViewChange: DashboardGridProps['onViewChange'],
  onViewDelete: SavedViewSelectorProps['onDeleteClick'],
  onSetDefaultViewClick: SavedViewSelectorProps['onDefaultClick'],
): DataGridHeaderProps['savedViews'] => {
  const defaultConfig = {
    isSearchable: false,
    items: [],
  };

  if (!savedViews) {
    return defaultConfig;
  }

  const items = savedViews.reduce<Array<ListItemProps>>((result, savedView) => {
    const { id, name: title, entity } = savedView;
    const isDisabled = isViewsFetching && (processedView?.id === id);

    result.push({
      key: id,
      title,
      onClick: (event: MouseEvent<HTMLElement>, { close }: DropdownChildAsFunctionProps) => {
        onViewChange(savedView, entity);
        close();
      },
      isActive: activeView?.id === id,
      disableRipple: true,
      renderContent: () => (
        <DashboardSavedViewSelector
          view={savedView}
          isDisabled={isDisabled}
          onDefaultClick={onSetDefaultViewClick}
          onDeleteClick={onViewDelete}
        />
      ),
    });

    return result;
  }, []);

  return {
    ...defaultConfig,
    items,
  };
};
