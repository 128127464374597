import { groupArrayByKey } from 'utils';

import { ALL_CATEGORY } from './constants';
import { Props } from './types';


export const getInitialCategory = (grouppedList: any) => Object.keys(grouppedList)[0];

export const getGrouppedList = (data: Props['data'], categories: Props['categories'], withAllTab: Props['withAllTab']): Record<string, any> => ({
  ...(withAllTab ? {
    [ALL_CATEGORY]: data,
  } : {}),
  ...categories.reduce<Record<string, any>>((result, { name, field }) => {
    result[name] = groupArrayByKey<any>(data, field);
    return result;
  }, {}),
});

export const getCategoryFieldByName = (categoryName: string, categories: Props['categories']): string | null => {
  const category = categories.find(({ name }) => name === categoryName);

  return category?.field || null;
};
