import { Components, Theme } from '@mui/material/styles';

import { muiActionClasses } from '../constants';


const overrides: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: (props) => {
      const localTheme = props.theme as Theme;

      return {
        height: 34,
        textTransform: 'none',
        border: 'none',
        background: localTheme.palette.grey['50'],
        color: localTheme.palette.primary.main,
        borderRight: `2px solid ${localTheme.palette.common.white}`,

        ...localTheme.typography.body1,

        [`&.${muiActionClasses.selected}`]: {
          color: localTheme.palette.common.black,
          backgroundColor: localTheme.palette.background.default,
        },
      };
    },
  },
};


export default overrides;
