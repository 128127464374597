import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  padding: theme.spacing(3.5),
}));

export const TitleWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3.75),
  textAlign: 'center',
}));

