import { styled } from '@mui/material/styles';


export interface ArrowIconProps {
  $isOpen: boolean;
}

export const ArrowIcon = styled('div')<ArrowIconProps>(({ theme, $isOpen }) => ({
  color: theme.palette.grey['400'],
  transform: `rotate(${$isOpen ? -180 : 0}deg)`,
  transition: theme.transitions.create('transform'),
}));

export const ButtonFlex = styled('button')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const PopperContainer = styled('div')(({ theme }) => ({
  // background: theme.palette.common.black,
  backgroundColor: '#0b0c0f',
  // width: 430,
  color: '#fff',
}));
