import { format } from 'date-fns';

import { DateRangeFilter, DateRangeFilterConverted, DateRangeRelativeFilter } from 'core/dashboards/types';

import { DEFAULT_LABEL, LABELS } from './constants';


export const getConvertedValue = (value: DateRangeFilter | undefined) => {
  if (!value) {
    return undefined;
  }

  return {
    from: new Date(value.from),
    to: new Date(value.to),
  };
};

export const getLabel = (value: DateRangeFilterConverted | undefined, relativeValue?: DateRangeRelativeFilter): { startLabel: string; endLabel?: string; startDate?: string | null; endDate?: string | null } => {
  if (relativeValue) {
    const today = new Date();

    if (today.getDate() === 1 && relativeValue === DateRangeRelativeFilter.MONTH_TO_DATE) {
      return {
        startLabel: 'First day of last month',
        endLabel: 'Yesterday',
      };
    }

    return {
      ...(LABELS[relativeValue] || {}),
      startDate: value?.from && format(value.from, 'P'),
      endDate: value?.to && format(value.to, 'P'),
    };
  }

  if (!value) {
    return {
      startLabel: DEFAULT_LABEL,
    };
  }

  const { from, to } = value;

  if (!from && !to) {
    return {
      startLabel: DEFAULT_LABEL,
    };
  }

  const start = from && format(from, 'P');
  const end = to && format(to, 'P');

  return {
    startLabel: start,
    endLabel: end,
  };
};
