import { Components, Theme } from '@mui/material/styles';


const overrides: Components['MuiTooltip'] = {
  styleOverrides: {
    tooltip: (props) => {
      const localTheme = props.theme as Theme;

      return {
        borderRadius: 4,
        fontSize: '10px',
        maxWidth: '350px',

        padding: localTheme.spacing(1),

        ...localTheme.typography.body1,

        'a': {
          color: '#83AEFF',
        },
      };
    },
  },
};


export default overrides;
