import memoize from 'lodash.memoize';
import { styled } from '@mui/material/styles';

import { inputBaseClasses } from '@mui/material/InputBase';
import { autocompleteClasses } from '@mui/material/Autocomplete';


export const PopperStyles = {
  width: 330,
};

export const StyledPaper = styled('div')(({ theme }) => ({
  boxShadow: '0px 7px 20px rgba(0, 0, 0, 0.15)',

  [`.${autocompleteClasses.listbox}`]: {
    padding: 0,
  },

  [`.${autocompleteClasses.option}`]: {
    paddingTop: 0,
    paddingBottom: 0,
    height: 40,
    ...theme.typography.body1,

    '&.Mui-focused, &.Mui-focusVisible': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.action.hover,
    },
  },

  [`.${autocompleteClasses.noOptions}`]: {
    color: theme.palette.text.primary,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'center',
  },
}));

export const AutocompleteStyles = memoize((isEditMode: boolean) => ({
  [`.${autocompleteClasses.inputRoot}`]: {
    cursor: 'default',
    paddingBottom: 0,

    [`.${autocompleteClasses.input}`]: {
      minWidth: isEditMode ? 100 : 0,
      flexGrow: isEditMode ? 1 : 0,
      ...(isEditMode ? {} : { padding: 0 }),
    },
  },

  [`.${inputBaseClasses.root}`]: {

    '&::after, &::before': {
      display: 'none',
    },
  },
}));
