export class ComplexType {
  baseType: string;

  valueType: string;

  constructor (baseType: string, valueType: string) {
    this.baseType = baseType;
    this.valueType = valueType;
  }
}
