import { memo, FunctionComponent } from 'react';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { Props } from './types';
import { Wrapper, TitleWrapper } from './styles';


const ConfirmationModal: FunctionComponent<Props> = memo(({ isOpen, onClose, onSubmit, title, confirmText }) => (
  <Modal
    open={isOpen}
    onClose={onClose}
  >
    <Wrapper
      as={Paper}
    >
      <TitleWrapper>
        <Typography
          variant="h2"
        >
          {title}
        </Typography>
      </TitleWrapper>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Button
          variant="contained"
          color="error"
          onClick={onSubmit}
        >
          {confirmText}
        </Button>

        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Stack>
    </Wrapper>
  </Modal>
));


ConfirmationModal.displayName = 'ConfirmationModal';


export default ConfirmationModal;
