import { FilterOptionsState } from '@mui/base/AutocompleteUnstyled';

import { CUSTOM_FILTER_NAMES } from 'core/settings/constants';
import { PLATFORM_NAMES } from 'core/dashboards/constants';
import { SkuAppNameOption, SkuAppNameValue, BaseValue } from 'core/settings/types';

import PlatformContainer from 'components/common/platform-container';
import Country from 'components/common/country';
import { getCountry } from 'components/common/country/utils';
import { Code } from 'components/common/country/types';
import { Option } from 'components/common/multiple-autocomplete-string/types';


export default {
  [CUSTOM_FILTER_NAMES.SKU_APP_NAME]: {
    renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: SkuAppNameOption) => (
      <li
        {...props}
        key={option.id}
      >
        <PlatformContainer platform={option.platform}>
          {option.name}
        </PlatformContainer>
      </li>
    ),
    renderTagLabel: (value: SkuAppNameValue) => `${value.name} - ${PLATFORM_NAMES[value.platform]}`,
  },

  [CUSTOM_FILTER_NAMES.GEO]: {
    renderOption: (props: React.HTMLAttributes<HTMLLIElement>, option: SkuAppNameOption) => (
      <li
        {...props}
        key={option.id}
      >
        <Country
          code={option.id as Code}
        />
      </li>
    ),
    renderTagLabel: (value: BaseValue) => getCountry(value.id as Code).name,
    filterOptions: (options: Option[], state: FilterOptionsState<Option>) => {
      const { inputValue } = state;
      const value = inputValue.toLowerCase();

      const filteredOptions = options.filter(({ id }) => {
        const { name } = getCountry(id as Code);

        return name.toLowerCase().includes(value) || (id as string).toLowerCase().includes(value);
      });
      
      return filteredOptions;
    },
  },
};
