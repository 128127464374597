import { styled } from '@mui/material/styles';

import { WrapperProps } from './types';


export const LinkIcon = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  transition: theme.transitions.create(['color', 'fill']),
  position: 'absolute',
  top: theme.spacing(0.625),
  right: theme.spacing(0.625),
}));

export const Wrapper = styled('a')<WrapperProps>(({ theme, $iconColor, $iconActiveColor }) => ({
  display: 'block',
  position: 'absolute',
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  left: 0,
  top: 0,

  '&:hover + * p': { // NOTE: highlight any p element next to link wrapper
    color: theme.palette.primary.main,
    transition: theme.transitions.create('color'),
  },

  [LinkIcon]: {
    ...($iconColor ? { fill: $iconColor } : {}),
  },

  '&:hover': {
    [LinkIcon]: {
      color: theme.palette.primary.main,
      ...($iconActiveColor ? { fill: $iconActiveColor } : {}),
    },
  },
}));
