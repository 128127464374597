import LionstudiosApi from 'services/lionstudios-api';

import { signInModel } from './models';
// import { AUTH_URL } from './constants';
import {
  SignInPayload,
  ResolvedAuthPayload,
  SignInResponse,
} from './types';


const AuthService = {
  signIn: async (data: SignInPayload): Promise<ResolvedAuthPayload> => {
    // const response = await LionstudiosApi.post<SignInResponse>(`${AUTH_URL}/login`, data);
    const response = await LionstudiosApi.post<SignInResponse>('login', data);

    return signInModel(response.data);
  },
};


export default AuthService;
