import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const StyledIconButton = styled('div')(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  padding: '1px',
  backgroundColor: theme.palette.grey[300],
  color: theme.palette.common.white,

  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));
