import React, { FormEvent, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import Button from '@mui/material/Button';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// import config from 'config';

import authActions from 'core/auth/actions';
import { getIsAuthorized } from 'core/auth/selectors';

import AuthLayout from 'components/auth/layout';

import Background from 'assets/images/sign-in-okta-bg.png';
import FormBackground from 'assets/images/sign-in-okta-form-bg.svg';
// import { ReactComponent as OctaIcon } from 'assets/icons/okta-icon.svg';
import { ROUTES } from 'containers/router/constants';

import { buttonStyles, subButtonStyles } from './styles';


interface State {
  email: string;
  password: string;
  showPassword: boolean;
  isPasswordForm: boolean;
  recaptchaCode: any;
}

const SignIn = memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthorized = useSelector(getIsAuthorized);


  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [values, setValues] = useState<State>({
    email: '',
    password: '',
    showPassword: false,
    isPasswordForm: true,
    recaptchaCode: '',
  });

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickPasswordForm = () => {
    setValues({
      ...values,
      isPasswordForm: !values.isPasswordForm,
    });
  };

  const handleLogin = useCallback((event: FormEvent) => {
    event.preventDefault();
    if (!values.recaptchaCode.length){
      alert('check reCAPTCHA');
      return;
    }
    dispatch(authActions.signIn({ email: values.email, password: values.password, recaptchaCode: values.recaptchaCode }));
  }, [values, dispatch]);

  const handleClickForgotPassword = useCallback(() => {
    navigate(ROUTES.FORGOT_PASSWORD);
  }, [navigate]);

  const handleRecaptcha = (key: any) => {
    setValues({
      ...values,
      recaptchaCode: key,
    });
  };

  const googleSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || '';
  const formView = (
    <Box
      component="form"
      onSubmit={handleLogin}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', m: '0 8px' }}
    >
      <FormControl
        sx={{ m: 1, width: '19rem' }}
        variant="outlined"
      >
        <InputLabel
          htmlFor="outlined-email"
          sx={{ color: 'currentColor', backgroundColor: '#fff', outline: '#fff solid 6px' }}
        >Email</InputLabel>
        <OutlinedInput
          id="outlined-email"
          type="text"
          value={values.email}
          onChange={handleChange('email')}
          required
        />
      </FormControl>
      <FormControl
        sx={{ m: 1, width: '19rem' }}
        variant="outlined"
      >
        <InputLabel
          htmlFor="outlined-adornment-password"
          sx={{ color: 'currentColor' }}
        >Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={values.showPassword ? 'text' : 'password'}
          value={values.password}
          onChange={handleChange('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          required
        />
      </FormControl>
      <div style={{ margin:'2rem 0' }}>
        <ReCAPTCHA
          onChange={handleRecaptcha}
          sitekey={googleSiteKey}
          type="image"
          size="normal"
        />
      </div>
      <Button
        type="submit"
        variant="outlined"
        sx={buttonStyles}
      >Submit</Button>
      <hr />
    </Box>
  );

  return (
    <AuthLayout
      image={Background}
      formImage={FormBackground}
    >
      {values.isPasswordForm && formView}
      {/*
      <Button
        variant="contained"
        href={config.OKTA_LOGIN_URL}
        startIcon={(<OctaIcon />)}
        sx={buttonStyles}
      >
        Sign In with Okta
      </Button>
      */}
      {
        !values.isPasswordForm && (
          <Button
            variant="text"
            size="small"
            sx={subButtonStyles}
            onClick={handleClickPasswordForm}
          >
            Sign In with password
          </Button>
        )
      }

      <Button
        variant="text"
        size="small"
        sx={subButtonStyles}
        onClick={handleClickForgotPassword}
      >
        Forgot Password
      </Button>

    </AuthLayout>
  );
});


SignIn.displayName = 'SignIn';


export default SignIn;
