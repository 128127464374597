import React, { memo, useMemo, forwardRef } from 'react';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { StyledInput, StyledInputAdornment } from './styles';


const SearchInput: React.FunctionComponent<TextFieldProps> = memo(forwardRef(({ ...otherProps }, ref) => {
  const inputProps = useMemo(() => ({
    endAdornment: (
      <StyledInputAdornment
        as={InputAdornment}
        position="end"
      >
        <SearchIcon />
      </StyledInputAdornment>
    ),
    ...(otherProps.InputProps || {}),
  }), [otherProps.InputProps]);

  return (
    <StyledInput
      as={TextField}
      inputRef={ref}
      placeholder="Search"
      InputProps={inputProps}
      fullWidth
      {...otherProps}
    />
  );
}));


SearchInput.displayName = 'SearchInput';


export default SearchInput;
