import { ChangeEvent, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';

import { Autocomplete, Box, Button, Chip, ClickAwayListener, Dialog, FormControl, IconButton, NativeSelect, Paper, Popper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import { ChevronRight, DeleteForever, FilterAlt } from '@mui/icons-material';
import moment from 'moment';

import { getActivityLogsIsLoading, getActivityLogs } from 'core/admin/activity-logs/selectors';
import { actions as adminActivityLogsAction } from 'core/admin/activity-logs/actions';
import { ActivityLog } from 'core/admin/activity-logs/types';
import { FilterOperator, FilterParameters, RequestParameters, SortDirection } from 'core/admin/types';
import theme from 'containers/theme-provider/theme';
import DateRangeFilterComponent from 'components/common/date-range-filter';
import { DateRangeFilter, DateRangeRelativeFilter } from 'core/dashboards/types';
import { getDateRangeFilterObject } from 'core/dashboards/utils';
import { BootstrapInput } from 'components/common/bootstrap-input/styles';
import { getUser } from 'core/admin/users/selectors';
import { actions as adminUsersAction } from 'core/admin/users/actions';
import AdminUsersService from 'core/admin/users/service';
import useQueryParams from 'hooks/use-query-params';

import SortButton from './sort-button';
import { ColDef, DateRangeObj, DEFAULT_DATE_RANGE_RELATIVE, FilterConfig, FILTER_FIELDS, QUERY_PARAMS, QUERY_PARAMS_WITH_TYPES } from './types';


const changesValuesDisplay = (values?: Record<string, any>, space?: number) => (values ? JSON.stringify(values, null, space) : '');

const ActivitiesIndex = () => {
  const dispatch = useDispatch();
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAMS_WITH_TYPES);

  const paginatedActivityLogs = useSelector(getActivityLogs);
  const isLoading = useSelector(getActivityLogsIsLoading);

  const [reqParams, setReqParams] = useState<RequestParameters>({
    page: 1,
    perPage: 50,
    sorts: [],
    filters: [],
  });

  const [dateRange, setDateRange] = useState<DateRangeObj>({
    dateRangeFilter: getDateRangeFilterObject(DEFAULT_DATE_RANGE_RELATIVE),
    dateRangeRelativeFilter: DEFAULT_DATE_RANGE_RELATIVE,
  });
  const handleSubmitDateRange = useCallback((nextDateRangeFilter: DateRangeFilter, nextDateRangeRelativeFilter?: DateRangeRelativeFilter) => {
    setDateRange({
      dateRangeFilter: nextDateRangeFilter,
      dateRangeRelativeFilter: nextDateRangeRelativeFilter,
    });
  }, [setDateRange]);

  const userId: number = queryParams[QUERY_PARAMS.USER_ID];
  const isSpecificUser = Boolean(userId);

  const user = useSelector(getUser);

  useEffect(() => {
    if (isSpecificUser && userId) {
      dispatch(adminUsersAction.fetchOne({ userId: userId }));
    }
  }, [isSpecificUser, userId, dispatch]);
  const handleRemoveSpecificUser = useCallback(() => {
    setQueryParams({});
  }, [queryParams, setQueryParams]);

  useEffect(() => {
    const dateFilters: FilterParameters[] = [
      {
        field: 'createdAt',
        operator: FilterOperator.GREATER_OR_EQUAL,
        value: moment(dateRange.dateRangeFilter.from).utc().format('Y-MM-DDTHH:mm:ss'),
      },
      {
        field: 'createdAt',
        operator: FilterOperator.LESS_OR_EQUAL,
        value: moment(dateRange.dateRangeFilter.to).utc().format('Y-MM-DDTHH:mm:ss'),
      },
    ];
    const userFilter: FilterParameters[] = isSpecificUser && userId ?  [
      {
        field: 'userId',
        operator: FilterOperator.EQUAL,
        value: userId.toString(),
      },
    ] : [];
    dispatch(adminActivityLogsAction.fetchAll({
      ...reqParams,
      filters: reqParams.filters.concat(dateFilters, userFilter),
    }));
  }, [dispatch, reqParams, dateRange, userId]);

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setReqParams({
      ...reqParams,
      page: newPage + 1,
    });
  }, [setReqParams, reqParams]);
  const handleChangePerPage = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setReqParams({
      ...reqParams,
      page: 1,
      perPage: parseInt(event.target.value, 10),
    });
  }, [setReqParams, reqParams]);

  const [ctxActivityLog, setCtxActivityLog] = useState<ActivityLog | null>(null);
  const [isOpenChangesModal, setIsOpenChangesModal] = useState(false);
  const handleOpenChangesModal = (item: ActivityLog) => () => {
    setCtxActivityLog(item);
    setIsOpenChangesModal(true);
  };
  const handleCloseChangesModal = useCallback(() => {
    setIsOpenChangesModal(false);
  }, [setIsOpenChangesModal]);

  const handleSetSort = (field: string) => (direction: SortDirection | null) => {
    // handling 1 sort for now
    setReqParams({
      ...reqParams,
      sorts: direction === null ? [] : [{ field, direction }],
    });
  };
  const getSortDir = useCallback((field: string): SortDirection | null => {
    for (let i = 0; i < reqParams.sorts.length; i++) {
      if (reqParams.sorts[i].field === field) {
        return reqParams.sorts[i].direction;
      }
    }
    return null;
  }, [reqParams]);

  const [filtersState, setFiltersState] = useState(reqParams.filters);
  const [filterPopoverAnchor, setFilterPopoverAnchor] = useState<HTMLElement | null>(null);
  const handleAddFilter = useCallback((field: string) => {
    setFiltersState([
      ...filtersState,
      {
        field,
        operator: FilterOperator.CONTAINS,
        value: '',
      },
    ]);
  }, [filtersState, setFiltersState]);
  const handleClickFilter = (field: string) => (event: MouseEvent<HTMLElement>) => {
    if (filtersState.findIndex((e) => e.field === field) === -1) {
      handleAddFilter(field);
    }
    setFilterPopoverAnchor(event.currentTarget);
    event.stopPropagation();
  };
  const filterFiltersState = useCallback(() => (
    filtersState.filter((v) => v.value.length)
  ), [filtersState]);
  const handleCloseFilter = useCallback(() => {
    setFilterPopoverAnchor(null);
    setFiltersState(filterFiltersState());
  }, [setFilterPopoverAnchor, filterFiltersState, setFiltersState]);

  const handleFilterChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const parsedName = event.target.name.split('-');
    const key = parsedName[0];
    const filterIdx = parseInt(parsedName[1]);
    const newFilters = [...filtersState];
    if (key === 'field') {
      newFilters[filterIdx] = {
        field: event.target.value,
        operator: FilterOperator.CONTAINS,
        value: '',
      };
    } else {
      newFilters[filterIdx] = {
        ...newFilters[filterIdx],
        [key]: event.target.value,
      };
    }
    setFiltersState(newFilters);
  }, [filtersState, setFiltersState]);
  const handleFilterDelete = useCallback((filterIdx: number) => {
    const newFilters = [...filtersState];
    newFilters.splice(filterIdx, 1);
    setFiltersState(newFilters);
  }, [filtersState, setFiltersState]);
  const handleFilterReset = useCallback(() => {
    setFiltersState([]);
    setReqParams({
      ...reqParams,
      filters: [],
    });
    // handleCloseFilter() // race condition with filterFilterState
    setFilterPopoverAnchor(null);
  }, [reqParams, setReqParams, setFiltersState, setFilterPopoverAnchor]);
  const handleFilterSubmit = useCallback(() => {
    setReqParams({
      ...reqParams,
      filters: filterFiltersState(),
    });
    handleCloseFilter();
  }, [filtersState, reqParams, setReqParams, filterFiltersState, handleCloseFilter]);

  const [autocompleteUserOptions, setAutocompleteUserOptions] = useState<string[]>([]);
  const [autocompleteUserInputValue, setAutocompleteUserInputValue] = useState<string>('');
  const [autocompleteUserLoading, setAutocompleteUserLoading] = useState<boolean>(false);
  const [autocompleteUserInputValueDebounced] = useDebounce(autocompleteUserInputValue, 300);
  const handleAutocompleteUserInputChange = useCallback((event: SyntheticEvent, value: string, reason: any) => {
    if (reason === 'reset') {
      return;
    }
    setAutocompleteUserInputValue(value);
  }, [setAutocompleteUserInputValue, setAutocompleteUserOptions]);
  useEffect(() => {
    if (autocompleteUserInputValueDebounced.length) {
      setAutocompleteUserLoading(true);
      AdminUsersService.autocomplete(autocompleteUserInputValueDebounced)
        .then((data) => {
          setAutocompleteUserOptions(data.map((e) => e.label));
          setAutocompleteUserLoading(false);
        });
    } else {
      setAutocompleteUserOptions([]);
    }
  }, [autocompleteUserInputValueDebounced, setAutocompleteUserOptions, setAutocompleteUserLoading]);

  const handleAutocompleteClearInputValue = useCallback(() => {
    setAutocompleteUserInputValue('');
  }, [setAutocompleteUserInputValue]);


  const handleFilterAutocompleteChange = useCallback((filterIdx: number) => (event: SyntheticEvent, value: string[]) => {
    const newFilters = [...filtersState];
    newFilters[filterIdx] = {
      ...newFilters[filterIdx],
      value: value.join(','),
    };
    setFiltersState(newFilters);
    handleAutocompleteClearInputValue();
  }, [filtersState, setFiltersState, handleAutocompleteClearInputValue]);

  const filterConfig = useMemo<FilterConfig>(() => ({
    event: {
      autocomplete: {
        options: [
          'Suspend', 'Activate',
          'Edit', 'Create',
        ],
      },
    },
    objectType: {
      autocomplete: {
        options: [
          'user',
        ],
      },
    },
    'user.fullName': {
      autocomplete: {
        options: autocompleteUserOptions,
        inputValue: autocompleteUserInputValue,
        loading: autocompleteUserLoading,
        onInputChange: handleAutocompleteUserInputChange,
      },
    },
  }), [autocompleteUserOptions, autocompleteUserInputValue, autocompleteUserLoading, handleAutocompleteUserInputChange]);

  const columns = useMemo<ColDef[]>(() => [
    {
      label: 'Date',
      setSort: handleSetSort('createdAt'),
      sortDirection: getSortDir('createdAt'),
    },
    { label: 'Time' },
    {
      label: 'User',
      setSort: handleSetSort('user.fullName'),
      sortDirection: getSortDir('user.fullName'),
      onClickFilter: handleClickFilter('user.fullName'),
    },
    { label: 'LionApp' },
    { label: 'Platform' },
    { label: 'Channel' },
    { label: 'Path' },
    {
      label: 'Element',
      setSort: handleSetSort('objectType'),
      sortDirection: getSortDir('objectType'),
      onClickFilter: handleClickFilter('objectType'),
    },
    {
      label: 'Action',
      setSort: handleSetSort('event'),
      sortDirection: getSortDir('event'),
      onClickFilter: handleClickFilter('event'),
    },
    {
      label: 'Prior Value',
      align: 'center',
    },
    {
      label: 'New Value',
      align: 'center',
    },
  ], [reqParams, handleSetSort, getSortDir, handleClickFilter]);

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='space-between'
        mb={2}
      >
        <Stack
          direction='row'
          alignItems='center'
          gap={2}
        >
          <DateRangeFilterComponent
            value={dateRange.dateRangeFilter}
            relativeValue={dateRange.dateRangeRelativeFilter}
            isFetching={false}
            onSubmit={handleSubmitDateRange}
          />
          {
            isSpecificUser && (
              <Chip
                label={user?.fullName ?? '-'}
                onDelete={handleRemoveSpecificUser}
                // color='primary'
                variant='outlined'
              />
            )
          }
        </Stack>
        <div></div>
      </Stack>

      <Paper>
        <TableContainer>
          <Table
            size='small'
            sx={{
              '& .MuiTableCell-head': {
                padding: '0',
              },
              '& .MuiTableCell-root': {
                border: '1px solid',
                borderColor: theme.palette.grey[300],
                '&:first-child': {
                  borderLeft: 'none',
                },
                '&:last-child': {
                  borderRight: 'none',
                },
              },
            }}
          >
            <TableHead>
              <TableRow>
                {
                  columns.map((col, idx) => (
                    <TableCell key={idx}>
                      <Stack>
                        <Typography
                          fontWeight='600'
                          align={col.align}
                          padding='12px 16px'
                        >{col.label}</Typography>
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          borderTop={1}
                          borderColor={theme.palette.grey[300]}
                          height='34px'
                          padding='0 12px'
                        >
                          <div>{
                            col.onClickFilter && (
                              <IconButton
                                size='small'
                                sx={{
                                  fontSize: '14px',
                                  color: theme.palette.grey[400],
                                }}
                                onClick={col.onClickFilter}
                              >
                                <FilterAlt
                                  fontSize='inherit'
                                />
                              </IconButton>
                            )
                          }</div>
                          <div>{
                            col.setSort && (
                              <SortButton
                                direction={col.sortDirection ?? null}
                                setSort={col.setSort}
                              />
                            )
                          }</div>
                        </Stack>
                      </Stack>
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& .MuiTableRow-root .MuiTableCell-root': {
                  fontWeight: '400',
                },
              }}
            >
              {
                paginatedActivityLogs.data.map((item) => {
                  const createdAt = item.createdAt ? moment.utc(item.createdAt) : null;
                  return (
                    <TableRow
                      key={item.id}
                    >
                      <TableCell>{createdAt?.format('Y-MM-DD')}</TableCell>
                      <TableCell>{createdAt?.format('h:mm A')}</TableCell>
                      <TableCell>{item.user?.fullName}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{item.objectType}</TableCell>
                      <TableCell>{item.event}</TableCell>
                      <TableCell align='center'>
                        <Button
                          variant='text'
                          size='small'
                          onClick={handleOpenChangesModal(item)}
                        >
                          <Typography noWrap>{changesValuesDisplay(item.oldValues)}</Typography>
                        </Button>
                      </TableCell>
                      <TableCell align='center'>
                        <Button
                          variant='text'
                          size='small'
                          onClick={handleOpenChangesModal(item)}
                        >
                          <Typography noWrap>{changesValuesDisplay(item.newValues)}</Typography>
                        </Button>
                      </TableCell>

                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPage={paginatedActivityLogs.perPage}
          page={paginatedActivityLogs.page - 1}
          count={paginatedActivityLogs.total}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangePerPage}
        />
      </Paper>
      <Dialog
        open={isOpenChangesModal}
        onClose={handleCloseChangesModal}
        maxWidth='md'
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          padding={2}
          width='900px'
        >
          <Box
            width='46%'
            alignSelf='stretch'
          >
            <Typography variant='h2'>Prior Values</Typography>
            <pre
              style={{
                border: '1px solid rgba(224,224,224,1)',
                padding: '4px',
              }}
            >{changesValuesDisplay(ctxActivityLog?.oldValues, 2)}</pre>
          </Box>
          <ChevronRight/>
          <Box
            width='46%'
            alignSelf='stretch'
          >
            <Typography variant='h2'>New Values</Typography>
            <pre
              style={{
                border: '1px solid rgba(224,224,224,1)',
                padding: '4px',
              }}
            >{changesValuesDisplay(ctxActivityLog?.newValues, 2)}</pre>
          </Box>
        </Stack>
      </Dialog>
      <ClickAwayListener onClickAway={handleCloseFilter}>
        <Popper
          anchorEl={filterPopoverAnchor}
          open={Boolean(filterPopoverAnchor)}
          placement='bottom-start'
        >
          <Paper
            elevation={8}
            sx={{
              overflow: 'hidden',
              minWidth: '608px',
            }}
          >
            <Stack
              padding='20px'
              gap={2}
              maxHeight='400px'
              overflow='auto'
            >
              {
                filtersState.map((filter, i) => (
                  <Stack
                    direction='row'
                    alignItems='center'
                    gap={2}
                    key={i}
                  >
                    <IconButton
                      size='small'
                      onClick={() => handleFilterDelete(i)}
                    ><DeleteForever fontSize='small' /></IconButton>
                    <FormControl
                      sx={{
                        width: '150px',
                      }}
                    >
                      <NativeSelect
                        onChange={handleFilterChange}
                        name={`field-${i}`}
                        value={filter.field}
                        input={<BootstrapInput/>}
                      >
                        {
                          FILTER_FIELDS.map((filterField, j) => (
                            <option
                              value={filterField.key}
                              key={j}
                            >{filterField.label}</option>
                          ))
                        }
                      </NativeSelect>
                    </FormControl>
                    <FormControl
                      sx={{
                        width: '150px',
                      }}
                    >
                      <NativeSelect
                        onChange={handleFilterChange}
                        name={`operator-${i}`}
                        value={filter.operator}
                        input={<BootstrapInput/>}
                      >
                        <option value={FilterOperator.CONTAINS}>Contains</option>
                        <option value={FilterOperator.EQUAL}>Equal</option>
                        {
                          filterConfig[filter.field]?.autocomplete &&
                            <option value={FilterOperator.IN}>In</option>
                        }
                      </NativeSelect>
                    </FormControl>
                    {
                      filter.operator === FilterOperator.IN && filterConfig[filter.field]?.autocomplete ? (
                        <Autocomplete
                          multiple
                          openOnFocus
                          options={filterConfig[filter.field]?.autocomplete?.options ?? []}
                          onChange={handleFilterAutocompleteChange(i)}
                          onInputChange={filterConfig[filter.field]?.autocomplete?.onInputChange}
                          filterOptions={filterConfig[filter.field]?.autocomplete?.onInputChange ? (x) => x : undefined}
                          value={filter.value.length ? filter.value.split(',') : []}
                          inputValue={filterConfig[filter.field]?.autocomplete?.inputValue}
                          loading={filterConfig[filter.field]?.autocomplete?.loading}
                          // size='small'
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                minWidth: '190px',
                                maxWidth: '400px',
                              }}
                            />
                          )}
                        />
                      ) : (
                        <FormControl
                          size='small'
                          sx={{
                            width: '190px',
                          }}
                        >
                          <BootstrapInput
                            placeholder='Filter value'
                            onChange={handleFilterChange}
                            name={`value-${i}`}
                            value={filter.value}
                          />
                        </FormControl>
                      )
                    }
                  </Stack>
                ))
              }
            </Stack>
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{
                backgroundColor: theme.palette.grey[200],
                padding: '16px 20px',
              }}
            >
              <Stack
                direction='row'
                gap={2}
              >
                <Button
                  variant='outlined'
                  onClick={() => handleAddFilter(FILTER_FIELDS[0].key)}
                  sx={{
                    minWidth: '100px',
                    width: 'fit-content',
                    height: '30px',
                  }}
                >+ Add filter</Button>
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={handleFilterReset}
                  sx={{
                    minWidth: '100px',
                    width: 'fit-content',
                    height: '30px',
                  }}
                >Reset all</Button>
              </Stack>
              <Stack
                direction='row'
                gap={2}
              >
                <Button
                  variant='outlined'
                  onClick={handleCloseFilter}
                  color='secondary'
                  sx={{
                    minWidth: '100px',
                    width: 'fit-content',
                    height: '30px',
                  }}
                >Cancel</Button>
                <Button
                  variant='contained'
                  onClick={handleFilterSubmit}
                  sx={{
                    minWidth: '100px',
                    width: 'fit-content',
                    height: '30px',
                  }}
                >Apply</Button>
              </Stack>
            </Stack>
          </Paper>
        </Popper>
      </ClickAwayListener>
    </div>
  );
};

ActivitiesIndex.displayName = 'ActivitiesIndex';

export default ActivitiesIndex;
