import React, { memo, useCallback, useState, useRef, ReactElement } from 'react';
import update from 'immutability-helper';

import Autocomplete, { AutocompleteRenderInputParams, AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import Input from '@mui/material/Input';
import Popper, { PopperProps } from '@mui/material/Popper';
import Paper, { PaperProps } from '@mui/material/Paper';

import Tag from 'components/common/tag';
import AddButton from 'components/common/add-button';

import { getOptionLabel } from './utils';
import { PopperStyles, StyledPaper, AutocompleteStyles } from './styles';
import { Props, Values } from './types';


const MultipleAutocomplete: React.FunctionComponent<Props> = memo(({
  options,
  values,
  onChange,
  onInputChange,
  renderTagLabel,
  inputProps,
  disableInclude,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const handleAddButtonClick = useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation();
    setIsEditMode(true);

    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleBlur = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const handleInputChange = useCallback((event: React.SyntheticEvent, nextValue: string) => {
    setInputValue(nextValue);

    if (onInputChange) {
      onInputChange(nextValue);
    }
  }, [onInputChange]);

  const renderInput = useCallback((params: AutocompleteRenderInputParams): ReactElement => {
    const { inputProps: renderInputProps, InputProps, InputLabelProps, ...restInputProps } = params;

    return (
      <Input
        inputProps={{
          ...renderInputProps,
          ...inputProps,
        }}
        {...InputProps}
        {...restInputProps}
        inputRef={inputRef}
        type="text"
        autoComplete="new-password"
        endAdornment={
          !isEditMode && (
            <AddButton
              onClick={handleAddButtonClick}
            />
          )
        }
      />
    );
  }, [isEditMode, handleAddButtonClick, inputProps]);

  const PopperComponent = useCallback((params: PopperProps) => (
    <Popper
      {...params}
      style={PopperStyles}
      anchorEl={inputRef.current}
      placement="bottom-start"
    />
  ), [inputRef]);

  const PaperComponent = useCallback((params: PaperProps) => (
    <StyledPaper
      as={Paper}
      {...params}
    />
  ), []);

  const handleChange = useCallback((event: React.SyntheticEvent, nextValue: Values, reason) => {
    onChange(nextValue, reason, inputValue);
  }, [onChange, inputValue]);

  const handleIncludeChange = useCallback((include: boolean, index: number) => {
    const nextValues = update(values, {
      [index]: {
        include: { $set: include },
      },
    });

    onChange(nextValues, null);
  }, [values, onChange]);

  const handleTagDelete = useCallback((index: number) => () => {
    const nextValues = update(values, { $splice: [[index, 1]] });
    onChange(nextValues, null);
  }, [onChange, values]);

  const renderTags = useCallback((valuesToRender: Values, getTagProp: AutocompleteRenderGetTagProps) => valuesToRender.map((valueToRender, index: number) => {
    const { key, onDelete, ...restTagProps } = getTagProp({ index });

    return (
      <Tag
        key={key}
        index={index}
        onIncludeChange={handleIncludeChange}
        onDelete={onDelete || handleTagDelete(index)}
        value={valueToRender}
        renderLabel={renderTagLabel}
        disableInclude={disableInclude}
        {...restTagProps}
      />
    );
  }), [handleIncludeChange, handleTagDelete, disableInclude]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      options={options}
      renderInput={renderInput}
      getOptionLabel={getOptionLabel}
      multiple
      disableClearable
      filterSelectedOptions
      value={values}
      inputValue={inputValue}
      forcePopupIcon={false}
      onChange={handleChange}
      onInputChange={handleInputChange}
      fullWidth
      renderTags={renderTags}
      disablePortal
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
      openOnFocus
      onBlur={handleBlur}
      readOnly={!isEditMode}
      sx={AutocompleteStyles(isEditMode)} // NOTE: I didn't find a way how we can pass custom props to MUI component and don't loose props definition at the same time
      {...rest}
    />
  );
});


MultipleAutocomplete.displayName = 'MultipleAutocomplete';


export default MultipleAutocomplete;
