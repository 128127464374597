import { styled } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';


export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2.5),
}));

export const SavedFiltersPopoverPaperStyles = {
  sx: {
    borderRadius: '12px',
    borderTopLeftRadius: 0,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.15)',
    width: '400px',
    padding: 0,
  },
};

export const SavedFiltersButtonWrapper = styled('div')({
  position: 'relative',
});

export const ShareButton = styled(LoadingButton)(({ theme }) => ({
  marginLeft: theme.spacing(1.25),
  width: 38,
  minWidth: 38,
  padding: 0,
}));
