import { Props } from './types';
import { PROPS_TO_COMPARE } from './constants';


export const rerenderComparator = (prevProps: Props, nextProps: Props): boolean => {
  for (let i = 0; i < PROPS_TO_COMPARE.length; i++) {
    const prop = PROPS_TO_COMPARE[i] as keyof Props;

    if (prevProps[prop] !== nextProps[prop]) {
      return false;
    }
  }

  return true;
};
