import { styled } from '@mui/material/styles';

import { ButtonsWrapperProps } from './types';


export const Button = styled('div')(({ theme }) => ({
  padding: theme.spacing(0.25),

  '& svg': {
    color: theme.palette.grey[500],
    fontSize: 14,
  },

  '&:hover': {
    '& svg': {
      color: theme.palette.common.black,
    },
  },
}));

export const ButtonsWrapper = styled('div')<ButtonsWrapperProps>(({ theme, $isSelected }) => ({
  display: $isSelected ? 'flex' : 'none',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  margin: 'auto',
  background: 'linear-gradient(88.7deg, #fff 60.89%, rgba(0, 0, 0, 0) 105.11%)',
  padding: theme.spacing(0, 3, 0, 0.5),
  zIndex: 9,
  pointerEvents: 'none',
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  pointerEvents: 'auto',

  '&:last-child': {
    paddingRight: theme.spacing(1),
  },
}));
