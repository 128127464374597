import { createActions } from 'utils/redux';

import { PREFIX } from './constants';
import { Actions, FetchOneUserPayload, Payload, Types } from './types';



export const actions = createActions<Types, Payload, Actions>({
  [Types.FETCH_ALL]: () => null,
  [Types.FETCH_ONE]: (payload: FetchOneUserPayload) => payload,
  [Types.FETCH_ONE_RESET]: () => null,
}, {
  prefix: PREFIX,
});


export default actions;
