import { State } from 'core/types';
import { KEY } from './constants';


const getLocalState = (state: State) => state[KEY];

export const getActivityLogs = (state: State) => getLocalState(state).fetchAll.data;
export const getActivityLogsIsLoading = (state: State) => getLocalState(state).fetchAll.isLoading;
export const getActivityLogsError = (state: State) => getLocalState(state).fetchAll.error;

export const getActivityLog = (state: State) => getLocalState(state).fetchOne.data;
export const getActivityLogIsLoading = (state: State) => getLocalState(state).fetchOne.isLoading;
export const getActivityLogError = (state: State) => getLocalState(state).fetchOne.error;
