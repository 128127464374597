import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { State } from './types';


const persistConfig: PersistConfig<State> = {
  key: 'auth',
  storage,
  blacklist: [
    'isFetching',
    'isTokenValid',
    'isRecoverPasswordFetching',
    'isResetPasswordFetching',
  ],
};


export default persistConfig;
