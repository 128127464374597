import { TypographyProps } from '@mui/material';
import { SortDirection } from 'core/admin/types';
import { DateRangeFilter, DateRangeRelativeFilter } from 'core/dashboards/types';
import { TYPES } from 'hooks/use-query-params/constants';
import { MouseEvent, SyntheticEvent } from 'react';


export const DEFAULT_DATE_RANGE_RELATIVE = DateRangeRelativeFilter.LAST_7_DAYS_TODAY;

export const FILTER_FIELDS = [
  {
    key: 'user.fullName',
    label: 'User',
  },
  {
    key: 'objectType',
    label: 'Element',
  },
  {
    key: 'event',
    label: 'Action',
  },
];

export type ColDef = {
  label: string;
  align?: TypographyProps['align'];
  setSort?: (direction: SortDirection | null) => void;
  sortDirection?: SortDirection | null;
  onClickFilter?: (event: MouseEvent<HTMLElement>) => void;
};

export type DateRangeObj = {
  dateRangeFilter: DateRangeFilter;
  dateRangeRelativeFilter?: DateRangeRelativeFilter;
};

export const QUERY_PARAMS = {
  USER_ID: 'userId',
};
export const QUERY_PARAMS_WITH_TYPES = {
  userId: TYPES.NUMBER,
};

export type FilterConfig = {
  [field: string]: {
    autocomplete?: {
      options: string[];
      inputValue?: string;
      loading?: boolean;
      onInputChange?: (event: SyntheticEvent, value: string, reason: any) => void;
    };
  };
};
