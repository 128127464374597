import LionstudiosApi from 'services/lionstudios-api';
import { CreatePermissionTemplatePayload, EditPermissionTemplatePayload, FetchOnePermissionTemplatePayload } from './types';


const AdminPermissionTemplateService = {
  fetchAll: async () => (await LionstudiosApi.get('permissions')).data,
  fetchOne: async ({ permissionTemplateId }: FetchOnePermissionTemplatePayload) => (
    await LionstudiosApi.get(`permissions/${permissionTemplateId}`)
  ).data,
  create: async (payload: CreatePermissionTemplatePayload) => {
    const { data } = payload;
    await LionstudiosApi.post<null>('permissions', data);
    return payload;
  },
  edit: async (payload: EditPermissionTemplatePayload) => {
    const { permissionTemplateId, data } = payload;
    await LionstudiosApi.post<null>(`permissions/${permissionTemplateId}`, data);
    return payload;
  },
  setDefault: async ({ permissionTemplateId }: { permissionTemplateId: number }) => {
    await LionstudiosApi.post<null>(`permissions/${permissionTemplateId}/set-default`);
    return { permissionTemplateId };
  },
  delete: async ({ permissionTemplateId }: { permissionTemplateId: number }) => {
    await LionstudiosApi.delete<null>(`permissions/${permissionTemplateId}`);
    return { permissionTemplateId };
  },
};

export default AdminPermissionTemplateService;
