import { ExpressionNode } from '@rsql/ast';

import { CUSTOM_FILTER_NAMES, CUSTOM_FILTER_FIELDS, SKU_PLATFORM_NULL_OPTION } from 'core/settings/constants';
import { SkuAppNameValue, Value } from 'core/settings/types';

import builder from './builder';


export default {
  [CUSTOM_FILTER_NAMES.SKU_APP_NAME]: (values: SkuAppNameValue[]) => {
    const equals: ExpressionNode[] = [];
    const notEquals: ExpressionNode[] = [];

    values.forEach((value) => {
      const comparisonOperator = value.include ? builder.eq : builder.neq;
      const logicOperator = value.include ? builder.and : builder.or;
      const targetArray = value.include ? equals : notEquals;

      targetArray.push(logicOperator(
        comparisonOperator(CUSTOM_FILTER_FIELDS[CUSTOM_FILTER_NAMES.SKU_APP_NAME].NAME, value.name),
        comparisonOperator(CUSTOM_FILTER_FIELDS[CUSTOM_FILTER_NAMES.SKU_APP_NAME].PLATFORM, value.platform),
      ));
    });

    if (!notEquals.length) {
      return builder.or(...equals);
    }

    if (!equals.length) {
      return builder.and(...notEquals);
    }

    return builder.and(
      builder.or(...equals),
      builder.and(...notEquals),
    );
  },
  [CUSTOM_FILTER_NAMES.SKU_PLATFORM]: (values: Value[]) => {
    const expressions = [];
    const included: string[] = [];
    const excluded: string[] = [];

    values.forEach((value) => {
      if (value.id === SKU_PLATFORM_NULL_OPTION.id) {
        expressions.push((value.include ? builder.eq : builder.neq)(CUSTOM_FILTER_NAMES.SKU_PLATFORM, value.id));
        return;
      }

      if (value.include) {
        included.push(String(value.id));
      } else {
        excluded.push(String(value.id));
      }
    });

    if (included.length) {
      expressions.push(builder.in(CUSTOM_FILTER_NAMES.SKU_PLATFORM, included));
    }

    if (excluded.length) {
      expressions.push(builder.out(CUSTOM_FILTER_NAMES.SKU_PLATFORM, excluded));
    }

    return builder.and(...expressions);
  },
};
