import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { State } from 'core/types';
import { KEY as AUTH_KEY } from 'core/auth/constants';
import { KEY as DASHBOARDS_KEY } from 'core/dashboards/constants';
import { KEY as SETTINGS_KEY } from 'core/settings/constants';
import { KEY as USERS_KEY } from 'core/users/constants';
import { KEY as ADMIN_USERS_KEY } from './admin/users/constants';
import { KEY as ADMIN_PERMISSION_TEMPLATES_KEY } from './admin/permission-templates/constants';
import { KEY as ADMIN_ACTIVITY_LOGS_KEY } from './admin/activity-logs/constants';
import { KEY as ADMIN_APPSETTINGS_KEY } from './admin/appsettings/constants';


export const rootPersistConfig: PersistConfig<State> = {
  key: 'root',
  stateReconciler: autoMergeLevel2,
  blacklist: [
    AUTH_KEY,
    DASHBOARDS_KEY,
    SETTINGS_KEY,
    USERS_KEY,
    ADMIN_USERS_KEY,
    ADMIN_PERMISSION_TEMPLATES_KEY,
    ADMIN_ACTIVITY_LOGS_KEY,
    ADMIN_APPSETTINGS_KEY,
  ],
  storage,
};
