import LionstudiosApi from 'services/lionstudios-api';
import { RequestParameters } from 'core/admin/types';
import { FetchOneActivityLogPayload } from './types';


const AdminPermissionTemplateService = {
  fetchAll: async (params: RequestParameters) => (await LionstudiosApi.get('activity-log', { params })).data,
  fetchOne: async ({ activityLogId }: FetchOneActivityLogPayload) => (
    await LionstudiosApi.get(`activity-log/${activityLogId}`)
  ).data,
};

export default AdminPermissionTemplateService;
