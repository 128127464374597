import { styled, alpha } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';

import { SelectedIndicatorProps, ResetButtonProps } from './types';


export const ResetButton = styled(IconButton)<ResetButtonProps>(({ theme }) => ({
  fontSize: '1rem',
  padding: 0,
  position: 'absolute',
  right: theme.spacing(0.6),

  opacity: 0,
  visibility: 'hidden',
  overflow: 'hidden',
  width: 0,

  '& > svg': {
    color: alpha(theme.palette.common.white, 0.6),
  },
}));

export const StyledSelectedIndicator = styled('div')<SelectedIndicatorProps>(({ theme, $color, $colorAlpha }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  minWidth: 21,
  minHeight: 21,

  borderRadius: 16,
  padding: theme.spacing(0, 0.75),

  backgroundColor: alpha($color, $colorAlpha as number),
  color: theme.palette.common.white,
  ...theme.typography.body2,

  transition: theme.transitions.create('padding'),

  '&:hover, &:focus': {
    paddingRight: theme.spacing(3),

    [ResetButton]: {
      opacity: 1,
      visibility: 'visible',
      width: 'auto',
      marginLeft: theme.spacing(0.6),
    },
  },
}));


export const StyledPaper = styled('div')({
  boxShadow: 'none',
});
