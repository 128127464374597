import { styled } from '@mui/material/styles';
import { inputBaseClasses } from '@mui/material/InputBase';

import { EditButtonWrapperProps } from './types';


export const Wrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const EditButtonWrapper = styled('div')<EditButtonWrapperProps>(({ theme, $isDisabled }) => ({
  flex: 0,
  transition: theme.transitions.create('opacity'),

  ...($isDisabled ? {
    visibility: 'hidden',
    pointerEvents: 'none',
  } : {}),
  
  '& svg': {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));

export const SubmitButtonWrapper = styled(EditButtonWrapper)<EditButtonWrapperProps>(({ theme }) => ({
  '& svg': {
    fontSize: 18,
  },
}));

export const CellWrapper = styled('div')(({ theme }) => ({
  [`.${inputBaseClasses.root}`]: {
    color: theme.palette.primary.main,

    '&::after, &::before': {
      display: 'none',
    },

    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },

    'input[type=number]': {
      appearance: 'textfield',
    },
  },
}));

export const RestartButtonWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.25),
  left: theme.spacing(0.25),
  padding: theme.spacing(0.25),

  '& svg': {
    fontSize: 14,
    color: theme.palette.primary.main,
  },
}));
