import { styled } from '@mui/material/styles';


export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.2)',
  lineHeight: 0,

  '& .fi': {
    width: '20px',
    height: '13px',
    backgroundSize: 'cover',
  },

  '& svg': {
    fontSize: '20px',
  },
}));
