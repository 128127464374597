import React from 'react';

import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import { INFO_TEXT } from './constants';
import { Wrapper, InfoIcon } from './styles';


const RowSummaryTitle: React.FunctionComponent = () => (
  <Wrapper>
    {/* TODO: create info component */}
    <Tooltip
      title={INFO_TEXT}
      placement="right"
      arrow
    >
      <InfoIcon
        as={InfoOutlinedIcon}
      />
    </Tooltip>

    <Typography>
      Total
    </Typography>
  </Wrapper>
);


RowSummaryTitle.displayName = 'RowSummaryTitle';


export default RowSummaryTitle;
