import { memo, FunctionComponent, useState, useCallback, MouseEvent } from 'react';

import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Button from '@mui/material/Button';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Skeleton from '@mui/material/Skeleton';

import DateRangePicker from 'components/common/date-range-picker';

import { Props } from './types';
import { DIVIDER } from './constants';
import { getConvertedValue, getLabel } from './utils';
import { StyledButton, StyledIcon, PaperStyles, Label, SubLabel, LabelWrapper } from './styles';


const backdropProps = {
  invisible: true,
};

const paperProps = {
  sx: PaperStyles,
};

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const popoverAnchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

const DateRangeFilter: FunctionComponent<Props> = memo(({ value, relativeValue, onSubmit, isFetching }) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const convertedValue = getConvertedValue(value);

  const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchor(null);
  }, []);

  const handleSubmit = useCallback((nextValue, nextRelativeValue) => {
    handleClose();
    onSubmit({
      from: nextValue.from.getTime(),
      to: nextValue.to.getTime(),
    }, nextRelativeValue);
  }, [onSubmit, handleClose]);

  const { startLabel, endLabel, startDate, endDate } = getLabel(convertedValue, relativeValue);

  return (
    <>
      <StyledButton
        as={Button}
        variant="outlined"
        color="primary"
        onClick={handleClick}
        endIcon={(
          <StyledIcon as={CalendarTodayIcon} />
        )}
      >
        {
          isFetching ? (
            <Skeleton
              variant="text"
              animation="pulse"
              width="100%"
            />
          ) : (
            <div>
              <LabelWrapper>
                <div>
                  <Label
                    $isStyled={Boolean(startDate)}
                  >
                    {startLabel}
                  </Label>

                  {startDate && (
                    <SubLabel>
                      {startDate}
                    </SubLabel>
                  )}
                </div>

                {endLabel && (
                  <div>
                    <Label
                      $isStyled={Boolean(endDate)}
                    >
                      {DIVIDER}
                    </Label>
                  </div>
                )}

                {endLabel && (
                  <div>
                    <Label
                      $isStyled={Boolean(endDate)}
                    >
                      {endLabel}
                    </Label>

                    {endDate && (
                      <SubLabel>
                        {endDate}
                      </SubLabel>
                    )}
                  </div>
                )}
              </LabelWrapper>
            </div>
          )
        }
      </StyledButton>

      <Popover
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        BackdropProps={backdropProps}
        anchorOrigin={popoverAnchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={paperProps}
      >
        <DateRangePicker
          value={convertedValue}
          relativeValue={relativeValue}
          onSubmit={handleSubmit}
          onClose={handleClose}
        />
      </Popover>
    </>
  );
});


DateRangeFilter.displayName = 'DateRangeFilter';


export default DateRangeFilter;
