import { PopoverOrigin } from '@mui/material/Popover';

import { PADDING_BASE } from 'containers/theme-provider/constants';


export const PINNED_COLUMN_TEXT = 'This column is frozen';
export const UNPINNED_COLUMN_TEXT = 'If this column will be frozen, than other columns with Lock icon will be frozen as well. Tap to freeze.';

export const HEATMAP_POPOVER_ANCHOR_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right',
};

export const HEATMAP_POPOVER_TRANSFORM_ORIGIN: PopoverOrigin = {
  vertical: 'top',
  horizontal: -PADDING_BASE * 2,
};