import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button, Container, FormControl, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ChevronLeft } from '@mui/icons-material';

import { actions as adminAppSettingsAction } from 'core/admin/appsettings/actions';
import { getAppSetting, getAppSettingError, getAppSettingIsLoading } from 'core/admin/appsettings/selectors';
import AdminAppSettingService from 'core/admin/appsettings/service';
import { BootstrapInput, BootstrapLabel } from 'components/common/bootstrap-input/styles';
import { ROUTES } from 'containers/router/constants';
import theme from 'containers/theme-provider/theme';
import SnackbarService from 'services/snackbar';


type AppSettingForm = {
  name: string;
  section: string;
  value: string;
  comment: string;
};

const AppSettingsEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const appSettingId = params.appSettingId; //appSettingId
  const isEdit = !!appSettingId;

  const appSetting = useSelector(getAppSetting);
  const isLoadingAppSetting = useSelector(getAppSettingIsLoading);
  const appSettingError = useSelector(getAppSettingError);

  const [isLoading, setIsLoading] = useState(false); // submit btn loading
  const [values, setValues] = useState<AppSettingForm>({
    name: '',
    section: '',
    value: '',
    comment: '',
  });

  // fetch if editing
  useEffect(() => {
    if (appSettingId) {
      dispatch(adminAppSettingsAction.fetchOne({ appSettingId: parseInt(appSettingId) }));
    }
  }, [appSettingId, dispatch]);

  // set fetched data to state
  useEffect(() => {
    if (isEdit && appSetting) {
      setValues({
        name: appSetting.name,
        section: appSetting.section,
        value: appSetting.value,
        comment: appSetting.comment,
      });
    }
  }, [appSetting, isEdit, setValues]);

  // handle fetch user error
  useEffect(() => {
    if (appSettingError) {
      dispatch(adminAppSettingsAction.fetchOneReset());
      navigate(ROUTES.APPSETTINGS_INDEX);
    }
  }, [appSettingError, navigate, dispatch]);

  // handle general input change
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleClickBack = useCallback(() => {
    navigate(ROUTES.APPSETTINGS_INDEX);
  }, [navigate]);

  const handleSubmit = useCallback((event: FormEvent) => {
    event.preventDefault();
    if (isEdit && !appSetting) {
      return;
    }
    setIsLoading(true);
    const data = { ...values };

    (
      isEdit ? AdminAppSettingService.edit({ appSettingId: parseInt(appSettingId), data }) :
        AdminAppSettingService.create({ data })
    )
      .then(() => {
        navigate(ROUTES.APPSETTINGS_INDEX);
      }).catch((e: any) => {
        setIsLoading(false);
        SnackbarService.showError(
          e?.response?.data ?? (e?.message ?? 'Error'),
          {
            vertical: 'bottom',
            horizontal: 'left',
          },
        );
      });
  }, [values, navigate, isEdit, appSetting, appSettingId]);

  const inputSkeleton = useMemo(() => (
    <Skeleton
      height={50}
      sx={{ marginTop: '6px' }}
    />
  ), []);

  return (
    <Container
      maxWidth="lg"
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        color={theme.palette.grey[800]}
        marginBottom="8px"
      >{isEdit ? 'Edit' : 'Create'} App Setting</Typography>
      <Paper
        elevation={3}
      >
        <Box
          component="form"
          autoComplete="off"
          padding={2}
          onSubmit={handleSubmit}
        >
          <Stack
            direction="row"
            gap={2}
            justifyContent="space-between"
          >
            <FormControl
              variant="standard"
              fullWidth
              sx={{ marginBottom: '12px' }}
            >
              <BootstrapLabel shrink>Section</BootstrapLabel>
              {
                isLoadingAppSetting ? inputSkeleton :
                  <BootstrapInput
                    value={values.section}
                    name="section"
                    onChange={handleChange}
                    required
                  />
              }
            </FormControl>
            <FormControl
              variant="standard"
              fullWidth
              sx={{ marginBottom: '12px' }}
            >
              <BootstrapLabel shrink>Name</BootstrapLabel>
              {
                isLoadingAppSetting ? inputSkeleton :
                  <BootstrapInput
                    value={values.name}
                    name="name"
                    onChange={handleChange}
                    required
                  />
              }
            </FormControl>
          </Stack>
          <Stack
            direction="row"
          >
            <FormControl
              variant="standard"
              fullWidth
              sx={{ marginBottom: '12px' }}
            >
              <BootstrapLabel shrink>Value</BootstrapLabel>
              {
                isLoadingAppSetting ? inputSkeleton :
                  <BootstrapInput
                    value={values.value}
                    name="value"
                    onChange={handleChange}
                    required
                  />
              }
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            sx={{ marginBottom: '24px' }}
          >
            <FormControl
              variant="standard"
              fullWidth
            >
              <BootstrapLabel shrink>Comment</BootstrapLabel>
              {
                isLoadingAppSetting ? inputSkeleton :
                  <BootstrapInput
                    value={values.comment}
                    name="comment"
                    onChange={handleChange}
                  />
              }
            </FormControl>
          </Stack>

          <Stack
            direction="row"
            justifyContent="end"
          >
            <Button
              variant="text"
              startIcon={<ChevronLeft />}
              onClick={handleClickBack}
            >Back</Button>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isLoading}
              disabled={isEdit && isLoadingAppSetting}
            >Submit</LoadingButton>
          </Stack>

        </Box>
      </Paper>
    </Container>
  );
};

AppSettingsEdit.displayName = 'AppSettingsEdit';

export default AppSettingsEdit;
