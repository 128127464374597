import React, { memo } from 'react';

import Typography from '@mui/material/Typography';

import { ReactComponent as NoRowsImage } from 'assets/images/no-rows.svg';

import { Wrapper } from './styles';


const NoRows: React.FunctionComponent = memo(() => (
  <Wrapper>
    <NoRowsImage />

    <Typography
      variant="h2"
      color="secondary"
      mt={4}
    >
      There are no data here, but don&apos;t be upset!
    </Typography>
  </Wrapper>
));


NoRows.displayName = 'NoRows';


export default NoRows;
