import { createAction as baseCreateAction, createActions as baseCreateActions, handleActions as baseHandleActions, Options } from 'redux-actions';
import { persistReducer as basePersistReducer, PersistConfig } from 'redux-persist';

import {
  PayloadHandlers,
  Reducer,
  Action,
  FetchingAction,
  ReducerMap,
  PersistedReducer,
  Resolved,
  Rejected,
  Payload as BasePayload,
  Meta as BaseMeta,
  Fetching,
  FetchingActionTypes,
} from 'types/redux';


export const resolved = <Types extends string>(type: Types): Resolved<Types> => `${type}_${FetchingActionTypes.SUCCESS}`;

export const rejected = <Types extends string>(type: Types): Rejected<Types> => `${type}_${FetchingActionTypes.FAILED}`;

export const fetching = <Types extends string>(type: Types): Fetching<Types> => `${FetchingActionTypes.FETCHING}/${type}`;

export const isFetchingAction = (action: Action): boolean => action.type.includes(FetchingActionTypes.FETCHING) && !action.type.includes(FetchingActionTypes.SUCCESS) && !action.type.includes(FetchingActionTypes.FAILED);

export function createAction<
  Type extends string,
  Payload extends BasePayload = BasePayload,
  Meta extends BaseMeta = BaseMeta,
> (type: Type, payload?: Payload, meta?: Meta): Action<Type, Payload, Meta> | FetchingAction<Type, Payload, Meta> {
  return baseCreateAction<Payload, Meta>(
    type,
    (payloadData) => payloadData,
    () => meta as Meta,
  )(payload) as any;
}

export function createActions<Types extends string, Paylaod, Actions> (actions: PayloadHandlers<Types, Paylaod>, config: Options): Actions {
  return baseCreateActions(actions as any, config) as any;
}

export function handleActions<State, Types extends string, Actions extends Action = Action> (reducerMap: ReducerMap<State, Types, Actions>, initialState: State): Reducer<State, Actions> {
  return baseHandleActions(reducerMap as any, initialState) as any;
}

export function combineActions<Types extends string> (...args: (Types | Resolved<Types> | Rejected<Types>)[]): string {
  return args.join('||');
}

export function persistReducer<State, Actions extends Action = Action> (persistConfig: PersistConfig<State>, reducer: Reducer<State, Actions>): PersistedReducer<State, Actions> {
  return basePersistReducer(persistConfig, reducer as any);
}

export const resolvedAction = <
  Types extends string,
  Payload extends BasePayload = BasePayload,
  Meta extends BaseMeta = BaseMeta,
>(type: Types, payload?: Payload, meta?: Meta) => createAction<Resolved<Types>, Payload, Meta>(resolved<Types>(type), payload, meta);

export const rejectedAction = <
  Types extends string,
  Payload extends BasePayload = BasePayload,
  Meta extends BaseMeta = BaseMeta,
>(type: Types, payload?: Payload, meta?: Meta) => createAction<Rejected<Types>, Payload, Meta>(rejected<Types>(type), payload, meta);

// TODO: improve 'any' type
export const getAsyncAction = (action: any, payload: any) => new Promise((onSuccess, onError) => action({ ...payload, onSuccess, onError })); // eslint-disable-line no-promise-executor-return
