import { styled } from '@mui/material/styles';


export const StyledButton = styled('button')(({ theme }) => ({
  width: 100,
  height: 30,

  ...theme.typography.body2,

  '& + &': {
    marginLeft: theme.spacing(1.25),
  },
}));
