import { isObject } from 'utils';
import { DateRangeFilter, ColumnFilterModel, Entities, DateRangeRelativeFilter, ViewsConfig, BreakdownValue, PublishModes } from 'core/dashboards/types'; // eslint-disable-line import/no-cycle
import { ActiveViewState, SavedViews } from 'core/users/types'; // eslint-disable-line import/no-cycle
import { COUNTRY_BREAKDOWN_VALUE } from 'core/settings/constants';
import { Filter } from 'core/settings/types';

import { DEFAULT_COLUMN_FILTERS, DEFAULT_BREAKDOWN_PAGE_SIZE, COUNTRY_BREAKDOWN_PAGE_SIZE } from './constants'; // eslint-disable-line import/no-cycle


export const getListDependencies = (lists: Record<string, string>) => Object.values(lists).reduce((result, key, index, array) => {
  result.parents[key] = array.slice(0, index);
  result.childrens[key] = array.slice(index + 1);
  return result;
}, {
  childrens: {},
  parents: {},
} as Record<string, Record<string, string[]>>);

export const getDefaultListState = <T>(initialValue: T) => Object.values(Entities).reduce((res, value) => {
  res[value] = isObject(initialValue) ? Object.assign(initialValue as unknown as object) : initialValue;
  return res;
}, {} as Record<string, T>);

export const getDefaultColumnFilter = () => Object.values(Entities).reduce((res, value) => {
  const defaultFilter = DEFAULT_COLUMN_FILTERS[value as keyof typeof DEFAULT_COLUMN_FILTERS];

  res[value] = {
    items: defaultFilter || [],
  };

  return res;
}, {} as Record<string, ColumnFilterModel>);

export const getDateRangeForRelativeFilter = (relativeFilter: DateRangeRelativeFilter) => {
  const from = new Date();
  const to = new Date();

  switch (relativeFilter) {
    case DateRangeRelativeFilter.TODAY:
      break;

    case DateRangeRelativeFilter.YESTERDAY:
      from.setDate(from.getDate() - 1);

      to.setDate(to.getDate() - 1);
      break;

    case DateRangeRelativeFilter.LAST_7_DAYS:
      from.setDate(from.getDate() - 7);

      to.setDate(to.getDate() - 1);
      break;

    case DateRangeRelativeFilter.LAST_7_DAYS_TODAY:
      from.setDate(from.getDate() - 6);

      to.setDate(to.getDate());
      break;

    case DateRangeRelativeFilter.MONTH_TO_DATE:
      if (from.getDate() !== 1) {
        from.setDate(1);

        to.setDate(to.getDate() - 1);
      } else {
        from.setDate(1);
        from.setMonth(from.getMonth() - 1);

        to.setDate(0);
      }
      break;

    case DateRangeRelativeFilter.LAST_MONTH:
      from.setDate(1);
      from.setMonth(from.getMonth() - 1);

      to.setDate(0);
      break;
  }

  from.setHours(0, 0, 0, 0);
  to.setHours(23, 59, 59);

  return [from, to];
};

export const getDateRangeFilterObject = (relativeFilter: DateRangeRelativeFilter): DateRangeFilter => {
  const [from, to] = getDateRangeForRelativeFilter(relativeFilter);

  return {
    from: from.getTime(),
    to: to.getTime(),
  };
};

// NOTE: JS is sorting object with number keys. This behavior breaks our sorting logic
export const getSafeId = (id: string | number) => `${id} `;

export const getViewsValues = (activeViews: ActiveViewState, savedViews: SavedViews): ViewsConfig => Object.entries(activeViews).reduce<ViewsConfig>((res, [entity, activeView]) => {
  if (activeView === null) {
    return res;
  }

  const { id } = activeView;
  const view = savedViews[entity as Entities]?.find((savedView) => savedView.id === id);

  if (view) {
    res[entity as Entities] = view.value;
  }

  return res;
}, {} as ViewsConfig);

export const getPageSizeByBreakdown = (breakdown: BreakdownValue, activePageSize: number): number => {
  if (!breakdown) {
    return activePageSize;
  }

  if (breakdown === COUNTRY_BREAKDOWN_VALUE) {
    return COUNTRY_BREAKDOWN_PAGE_SIZE;
  }

  return DEFAULT_BREAKDOWN_PAGE_SIZE;
};

export const getFiltersBasedOnPublishMode = (initialFilters: Filter[], publishMode: PublishModes) => {
  switch (publishMode) {
    case PublishModes.Unpublished:
      return initialFilters.map((filter) => ({
        ...filter,
        options: filter.testOptions,
      }));
    default:
      return initialFilters;
  }
};
