import { forwardRef, useCallback, useRef, useEffect, useMemo, useState } from 'react';

import {
  GridFilterItem,
  GridLinkOperator,
  useGridApiContext,
  GridPanelContent,
  GridPanelWrapper,
  GridFilterForm,
  useGridSelector,
  gridFilterModelSelector,
  gridFilterableColumnDefinitionsSelector,
  useGridRootProps,
} from '@mui/x-data-grid-premium';

import { getRandomId } from 'utils';

import GridFilterFooter from '../grid-filter-footer';

import { getInitialState } from './utils';
import { GridFilterPanelProps } from './types';


const GridFilterPanel = forwardRef<HTMLDivElement, GridFilterPanelProps>((props, ref) => {
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector);
  const filterableColumns = useGridSelector(apiRef, gridFilterableColumnDefinitionsSelector);

  const {
    linkOperators = [GridLinkOperator.And],
    columnsSort,
    filterFormProps,
    children,
    targetFilterColumnField,
    ...other
  } = props;

  const lastFilterRef = useRef<any>(null);
  const submitTimeoutRef = useRef<any>(null);
  const [innerFilterModel, setInnerFilterModel] = useState(() => getInitialState(filterModel, rootProps.disableMultipleColumnsFiltering, targetFilterColumnField, apiRef));
  const [isChangesApplying, setIsChangesApplying] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const applyFilter = useCallback((item: GridFilterItem) => {
    const items = [...innerFilterModel.items];
    const itemIndex = items.findIndex((filterItem) => filterItem.id === item.id);
    if (itemIndex === -1) {
      items.push(item);
    } else {
      items[itemIndex] = item;
    }

    setInnerFilterModel({ ...innerFilterModel, items });
  }, [innerFilterModel]);

  const applyFilterLinkOperator = useCallback((operator: GridLinkOperator) => {
    if (innerFilterModel.linkOperator === operator) {
      return;
    }

    setInnerFilterModel({
      ...innerFilterModel,
      linkOperator: operator,
    });
  }, [innerFilterModel]);

  const getDefaultItem = useCallback((): GridFilterItem | null => {
    const firstColumnWithOperator = filterableColumns.find((colDef) => colDef.filterOperators?.length);

    if (!firstColumnWithOperator) {
      return null;
    }

    return {
      columnField: firstColumnWithOperator.field,
      operatorValue: firstColumnWithOperator.filterOperators![0].value,
      id: getRandomId(),
    };
  }, [filterableColumns]);

  const items = useMemo<GridFilterItem[]>(() => {
    if (innerFilterModel.items.length) {
      return innerFilterModel.items;
    }

    const defaultItem = getDefaultItem();

    return defaultItem ? [defaultItem] : [];
  }, [innerFilterModel.items, getDefaultItem]);

  const addNewFilter = useCallback(() => {
    const defaultItem = getDefaultItem();
    if (!defaultItem) {
      return;
    }
    setInnerFilterModel({ ...innerFilterModel, items: [...items, defaultItem] });
  }, [getDefaultItem, innerFilterModel, items]);

  const deleteFilter = useCallback((itemToDelete: GridFilterItem) => {
    const shouldCloseFilterPanel = items.length === 1;

    const nextItems = innerFilterModel.items.filter((item) => item.id !== itemToDelete.id);

    if (nextItems.length === innerFilterModel.items.length) {
      return;
    }

    setInnerFilterModel({ ...innerFilterModel, items: nextItems });

    if (shouldCloseFilterPanel) {
      apiRef.current.setFilterModel({
        items: [],
        linkOperator: undefined,
      });
      apiRef.current.hideFilterPanel();
    }
  }, [innerFilterModel, apiRef, items.length]);

  const closeFilter = useCallback(() => {
    apiRef.current.hideFilterPanel();
  }, [apiRef]);

  const handleSubmitFilter = useCallback(() => { // TODO: MUI has 500ms timeout for applying changes to filter.... :/
    setIsSubmitClicked(true);
  }, []);

  const resetFilter = useCallback(() => {
    if (filterModel.items.length !== 0) {
      apiRef.current.setFilterModel({
        items: [],
        linkOperator: undefined,
      });
    }

    apiRef.current.hideFilterPanel();
  }, [apiRef, filterModel]);

  const handleInputValueChange = useCallback(() => {
    setIsChangesApplying(true);

    submitTimeoutRef.current = setTimeout(() => {
      setIsChangesApplying(false);
    }, 1000);
  }, []);

  const valueInputProps = useMemo(() => ({
    onChange: handleInputValueChange,
  }), []);

  useEffect(() => {
    if (linkOperators.length > 0 && innerFilterModel.linkOperator && !linkOperators.includes(innerFilterModel.linkOperator)) {
      applyFilterLinkOperator(linkOperators[0]);
    }
  }, [linkOperators, applyFilterLinkOperator, innerFilterModel.linkOperator]);

  useEffect(() => {
    if (items.length > 0) {
      lastFilterRef.current!.focus();
    }
  }, [items.length]);

  useEffect(() => {
    if (targetFilterColumnField) {
      const nextInnerFilterModel = getInitialState(innerFilterModel, rootProps.disableMultipleColumnsFiltering, targetFilterColumnField, apiRef);

      setInnerFilterModel(nextInnerFilterModel);
    }
  }, [targetFilterColumnField]);

  useEffect(() => {
    if (isSubmitClicked && !isChangesApplying) {
      apiRef.current.setFilterModel(innerFilterModel);
      apiRef.current.hideFilterPanel();
    }
  }, [isChangesApplying, isSubmitClicked]);

  useEffect(() => () => clearTimeout(submitTimeoutRef.current), []);

  const hasMultipleFilters = items.length > 1;

  return (
    <GridPanelWrapper
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      {...other}
    >
      <GridPanelContent>
        {
          items.map((item: GridFilterItem, index: number) => (
            <GridFilterForm
              key={item.id == null ? index : item.id}
              item={item}
              applyFilterChanges={applyFilter}
              deleteFilter={deleteFilter}
              hasMultipleFilters={hasMultipleFilters}
              showMultiFilterOperators={index > 0}
              multiFilterOperator={innerFilterModel.linkOperator}
              disableMultiFilterOperator={index !== 1}
              applyMultiFilterOperatorChanges={applyFilterLinkOperator}
              focusElementRef={index === items.length - 1 ? lastFilterRef : null}
              linkOperators={linkOperators}
              columnsSort={columnsSort}
              valueInputProps={valueInputProps}
              {...filterFormProps}
            />
          ))
        }
      </GridPanelContent>

      <GridFilterFooter
        onAddNewFilter={addNewFilter}
        onCancel={closeFilter}
        onReset={resetFilter}
        onSubmit={handleSubmitFilter}
        isSubmitLoading={isChangesApplying && isSubmitClicked}
      />
    </GridPanelWrapper>
  );
},
);


GridFilterPanel.displayName = 'GridFilterPanel';


export default GridFilterPanel;
