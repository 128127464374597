import { styled } from '@mui/material/styles';
import BaseTypography from '@mui/material/Typography';

import { PADDING_BASE } from 'containers/theme-provider/constants';

import { AbsoluteTextProps, StyledWrapperProps } from './types';


export const DEFAULT_PADDING_ABSOLUTE_TEXT = 0.5;
export const PAGE_OFFSET = 5;

export const Wrapper = styled('div')<StyledWrapperProps>(({ $isFullTextVisible, $textAlign }) => ({
  maxWidth: '100%',
  width: '100%',
  overflow: 'hidden',
  textOverflow: $isFullTextVisible ? 'clip' : 'ellipsis',
  ...($textAlign ? { textAlign: $textAlign } : {}),
}));

export const Typography = styled(BaseTypography)({
  display: 'inline',

  'a': {
    display: 'inline',
  },
});

export const AbsoluteText = styled(Typography)<AbsoluteTextProps>(({ theme, $offset }) => ({
  zIndex: 10,
  position: 'absolute',
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(DEFAULT_PADDING_ABSOLUTE_TEXT),
  marginTop: theme.spacing(-DEFAULT_PADDING_ABSOLUTE_TEXT),
  marginLeft: theme.spacing(-($offset / PADDING_BASE)),
  borderRadius: `${theme.shape.borderRadius as number / 2}px`,
  boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
}));
