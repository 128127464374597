import { ChangeEvent, FunctionComponent, KeyboardEvent, memo, useCallback, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import SearchInput from 'components/common/search-input';
import { KeyCodes } from 'constants/events';

import { Props } from './types';


const SearchBar: FunctionComponent<Props> = memo(({ onSearchChange, sx }) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(searchValue, 350);

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchValue(value);
  }, []);

  const handleSearchKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.code !== KeyCodes.Escape && event.code !== KeyCodes.Tab) {
      event.stopPropagation();
    }
  }, []);

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearchValue);
    }
  }, [onSearchChange, debouncedSearchValue]);

  return (
    <SearchInput
      value={searchValue}
      onChange={handleSearchChange}
      onKeyDown={handleSearchKeyPress}
      sx={sx}
    />
  );
});

SearchBar.displayName = 'SearchBar';


export default SearchBar;
