import { CurrentFilter, PublishModes } from 'core/dashboards/types';
import { Filter } from 'core/settings/types';


export const getSanitizedFilters = (appliedFilters: Filter[]): CurrentFilter[] => appliedFilters.map(({ id, values, type }) => ({ id, values, type }));

export const getIsSomeFiltersApplied = (appliedFilters: Filter[]): boolean => Boolean(appliedFilters.some(({ values }) => values.length));

export const getAppliedFiltersCount = (filters: CurrentFilter[]): number => filters.filter(({ values }) => Boolean(values.length)).length;

export const getFiltersBySaved = (savedFilters: CurrentFilter[], initialFilters: Filter[]): Filter[] => savedFilters.reduce<Filter[]>((result, savedFilter) => {
  const filter = initialFilters.find(({ id }) => id === savedFilter.id);

  if (filter) {
    result.push({
      ...filter,
      values: (savedFilter?.values) || [],
    });
  }

  return result;
}, []);
