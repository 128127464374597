import { ChevronRight } from '@mui/icons-material';
import { Button, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover } from '@mui/material';
import { FunctionComponent, memo, useCallback, useRef, useState } from 'react';

import theme from 'containers/theme-provider/theme';

import { Props } from './types';
import { StyledButton, StyledIcon } from './styles';


const FilterCheckboxDropdown: FunctionComponent<Props> = memo(({ filterItems, label, disabled, onChange, initialValue }) => {
  const wrapperRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState<string[]>(initialValue ?? []);

  const toggleIsOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const handleClickAway = useCallback(() => setIsOpen(false), []);

  const toggleChecked = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <div
      ref={wrapperRef}
    >
      <StyledButton
        as={Button}
        variant="outlined"
        color="primary"
        onClick={toggleIsOpen}
        disabled={disabled}
        endIcon={(
          <>
            <StyledIcon
              as={ChevronRight}
              $isExpanded={isOpen}
            />
          </>
        )}
        $isExpanded={isOpen}
      >
        {label}
      </StyledButton>
      <Popover
        open={isOpen}
        anchorEl={wrapperRef.current}
        onClose={handleClickAway}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.common.white,
            borderRadius: '12px',
            borderTopLeftRadius: 0,
            boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.15)',
            // width: '400px',
            padding: '4px 0',
          },
        }}
      >
        <List>
          {
            filterItems.map((filterItem) => (
              <ListItem
                key={filterItem.key}
                disablePadding
              >
                <ListItemButton
                  onClick={toggleChecked(filterItem.key)}
                  sx={{
                    padding: '4px 24px',
                  }}
                >
                  <ListItemIcon
                    sx={{ minWidth: '0' }}
                  >
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(filterItem.key) !== -1}
                      tabIndex={-1}
                      disableRipple
                      sx={{
                        padding: '0 12px 0 0',
                        // minWidth: '0',
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText>{filterItem.label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))
          }
        </List>
      </Popover>
    </div>
  );
});

FilterCheckboxDropdown.displayName = 'FilterCheckboxDropdown';

export default FilterCheckboxDropdown;
