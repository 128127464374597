import LionstudiosApi from 'services/lionstudios-api';

import { PublishModes } from 'core/dashboards/types';
import { STAGE } from 'core/dashboards/constants';

import { BackEndGroupingModel, BreakdownResolvedPayload } from './types';
import { BASE_URL } from './constants';
import { baseOptionsModel } from './models';


const SettingsService = {
  fetchGrouping: async () => (await LionstudiosApi.get<BackEndGroupingModel>(`/${BASE_URL}/grouping`)).data,

  fetchFilterBasicData: async () => (await LionstudiosApi.get(`/${BASE_URL}/filters`)).data,

  fetchAutocompleteData: async (entity: string, field: string, search: string, publishMode: PublishModes) => {
    const response = await LionstudiosApi.get(`autocomplete/${entity}`, {
      params: {
        field,
        startsWith: search,
        stage: STAGE[publishMode],
      },
    });

    return baseOptionsModel(response.data);
  },

  fetchViewConfiguration: async () => (await LionstudiosApi.get(`/${BASE_URL}/fields`)).data,

  fetchDataStatus: async () => (await LionstudiosApi.get(`/${BASE_URL}/feed`)).data,

  fetchBreakdown: async () => (await LionstudiosApi.get<BreakdownResolvedPayload>(`/${BASE_URL}/breakdown`)).data,
};


export default SettingsService;
