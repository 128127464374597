import React, { memo, useCallback, useMemo, useState, useEffect } from 'react';

import MultipleAutocomplete from 'components/common/multiple-autocomplete';

import { getExtendedOptions, isOptionEqualToValue, filterOptionsForAsyncAutocomplete } from './utils';
import { Props, Values } from './types';


const MultipleAutocompleteString: React.FunctionComponent<Props> = memo(({ options: initialOptions, values, onChange, renderTagLabel, isAsync = false, fetchOptions, ...rest }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [options, setOptions] = useState(initialOptions || []);

  const extendedOptions = useMemo(() => getExtendedOptions(options), [options]);

  const handleFetchOptions = useCallback(async (search: string) => {
    if (!search.length) {
      setOptions([]);
      return;
    }

    try {
      setIsFetching(true);
      const nextOptions = await fetchOptions(search);
      setOptions(nextOptions);
    } finally {
      setIsFetching(false);
    }
  }, [fetchOptions]);

  const handleInputChange = useCallback((nextValue: string) => {
    setInputValue(nextValue);

    if (isAsync) {
      handleFetchOptions(nextValue);
    }
  }, [isAsync, handleFetchOptions]);

  const handleChange = useCallback((nextValue: Values) => {
    onChange(nextValue);
  }, [onChange]);

  const asyncProps = useMemo(() => (isAsync ? {
    onInputChange: handleInputChange,
    filterOptions: filterOptionsForAsyncAutocomplete,
    loading: isFetching,
    ...(inputValue.length === 0 ? { noOptionsText: 'Start typing to search' } : {}),
  } : {}), [handleInputChange, inputValue, isAsync, isFetching]);

  const inputProps = useMemo(() => ({
    type: 'text',
  }), []);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  return (
    <MultipleAutocomplete
      options={extendedOptions}
      filterSelectedOptions
      values={values}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChange}
      renderTagLabel={renderTagLabel}
      inputProps={inputProps}
      {...asyncProps}
      {...rest}
    />
  );
});


MultipleAutocompleteString.displayName = 'MultipleAutocompleteString';


export default MultipleAutocompleteString;
