import React, { memo, useCallback, useRef } from 'react';

import { useGridRootProps, useGridApiContext } from '@mui/x-data-grid-premium';

import IconButton from '@mui/material/IconButton';

import { useUtilityClasses } from './utils';
import { Props } from './types';


const FilterIconButton: React.FunctionComponent<Props> = memo(({ field, onFilterClick }) => {
  const ref = useRef(null);

  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();

  const ownerState = {
    field,
    classes: rootProps.classes,
  };

  const classes = useUtilityClasses(ownerState);

  const toggleFilter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    onFilterClick(ref.current, field);
  }, [onFilterClick, field]);

  return (
    <IconButton
      onClick={toggleFilter}
      color="default"
      aria-label={apiRef.current.getLocaleText('columnHeaderFiltersLabel')}
      size="small"
      tabIndex={-1}
      ref={ref}
    >
      <rootProps.components.ColumnFilteredIcon
        className={classes.icon}
        fontSize="small"
      />
    </IconButton>
  );
});


FilterIconButton.displayName = 'FilterIconButton';


export default FilterIconButton;
