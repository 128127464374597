import { withPrefix } from 'utils';
import { Entities } from 'core/dashboards/types';
import { BACK_END_KEYS } from 'core/dashboards/constants';


export const DEFAULT_GROUP_NAME = 'Ungrouped';

export const DEFAULT_COLUMN_FIELD = 'index';

export const DEFAULT_AGGREGATION_FUNC = 'sum'; // NOTE: mui just hardcode this value

export const CUSTOM_FILTERS_FIELDS_MAP = {
  [withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORMS)]: withPrefix(Entities.Skus, BACK_END_KEYS.PLATFORM),
};
