import React, { memo, useCallback, useMemo } from 'react';

import debounce from 'lodash.debounce';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Filter, FilterType, NumberValue } from 'core/settings/types';
import SettingsService from 'core/settings/service';

import MultipleAutocompleteString from 'components/common/multiple-autocomplete-string';
import MultipleAutocompleteNumber from 'components/common/multiple-autocomplete-number';

import CustomFilterRenderer from './custom-filter-renderer';
import { Wrapper, StyledLabel, StyledIconButton, ButtonWrapper } from './styles';
import { Props } from './types';


const debouncedFetchAutocompleteData = debounce(
  SettingsService.fetchAutocompleteData,
  300,
  {
    leading: true, // TODO: think about async fn in debounce
  },
);

const DashboardFilterRow: React.FunctionComponent<Props> = memo(({ name, onChange, onDelete, onReset,  id, options, values, entity, field, isDefault, isAsync, type, publishMode }) => {
  const handleChange = useCallback((nextValues: Filter['values']) => {
    onChange(id, nextValues);
  }, [id, onChange]);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  const handleReset = useCallback(() => {
    onReset(id);
  }, [id, onReset]);

  const fetchAutocompleteData = useCallback((search: string) => debouncedFetchAutocompleteData(entity, field, search, publishMode), [entity, field, publishMode]);

  const customFilterProps = useMemo(() => {
    if (CustomFilterRenderer[id]) {
      return CustomFilterRenderer[id];
    }

    return {};
  }, [id]);

  return (
    <Wrapper>
      <StyledLabel
        as={Typography}
        variant="body1"
      >
        {name}
      </StyledLabel>

      {
        type === FilterType.NUMBER ? (
          <MultipleAutocompleteNumber
            values={values as NumberValue[]}
            onChange={handleChange}
          />
        ) : (
          <MultipleAutocompleteString
            options={options}
            values={values}
            onChange={handleChange}
            isAsync={isAsync}
            fetchOptions={fetchAutocompleteData}
            {...customFilterProps}
          />
        )
      }

      <ButtonWrapper>
        {
          !isDefault && (
            <StyledIconButton
              as={IconButton}
              onClick={handleDelete}
              aria-label="Delete"
            >
              <DeleteForeverIcon
                fontSize="inherit"
                color="inherit"
              />
            </StyledIconButton>
          )
        }

        {
          !!values.length && (
            <StyledIconButton
              as={IconButton}
              onClick={handleReset}
              aria-label="Reset"
            >
              <RestartAltIcon
                fontSize="inherit"
                color="inherit"
              />
            </StyledIconButton>
          )
        }
      </ButtonWrapper>
    </Wrapper>
  );
});


DashboardFilterRow.displayName = 'DashboardFilterRow';


export default DashboardFilterRow;
