import { memo, FunctionComponent, useMemo, useCallback, useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { useDebounce } from 'use-debounce';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import SearchInput from 'components/common/search-input';

import { KeyCodes } from 'constants/events';

import { DEFAULT_NO_RESULTS_TEXT } from './constants';
import { Props } from './types';
import { defaultKeyExtractor, defaultContentRenderer } from './utils';

import { SearchWrapper, Wrapper, StyledListItem } from './styles';


const List: FunctionComponent<Props> = memo(({
  items,
  isSearchable,
  onSearchChange,
  extractItemKey = defaultKeyExtractor,
  noResultsText = DEFAULT_NO_RESULTS_TEXT,
  ...otherProps
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [debouncedSearchValue] = useDebounce(isSearchable ? searchValue : null, 350);

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setSearchValue(value);
  }, []);

  const handleSearchKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.code !== KeyCodes.Escape && event.code !== KeyCodes.Tab) {
      event.stopPropagation();
    }
  }, []);

  useEffect(() => {
    if (onSearchChange) {
      onSearchChange(debouncedSearchValue);
    }
  }, [onSearchChange, debouncedSearchValue]);

  const listItems = useMemo(() => {
    const value = debouncedSearchValue?.toLowerCase().trim();

    if (!value || !items) {
      return items;
    }

    return items.filter(({ title }) => title.toLocaleLowerCase().includes(value));
  }, [items, debouncedSearchValue]);

  const menuItems = useMemo(() => listItems?.map((item, index) => {
    const { title, renderContent, onClick, isActive, ...otherItemProps } = item;
    const key = extractItemKey(item, index);

    return (
      <StyledListItem
        as={MenuItem}
        key={key}
        onClick={onClick}
        $isActive={isActive}
        {...otherItemProps}
      >
        {renderContent ? renderContent(item) : defaultContentRenderer(item)}
      </StyledListItem>
    );
  }), [listItems, extractItemKey]);

  return (
    <Wrapper>
      {isSearchable && (
        <SearchWrapper>
          <SearchInput
            value={searchValue}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyPress}
          />
        </SearchWrapper>
      )}

      {menuItems?.length ? (
        <MenuList
          {...otherProps}
        >
          {menuItems}
        </MenuList>
      ) : (
        <Typography
          className={otherProps.className}
          align="center"
          variant="subtitle1"
        >
          {noResultsText}
        </Typography>
      )}
    </Wrapper>
  );
});


List.displayName = 'List';


export default List;
