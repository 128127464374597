import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { DataGridPro, GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Box, Button, Skeleton, Stack } from '@mui/material';

// import LionstudiosApi from 'services/lionstudios-api';
import { UserRole, UserRoleDisplay } from 'core/users/types';
import SearchBar from 'components/common/search-bar';
import theme from 'containers/theme-provider/theme';
import { getUsers, getUsersIsLoading  } from 'core/admin/users/selectors';
import { actions as adminUsersAction } from 'core/admin/users/actions';
import FilterCheckboxDropdown from 'components/common/filter-checkbox-dropdown';
import { FilterItem } from 'components/common/filter-checkbox-dropdown/types';
import { UserList } from 'core/admin/users/types';
import { isNumber } from 'utils';
import { ROUTES } from 'containers/router/constants';
import { getAuthorizedUserRole } from 'core/users/selectors';

import UsersRowAction from './row-action';
import { FilterConfigState } from './types';


const UsersIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authRole = useSelector(getAuthorizedUserRole);
  // const [users, setUsers] = useState<UsersDataPayload>([]);
  // const [isFetching, setIsFetching] = useState<boolean>(false);
  // const mountedRef = useRef(true);
  const [filteredUsers, setFilteredUsers] = useState<UserList>([]);
  const [filterConfig, setFilterConfig] = useState<FilterConfigState>({
    search: '',
    userTypes: [],
    userStatuses: ['ACTIVE'],
  });

  const users = useSelector(getUsers);
  const isLoading = useSelector(getUsersIsLoading);

  useEffect(() => {
    dispatch(adminUsersAction.fetchAll());
    /*
    setIsFetching(true);
    LionstudiosApi.get('users')
      .then((res) => {
        if (!mountedRef.current) { return null; } // handle cleanup
        if (res.data) {
          setUsers(res.data);
        }
      })
      .finally(() => {
        if (!mountedRef.current) { return null; }
        setIsFetching(false);
      });

    return () => { mountedRef.current = false; };
    */
  }, [dispatch]);

  const getRoleDisplay = useCallback((params: GridValueGetterParams) => UserRoleDisplay[params.row.role as UserRole], []);

  const handleClickCreateUser = useCallback(() => {
    navigate(ROUTES.USERS_CREATE);
  }, [navigate]);

  const columns = useMemo<GridColDef[]>(() => [
    { field: 'fullName', headerName: 'Name', minWidth: 300, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 300, flex: 1 },
    {
      field: 'role',
      headerName: 'User Type',
      minWidth: 100,
      flex: 1,
      valueGetter: getRoleDisplay,
    },
    { field: 'status', headerName: 'Status', minWidth: 100, flex: 1 },
    {
      field: 'lastSeenAt',
      headerName: 'Last Activity',
      type: 'date',
      valueFormatter: (params: GridValueFormatterParams<string>) => {
        const date = params.value ? moment.utc(params.value) : null;
        if (!date) { return ''; }
        if (moment().diff(date, 'hours') > 23) { return date.format('Y-MM-DD'); }
        return date.fromNow();
      },
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => <UsersRowAction user={params.row} />,
      renderHeader: () => (authRole === UserRole.SuperAdmin) && (
        <Button
          size="small"
          variant="contained"
          onClick={handleClickCreateUser}
        >Create</Button>
      ),
    },
  ], [getRoleDisplay, authRole]);

  const LoadingSkeleton = useCallback(() => (
    <Box
      sx={{
        height: 'max-content',
      }}
    >
      {[...Array(10)].map((_, i) => (
        <Skeleton
          variant='rectangular'
          sx={{ my: 4, mx: 1 }}
          key={i}
        />
      ))}
    </Box>
  ), []);

  const onSearchChange = useCallback((search: string) => {
    setFilterConfig({
      ...filterConfig,
      search,
    });
  }, [setFilterConfig]);

  const onUserTypesChange = useCallback((userTypes: string[]) => {
    setFilterConfig({
      ...filterConfig,
      userTypes,
    });
  }, [setFilterConfig]);

  const onUserStatusesChange = useCallback((userStatuses: string[]) => {
    setFilterConfig({
      ...filterConfig,
      userStatuses,
    });
  }, [setFilterConfig]);

  useEffect(() => {
    setFilteredUsers(users.filter((user) => {
      if (filterConfig.userTypes.length) {
        if (filterConfig.userTypes.indexOf(user.role.toString()) === -1) {
          return false;
        }
      }
      if (filterConfig.userStatuses.length) {
        if (filterConfig.userStatuses.indexOf(user.status) === -1) {
          return false;
        }
      }
      const searchContext = (
        user.fullName
        + user.email
      );
      if (filterConfig.search.length) {
        if (!searchContext.toLowerCase().includes(filterConfig.search.toLowerCase())) {
          return false;
        }
      }
      return true;
    }));
  }, [users, filterConfig]);


  const filterRoleItems = useMemo<FilterItem[]>(() => {
    const arr: FilterItem[] = [];
    for (const role in UserRole) {
      if (isNumber(role)) {
        arr.push({
          key: role,
          label: UserRoleDisplay[role as unknown as UserRole],
        });
      }
    }
    return arr;
  }, []);

  const filterStatusItems = useMemo<FilterItem[]>(() => [
    {
      key: 'ACTIVE',
      label: 'ACTIVE',
    },
    {
      key: 'SUSPENDED',
      label: 'SUSPENDED',
    },
  ], []);

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        mb={2}
      >
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <FilterCheckboxDropdown
            filterItems={filterRoleItems}
            label="User Types"
            onChange={onUserTypesChange}
          />
          <FilterCheckboxDropdown
            filterItems={filterStatusItems}
            label="User Status"
            onChange={onUserStatusesChange}
            initialValue={filterConfig.userStatuses}
          />
        </Stack>
        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
        >
          <SearchBar
            onSearchChange={onSearchChange}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: theme.palette.common.white,
              },
            }}
          />
        </Stack>
      </Stack>
      <div style={{ height: 600, width: '100%' }}>
        <DataGridPro
          rows={isLoading ? [] : filteredUsers}
          columns={columns}
          autoHeight={true}
          disableColumnMenu
          loading={isLoading}
          components={{
            LoadingOverlay: LoadingSkeleton,
          }}
        />
      </div>
    </div>
  );
};

UsersIndex.displayName = 'UsersIndex';

export default UsersIndex;
