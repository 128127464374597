import { Components } from '@mui/material/styles';
import { buttonClasses } from '@mui/material/Button';


export const BUTTON_ACTIVE_CLASS = 'is-active';

export const BUTTON_MIN_HEIGHT = '38px';

const overrides: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      height: BUTTON_MIN_HEIGHT,
      boxShadow: 'none',
      width: 160,
      fontWeight: 500,
    },
    contained: {
      '&:hover': {
        backgroundColor: '#004fe5',
        boxShadow: 'none',
      },

      [`&.${buttonClasses.contained}Error`]: {
        '&:hover': {
          backgroundColor: '#D4483C',
        },
      },
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: '#F1F1F1',
      },
    },
    text: {
      color: '#9E9E9E',

      [`&:hover, &.${BUTTON_ACTIVE_CLASS}`]: {
        backgroundColor: '#F2F7FF',
        color: '#2D2D2D',
      },
    },
  },
};


export default overrides;
