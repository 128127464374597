import { FunctionComponent, memo, useCallback } from 'react';

import { IconButton } from '@mui/material';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

import { SortDirection } from 'core/admin/types';
import theme from 'containers/theme-provider/theme';


type Props = {
  direction: SortDirection | null;
  setSort: (direction: SortDirection | null) => void;
};

const SortButton: FunctionComponent<Props> = memo(({ direction, setSort }) => {

  const handleClick = useCallback(() => {
    switch (direction) {
      case null:
        setSort(SortDirection.DESC);
        break;
      case SortDirection.DESC:
        setSort(SortDirection.ASC);
        break;
      default:
        setSort(null);
        break;
    }
  }, [direction, setSort]);

  return (
    <IconButton
      onClick={handleClick}
      size='small'
      sx={{
        fontSize: '14px',
        color: theme.palette.grey[400],
      }}
    >
      {
        direction === SortDirection.DESC ?
          <SouthIcon
            fontSize='inherit'
            color='primary'
          />
          : (
            direction === SortDirection.ASC ?
              <NorthIcon
                fontSize='inherit'
                color='primary'
              />
              : <SouthIcon fontSize='inherit' />
          )
      }

    </IconButton>
  );
});

SortButton.displayName = 'SortButton';

export default SortButton;
