import { put, call, takeLatest, take, select, all, race, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { resolvedAction, rejectedAction, resolved, rejected } from 'utils/redux';
import { Action } from 'types/redux';
import { getQueryParamFromHistory, addQueryParamToHistory } from 'utils/history' ;
import { encodeObject, decodeBase64String } from 'utils';

import SnackbarService from 'services/snackbar';

import { Types as AppTypes } from 'core/app/types';
import { waitForUserSession } from 'core/app/sagas';
import { getActiveViews } from 'core/users/selectors';
import { fetchSavedUserViewsSlice } from 'core/users/slices';

import dashboardsActions from './actions';
import { QUERY_CONFIG_PARAM_NAME } from './constants';
import { getIsQueryConfigLoaded } from './selectors';
import { Types as DashboardsTypes, FetchDataResolvedPayload, GetConfigFromUrlResolvedPayload, Actions, Config } from './types';
import DashboardsService from './service';
import { getViewsValues, getDateRangeFilterObject } from './utils';

/*
 * Sagas
 */

function* init () {
  yield all([
    put(dashboardsActions.getConfigFromQuery()),
  ]);
}

function* getConfigFromQuery (): SagaIterator<void> {
  try {
    const queryConfig = getQueryParamFromHistory(QUERY_CONFIG_PARAM_NAME);

    if (queryConfig === null) {
      yield put(resolvedAction<DashboardsTypes, GetConfigFromUrlResolvedPayload>(DashboardsTypes.GET_CONFIG_FROM_QUERY, { data: null }));
      return;
    }

    yield call(waitForUserSession);
    yield delay(0);
    const data = decodeBase64String(queryConfig) as Config;

    // TODO: refactor to model
    let result = data;
    if (data.dateRangeRelativeFilter) {
      result = {
        ...data,
        dateRangeFilter: getDateRangeFilterObject(data.dateRangeRelativeFilter),
      };
    }

    yield put(resolvedAction<DashboardsTypes>(DashboardsTypes.GET_CONFIG_FROM_QUERY, {
      data: result,
    }));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);

    yield put(rejectedAction<DashboardsTypes>(DashboardsTypes.GET_CONFIG_FROM_QUERY, {
      message,
    }));
  }
}

function* setConfigToQuery ({ payload: { config } }: ReturnType<Actions['setConfigToQuery']>): SagaIterator<void> {
  try {
    const activeViews = yield select(getActiveViews);
    const savedViews = yield select(fetchSavedUserViewsSlice.selectors.getData);

    if (savedViews === null) {
      return;
    }

    const data = {
      ...config,
      views: getViewsValues(activeViews, savedViews),
    };

    addQueryParamToHistory(QUERY_CONFIG_PARAM_NAME, encodeObject(data));

    yield put(resolvedAction<DashboardsTypes>(DashboardsTypes.SET_CONFIG_TO_QUERY));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);

    yield put(rejectedAction<DashboardsTypes>(DashboardsTypes.SET_CONFIG_TO_QUERY, {
      message,
    }));
  }
}

function* fetchData ({ payload }: ReturnType<Actions['fetchData']>): SagaIterator<void> {
  try {
    yield call(waitForUserSession);

    const isQueryConfigLoaded = yield select(getIsQueryConfigLoaded);
    if (!isQueryConfigLoaded) {
      const [success]: Action<DashboardsTypes, GetConfigFromUrlResolvedPayload>[] = (yield race([
        take(resolved(DashboardsTypes.GET_CONFIG_FROM_QUERY)),
        take(rejected(DashboardsTypes.GET_CONFIG_FROM_QUERY)),
      ]));

      if (success?.payload.data) {
        return;
      }
    }

    const data = (yield call(DashboardsService.fetchData, payload)) as FetchDataResolvedPayload;
    const { tabKey } = payload;
    yield put(resolvedAction<DashboardsTypes, FetchDataResolvedPayload>(DashboardsTypes.FETCH_DATA, {
      ...data,
      tabKey,
    }));
  } catch (error) {
    const message = (error as Error).message;
    SnackbarService.showError(message);

    const { tabKey } = payload;

    yield put(rejectedAction<DashboardsTypes>(DashboardsTypes.FETCH_DATA, {
      message,
      tabKey,
    }));
  }
}

/*
 * Watchers
 */

function* initWatcher () {
  yield take(AppTypes.INIT);
  yield call(init);
}

function* getConfigFromQueryWatcher () {
  yield takeLatest(DashboardsTypes.GET_CONFIG_FROM_QUERY, getConfigFromQuery);
}

function* setConfigToQueryWatcher () {
  yield takeLatest(DashboardsTypes.SET_CONFIG_TO_QUERY, setConfigToQuery);
}

function* fetchDataWatcher () {
  yield takeLatest(DashboardsTypes.FETCH_DATA, fetchData);
}


export default [
  initWatcher,
  getConfigFromQueryWatcher,
  setConfigToQueryWatcher,
  fetchDataWatcher,
];
