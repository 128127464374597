import { useState, useCallback, useRef, FunctionComponent } from 'react';

import { TypographyProps } from '@mui/material/Typography';

import { PADDING_BASE } from 'containers/theme-provider/constants';

import { AbsoluteText, Typography, Wrapper, DEFAULT_PADDING_ABSOLUTE_TEXT, PAGE_OFFSET } from './styles';


const AdaptiveText: FunctionComponent<TypographyProps> = ({ children, align, ...otherProps }) => {
  const ref = useRef<HTMLElement | null>(null);

  const [isFullTextVisible, setIsFullTextVisible] = useState(false);
  const [offset, setOffset] = useState(DEFAULT_PADDING_ABSOLUTE_TEXT * PADDING_BASE);

  const showFullText = useCallback(() => { // TODO: extract somewhere?
    const { current } = ref;

    if (!current) {
      return;
    }

    const { offsetWidth, parentElement } = current;

    if (!parentElement) {
      return;
    }

    const { offsetWidth: parentOffsetWidth } = parentElement;

    const { x, width } = current.getBoundingClientRect();

    if (document.body.clientWidth - (PAGE_OFFSET * PADDING_BASE) < x + width) {
      const nextOffset = x + width;
      setOffset(Math.abs(document.body.clientWidth - nextOffset - (PAGE_OFFSET * PADDING_BASE)));
    }

    setIsFullTextVisible(offsetWidth > parentOffsetWidth);
  }, []);

  const hideFullText = useCallback(() => {
    setIsFullTextVisible(false);
  }, []);

  return (
    <Wrapper
      onMouseEnter={showFullText}
      onMouseLeave={hideFullText}
      $textAlign={align}
      $isFullTextVisible={isFullTextVisible}
    >
      {
        isFullTextVisible && (
          <AbsoluteText
            $offset={offset}
            {...otherProps}
          >
            {children}
          </AbsoluteText>
        )
      }

      <Typography
        {...otherProps}
        ref={ref}
      >
        {children}
      </Typography>
    </Wrapper>
  );
};


AdaptiveText.displayName = 'AdaptiveText';


export default AdaptiveText;
