import { MouseEvent } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { BreakdownOption, Breakdown } from 'core/settings/types';

import { Props as DataGridHeaderProps } from 'components/dashboard/grid-header/types';
import SelectableContainer from 'components/common/selectable-container';
import { DropdownChildAsFunctionProps } from 'components/common/dropdown/types';

import { BreakdownActionIconWrapper, ActionTitleWrapper } from './styles';


export const getBreakdownActions = (
  breakdownOptions: Breakdown[],
  selectedBreakdown: BreakdownOption | null,
  onBreakdownChange: (key: BreakdownOption | null) => void,
): DataGridHeaderProps['breakdownActions'] => ({
  isSearchable: false,
  items: breakdownOptions ? breakdownOptions.map(({ label, options }) => ({
    key: label, // TODO: is it correct ?
    title: label,
    renderContent: ({ title }) => {
      const isActive = Boolean(options.find((option) => option.key === selectedBreakdown?.key));

      return (
        <ActionTitleWrapper
          $isActive={isActive}
        >
          <BreakdownActionIconWrapper>
            <ChevronLeftIcon />
          </BreakdownActionIconWrapper>
          {title}
        </ActionTitleWrapper>
      );
    },
    onClick: () => {},
    disableRipple: true,
    nestedActions: {
      isSearchable: true,
      items: options.map((option) => ({
        key: option.key,
        title: option.label,
        renderContent: ({ title }) => (
          <SelectableContainer
            isSelected={selectedBreakdown?.key === option.key}
          >
            {title}
          </SelectableContainer>
        ),
        onClick: (event: MouseEvent<HTMLElement>, { close }: DropdownChildAsFunctionProps) => {
          onBreakdownChange(option);
          close();
        },
        disableRipple: true,
      })),
    },
  })) : [],
});
