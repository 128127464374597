import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { Box, Button, Chip, Skeleton, Stack, Typography } from '@mui/material';

import { actions as adminAppSettingsAction } from 'core/admin/appsettings/actions';
import { getAppSettings, getAppSettingsIsLoading } from 'core/admin/appsettings/selectors';
import { ROUTES } from 'containers/router/constants';

import AppSettingsRowAction from './row-action';


const AppSettingsIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appSettings = useSelector(getAppSettings);
  const isLoading = useSelector(getAppSettingsIsLoading);

  useEffect(() => {
    dispatch(adminAppSettingsAction.fetchAll());
  }, [dispatch]);

  const handleClickCreate = useCallback(() => {
    navigate(ROUTES.APPSETTINGS_CREATE);
  }, [navigate]);

  const columns = useMemo<GridColDef[]>(() => [
    {
      field: 'section',
      headerName: 'Section',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 300,
      flex: 1,
      renderCell: (params) => (
        <Stack
          direction='row'
          alignItems='center'
          gap='4px'
        >
          <Typography>{params.row.name}</Typography>
          {
            params.row.isDefault &&
              <Chip
                label="default"
                size='small'
              />
          }
        </Stack>
      ),
    },
    {
      field: 'value',
      headerName: 'Value',
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      renderCell: (params) => <AppSettingsRowAction appSetting={params.row} />,
      renderHeader: () => (
        <Button
          size="small"
          variant="contained"
          onClick={handleClickCreate}
        >Create</Button>
      ),
    },
  ], [handleClickCreate]);

  const LoadingSkeleton = useCallback(() => (
    <Box
      sx={{
        height: 'max-content',
      }}
    >
      {[...Array(10)].map((_, i) => (
        <Skeleton
          variant='rectangular'
          sx={{ my: 4, mx: 1 }}
          key={i}
        />
      ))}
    </Box>
  ), []);

  return (
    <div>
      <div style={{ height: 600, width: '100%' }}>
        <DataGridPro
          rows={isLoading ? [] : appSettings}
          columns={columns}
          autoHeight={true}
          disableColumnMenu
          loading={isLoading}
          components={{
            LoadingOverlay: LoadingSkeleton,
          }}
        />
      </div>
    </div>
  );
};

AppSettingsIndex.displayName = 'AppSettingsIndex';

export default AppSettingsIndex;
