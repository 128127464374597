import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { viewConfigurationSlice } from 'core/settings/slices';
import { ViewColumn } from 'core/settings/types';

import { isObjectDeepChanged } from 'utils';
import useOriginal from 'hooks/use-original';

import { DropdownChildAsFunctionProps } from 'components/common/dropdown/types';
import DashboardSavedViewsDropdown from 'components/dashboard/saved-views-dropdown';

import { getSavedViews } from './saved-views-config';

import FieldsList from './fields-list';

import {
  Wrapper,
  CloseButton,
  ContentWrapper,
  ColumnsConfigWrapper,
  Header,
  CreateViewButton,
  SubmitButton,
  SaveButton,
  SavedViewsDropdownWrapper,
  SavedViewsHeaderActionsWrapper,
} from './styles';
import { Props } from './types';
import { getActiveView } from './utils';
import { CATEGORIES } from './constants';


const DashboardSidebar: React.FunctionComponent<Props> = memo(({
  isOpen,
  type,
  views,
  activeView: initalActiveView,
  processedView,
  isViewsFetching,
  onActiveViewChange,
  onSetDefaultViewClick,
  onViewDeleteClick,
  onClose,
  onCancel,
  onApply,
  onSave,
  onCreateView,
}) => {
  const fields = useSelector(viewConfigurationSlice.selectors.getData)?.[type];

  const activeView = useMemo(() => getActiveView(initalActiveView, views, type), [initalActiveView, views, type]);

  const addedFields = useMemo(() => activeView.value.fields.map(({ key }) => key), [activeView]);

  const originalActiveView = useOriginal({
    value: activeView,
    getShouldSkipValue: (view, currentValue) => {
      if (!view?.value?.fields) {
        return true;
      }

      return !view.value.fields.length || Boolean(currentValue);
    },
  });

  const isActiveViewChanged = useMemo(() => {
    if (!activeView || !originalActiveView) {
      return false;
    }

    return isObjectDeepChanged(originalActiveView.value, activeView.value);
  }, [activeView]);

  const handleApply = useCallback(() => {
    onApply(activeView);
  }, [activeView, onApply]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const renderSavedViewsFooter = useCallback(({ close }: DropdownChildAsFunctionProps) => (
    <CreateViewButton
      as={Button}
      onClick={() => {
        close();
        onCreateView();
      }}
      variant="contained"
      color="primary"
    >
      Create new view
    </CreateViewButton>
  ), [onCreateView]);

  const viewsActions = useMemo(() => getSavedViews(
    views,
    activeView,
    isViewsFetching,
    processedView,
    onActiveViewChange,
    onViewDeleteClick,
    onSetDefaultViewClick,
  ), [
    views,
    activeView,
    isViewsFetching,
    processedView,
    onViewDeleteClick,
    onSetDefaultViewClick,
    onActiveViewChange,
  ]);
  const handleFieldAdd = useCallback((fieldKeys: string[]) => {
    const newFields = fieldKeys.map((fieldKey) => (fields || {})[fieldKey]);

    onActiveViewChange({
      ...activeView,
      value: {
        ...activeView.value,
        fields: [...(activeView.value.fields), ...newFields],
      },
    });
  }, [fields, activeView, onActiveViewChange]);

  const handleFieldDelete = useCallback((fieldKeys: string[]) => {
    onActiveViewChange({
      ...activeView,
      value: {
        ...activeView.value,
        fields: activeView.value.fields.filter(({ key }) => !fieldKeys.includes(key)),
      },
    });
  }, [activeView, onActiveViewChange]);

  const handleFieldsSort = useCallback((nextFields: ViewColumn[]) => {
    onActiveViewChange({
      ...activeView,
      value: {
        ...activeView.value,
        fields: nextFields,
      },
    });
  }, [activeView, onActiveViewChange]);

  const getIsFieldAdded = useCallback((field: ViewColumn) => addedFields.includes(field.key), [addedFields]);

  const handleSave = useCallback(() => {
    onSave(activeView);
  }, [onSave, activeView]);

  const metricFields = useMemo(() => Object.values(fields || {}).sort((fieldA, fieldB) => fieldA.name.localeCompare(fieldB.name)), [fields]);

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    }

    handleClose();
  }, [onCancel, handleClose]);

  const savedViewsDropdownButtonProps = useMemo(() => ({
    isRounded: true,
  }), []);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
    >
      <Wrapper>
        <Header
          as={Stack}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SavedViewsDropdownWrapper>
            <DashboardSavedViewsDropdown
              views={viewsActions}
              activeView={activeView}
              renderListFooter={renderSavedViewsFooter}
              buttonProps={savedViewsDropdownButtonProps}
            />
          </SavedViewsDropdownWrapper>

          <SavedViewsHeaderActionsWrapper
            as={Stack}
            direction="row"
            justifyContent="space-between"
          >
            <SaveButton
              as={Button}
              variant='text'
              color="primary"
              onClick={handleSave}
              disabled={!activeView.isCustom && !isActiveViewChanged}
            >
              Save view
            </SaveButton>

            <div>
              <SubmitButton
                as={Button}
                variant="contained"
                onClick={handleApply}
              >
                Show table
              </SubmitButton>

              <CloseButton
                as={IconButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </CloseButton>
            </div>
          </SavedViewsHeaderActionsWrapper>
        </Header>

        <ColumnsConfigWrapper>
          <ContentWrapper>
            <FieldsList
              title="Choose metrics"
              fields={metricFields}
              onAdd={handleFieldAdd}
              onDelete={handleFieldDelete}
              isAddable
              isDeletable
              getIsAdded={getIsFieldAdded}
              isSearchable
              categories={CATEGORIES}
              isFullListItemAction
              focusSearchOnMount
              isExpandAllAvailable
            />
          </ContentWrapper>

          <ContentWrapper>
            <FieldsList
              title="Current table view"
              fields={activeView.value.fields}
              onAdd={handleFieldAdd}
              onDelete={handleFieldDelete}
              isDeletable
              isDeleteAllAvailable
              isSortable
              onSort={handleFieldsSort}
            />
          </ContentWrapper>
        </ColumnsConfigWrapper>
      </Wrapper>
    </Drawer>
  );
});


DashboardSidebar.displayName = 'DashboardSidebar';


export default DashboardSidebar;
