import { styled } from '@mui/material/styles';

import { EXPAND_BUTTON_WIDTH } from 'components/common/expand-button/styles';


export const CollapseStyles = {
  position: 'relative',
  top: -20,
};

export const Divider = styled('div')(({ theme }) => ({
  height: 10,
  width: EXPAND_BUTTON_WIDTH,
  backgroundColor: theme.palette.common.white,
}));

export const ContentWrapper = styled('div')(({ theme }) => ({
  borderBottomRightRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.common.white,
}));

export const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[50],
  padding: theme.spacing(1.25, 2.5),
  borderBottomRightRadius: theme.shape.borderRadius,
  borderBottomLeftRadius: theme.shape.borderRadius,
}));

export const ButtonWrapper = styled('div')(({ theme }) => ({
  '* + *': {
    marginLeft: theme.spacing(2.5),
  },
}));

export const ResetButton = styled('div')(({ theme }) => ({
  color: theme.palette.grey[500],
}));
