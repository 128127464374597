import { Components } from '@mui/material/styles';


const overrides: Components['MuiTablePagination'] = {
  styleOverrides: {
    menuItem: { // NOTE: workaround to override styles for Data Grid Pagination
      minWidth: '0 !important',
      border: 'none !important',
      textAlign: 'center',
      justifyContent: 'center !important',
      marginLeft: -16,
      marginRight: -16,
    },
  },
};


export default overrides;
